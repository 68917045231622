/* globals Turbolinks */

import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {List} from 'immutable';

class MeetingInvitationNotification extends React.PureComponent {

    render() {

        return (
            <li onClick={this.props.onClick}>
                <img className='thumb-img' alt='' src={`/avatars/${this.props.data.signed_id}`}/>
                <div className='info'>
                    <span className='title'>
                        <span className='font-weight-bolder text-dark'>{ this.props.data.author }&nbsp;</span>
                        <span>{ this.props.message }</span>
                    </span>
                    <span className='sub-title'>
                        <i className='fa-light fa-alarm-clock mr-1' />{ this.props.age }
                    </span>
                </div>
            </li>
        )

    }

}

MeetingInvitationNotification.propTypes = {
    message: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    data: PropTypes.shape({
        author: PropTypes.string.isRequired,
        signed_id: PropTypes.string.isRequired
    }),
    onClick: PropTypes.func.isRequired
};

class DefaultNotification extends React.Component {

    render() {

        return (
            <li onClick={this.props.onClick}>
                <div className='info'>
                    <span className='title'>
                        { this.props.message }
                    </span>
                    <span className='sub-title'>{ this.props.age }</span>
                </div>
            </li>
        )

    }

}

DefaultNotification.propTypes = {
    message: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};


class ReadAllButton extends React.Component {

    handleClick = () => {

        axios.patch(this.props.notificationPath, {
            ids: this.props.notifications.map(n => n.id)
        },{
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        }).then((result) => {

            if(result.status !== 200) {
                return;
            }

            this.props.removeNotifications();

        });

    };

    render() {

        return (
            <li className='notice-footer text-gray' onClick={this.handleClick}>
                { this.props.markAsRead }
                <i className='fa-light fa-chevron-right pdd-left-5 font-size-10'/>
            </li>
        )

    }

}

ReadAllButton.propTypes = {
    notificationPath: PropTypes.string,
    markAsRead: PropTypes.string,
    notifications: PropTypes.object,
    removeNotifications: PropTypes.func
};


class Notifications extends React.Component {

    componentMap = {
        'general': DefaultNotification,
        'complete_profile': DefaultNotification,
        'invalid_documents': DefaultNotification,
        'meeting_invitation': MeetingInvitationNotification
    }

    state = {
        notifications: List()
    };

    componentDidMount() {
        axios.get(this.props.notificationPath, {
            headers: {
                'Accept': 'application/json'
            }
        }).then((result) => {

            if(result.status !== 200) {
                return;
            }

            this.setState({notifications: List(result.data)})

        });
    }

    clickNotification = (notification) => {

        axios.patch(this.props.notificationPath, {
            ids: [notification.id]
        },{
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        }).then((result) => {

            if(result.status !== 200) {
                return;
            }

            if(notification.path) {
                Turbolinks.visit(notification.path);
            }

        });

    };

    removeNotifications = () => {
        this.setState({notifications: this.state.notifications.clear()});
    };

    render() {

        const messages = this.state.notifications.size;

        if (messages === 0) {
            return null;
        }

        return (
                <React.Fragment>
                    <span className='counter' >{ messages }</span>
                    <a href='' className='dropdown-toggle' data-toggle='dropdown'>
                        <i className='fa-light fa-bell'/>
                    </a>
                    <ul className='dropdown-menu'>
                        <li className='notice-header'>
                            <i className='fa-light fa-bell pdd-right-10'/>
                            <span>{ this.props.title }</span>
                        </li>
                        <li>
                            <ul className='list-info overflow-y-auto relative scrollable'>
                                { this.state.notifications.map(notification =>
                                        {

                                            const Component = this.componentMap[notification.kind];

                                            return <Component key={notification.id} onClick={() => this.clickNotification(notification)} {...notification} />;

                                        }
                                    )
                                }
                            </ul>
                        </li>
                        <ReadAllButton markAsRead={ this.props.markAsRead } notificationPath={this.props.notificationPath} notifications={this.state.notifications} removeNotifications={this.removeNotifications}/>
                    </ul>
                </React.Fragment>

        )

    }

}

Notifications.propTypes = {
    notificationPath: PropTypes.string,
    title: PropTypes.string,
    markAsRead: PropTypes.string
};

export default Notifications;