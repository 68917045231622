import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import SelectBox from './SelectBox';
import Modal, {Dialog} from './Modal';
import DateTimePicker from './DateTimePicker';

const dateFormatter = new Intl.DateTimeFormat(document.documentElement.lang, {day: '2-digit', month: '2-digit', year: 'numeric'})

const years = () => {
    const currentYear = new Date().getFullYear() - 1
    return Array(currentYear - (currentYear - 3)).fill('').map((_, idx) => currentYear + idx)
}

const weekdays = (locale, weekdayFormat) => {
    const format = new Intl.DateTimeFormat(locale, { weekday: weekdayFormat }).format

    return [...Array(5).keys()].map((day) => (
        format(new Date(Date.UTC(2021, 11, day - 1)))
    ));
}

const dates = (year, month) => (new Array(31))
    .fill('')
    .map((_,idx) => new Date(year,month,idx + 1))
    .filter(d => d.getMonth() === month && d.getDay() !== 0 && d.getDay() !== 6)

const CalendarDate = ({index, employeeId, date, absence, onSelect}) => {
    const classes = absence ? 'absent' : null;

    if(!absence) {
        const endDate = new Date(date.getTime())
        endDate.setHours(23, 59, 59, 999)

        absence = {id: null, employeeId: employeeId, startsAt: date, endsAt: endDate}
    }

    return (
        <div key={date.getDate()} className={classes} style={index === 0 ? {gridColumn: date.getDay()} : null} onClick={() => onSelect(absence)}>{date.getDate()}</div>
    )
}

CalendarDate.propTypes = {
    index: PropTypes.number.isRequired,
    employeeId: PropTypes.number.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    onSelect: PropTypes.func.isRequired,
    absence: PropTypes.object
}

const CalendarSheet = ({year, month, children}) => {
    const locale = document.documentElement.lang

    return (
        <div className='sheet'>
            <div className='month'>
                { new Intl.DateTimeFormat(locale, { month: 'long', year: 'numeric'}).format(new Date(Date.UTC(year, month, 1))) }
            </div>
            <div className='day' style={{display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)'}}>
                { weekdays(locale,'short').map(day => <div key={day}>{day}</div> ) }
            </div>
            <div className='date' style={{display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)'}}>
                {
                    dates(year, month).map((date, idx) => children(date, idx))
                }
            </div>
        </div>
    )
}

CalendarSheet.propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired
}

const DateRangeDialog = ({absence, absences, onClose, onDelete}) => {
    const [startsAt, setStartsAt] = useState(new Date(absence.startsAt))
    const [endsAt, setEndsAt] = useState(new Date(absence.endsAt))
    const [isValid, setIsValid] = useState(true)

    useEffect(() => {
        if(startsAt > endsAt) {
            setEndsAt(startsAt)
        }
    }, [startsAt])

    useEffect(() => {
        let validity = true

        for (const d = new Date(startsAt.getTime()); d <= endsAt; d.setDate(d.getDate() + 1)) {
            const currentRange = d >= new Date(absence.startsAt) && d <= new Date(absence.endsAt)

            if(currentRange) {
                continue
            }

            const month = absences[d.getMonth()]
            const day = (month || {})[d.getDate()]

            if(day) {
                validity = false
                break
            }
        }

        setIsValid(validity)
    }, [startsAt, endsAt])

    const onSubmit = async (e) => {
        e.preventDefault()

        if(!e.target.reportValidity()) {
            return;
        }

        const method = absence.id ? 'PATCH' : 'POST'

        let url = `/administration/employees/${absence.employeeId}/absences`

        if(absence.id) {
            url = `${url}/${absence.id}`
        }

        const data = new FormData(e.target)

        const headers = {
            'Accept': 'application/javascript',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }

        const result = await axios({method, url, data, headers})

        if(result.status === 200) {
            onClose()
        }
    }

    const onChangeStartDate = (date) => {

        date.setHours(0, 0, 0, 0)
        setStartsAt(date)

    }

    const onChangeEndDate = (date) => {

        date.setHours(23, 59, 59, 999)
        setEndsAt(date)

    }

    return (
        <Dialog title="Abwesenheit einplanen" onClose={onClose}>
            {!isValid &&
                <div className="alert alert-danger" role="alert">
                    Gewählte Periode überschneidet sich mit einer anderen Abwesenheit
                </div>
            }
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col mb-2">
                        <label className="required">Art der Abwesenheit</label>
                        <SelectBox name="absence[kind]" required={true} items={[{value: 'vacation', title: 'Urlaub'}, {
                            value: 'sick_day',
                            title: 'Krankheit'
                        }]} value={absence.kind || 'vacation'}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="required">Startdatum</label>
                        <input className="form-control" type="text" name="absence[starts_at]" required readOnly={true}
                               value={dateFormatter.format(startsAt)}/>
                        <input type="hidden" name="absence[starts_at]" value={startsAt.toISOString()}/>
                        <DateTimePicker date={startsAt} displayTime={false} saveLabel="Speichern"
                                        onChange={onChangeStartDate}/>
                    </div>
                    <div className="col-6">
                        <label className="required">Enddatum</label>
                        <input className="form-control" type="text" name="absence[ends_at]" required readOnly={true}
                               value={dateFormatter.format(endsAt)}/>
                        <input type="hidden" name="absence[ends_at]" value={endsAt.toISOString()}/>
                        <DateTimePicker date={endsAt} displayTime={false} saveLabel="Speichern"
                                        onChange={onChangeEndDate}/>
                    </div>
                    {!absence.id &&
                        <input type="hidden" name="absence[employee_id]" value={absence.employeeId}/>
                    }
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <button type="submit" className="btn btn-primary" disabled={!isValid}>Übernehmen</button>
                    </div>
                    {absence.id &&
                        <div className="col-auto">
                            <button type="button" className="btn btn-danger" onClick={onDelete}>Entfernen</button>
                        </div>
                    }
                </div>
            </form>
        </Dialog>
    )

}

DateRangeDialog.propTypes = {
    absence: PropTypes.object,
    absences: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}

const Absences = () => {
    const [absences, setAbsences] = useState({})
    const [employees, setEmployees] = useState([])
    const [employeeId, setEmployeeId] = useState(null)
    const [year, setYear] = useState(new Date().getFullYear())
    const [absence, setAbsence] = useState(null)

    useEffect(() => {(async () => {
        const result = await axios.get('/administration/employees', {
            headers: { 'Accept': 'application/json' }
        })

        if(result.status === 200) {
            setEmployees(result.data)
        }
    })()}, [])

    useEffect(() => {(async () => {
        await fetchAbsences()
    })()}, [employeeId, year])

    const fetchAbsences = async () => {
        if(employeeId === null) { setAbsences({}); return }

        const result = await axios.get(`/administration/employees/${employeeId}/absences`, {
            headers: { 'Accept': 'application/json' },
            params: { year }
        })

        if(result.status === 200) {
            const dates = {}

            result.data.forEach(range => {

                for (let d = new Date(range.startsAt); d <= new Date(range.endsAt); d.setDate(d.getDate() + 1)) {
                    const month = d.getMonth()

                    if(!dates[month]) { dates[month] = {} }

                    dates[month][d.getDate()] = range
                }

            })

            setAbsences(dates)
        }
    }

    const onDeleteAbsence = async () => {
        const result = await axios.delete(`/administration/employees/${absence.employeeId}/absences/${absence.id}`, {
            headers: {
                'Accept': 'application/javascript',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(result.status === 200) {
            setAbsence(null)
            await fetchAbsences()
        }
    }

    const onCloseModal = async () => {
        setAbsence(null)
        await fetchAbsences()
    }

    return (
        <div className='absence-calendar'>
            <div className='row form'>
                <div className='col'>
                    <SelectBox placeholder='Mitarbeiter' includeEmpty={true} items={employees.map(e => ({value: e.id, title: e.fullName}))} value={employeeId} onChange={({value}) => setEmployeeId(value)} />
                </div>
                <div className='col'>
                    <SelectBox placeholder='Jahr' items={years().map(y => ({value: y, title: y}))} value={year} onChange={({value}) => setYear(value)} />
                </div>
            </div>
            { employeeId &&
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem'}}>
                    { [...Array(12).keys()].map(month => (
                        <CalendarSheet key={month} year={year} month={month} onSelectDate={setAbsence}>
                            {(date, idx) => <CalendarDate key={idx} employeeId={employeeId} date={date} index={idx} absence={(absences[month]||{})[date.getDate()]} onSelect={setAbsence} />}
                        </CalendarSheet>
                        )
                    )}
                </div>
            }
            <Modal isVisible={absence !== null}>
                <DateRangeDialog absence={absence} absences={absences} onClose={onCloseModal} onDelete={onDeleteAbsence} />
            </Modal>
        </div>
    )
}

export default Absences