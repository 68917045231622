import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Timer, Countdown} from './Examination';

class Choice extends React.PureComponent {

    timeout = null;

    state = {
        saved: null
    }

    componentWillUnmount() {
        this.unsetTimeout();
    }

    unsetTimeout = () => {

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

    }

    onChange = async () => {

        this.props.onChange(this.props.id);

        this.setState({saved: true});

        this.unsetTimeout();

        this.timeout = setTimeout(() => {
            this.setState({saved: null});
        }, 1000);

    }

    getMessage = () => {

        if(this.state.saved) {
            return <span className='badge badge-success ml-2'>Auswahl wurde gespeichert</span>;
        } else {
            return null;
        }

    }

    render() {

        return (
            <li>
                <div className={this.props.kind}>
                    <input id={`choice-${this.props.questionId}-${this.props.id}`} name={`choice-${this.props.questionId}`} type={this.props.kind} checked={this.props.checked} onChange={this.onChange} />
                    <label htmlFor={`choice-${this.props.questionId}-${this.props.id}`} style={{display: 'inline-block'}}>{this.props.name}{this.getMessage()}</label>
                </div>
            </li>
        )

    }

}

Choice.propTypes = {
    id: PropTypes.number.isRequired,
    questionId: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

class Question extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            choices: this.props.choices,
            selection: []
        }

    }

    onChange = (choiceId) => {

        let selection;

        if(this.props.kind === 'radio') {
            selection = [choiceId];
        } else {
            selection = this.state.selection.includes(choiceId) ? this.state.selection.filter(s => s !== choiceId) : [...this.state.selection, choiceId];
        }

        this.setState({selection});

    }

    render() {

        return (

            <div className='card'>
                <div className='card-heading border bottom'>
                    <h4 className='dropdown card-title'>
                        { this.props.title }
                        { this.props.help && this.props.help.length > 0 &&
                        <React.Fragment>
                            <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='help-tag float-right'>
                                <i className='fa-light fa-question-circle'/>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right dropdown-menu-help'>
                                <div className='card-body'>
                                    { this.props.help }
                                </div>
                            </div>
                        </React.Fragment>
                        }
                    </h4>
                    { this.props.kind === 'checkbox' &&
                    <div className='card-sub-title'>(Mehrfachauswahl möglich)</div>
                    }
                </div>
                <div className='card-body'>
                    <ul className='choices'>
                        { this.state.choices.map(choice => <Choice key={`${this.props.id}-${choice.id}`} questionId={this.props.id} kind={this.props.kind} checked={this.state.selection.includes(choice.id)} onChange={this.onChange} {...choice} />) }
                    </ul>
                </div>
            </div>

        )

    }

}

Question.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    help: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.object).isRequired,
    kind: PropTypes.string.isRequired
}

class DemoExamination extends React.PureComponent {

    container = React.createRef();

    initialState = {
        countdown: -10,
        showTimer: true,
        questions: [],
        isSaving: false,
        isProofPermitted: false,
        submitted: false
    }

    state = this.initialState;

    fetchData = async () => {

        const result = await axios.get(location.href, {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({...result.data});
        }

    }

    simulateSave = () => {

        this.setState({isSaving: true}, () => {

            setTimeout(() => {

                let state = this.initialState;
                state['submitted'] = true;

                this.setState({...state});

            }, 3000);

        });

    }

    resetExamination = () => {
        this.setState({submitted: false});
    }

    render() {

        if(this.state.submitted) {
            return (
                <React.Fragment>
                    <div className='alert alert-success'>
                        Die Beispielprüfung wurde erfolgreich gespeichert.
                    </div>
                    <div className='text-center pb-5'>
                        <button className='btn btn-primary mt-3' onClick={this.resetExamination}>
                            Prüfung erneut starten
                        </button>
                    </div>
                </React.Fragment>
            );
        }

        if(this.state.countdown < 0) {
            return <Countdown duration={this.state.countdown * -1} onExpired={this.fetchData} />;
        }

        return (

            <div className='row multiple-choice'>
                {this.state.isSaving &&
                    <div className='is-saving'>
                        <i className='fa-light fa-sync'/>
                    </div>
                }
                <div className='col-12 col-md order-2 order-md-1'>
                    <div ref={this.container} className='questions'>
                        { this.state.questions.map(question => <Question key={question.id} {...question} />) }
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='checkbox'>
                                <input id='permit-proof' type='checkbox' name='permit-proof' checked={this.state.isProofPermitted} onChange={() => this.setState({isProofPermitted: !this.state.isProofPermitted})} />
                                <label htmlFor='permit-proof'>Anfertigung des Prüfungsnachweises erlauben</label>
                                <p className='small'>
                                    Ihre Antworten werden, zusätzlich zu den auf dem Server gespeicherten Angaben, in Form eines Bildes abgespeichert.
                                    Im Falle eines Widerspruchs, können wir so sicherstellen, dass Ihre Antworten korrekt an den Server übertragen worden sind.
                                    Ohne diesen Nachweis, gelten alleine die auf dem Server gespeicherten Angaben.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            { this.state.questions.length > 0 &&
                            <button className='btn btn-success' onClick={this.simulateSave}>
                                {!this.state.isSaving ? 'Blockprüfung abschließen' : 'Wird gespeichert...'}
                            </button>
                            }
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-default' disabled={true}>Zurück</button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-auto order-1 order-md-2 mb-4 mb-md-0'>
                    <div className='sidebar'>
                        <h2 className='title'>
                            Verbleibende Zeit
                        </h2>
                        <Timer onClick={() => this.setState({showTimer: false})} onExpired={this.simulateSave} duration={this.state.countdown} />
                    </div>
                </div>
            </div>

        );

    }

}

export default DemoExamination;