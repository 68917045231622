import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {List} from 'immutable';
import SelectBox from './SelectBox';

class Participation extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            blockInitiallySelected: false,
            blockVersionId: props.blockVersionId,
            blockInstanceId: props.blockInstanceId,
            instances: List()
        }

    }

    fetchInstances = async (blockVersionId) => {

        const result = await axios.get(`/administration/block_instances`, {
            headers: {'Accept': 'application/json'},
            params: { block_version_id: blockVersionId }
        });

        if(result.status !== 200) {
            return List();
        }

        return List(result.data).map(i => { return {title: i.name, value: i.id} });

    }

    onChange = async ({value}) => {

        const valueChanged = this.state.blockVersionId !== value;

        if(!valueChanged && this.state.instances.size > 0) {
            return;
        }

        let instances = await this.fetchInstances(value);

        if(this.props.showCredited) {
            instances = instances.insert(0, {title: this.props.labelCredit, value: 0});
        }

        this.setState({blockInitiallySelected: true, blockVersionId: value, instances});

        if(this.instanceSelection && valueChanged) {
            this.instanceSelection.reset();
        }

    }

    onChangeInstance = ({value}) => {
        this.setState({blockInstanceId: value});
    }

    render() {

        return (
            <tr>
                <td>
                    <SelectBox name={this.props.blockVersionParam} required={true} value={this.state.blockVersionId} items={this.props.blockVersions.toJS()} onChange={this.onChange} />
                    {this.state.blockVersionId != null && this.state.instances.size > 0 &&
                        <SelectBox name={this.props.blockInstanceParam} required={true} value={this.props.blockInstanceId} items={this.state.instances.toJS()} ref={el => this.instanceSelection = el} onChange={this.onChangeInstance} />
                    }
                    {this.state.blockInitiallySelected && this.state.blockVersionId != null && this.state.instances.size === 0 && !this.props.showCredited &&
                        <div className='alert alert-warning mt-1'>
                            Keine Termine gefunden.
                            <input name={this.props.blockInstanceParam} required={true} style={{opacity: 0, width: 0, height: 0, float: 'left'}} />
                        </div>
                    }
                </td>
                {this.props.deletable &&
                    <td className='action'>
                        <button type='button' className='btn btn-icon btn-flat btn-rounded'
                                onClick={this.props.onClickDelete}>
                            <i className='fa-light fa-trash'/>
                        </button>
                    </td>
                }
            </tr>
        )

    }

}

Participation.propTypes = {
    blockVersionId: PropTypes.number,
    blockInstanceId: PropTypes.number,
    index: PropTypes.number.isRequired,
    blockVersions: PropTypes.instanceOf(List).isRequired,
    onClickDelete: PropTypes.func,
    deletable: PropTypes.bool.isRequired,
    showCredited: PropTypes.bool.isRequired,
    labelCredit: PropTypes.string,
    blockVersionParam: PropTypes.string.isRequired,
    blockInstanceParam: PropTypes.string.isRequired
}

Participation.defaultProps = {
    deletable: true,
    showCredited: true
}

class ParticipationAssignment extends React.PureComponent {

    state = {
        participation: List(),
        blockVersions: List()
    }

    async componentDidMount() {

        const result = await axios.get('/administration/block_versions', {headers: {'Accept': 'application/json'}});

        if(result.status !== 200) {
            return;
        }

        const blockVersions = List(result.data).map(b => { return {title: b.humanName, value: b.id} });

        let participation = List()

        if(this.props.blockVersions) {

            participation = participation.concat(this.props.blockVersions.map((b, idx) => (
                {blockVersionId: b, blockInstanceId: this.props.blockInstances ? this.props.blockInstances[idx] : 0}
            )))

        }

        if(!this.props.blockVersions && this.props.showEmpty) {
            participation = participation.push({blockId: 0, blockInstanceId: 0})
        }

        this.setState({blockVersions, participation});

    }

    onClick = () => {
        this.setState({participation: this.state.participation.push({blockId: 0, blockInstanceId: 0})});
    }

    removeParticipation = (idx) => {
        this.setState({participation: this.state.participation.remove(idx)});
    }

    render() {

        return (
            <React.Fragment>
                {
                    this.state.participation.map((p, idx) =>
                        (
                            <Participation key={idx} {...p}
                                           index={this.props.defaultBlocks + idx}
                                           blockVersions={this.state.blockVersions}
                                           deletable={!this.props.singleSelection}
                                           showCredited={this.props.showCredited}
                                           labelCredit={this.props.labelCredit}
                                           blockVersionParam={this.props.blockVersionParam}
                                           blockInstanceParam={this.props.blockInstanceParam}
                                           onClickDelete={() => this.removeParticipation(idx)}
                            />
                        )
                    )
                }
                {!this.props.singleSelection && this.props.tooltip.length > 0 &&
                    <tr>
                        <td className='border-top-0 pt-0' colSpan='2'>
                            <button type='button' onClick={this.onClick} className='btn btn-block btn-add-block'
                                    data-placement='top' data-toggle='tooltip' title={this.props.tooltip}>
                                <i className='fa-light fa-plus'/>
                            </button>
                        </td>
                    </tr>
                }
            </React.Fragment>
        )

    }
}

ParticipationAssignment.propTypes = {
    showEmpty: PropTypes.bool.isRequired,
    defaultBlocks: PropTypes.number,
    singleSelection: PropTypes.bool.isRequired,
    showCredited: PropTypes.bool.isRequired,
    labelCredit: PropTypes.string,
    tooltip: PropTypes.string,
    blockVersionParam: PropTypes.string.isRequired,
    blockInstanceParam: PropTypes.string.isRequired,
    blockVersions: PropTypes.arrayOf(PropTypes.number),
    blockInstances: PropTypes.arrayOf(PropTypes.number)
}

ParticipationAssignment.defaultProps = {
    showEmpty: false,
    defaultBlocks: 0,
    singleSelection: false,
    showCredited: true,
    blockVersionParam: 'forms_participation[participation][][block_version_id]',
    blockInstanceParam: 'forms_participation[participation][][block_instance_id]'
}

export default ParticipationAssignment;