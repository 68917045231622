import React from 'react'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list';
import deLocale from '@fullcalendar/core/locales/de';
import bootstrapPlugin from "@fullcalendar/bootstrap";
import axios from "axios";

const PersonalCalendarList = () => {
    let titleElement = null

    const fetchEvents = async ({startStr, endStr}) => {
        let data = []

        const result = await axios.get('/administration/employee/calendar_entries', {
            headers: {
              'Accept': 'application/json'
            },
            params: {
                start: startStr,
                end: endStr
            }
        })

        if (result.status === 200) {
            data = result.data.map(entry => (
                {title: entry.name, start: entry.startsAt, end: entry.endsAt, editable: false, backgroundColor: '#193280'}
            ))
        }

        return data
    }

    const onViewDidMount = ({view, el}) => {

        const toolbar = el.closest('.fc').querySelector('.fc-header-toolbar')

        titleElement = document.createElement('h2')
        titleElement.classList.add('fc-title')
        titleElement.innerText = view.title

        toolbar.after(titleElement)

    }

    const onDataSet = ({view}) => {
        if(!titleElement) { return }

        titleElement.innerText = view.title
    }

    return (
        <FullCalendar
            class
            locale={deLocale}
            themeSystem='bootstrap'
            height='auto'
            plugins={[listPlugin, bootstrapPlugin]}
            initialView='listWeek'
            views={{
                listDay: { buttonText: 'Tag' },
                listWeek: { buttonText: 'Woche' },
                listMonth: { buttonText: 'Monat' }
            }}
            headerToolbar={{
                left: 'prev,today,next',
                center: '',
                right: 'listDay,listWeek,listMonth'
            }}
            weekends={false}
            events={fetchEvents}
            nowIndicator={true}
            viewDidMount={onViewDidMount}
            datesSet={onDataSet}
        />
    )

}

export default PersonalCalendarList