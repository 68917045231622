import React, {useEffect, useRef, useState} from 'react'
import PropType from 'prop-types'
import SignaturePad from 'signature_pad';
import trimCanvas from 'trim-canvas'

const Signature = ({minHeight, scope}) => {
    const [dimensions, setDimensions] = useState({width: 0, height: 0})
    const [pad, setPad] = useState(null)
    const [signature, setSignature] = useState('')

    const wrapper = useRef()
    const canvas = useRef()

    const fieldName = scope === '' ? 'signature' : `${scope}[signature]`

    useEffect(() => {
        setDimensions({
            width: wrapper.current.offsetWidth,
            height: wrapper.current.offsetHeight
        })
    }, [])

    useEffect(() => {
        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.current.width = canvas.current.offsetWidth * ratio;
        canvas.current.height = canvas.current.offsetHeight * ratio;
        canvas.current.getContext("2d").scale(ratio, ratio);
        setPad(new SignaturePad(canvas.current))
    }, [dimensions])

    useEffect(() => {
        const form = wrapper.current.closest('form')

        form.addEventListener('submit', onSubmit, false)

        return () => {
            form.removeEventListener('submit', onSubmit, false)
        }
    }, [wrapper, pad])

    const onUndo = () => {
        const data = pad.toData()

        if(data) {
            data.pop()
            pad.fromData(data)
        }
    }

    const onSubmit = () => {
        if(pad.toData().length === 0) {
            return
        }

        const clonedCanvas = cloneCanvas()

        trimCanvas(clonedCanvas)

        setSignature(clonedCanvas.toDataURL())
    }

    const cloneCanvas = () => {
        const clone = document.createElement('canvas')

        clone.width = canvas.current.width
        clone.height = canvas.current.height

        clone.getContext('2d', {willReadFrequently: true}).drawImage(canvas.current, 0, 0)

        return clone
    }

    return <>
        <div ref={wrapper} className='signature-pad' style={{minHeight}}>
            <canvas ref={canvas} width={dimensions.width * 2} height={dimensions.height * 2}
                    style={{width: '100%', border: '1px solid #E6ECF5'}}/>
            <div className='mt-2'>
                <button type='button' className='btn btn-sm btn-secondary signature-pad-clear'
                        onClick={() => pad.clear()}>
                    <i className='fa-light fa-trash-can'/>
                </button>
                <button type="button" className="btn btn-sm btn-secondary signature-pad-clear" onClick={onUndo}>
                    <i className="fa-light fa-rotate-left"/>
                </button>
            </div>
        </div>
        { signature &&
            <input type='hidden' name={fieldName} value={signature} />
        }
    </>
}

Signature.propTypes = {
    minHeight: PropType.number.isRequired,
    scope: PropType.string.isRequired
}

export default Signature