import React, {useState, useEffect, useRef} from 'react'

class CircularBuffer {
    constructor(size) {
        this.size = size
        this.buffer = new Array(size)
        this.head = 0
        this.tail = 0
        this.count = 0
    }

    add(element) {
        this.buffer[this.tail] = element
        this.tail = (this.tail + 1) % this.size

        if (this.count < this.size) {
            this.count++
        } else {
            this.head = (this.head + 1) % this.size
        }
    }

    getBuffer() {
        let result = []
        for (let i = 0; i < this.count; i++) {
            result.push(this.buffer[(this.head + i) % this.size])
        }
        return result
    }
}

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60).toString(10)
    const remainder = Math.floor(seconds % 60).toString(10)

    return `${minutes.padStart(2, '0')}:${remainder.padStart(2, '0')}`
}

const ActivityIndicator = ({audioElement}) => {
    const buffer = new CircularBuffer(6)
    const [volumeLevels, setVolumeLevels] = useState(buffer.getBuffer())

    useEffect(() => {
        const audioContext = new window.AudioContext

        const analyser = audioContext.createAnalyser()
        analyser.connect(audioContext.destination)
        analyser.fftSize = 512

        const source = audioContext.createMediaElementSource(audioElement)
        source.connect(analyser)

        const data = new Uint8Array(analyser.frequencyBinCount)
        let requestId

        const updateBars = () => {
            analyser.getByteTimeDomainData(data)

            const sliceWidth = Math.floor(data.length / 6)

            let sum = 0
            for (let j = 0; j < sliceWidth; j++) {
                sum += data[sliceWidth + j]
            }

            const average = (sum / sliceWidth);
            const normalizedHeight = Math.pow(average / 128, 6) * 100
            const percentageHeight = Math.abs(normalizedHeight - 100) * 3

            buffer.add(Math.round(percentageHeight))
            setVolumeLevels(buffer.getBuffer())

            requestId = requestAnimationFrame(updateBars)
        };

        updateBars()

        return () => {
            (async () => {
                await audioContext.close()
                cancelAnimationFrame(requestId)
            })()
        }
    }, [])

    return <div className="audio-activity">
        {
            volumeLevels.map((barHeight, index) => <div key={index} className="bar" style={{height: `${barHeight}%`}}/>)
        }
    </div>
};

const AudioPlayer = ({src}) => {
    const audio = useRef()
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState("00:00")

    const onMetaDataLoaded = () => {
        setDuration(formatTime(audio.current.duration))
    }

    const onEnded = () => {
        setIsPlaying(false)
    }

    useEffect(() => {
        if(!audio.current) { return }

        if(audio.current.readyState > 0) {
            setDuration(formatTime(audio.current.duration))
        }

        audio.current.addEventListener('loadedmetadata', onMetaDataLoaded)
        audio.current.addEventListener('ended', onEnded)

        return () => {
            if(audio.current) {
                audio.current.removeEventListener('loadedmetadata', onMetaDataLoaded)
                audio.current.removeEventListener('ended', onEnded)
            }
        }
    }, [audio])

    const togglePlayback = () => {
        setIsPlaying(!isPlaying)

        if(!isPlaying) {
            audio.current.muted = false
            audio.current.volume = 1.0
            audio.current.play()
        } else {
            audio.current.pause()
            audio.current.fastSeek(0)
        }
    };

    return <div className={`play-stop-button ${isPlaying ? "expanded" : ""}`} onClick={togglePlayback}>
        <audio ref={audio} src={src} preload='metadata' crossOrigin='anonymous' muted={true} />
        <i className={`fa-solid ${isPlaying ? 'fa-stop' : 'fa-play'}`} style={{fontSize: '12px', color: 'gray'}} />
        {isPlaying &&
                <>
                    <ActivityIndicator audioElement={audio.current} />
                    <span className="duration">{duration}</span>
                </>
        }
    </div>

}

export default AudioPlayer