import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import LoadingPreview from './LoadingPreview';

export const ImagePreview = ({file, loadingFailed, print, showControls}) => {
    const [image, setImage] = useState(null)
    const [scale, setScale] = useState(100)
    const [rotation, setRotation] = useState(0)

    const SCALE_UP = 10
    const SCALE_DOWN = -10

    const canvas = useRef()
    const wrapper = useRef()

    const obtainDimensions = () => {

        const bounds = wrapper.current.getBoundingClientRect();

        let width = image.width,
            height = image.height;

        if(bounds.width < width && (rotation === 0 || rotation === 180)) {

            const ratio = bounds.width / width;

            width = width * ratio;
            height = height * ratio;

        }

        if(bounds.width < height && (rotation === 90 || rotation === 270)) {

            const ratio = bounds.width / height;

            width = width * ratio;
            height = height * ratio;

        }

        return {width: width, height: height};

    };

    const loadImage = () => {

        const img = new Image();
        img.src = file.url;

        img.onload = () => {
            setImage(img)
        }

        img.onerror = () => {
            setImage(null)
        }

    }

    const drawImage = () => {

        if(!canvas.current) {
            return
        }

        const context = canvas.current.getContext('2d');

        context.save();

        let {width, height} = obtainDimensions();

        context.clearRect(0,0, width, height);

        width = width * (scale / 100);
        height = height * (scale / 100);

        if(rotation === 90 || rotation === 270) {

            // noinspection JSSuspiciousNameCombination
            canvas.current.width = height;
            // noinspection JSSuspiciousNameCombination
            canvas.current.height = width;

            context.translate(height / 2,width /2);

        } else {

            canvas.current.width = width;
            canvas.current.height = height;

            context.translate(width / 2,height /2);

        }

        context.rotate(rotation * Math.PI / 180);

        context.drawImage(image,-width / 2,-height / 2, width, height);

        context.restore();

    };

    const updateScale = (step) => {
        if((scale === 10 && step === -10) || (scale === 300 && step === 10)) {
            return;
        }

        setScale(s => s + step)
    };

    const rotate = () => {
        setRotation(r => (r + 90) % 360)
    };

    const resetView = () => {
        setScale(100)
        setRotation(0)
    };

    useEffect(() => {
        loadImage()
    }, [])

    useEffect(() => {
        if(!image) { return }

        drawImage()
    }, [image, rotation, scale])

    return (
        <React.Fragment>
            <div className='preview-wrapper' ref={wrapper}>
                { image !== null &&
                    <canvas className='image-canvas' ref={canvas} />
                }
                { image === null &&
                    <LoadingPreview message={loadingFailed} failed={true} />
                }
            </div>
            { image !== null && showControls &&

                <div className='controls-wrapper'>
                    <ul className='controls'>
                        <li className='print' onClick={() => print(file)}/>
                        <li className='download' onClick={() => window.location.href = file.downloadUrl} />
                        <li className='zoom-in' onClick={() => updateScale(SCALE_UP)} />
                        <li className='zoom-out' onClick={() => updateScale(SCALE_DOWN)} />
                        <li className='rotate' onClick={rotate} />
                        <li className='reset' onClick={resetView} />
                    </ul>
                </div>

            }
        </React.Fragment>
    );

}

ImagePreview.propTypes = {
    file: PropTypes.object,
    print: PropTypes.func,
    loadingFailed: PropTypes.string,
    showControls: PropTypes.bool
};

ImagePreview.defaultProps = {
    showControls: true
}

export default ImagePreview;