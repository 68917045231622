/* globals Turbolinks */

import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import qs from 'qs';
import FileTypeIcon from './FileTypeIcon';
import FileSizeFormatter from './FileSizeFormatter';

export class DocumentItem extends React.PureComponent {

    viewFile = () => {

        if(!this.props.file.viewed) {
            this.props.fileViewed();
        }

        const queryParameters = [];

        if(this.props.documentId) {
            queryParameters.push(`document=${this.props.documentId}`)
        }

        queryParameters.push(`index=${this.props.index}`);

        Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(`${location.href.split('?')[0]}?${queryParameters.join('&')}`, Turbolinks.uuid())

        // pushState does not trigger an event, so create a synthetic one
        const pushStateEvent = new CustomEvent('querychange', {detail: {}});
        window.dispatchEvent(pushStateEvent);

    };

    render() {

        return (
            <li onClick={this.viewFile}>
                <div className='file'>
                    <div className='file-name' >
                        <FileTypeIcon type={this.props.file.contentType}/>
                        <div className='title'>{this.props.file.name}</div>
                    </div>
                    <div className='file-information'>
                        <FileSizeFormatter size={parseInt(this.props.file.size,10)}/>
                    </div>
                </div>
                {this.props.showViewedIcon && this.props.file.viewed && <i className='checked' />}
            </li>
        );

    }

}

DocumentItem.propTypes = {
    documentId: PropTypes.number,
    index: PropTypes.number.isRequired,
    file: PropTypes.object.isRequired,
    showViewedIcon: PropTypes.bool.isRequired,
    fileViewed: PropTypes.func
};

class DocumentList extends React.PureComponent {

    constructor(props) {

        super(props);

        const files = this.props.document.files.map((file) => { return {name: file.name, size: file.size, contentType: file.contentType, viewed: false}});

        this.state = {
            filesViewed: 0,
            document: this.props.document,
            files: List(files),
        };

    }

    componentDidMount() {

        const currentFile = this.getCurrentFile();

        const [id, idx] = currentFile;

        if(this.props.document.id === id || (id === 0 && this.props.index === 0)) {
            this.fileViewed(idx);
        }

    }

    getCurrentFile = () => {

        const parameters = qs.parse(location.search.slice(1));

        const id = (parameters.document) ? parseInt(parameters.document, 10) : 0;
        const index = (parameters.index) ? parseInt(parameters.index, 10) : 0;

        return [id, index];

    };

    fileViewed = (idx) => {

        const files = this.state.files.update(idx, file => {

            file.viewed = true;
            return file;

        });

        this.setState({files: files, filesViewed: this.state.filesViewed + 1});

        if(this.props.onFileViewed) {
            this.props.onFileViewed(idx);
        }

    };

    render() {

        return (
            <div className='card'>
                <div className='card-heading border bottom'>
                    <h4 className='card-title inline-block'>
                        {this.props.title}
                    </h4>
                    {this.props.controls}
                </div>
                {this.props.children}
                <div className='card-body'>
                    <ul className='file-list'>
                        {this.state.files.map((file, idx) => <DocumentItem key={idx} documentId={this.props.document.id} index={idx} file={file} showViewdIcon={this.props.showViewedIcon} fileViewed={() => this.fileViewed(idx)} />)}
                    </ul>
                </div>
            </div>
        )

    }

}

DocumentList.propTypes = {
    index: PropTypes.number,
    title: PropTypes.string.isRequired,
    document: PropTypes.object.isRequired,
    controls: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    showViewedIcon: PropTypes.bool.isRequired,
    onFileViewed: PropTypes.func
};

DocumentList.defaultProps = {
    index: 0,
    showViewedIcon: true,
}

export default DocumentList;