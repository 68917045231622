import React from 'react';
import PropTypes from 'prop-types';

class TimePicker extends React.PureComponent {

    constructor(props) {

        super(props);

        this.hourInput = React.createRef();

        this.regularExpressions = {
            hours: /^[0-1]?[0-9]$|^2[0-3]$/,
            minutes: /^[0-5]?[0-9]$/,
        }

        this.state = {
            hours: this.props.date.getHours(),
            minutes: this.props.date.getMinutes(),
        }

    }

    componentDidMount() {
        this.hourInput.current.focus();
    }

    componentDidUpdate(_prevProps, prevState) {

        if(this.state.hours === prevState.hours && this.state.minutes === prevState.minutes) {
            return;
        }

        if(this.props.onChange) {
            this.props.onChange(this.state);
        }

    }

    update = (field, e) => {

        let value = parseInt(e.target.value, 10) || 0;

        if(this.regularExpressions[field].test(value)) {
            this.setState({[field]: value});
        }

    }

    mod = (n, m) => {
        return ((n % m) + m) % m;
    }

    step = (field, step) => {

        let updatedValue = this.state[field] + step;

        if(field === 'hours') {
            updatedValue = this.mod(updatedValue, this.props.hourCycle)
        } else {
            updatedValue = this.mod(updatedValue, 60)
        }

        this.setState({[field]: updatedValue});

    }

    render() {

        const hours = `0${this.state.hours}`.slice(-2)
        const minutes = `0${this.state.minutes}`.slice(-2)

        return (

            <div className='time-input'>
                <div className='hour'>
                    <div className='time-input-label'>Stunde</div>
                    <div className='input-with-controls'>
                        <input type='text' ref={this.hourInput} value={hours} onChange={this.update.bind(this, 'hours')} tabIndex='1'/>
                        <div className='controls'>
                            <button type='button' className='up' onClick={() => this.step('hours', 1)} />
                            <button type='button' className='down' onClick={() => this.step('hours', -1)} />
                        </div>
                    </div>
                </div>
                <div className='minute'>
                    <div className='time-input-label'>Minute</div>
                    <div className='input-with-controls'>
                        <input type='text' value={minutes} onChange={this.update.bind(this, 'minutes')} tabIndex='2' />
                        <div className='controls'>
                            <button type='button' className='up' onClick={() => this.step('minutes', 1)} />
                            <button type='button' className='down' onClick={() => this.step('minutes', -1)} />
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}

TimePicker.propTypes = {
    locale: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    hourCycle: PropTypes.number.isRequired,
    onChange: PropTypes.func
}

TimePicker.defaultProps = {
    locale: document.documentElement.lang,
    hourCycle: 24,
    date: new Date()
}

export default TimePicker;