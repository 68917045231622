import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import axios from 'axios';
import SelectBox from './SelectBox';
import Modal, {Dialog} from './Modal';

const MeasureForm = ({jobCenterId, measures, onSave}) => {
    const [certifications, setCertifications] = useState(List())
    const [locations, setLocations] = useState(List())

    useEffect(() => {(async () => {
        let result = await axios.get('/administration/certifications', {
            headers: {'Accept': 'application/json'}
        })

        if(result.status === 200) {
            setCertifications(List(result.data))
        }

        result = await axios.get('/administration/locations', {
            headers: {'Accept': 'application/json'}
        })

        if(result.status === 200) {
            setLocations(List(result.data))
        }
    })()}, [])

    const onSubmitMeasure = async (e) => {
        e.preventDefault()

        if(!e.target.reportValidity()) {
            return;
        }

        const data = new FormData(e.target)

        data.append('measure[job_center_id]', jobCenterId)

        const result = await axios.post('/administration/measures', data, {
            headers: {
                'Accept': 'application/javascript',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(result.status === 200) {
            onSave(result.data)
        }
    }

    return (
        <form onSubmit={onSubmitMeasure}>
            <div className='form-group'>
                <label className='required'>Standort</label>
                <SelectBox name='measure[location_id]' required={true} items={locations.toJS()} />
            </div>
            <div className='form-group'>
                <label className='required'>Zertifizierung</label>
                <SelectBox name='measure[certification_id]' required={true} items={certifications.map(c => ({value: c.id, title: `[${c.abbreviation}] ${c.name}`})).toJS()} />
            </div>
            <div className='form-group'>
                <label className='required'>Vergleichbare alternative Maßnahmennummer</label>
                <SelectBox name='measure[alternate_measure_id]' required={true} items={measures.map(m => ({value: m.id, title: m.number})).toJS()} />
            </div>
            <button type='submit' className='btn btn-primary'>Beantragen</button>
        </form>
    )

}

MeasureForm.propTypes = {
    jobCenterId: PropTypes.number,
    onSave: PropTypes.func.isRequired,
    measures: PropTypes.objectOf(List)
}

const EducationVoucherSelection = ({formScope, voucherOptions, voucherOption, jobCenterId, showMeasure, ...props}) => {
    const measureSelection = useRef()

    const [jobCenters, setJobCenters] = useState(List())
    const [jobCenter, setJobCenter] = useState({})
    const [measures, setMeasures] = useState(List())
    const [measureId, setMeasureId] = useState(props.measureId || '')

    const [hasVoucher, setHasVoucher] = useState(voucherOption === 'yes' || voucherOption === 'requested')
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {(async () => {
        const result = await axios.get('/administration/job_centers', {
            headers: {'Accept': 'application/json'}
        })

        if(result.status === 200) {
            setJobCenters(List(result.data))
        }

        await updateMeasures()
    })()}, [])

    useEffect(() => {(async () => {
        setMeasureId(props.measureId)
    })()}, [props.measureId])

    const updateMeasures = async () => {
        const result = await axios.get('/administration/measures', {
            headers: {'Accept': 'application/json'}
        })

        if(result.status === 200) {
            setMeasures(List(result.data))
        }
    }

    const getJobCenter = (id) => jobCenters.find(j => j.id === id) || {}

    useEffect(() => {
        setJobCenter(getJobCenter(jobCenterId))
    }, [jobCenters, jobCenterId])

    const onChangeVoucher = ({value}) => {
        setHasVoucher(value !== 'no')
    }

    const onChangeMeasure = ({value}) => {
        if(value !== -1) { return }

        setShowModal(true)
    }

    const onCloseModal = () => {
        measureSelection.current.reset();

        setShowModal(false)
    }

    const onSaveMeasure = async ({id}) => {
        setShowModal(false)

        await updateMeasures()

        setMeasureId(id)
    }

    const filteredMeasures = measures.filter(m => m.jobCenterId === jobCenter.id)

    return (
        <React.Fragment>
            <div className='form-group'>
                <label id='label_education_voucher' className='required'>Bildungsgutschein</label>
                <SelectBox name={`${formScope}[education_voucher]`} required={true} items={voucherOptions.map(o => ({value: o[1], title: o[0]}))} value={voucherOption} onChange={onChangeVoucher} />
                <input type='hidden' name={`${formScope}[job_center_id]`} value='' />
                <input type='hidden' name={`${formScope}[measure_id]`} value='' />
            </div>
            { hasVoucher &&
                <div className='row pdd-btm-30'>
                    <div className='col-md-6'>
                        <label id='label_job_center_id' className='required'>Arbeitsagentur</label>
                        <SelectBox name={`${formScope}[job_center_id]`} required={true} includeEmpty={true} items={jobCenters.map(j => ({value: j.id, title: `[${j.serviceNumber}] ${j.name}`})).toJS()} value={jobCenter.id} onChange={({value}) => setJobCenter(getJobCenter(value))} />
                    </div>
                    {showMeasure &&
                        <div className='col-md-6'>
                            <label id='label_measure_id' className='optional-required'>Maßnahmennummer</label>
                            <SelectBox ref={measureSelection} name={`${formScope}[measure_id]`} includeEmpty={true}
                                       disabled={Object.keys(jobCenter).length === 0}
                                       items={filteredMeasures.map(m => ({value: m.id, title: m.number})).push({value: -1, title: 'Maßnahmenummer beantragen'}).toJS()}
                                       onChange={onChangeMeasure}
                                       value={measureId}/>
                        </div>
                    }
                </div>
            }
            <Modal isVisible={showModal}>
                <Dialog title='Maßnahmenummer beantragen' onClose={onCloseModal}>
                    <MeasureForm jobCenterId={jobCenter.id} measures={measures} onSave={onSaveMeasure} />
                </Dialog>
            </Modal>
        </React.Fragment>
    )
}

EducationVoucherSelection.propTypes = {
    formScope: PropTypes.string.isRequired,
    voucherOptions: PropTypes.array.isRequired,
    voucherOption: PropTypes.string,
    jobCenterId: PropTypes.number,
    measureId: PropTypes.number,
    showMeasure: PropTypes.bool.isRequired
}

EducationVoucherSelection.defaultProps = {
    showMeasure: true
}

export default EducationVoucherSelection