import React from 'react';
import PropTypes from 'prop-types';

const Placeholder = ({selector, label, timeout}) => {

    if (document.querySelector(selector)) {
        return null
    }

    setTimeout(() => location.reload(), timeout * 1_000);

    return (
        <div className='fa-2x' style={{display: 'flex', justifyContent: 'center'}}>
            <p>
                <i className='fa-solid fa-sync fa-spin' />
                &nbsp;
                {label}
            </p>
        </div>
    )
}

Placeholder.propTypes = {
    selector: PropTypes.string.isRequired,
    label: PropTypes.string,
    timeout: PropTypes.number,
};

Placeholder.defaultProps = {
    timeout: 3,
    label: 'Warte auf Dokument...'
}

export default Placeholder