import React, {useEffect, useState} from 'react'
import axios from 'axios';
import Select from 'react-select';
import SearchSelect from './SearchSelect';

const euroFormatter = new Intl.NumberFormat(document.documentElement.lang, {style: "currency", currency: "EUR"})
const dateFormatter = new Intl.DateTimeFormat(document.documentElement.lang, {day: "2-digit", month: "2-digit", year: "numeric"})

const resetTime = (d) => {
    d.setHours(0, 0, 0, 0)
    return d
}

const parseDate = (d) => resetTime(new Date(d))

const InvoiceLine = ({line, onChange, disabled = false, checked = false, showDetails = false}) => {
    const [isChecked, setIsChecked] = useState(checked)
    const isReconciling = Object.prototype.hasOwnProperty.call(line, "creditedAmountCents")

    let productBadge

    if(line.productType === 'BlockVersion') {
        productBadge = <span className="badge badge-info mr-2">Block</span>
    } else {
        productBadge = <span className="badge badge-dark mr-2">Zertifikat</span>
    }

    useEffect(() => {
        if(!onChange) { return }

        onChange({ ...line }, isChecked)
    }, [line, isChecked])

    const onClickCheckbox = () => {
        if(disabled && !isChecked) { alert("Betrag bereits erschöpft") }
    }

    return <tr className={isChecked ? 'del' : ''}>
        {onChange &&
            <td className="check">
                <div className="checkbox">
                    <input type="checkbox" id={`line_${line.id}`} onChange={() => setIsChecked(!isChecked)} checked={isChecked} disabled={disabled && !isChecked} />
                    <label htmlFor={`line_${line.id}`} onClick={onClickCheckbox}></label>
                </div>
            </td>
        }
        <td className="kind">{productBadge}</td>
        <td>
            {line.name}
            {showDetails &&
                <>
                    <br />
                    <small>{line.student} | {line.invoiceNumber}</small>
                </>
            }
        </td>
        {isReconciling &&
            <>
                <td className="debit">{line.creditedAmountCents > 0 ? euroFormatter.format(line.creditedAmountCents/ 100.0) : null}</td>
                <td className="credit">{0 > line.creditedAmountCents ? euroFormatter.format(line.creditedAmountCents / 100.0) : null}</td>
            </>
        }
        {!isReconciling &&
            <>
                <td className="debit"></td>
                <td className="credit">{
                    (0 > line.remainingAmountCents ? euroFormatter.format(line.remainingAmountCents / 100.0) : null) ||
                    (line.remainingAmountCents > 0 ? euroFormatter.format(line.remainingAmountCents / 100.0) : null)}
                </td>
            </>
        }
    </tr>
}

const BankStatementReconciliation = () => {
    const [lines, setLines] = useState([])
    const [currentLine, setCurrentLine] = useState(0)
    const [student, setStudent] = useState(null)
    const [invoices, setInvoices] = useState([])
    const [invoicesLines, setInvoicesLines] = useState([])
    const [invoice, setInvoice] = useState(null)
    const [reconciliations, setReconciliations] = useState([])

    useEffect(() => {
        (async () => {
            await fetchLines()
        })()
    }, []);

    useEffect(() => {
        if(!student) { return }

        (async () => {
            const response = await axios.get(`/administration/students/${student.value}/invoices`, {
                headers: { 'Accept': 'application/json' }
            })

            if(response.status === 200) {
                setInvoices(response.data)
                setInvoicesLines([])
            }
        })()
    }, [student])

    useEffect(() => {
        if(!student || !invoice) { return }

        (async () => {
            const response = await axios.get(`/administration/students/${student.value}/invoices/${invoice.value}`, {
                headers: { 'Accept': 'application/json' }
            })

            if(response.status === 200) {
                setInvoices([response.data])
            }
        })()
    }, [student, invoice])

    useEffect(() => {
        if (invoices.length === 0) { return }

        setInvoicesLines(invoices.reduce((ary, inv) => ary.concat(inv.lines.filter(l => l.remainingAmountCents > 0).map(l => ({
            ...l,
            invoiceNumber: inv.number,
            student: student.label
        }))), []))
    }, [invoices])

    const fetchLines = async () => {
        const url = new URL('/administration/accounting/bank_statement_lines', location.href)

        url.searchParams.append('query[][column]', 'scope')
        url.searchParams.append('query[][operator]', 'eq')
        url.searchParams.append('query[][value]', 'incoming')
        url.searchParams.append('query[][column]', 'state')
        url.searchParams.append('query[][operator]', 'eq')
        url.searchParams.append('query[][value]', 'new')

        const response = await axios.get(url.toString(), {
            headers: { 'Accept': 'application/json' }
        })

        if (response.status === 200) {
            setLines(response.data)
        }
    }

    const navigate = (step) => {
        let nextLine = currentLine + step

        if(0 > nextLine) {
            nextLine = lines.length - 1
        } else if (nextLine > lines.length - 1) {
            nextLine = 0
        }

        setCurrentLine(nextLine)

        reset()
    }

    const reset = () => {
        setStudent(null)
        setInvoices([])
        setInvoicesLines([])
        setInvoice(null)
        setReconciliations([])
    }

    const ignoreLine = async () => {
        const line = lines[currentLine]

        const response = await axios.patch(`/administration/accounting/bank_statement_lines/${line.id}/ignore`)

        if(response.status !== 200) {
            alert('status der buchung konnte nicht geändert werden')
        } else {
            await fetchLines()
        }
    }

    const reconcileLine = async () => {
        const line = lines[currentLine]

        const response = await axios.patch(`/administration/accounting/bank_statement_lines/${line.id}/reconcile`,
            {
                payments: reconciliations.map(r => ({invoice_line_id: r.id, amount_cents: r.creditedAmountCents, amount_currency: r.netCurrency}))
            }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(response.status === 200) {
            reset()
            await fetchLines()
        }
    }

    const queryStudents = async (value, callback) => {
        if(2 > value.length) { callback([]) }

        const url = URL.parse('/administration/students', location.href)
        url.searchParams.set('search', value)

        const response = await axios.get(url, {
            headers: { 'Accept': 'application/json' }
        })

        callback(response.data.map(user => ({
            value: user.id,
            label: user.name
        })))
    }

    if(lines.length === 0) {
        return <div className="alert alert-info">Es sind keine zu buchenden Bankumsätze vorhanden.</div>
    }

    const line = lines[currentLine]
    const bookingAmount = Math.abs(line.amountCents * -1)
    const balance = (line.amountCents * -1) + reconciliations.reduce((sum, l) => {
        return sum + l.creditedAmountCents
    }, 0)

    const onLineChange = (l, isChecked) => {
        let lines

        if(isChecked) {
            lines = [...reconciliations, l]
        } else {
            lines =  [...reconciliations.filter(ln => ln.id !== l.id)]
        }

        lines = lines.map((ln, idx, lns) => {
            const lineBalance =  ((line.amountCents * -1) + lns.slice(0, idx).reduce((sum, ln) => sum + ln.remainingAmountCents, 0)) * -1

            let creditedAmountCents = ln.remainingAmountCents

            if(creditedAmountCents > lineBalance) {
                creditedAmountCents = lineBalance
            }

            return {...ln, creditedAmountCents}
        })

        setReconciliations(lines)
    }

    return <>
        <div className="card">
            <div className="card-heading card-component d-flex align-items-center border bottom">
                <h4 className="card-title flex-grow-1 d-flex align-items-center">Zahlung abgleichen</h4>
                <div className="card-controls">
                    <span className="badge badge-primary">Buchung {currentLine + 1} von {lines.length}</span>
                </div>
            </div>
            <div className="card-body">
                <div className="card card-reconciliation">
                    <div className="card-header">Buchungsdetails</div>
                    <div className="card-body">
                        Buchungsdatum: <b>{dateFormatter.format(parseDate(line.valueDate))}</b><br/>
                        Auftraggeber: <b>{line.name}</b><br/>
                        Verwendungszweck: <b>{line.reference}</b><br/>
                        <span className={`badge badge-large ${0 > line.amountCents ? 'badge-danger' : 'badge-success'} mt-2`}>Betrag: {euroFormatter.format(line.amountCents / 100.0)}</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group">
                        <label htmlFor="student">Teilnehmer</label>
                            <SearchSelect id='student' loadOptions={queryStudents} value={student} onChange={setStudent}
                                          messages={{noOptions: 'Keine Teilnehmer gefunden...', loading: 'Suche Teilnehmer...'}}
                            />
                        </div>
                    </div>
                    {student && invoices.length > 0 &&
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="student">Rechnung</label>
                                <Select id="contract" options={invoices.map(i => ({value: i.id, label: i.number}))}
                                        placeholder={null} value={invoice}
                                        components={{IndicatorSeparator: () => null}} styles={SearchSelect.styles}
                                        onChange={setInvoice}
                                />
                            </div>
                        </div>
                    }
                </div>
                { student && invoicesLines.length === 0 &&
                    <div className="alert alert-info">
                        Keine offenen Rechnungszeilen gefunden.
                    </div>
                }
                { invoicesLines.length > 0 &&
                    <div className='form-group'>
                        <label for='reconcile-table'>Nicht abgeglichene Rechnungszeilen</label>
                        <table id='reconcile-table' className="table table-sm table-reconciliation">
                            <tbody>
                            {
                                invoicesLines.map(
                                    l => <InvoiceLine key={l.id} line={l} onChange={onLineChange} disabled={0 >= balance * -1} checked={reconciliations.find(r => r.id === l.id)} />
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                }
                { reconciliations.length > 0 &&
                    <>
                        <div className="text-bold mt-5 mb-2">Zugeordnete Rechnungszeilen</div>

                        <table className="table table-sm table-reconciliation">
                            <thead>
                                <tr>
                                    <th colSpan="2">Buchung</th>
                                    <th className="text-right">Soll</th>
                                    <th className="text-right">Haben</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="name" colSpan="2">Bankumsatz</td>
                                    <td className="debit">{0 > line.amountCents ? euroFormatter.format(bookingAmount / 100.0) : null}</td>
                                    <td className="credit">{line.amountCents > 0 ? euroFormatter.format(bookingAmount / 100.0) : null}</td>
                                </tr>
                                {
                                    reconciliations.map((l) => {
                                        return <InvoiceLine key={l.id} line={l} showDetails={[...new Set(reconciliations.map(r => r.student))].length > 1} />
                                    })
                                }
                            </tbody>
                            <tfoot>
                            <tr>
                                <td className="name" colSpan="2">Summe</td>
                                <td className="debit">{balance > 0 ? euroFormatter.format(balance / 100.0) : null}</td>
                                <td className="credit">{0 >= balance ? euroFormatter.format((Math.abs(balance)) / 100.0) : null}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </>
                }
                <div className="row mt-4">
                    <div className="col">
                        <button className="btn btn-success  mb-0"
                                disabled={balance !== 0}
                                onClick={() => reconcileLine()}>
                            Umsatz zuweisen
                        </button>
                        <button className="btn btn-danger mb-0"
                                onClick={() => ignoreLine()}>
                            Umsatz ignorieren
                        </button>
                    </div>
                    <div className="col-auto">
                        <div className="btn-group">
                            <button className="btn btn-default mb-0" title="Vorheriger Bankumsatz"
                                    onClick={() => navigate(-1)}>
                                <i className="fal fa-arrow-left"></i>
                            </button>
                            <button className="btn btn-default mb-0" title=" Nächster Bankumsatz"
                                    onClick={() => navigate(1)}>
                            <i className='fal fa-arrow-right'></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}

export default BankStatementReconciliation

