import React from 'react';
import axios from 'axios';

class TerminationReasons extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            show: false,
            terminations: [],
        }

    }

    showTerminationStatistics = async () => {

        axios.get(
            '/administration/statistics/termination_reasons', {
                headers: {'Accept': 'application/json'}
            })
            .then((result) => {

                if(result.status !== 200) {
                    return;
                }

                this.setState({
                    terminations: result.data,
                    show: true
                });

            });

    }

    render() {
        return (
            <React.Fragment>
                <button type='button' onClick={this.showTerminationStatistics} data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='btn btn-link p-0 mb-0 mr-2 '>
                    <i className='fa-light fa-chart-bar'/>
                </button>
                <div className='dropdown-menu'>
                    <div className='card-body'>
                        {this.state.show &&
                            <table className='table'>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th className='text-center'>Absolut</th>
                                    <th className='text-right'>Prozentual</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.terminations.map((termination, idx) =>
                                    <tr key={idx}>
                                        <td>
                                            {termination.reason}
                                            <a href={termination.href} className='ml-2'>
                                                <i className='fa-light fa-search'/>
                                            </a>
                                        </td>
                                        <td className='text-center'>{termination.absolute}</td>
                                        <td className='text-right'>{termination.percentage}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        }

                        {!this.state.show &&
                            <p className='text-muted'>Daten werden geladen</p>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default TerminationReasons;