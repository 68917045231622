import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import SelectBox from './SelectBox';

class MeetingParticipations extends React.PureComponent {

    selectBox = null;
    selection = null;

    state = {
        participants: [],
        students: []
    }

    async componentDidMount() {
        await this.refresh();
    }

    refresh = async () => {

        let result = await axios.get('/students/meeting_participations', {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({students: result.data});
        }

        result = await axios.get(`/students/meeting_participations/${this.props.identifier}`, {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({participants: result.data});
        }

    }

    onChange = (item) => {
        this.selection = item;
    }

    addParticipant = async () => {

        if(!this.selection) {
            return;
        }

        const result = await axios.post(`/students/meeting_participations`, {user_id: this.selection.value}, {
            headers: {
                'Accept': 'application/javascript',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if(result.status === 200) {
            await this.refresh();
        }

        if(this.state.students.length > 0) {
            this.selectBox.reset();
            this.selection = null;
        }

    }

    removeParticipant = async (participant) => {

        const result = await axios.delete(`/students/meeting_participations/${participant.id}`, {
            headers: {
                'Accept': 'application/javascript',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if(result.status === 200) {
            await this.refresh();
        }

    }

    render() {

        return (

            <React.Fragment>
                { this.state.students.length > 0 &&
                    <div className='row'>
                        <div className='col'>
                            <SelectBox ref={e => this.selectBox = e} disableInput={true} placeholder={this.props.placeholder} items={this.state.students} onChange={this.onChange} />
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-primary' type='button' onClick={this.addParticipant}>{this.props.addStudent}</button>
                        </div>
                    </div>
                }
                { this.state.participants.length > 0 &&
                    <div className='table-responsive'>
                        <table className='table'>
                            <tbody>
                            {
                                this.state.participants.map(p =>
                                    (
                                        <tr key={p.id}>
                                            <td>{p.full_name}</td>
                                            <td className='text-right p-0'>
                                                <button className='btn btn-icon btn-flat btn-rounded mb-0 mr-0 mt-1' onClick={() => this.removeParticipant(p)}>
                                                    <i className='fa-light fa-trash'/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                }
            </React.Fragment>

        )

    }

}

MeetingParticipations.propTypes = {
    identifier: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    addStudent: PropTypes.string.isRequired
}

export default MeetingParticipations;