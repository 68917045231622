import React from 'react';
import PropType from 'prop-types';
import {useState} from 'react';
import SelectBox from './SelectBox';
import DropZone from './DropZone';
import InformationBoxWithVideo from './InformationBoxWithVideo';

const WizardHomeOfficeApplication = ({submitLater: _submitLater = '', labels, files = []}) => {
    const [submitLater, setSubmitLater] = useState(_submitLater)

    return <>
        <div className='form-group'>
            <label className='required' htmlFor='home_office_application_submit_later'>{labels.submitLater}</label>
            <SelectBox id='home_office_application_submit_later' name='home_office_application[submit_later]' required={true}
                       items={[{title: "\u00A0", value: ''}, {title: labels.notAvailable, value: true}, {title: labels.available, value: false}]} value={submitLater}
                       onChange={({value}) => setSubmitLater(value)}
            />
        </div>
        { submitLater === false &&
            <>
                <div className="form-group">
                    <label id="label_files" htmlFor="home_office_application_files" className="required">
                        {labels.files}
                    </label>
                    <DropZone description={labels.uploadDescription} buttonLabel={labels.uploadButtonLabel}
                              multiple={true} required={true} name="home_office_application[files][]" files={files}
                    />
                </div>
            </>
        }
    </>
}

WizardHomeOfficeApplication.propTypes = {
    submitLater: PropType.bool,
    labels: PropType.shape({
        submitLater: PropType.string,
        files: PropType.string,
        uploadDescription: PropType.string,
        uploadButtonLabel: PropType.string,
        information: PropType.string,
        notAvailable: PropType.string,
        available: PropType.string
    }),
    files: PropType.arrayOf(PropType.shape({
        id: PropType.string,
        name: PropType.string,
        type: PropType.string,
        size: PropType.number
    }))
}

export default WizardHomeOfficeApplication