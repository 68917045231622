import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ComposedChart, XAxis, YAxis, Tooltip, Line, ResponsiveContainer, CartesianGrid, Bar } from 'recharts';

class ParticipationChart extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            data: [],
            keyMap: {},
            loading: false
        };

    }

    async componentDidMount() {

        const result = await axios.get(
            '/administration/charts/courses', {
                headers: {'Accept': 'application/json'}
            });

        if(result.status !== 200) {
            return;
        }

        const keyMap = {};

        result.data.forEach(d => {
            keyMap[d.identifier] = d.name;
        });

        this.setState({
            data: result.data,
            keyMap: keyMap
        });

    }

    formatter = (value, name, props) => {

        if(props.dataKey === 'percentage') {
            return [`${value}%`, name];
        } else {
            return [value, name];
        }

    }

    labelFormatter = (identifier) => {
        return `[${identifier}] ${this.state.keyMap[identifier]}`;
    }

    tickFormatter = (value) => {
        return `${value}%`;
    }

    render() {

        return (
            <div className='card'>
                <div className='card-heading border bottom'>
                    <h4 className='card-title'>{this.props.title}</h4>
                </div>
                <div className='card-body'>
                    <ResponsiveContainer height={350}>
                        <ComposedChart data={this.state.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='identifier' height={80} interval={0} tick={{angle: -45, textAnchor: 'end'}}/>
                            <YAxis yAxisId='left' dataKey='course_count' domain={[0, 'dataMax']}/>
                            <YAxis yAxisId='right' tickFormatter={this.tickFormatter} dataKey='percentage' orientation='right' domain={[0, 100]}/>
                            <Tooltip formatter={this.formatter} labelFormatter={this.labelFormatter}/>
                            <Bar yAxisId='left' name='Teilnehmer' dataKey='course_count' fill='#5664A4' />
                            <Line yAxisId='right' name='Kumulierter Anteil' type='monotone' dataKey='percentage' stroke='#ff8c00' strokeWidth={2} dot={false} activeDot={{ r: 5 }} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );

    }

}

ParticipationChart.propTypes = {
    resourcePath: PropTypes.string,
};

export default ParticipationChart;