import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    ComposedChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    Bar,
    ResponsiveContainer,
    CartesianGrid,
    ReferenceLine
} from 'recharts';

class CumulatedBlockStartsChart extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            data: [],
            loading: false
        };

    }

    async componentDidMount() {

        const result = await axios.get(
            '/administration/charts/cumulated_block_starts', {
                headers: {'Accept': 'application/json'}
            });

        if(result.status !== 200) {
            return;
        }

        this.setState({ data: result.data });

    }

    dateFormatter = (date)  => {
        return new Date(date).toLocaleString(document.documentElement.lang, {year: 'numeric', month: '2-digit', day: '2-digit'});
    }

    render() {

        return (
            <div className='card'>
                <div className='card-heading border bottom'>
                    <h4 className='card-title'>{this.props.title}</h4>
                </div>
                <div className='card-body'>
                    <ResponsiveContainer height={350}>
                        <ComposedChart data={this.state.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey='date' height={80} tick={{angle: -45, textAnchor: 'end'}} interval={0} tickFormatter={this.dateFormatter} />
                            <YAxis domain={[0, 'dataMax']}/>
                            <Tooltip labelFormatter={this.dateFormatter}/>
                            <Bar dataKey='cumulated_participation_count' name='Blockteilnahmen kumuliert' stackId='participants' fill='#888da8' />
                            <Bar dataKey='existing_participation_count' name='Blockteilnahmen laufender Bildungsmaßnahmen' stackId='participants' fill='#5664A4' />
                            <Bar dataKey='new_participation_count' name='Blockteilnahmen neuer Bildungsmaßnahmen' stackId='participants' fill='#51AE2F' />
                            <Line name='Zielvorgabe' type='monotone' dataKey='target' stroke='#ff8c00' strokeWidth={2} dot={false} activeDot={{ r: 5 }} />
                            <ReferenceLine y={2505} label={{position: 'bottom',  value: 'Jahresziel'}} ifOverflow='extendDomain' stroke='#ff8c00' strokeWidth={2} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        );

    }

}

CumulatedBlockStartsChart.propTypes = {
    resourcePath: PropTypes.string,
    title: PropTypes.string.isRequired
};

export default CumulatedBlockStartsChart;