import React from 'react';
import PropTypes from 'prop-types';
import Plyr from 'plyr';
import Bowser from "bowser";
import axios from 'axios';

class PlyerVideo extends React.PureComponent {

    constructor(props) {

        super(props);

        this.plyerElement = React.createRef();

        // Check if mobile for smaller videos
        this.userAgent = Bowser.parse(window.navigator.userAgent);

        if(this.userAgent.platform.type === 'mobile') {

            this.videoPath = this.props.videoMobilePath;
            this.imagePath = this.props.imageMobilePath;

        } else {

            this.videoPath = this.props.videoWebPath;
            this.imagePath = this.props.imageWebPath;

        }

    }

    componentDidMount() {

        this.player = new Plyr(this.plyerElement.current, {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
            resetOnEnd: true
        });

        this.player.on('ready', () => {

            if(!this.props.capturePreview) { return }

            const controls = document.querySelector('.plyr__controls');

            if (!controls) { return }

            const button = document.createElement('button')
            button.classList.add('plyr__control')

            const icon = document.createElement('i')
            icon.classList.add('fas', 'fa-camera')

            button.appendChild(icon)
            controls.appendChild(button)

            icon.addEventListener('click', async () => {
                const response = await axios.post(`${location.href}/snapshot`, {
                    time: this.player.currentTime
                }, {
                    headers: {
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    }
                })

                if(response.status === 200) {
                    alert('Die Generierung des Vorschaubildes wurde gestartet, dieser Vorgang kann bis zu 30 Sekunden andauern.')
                } else {
                    alert('Fehler beim erstellen des Vorschaubildes.')
                }
            });

        })

        if(this.props.autoplay === true) {
            this.player.play();
        }

    }

    render() {

        return (
            <video ref={this.plyerElement} poster={this.imagePath} playsInline={true} controls={true}>
                <source src={this.videoPath} type="video/mp4"/>
            </video>
        )

    }

}

PlyerVideo.propTypes = {
    autoplay: PropTypes.bool,
    videoWebPath: PropTypes.string.isRequired,
    videoMobilePath: PropTypes.string.isRequired,
    imageWebPath: PropTypes.string,
    imageMobilePath: PropTypes.string,
    capturePreview: PropTypes.bool
};

export default PlyerVideo;