import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import SelectBox from './SelectBox';

class KickOffListViewer extends React.PureComponent {

    state = {
        dates: [],
        date: null,
        participants: []
    }

    async componentDidMount() {

        const result = await axios.get('/lecturers/kick_off_lists', {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({dates: result.data});
        }

    }

    componentDidUpdate(_prevProps, prevState) {

        if(prevState.date !== this.state.date) {
            this.fetchParticipants();
        }

    }

    fetchParticipants = async () => {

        const result = await axios.get(`/lecturers/kick_off_lists/${this.state.date.value}`, {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({participants: result.data});
        }

    }

    render() {

        return (

            <React.Fragment>
                <div className='card'>
                    <div className='card-body'>
                        <SelectBox disableInput={true} placeholder={this.props.translations.placeholder} items={this.state.dates} onChange={(item) => this.setState({date: item})} />
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        { this.state.participants.length === 0 &&
                            <p>{this.props.translations.empty_list}</p>
                        }
                        { this.state.participants.length > 0 &&
                            <div className='table-responsive'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>{this.props.translations.name}</th>
                                            <th>{this.props.translations.program}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.participants.map((participant) => {

                                            return (
                                                <tr key={participant.id}>
                                                    <td>{participant.student.name}</td>
                                                    <td>{participant.name}</td>
                                                </tr>
                                            )

                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>

        )

    }

}

KickOffListViewer.propTypes = {
    translations: PropTypes.object.isRequired
}

export default KickOffListViewer;