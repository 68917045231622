import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from "@fullcalendar/bootstrap";
import deLocale from '@fullcalendar/core/locales/de';
import enLocale from '@fullcalendar/core/locales/es-us';

class Holiday extends React.PureComponent {

    render() {
        return (
            <span className='event-content'>
                <i className='fa-light fa-calendar ml-2' />
                <span className='event-title ml-2' title={this.props.title}>{this.props.title}</span>
            </span>
        );
    }

}

Holiday.propTypes = {
    title: PropTypes.string
}

class Lecture extends React.PureComponent {
    render() {

        let icon, topic, title

        if(this.props.isAuditor) {
            icon = 'fa-shield'
            title = `${this.props.title} | Audit`
            topic = this.props.topic
        } else {
            icon = 'fa-users-class'
            title = this.props.title
            topic = this.props.topic
        }

        return (
            <span className="event-content">
                <i className={`fa-light ${icon} ml-2`}/>
                <span className="d-flex flex-column">
                    <span className="event-title ml-2 mb-2" title={this.props.title}><b>{title}</b></span>
                    <span className="event-title ml-2">{topic}</span>
                </span>
            </span>
        );
    }

}

Lecture.propTypes = {
    title: PropTypes.string,
    meetingUrl: PropTypes.string,
    isAuditor: PropTypes.bool,
    topic: PropTypes.string
}

class ExcuseDay extends React.PureComponent {

    render() {
        return (
            <span className='event-content'>
                <i className='fa-light fa-alarm-clock' />
                <span className='event-title ml-2' title={this.props.title}>{this.props.title}</span>
            </span>
        );
    }

}

ExcuseDay.propTypes = {
    title: PropTypes.string,
    excused: PropTypes.bool
}

class CoursePlan extends React.PureComponent {

    renders = 0;

    state = {
        events: [],
        loading: false
    }

    components = {
        lecture: Lecture,
        excuse_day: ExcuseDay,
        holiday: Holiday
    };

    fetchEvents = async (info, success, failure) => {

        const query = qs.stringify({start: info.start, end: info.end});

        let result;

        try {

            result = await axios.get(`${this.props.resourcePath}?${query}`, {headers: {'Accept': 'application/json'}});

        } catch(e) {

            if(e.response.status === 401) {
                location.replace('/');
            }

            failure(e);
            return;

        }

        if(result.status !== 200) {

            failure();
            return;

        }

        success(result.data);

        this.setState({events: result.data});

    }

    eventContent = ({event}) => {

        const { type, ...properties } = event.extendedProps;

        if(Object.prototype.hasOwnProperty.call(this.components, type)) {

            const EventComponent = this.components[type];
            return <EventComponent title={event.title} {...properties} />;

        }

        let details = event.title;

        if(Object.prototype.hasOwnProperty.call(event.extendedProps, 'details')) {
            details = event.extendedProps.details;
        }

        if(event.allDay) {

            const title = event.classNames.includes('auditor') ? `Audit | ${event.title}` : event.title;

            return <span title={details} className="event-content">
                { event.classNames.includes('auditor') && <i className="fa-solid fa-shield ml-2"/> }
                <span className="event-title ml-2" title={title}>{title}</span>
            </span>

        }

        let time;
        let now = new Date();
        let isNow = false;

        if (event.end) {
            isNow = now > event.start && now < event.end
            time = `${event.start.toLocaleTimeString(document.documentElement.lang, {hour: '2-digit', minute: '2-digit'})} - ${event.end.toLocaleTimeString(document.documentElement.lang, {hour: '2-digit', minute: '2-digit'})}`
        } else {
            time = event.start.toLocaleTimeString(document.documentElement.lang, {hour: '2-digit', minute: '2-digit'})
        }

        if(isNow) {
            time = <span style={{display: 'list-item', listStyleType: 'disc', listStylePosition: 'inside', color: '#51AE2F', cursor: 'pointer'}}>{time}</span>
        }

        return <>
            <div className='fc-event-time'>
                {time}
            </div>
            <div className='fc-event-title' title={details} style={isNow ? {cursor: 'pointer'} : null}>
                {event.title}
            </div>
        </>;

    }

    render() {

        return <FullCalendar
            plugins={[dayGridPlugin, bootstrapPlugin]}
            locales={[deLocale, enLocale]}
            locale={document.documentElement.lang}
            themeSystem='bootstrap'
            headerToolbar={{
                left: 'title',
                center: '',
                right: 'prev,today,next'
            }}
            hiddenDays={[0, 6]}
            eventContent={this.eventContent}
            events={this.fetchEvents}
            eventOrder={['priority']}
            eventClick={(info) => {

                const {type} = info.event.extendedProps;

                if(type !== 'lecture' && type !== 'consultation_hour') {
                    return;
                }

                info.jsEvent.preventDefault();

                if (info.event.url) {
                    window.open(info.event.url,'_blank','noreferrer');
                }

            }}
        />;

    }

}

CoursePlan.propTypes = {
    resourcePath: PropTypes.string,
};

export default CoursePlan;