import React from 'react';
import PropTypes from 'prop-types';

class Lightbox extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            isVisible: props.isVisible
        }

    }

    componentDidUpdate(prevProps) {

        if(prevProps.isVisible !== this.props.isVisible) {

            this.setState({
                isVisible: this.props.isVisible
            });

        }

    }

    close = () => {
        this.setState({ isVisible: false }, this.props.onClose);
    }

    render() {

        return (
            <div className={`lightbox ${this.props.classes}`} hidden={!this.state.isVisible}>
                <div className='content'>
                    <button type='button' className='btn-close' onClick={this.close} title='Schließen'/>
                    {this.props.children}
                </div>

            </div>
        )

    }

}

Lightbox.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    children: PropTypes.node,
    classes: PropTypes.string,
    onClose: PropTypes.func
};

Lightbox.defaultProps = {
    isVisible: false
}

export default Lightbox;