/* globals $ */

import React, {useState, useEffect, useRef} from 'react';
import PropType from 'prop-types';
import axios from 'axios';
import {List} from 'immutable';
import SelectBox from './SelectBox';

const BadgeIndicator = ({selection, cardinality}) => {
    let color = 'info';

    if(cardinality - selection === 0)
        color = 'success'
    else if (cardinality - selection === 1)
        color= 'primary'

    return (
        <span className={`badge badge-${color}`} title='Treffergenauigkeit'>{selection}/{cardinality}</span>
    )
}

BadgeIndicator.propTypes = {
    selection: PropType.number.isRequired,
    cardinality: PropType.number.isRequired
}

const CourseSelection = ({isCustom, onChangeIsCustom, estimatedStartAt, ...props}) => {
    const [courses, setCourses] = useState([])
    const [courseId, setCourseId] = useState(props.courseId)

    useEffect(() => {(async() => {
        if(!estimatedStartAt) {
            setCourses([])
            return
        }

        const response = await axios.get('/administration/course_versions', {
            headers: { 'Accept': 'application/json' },
            params: { 'date': estimatedStartAt }
        })

        if(response.status === 200) {
            setCourses(response.data.map(c => ({title: c.name, value: c.courseId})))
        }
    })()}, [estimatedStartAt])

    useEffect(() => {
        if(props.courseId) { setCourseId(props.courseId) }
    }, [props.courseId])

    if(isCustom) { return null }

    return <>
        <div className="form-group">
            {!isCustom &&
                <div className="row">
                    <div className="col">
                        <label>Gewünschte Weiterbildung</label>
                    </div>
                    <div className="col-auto">
                        <div className="checkbox p-0">
                            <input type="checkbox" id="course-wizard-custom" name="consultation_protocol[is_custom]"
                                   defaultValue="0" onChange={() => onChangeIsCustom(!isCustom)} checked={isCustom} disabled={estimatedStartAt === null}/>
                            <label htmlFor="course-wizard-custom">Individuell</label>
                        </div>
                    </div>
                </div>
            }
            {isCustom &&
                <label>Gewünschte Weiterbildung</label>
            }
            <SelectBox name='consultation_protocol[course_id]' items={courses} value={courseId}
                       onChange={({value})=> setCourseId(value)} disabled={estimatedStartAt === null}
            />
        </div>
    </>
}

CourseSelection.propTypes = {
    isCustom: PropType.bool.isRequired,
    onChangeIsCustom: PropType.func.isRequired,
    courseId: PropType.number,
    blockStarts: PropType.arrayOf(PropType.shape({title: PropType.string, value: PropType.string})),
    estimatedStartAt: PropType.string
}

const HelpTag = ({description}) => {
    let dropdownRef = useRef()

    const onClick = (e) => {
        e.preventDefault()
        e.stopPropagation()

        $(dropdownRef.current).dropdown('toggle')
    }

    return (
        <a ref={dropdownRef} className="help-tag" aria-haspopup="true" aria-expanded='false' href='#' onClick={onClick}>
            <i className='fa-light fa-question-circle' />
            <div className='dropdown-menu dropdown-menu-right dropdown-menu-help'>
                <div className='card-body'>
                    {description}
                </div>
            </div>
        </a>
    )
}

HelpTag.propTypes = {
    description: PropType.string.isRequired
}

const Suggestion = ({course, selection, setCourseId}) => {
    const [showDescription, setShowDescription] = useState(false)

    return (
        <tr key={course.id}>
            <td className='name'>
                {course.name}
                {course.link &&
                    <a href={course.link} className='ml-1' target='_blank' rel='noreferrer'>
                        <i className='fa-light fa-search'/>
                    </a>
                }
                { showDescription &&
                    <div className='description'>
                        {course.description}
                    </div>
                }
            </td>
            <td className='info'>
                { course.description &&
                    <i className='fa-light fa-info-circle' style={{cursor: 'pointer'}} onClick={() => setShowDescription(!showDescription)} />
                }
            </td>
            <td className='counter'>
                <BadgeIndicator selection={selection.length} cardinality={course.cardinality}/>
            </td>
            <td className='select'>
                <button className='btn btn-xs btn-primary m-0' type='button' onClick={() => setCourseId(course.id)}>Auswählen</button>
            </td>
        </tr>
    )
}

Suggestion.propTypes = {
    course: PropType.object.isRequired,
    selection: PropType.array.isRequired,
    setCourseId: PropType.func.isRequired
}

const CourseBuilder = ({isCustom, onChangeIsCustom, setCourseId, ...props}) => {
    if(!isCustom) { return null }

    const [blocks, setBlocks] = useState(List())
    const [courses, setCourses] = useState(List())
    const [selection, setSelection] = useState([])
    const selectBox = useRef()
    const titleInput = useRef()

    if(titleInput.current) {
        titleInput.current.focus()
    }

    useEffect(() => {(async () => {
        const result = await axios.get('/administration/blocks', {
            headers: { 'Accept': 'application/json' },
            params: { 'valid_to': props.estimatedStartAt }
        })

        if(result.status === 200) {
            let data = List(result.data)

            setBlocks(data)

            if(props.selection) {
                setSelection(props.selection.map(id => data.find(b => b.id === id)))
            }
        }
    })()}, [])

    useEffect(() => {(async () => {
        if(0 >= selection.length) {
            setCourses(List())
            return
        }

        const result = await axios.get('/administration/courses/block_assignments', {
            headers: { 'Accept': 'application/json' },
            params: {
                block_ids: selection.map(s => s.id)
            }
        })

        if(result.status === 200) {
            setCourses(List())
            setCourses(List(result.data))
        }
    })()}, [selection])

    const selectBlock = ({value}) => {
        const block = blocks.find(b => b.id === value)

        setSelection([...selection, block])

        selectBox.current.reset()
    }

    const deselectBlock = (block) => setSelection(selection.filter(b => b.id !== block.id))

    const selectableBlocks = blocks.filterNot(b => selection.some(s => s.id === b.id)).map(b => ({value: b.id, title: `[${b.key}] ${b.name}`})).toJS()

    return (
        <>
            <div className='row'>
                <div className='col'>
                    <label className='required'>Titel</label>
                </div>
                <div className='col-auto'>
                    <div className='checkbox p-0'>
                        <input type='checkbox' id='course-wizard-custom' name='consultation_protocol[is_custom]' defaultValue='1' onChange={() => onChangeIsCustom(!isCustom)} checked={isCustom} />
                        <label htmlFor='course-wizard-custom'>Individuell</label>
                    </div>
                </div>
            </div>
            <div className='form-group'>
                <input ref={titleInput} type='text' name='consultation_protocol[title]' className='form-control' defaultValue={props.title} />
            </div>
            <div className='form-group'>
                <label>Blockauswahl</label>
                <SelectBox ref={selectBox} includeEmpty={true} items={selectableBlocks} onChange={selectBlock} />
            </div>
            {selection.length > 0 &&
                <div className='form-group'>
                    <label>Ausgewählte Blöcke</label>
                    <ul className='list-group'>
                        {
                            selection.map(block => {
                                return (
                                    <li key={block.id} className='list-group-item d-flex justify-content-between align-items-center'>
                                        {`[${block.key}]`} {block.name}
                                        <i className='fa-light fa-trash fa-pull-right' style={{cursor: 'pointer'}} onClick={() => deselectBlock(block)}/>
                                        <input type='hidden' name='consultation_protocol[block_ids][]' value={block.id} />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
            { courses.size > 0 &&
                <div className='card course-wizard'>
                    <div className='card-heading border bottom'>
                        <h4 className='card-title'>
                            Kursvorschläge
                            <span className='badge badge-primary ml-2'>{courses.size}</span>
                        </h4>
                    </div>
                    <div className='table-container'>
                        <table className='table table-sm table-hover'>
                            <tbody>
                                {
                                    courses.map(course => <Suggestion key={course.id} course={course} selection={selection} setCourseId={setCourseId} />)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            <input type='hidden' name='consultation_protocol[course_id]' />
        </>
    )
}

CourseBuilder.propTypes = {
    isCustom: PropType.bool.isRequired,
    onChangeIsCustom: PropType.func.isRequired,
    setCourseId: PropType.func.isRequired,
    estimatedStartAt: PropType.string,
    title: PropType.string,
    selection: PropType.array
}

const CourseWizard = (props) => {
    const [isCustom, setIsCustom] = useState(props.isCustom)
    const [courseId, setCourseId] = useState(props.courseId)
    const [blockStart, setBlockStart] = useState(props.estimatedStartAt)

    useEffect(() => {
        if(!courseId) { return }
        setIsCustom(false)
    }, [courseId])

    return (
        <>
            <div className="form-group">
                <label className="required">Gewünschter Starttermin</label>
                <SelectBox name='consultation_protocol[estimated_start_at]' items={props.blockStarts}
                           value={blockStart} onChange={({value}) => setBlockStart(value)} includeEmpty={true}/>
            </div>
            <CourseSelection isCustom={isCustom} courseId={courseId} onChangeIsCustom={setIsCustom}
                             blockStarts={props.blockStarts} estimatedStartAt={blockStart} />
            <CourseBuilder title={props.title} isCustom={isCustom} selection={props.blockIds}
                           onChangeIsCustom={setIsCustom} setCourseId={setCourseId}
                           estimatedStartAt={blockStart}
            />
        </>
    )
}

CourseWizard.propTypes = {
    title: PropType.string,
    isCustom: PropType.bool.isRequired,
    blockIds: PropType.array.isRequired,
    courseId: PropType.number,
    blockStarts: PropType.arrayOf(PropType.shape({title: PropType.string, value: PropType.string})),
    estimatedStartAt: PropType.string
}

CourseWizard.defaultProps = {
    title: '',
    isCustom: false,
    courseId: null,
    blockIds: []
}

export default CourseWizard