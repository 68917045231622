/* globals Turbolinks */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'qs';
import {List} from 'immutable';
import {DateTimePopUp} from './DateTimePicker';

class Dropdown extends React.Component {

    constructor(props) {

        super(props);

        this.list = React.createRef();

        this.state = {
            items: List(),
            focused: 0
        }

    }

    componentDidUpdate(prevProps, prevState) {

        const visibilityChanged = this.props.visible !== prevProps.visible;
        const modeChanged = this.props.mode !== prevProps.mode;
        const userInputChanged = this.props.userInput !== prevProps.userInput;
        const itemsChanged = this.state.items !== prevState.items;

        if(visibilityChanged || modeChanged) {

            if(this.props.mode === 'column') {

                const items = this.props.schema.columns
                    .filter(column => column.selectable === true || typeof column.selectable === 'object')
                    .map((column) => { return { label: column.label, value: column.columnName } } );

                this.setState({items: List(items)});

            } else if(this.props.mode === 'operator') {

                const token = this.props.getToken();
                const items = (this.props.schema.columns.find((column) => column.columnName === token.column) || {}).operators;

                this.setState({items: List(items)}, () => {

                    // If there is only one operator available, auto-select it

                    if(items.length !== 1 || prevProps.mode !== 'column') {
                        return;
                    }

                    this.selectItem(0);

                });

            } else {

                this.updateValueItems(false);

            }

        }

        if(userInputChanged && this.props.mode === 'value') {
            this.updateValueItems(userInputChanged);
        }

        if(itemsChanged) {
            this.setState({focused: 0});
        }

        if(this.list.current != null && this.state.focused !== prevState.focused) {
            this.focus();
        }

    }

    updateValueItems = (userInputChanged) => {

        const token = this.props.getToken();
        const column = this.props.schema.columns.find((column) => column.columnName === token.column);

        // Tokens with a value do not need updating

        if(userInputChanged && Object.prototype.hasOwnProperty.call(token, 'value')) {
            return;
        }

        if(Object.prototype.hasOwnProperty.call(column, 'values')) {

            let values = column.values;

            if(column.nullable && ['eq', 'not_eq'].indexOf(token.operator.value) >= 0) {
                values.push({value: 'null', label: this.props.schema.nullLabel})
            }

            const items = this.props.userInput.length >= 2 ? List(values).filter(item => item.label.toLowerCase().indexOf(this.props.userInput.toLowerCase()) >= 0) : List(values)

            this.setState({items: items});
            return;

        }

        if(this.props.userInput.length >= 2) {
            this.fetchItems(column, this.props.userInput);
        } else {
            this.fetchItems(column);
        }

    };

    fetchItems = (column, value) => {

        if(['datetime', 'date', 'integer'].includes(column.type)) {
            return;
        }

        let columnName, tableName

        if(typeof column.selectable === 'object') {
            columnName = column.selectable.column
            tableName = column.selectable.table
        } else {
            columnName = column.columnName
            tableName = column.tableName
        }

        const parameters = { column: columnName };

        if(value) {
            parameters['value'] = value;
        }

        axios.get(
            `${this.props.queryBase}/${tableName}`,{
                params: parameters,
                headers: {'Accept': 'application/json'}
            })
            .then((result) => {

                if(result.status !== 200) {
                    return;
                }

                let values = result.data;

                if(column.nullable) {
                    values.push({value: 'null', label: this.props.schema.nullLabel});
                }

                this.setState({items: List(values)});

            });

    };

    selectItem = (idx) => {

        const item = this.state.items.get(idx);
        const token = {};

        if(this.props.mode === 'column') {

            token['label'] = item.label;
            token['column'] = item.value;

        } else if (this.props.mode === 'operator') {
            token['operator'] = item;
        } else {
            token['value'] = item.value;
            token['value_label'] = item.label;
        }

        this.props.updateToken(token);
        this.setState({items: this.state.items.clear()});
        this.props.resetInput();
        this.props.focusInput();

    };

    addCustomValue = (value) => {

        this.props.updateToken({
            value: value,
            value_label: value
        });

        this.setState({items: List()});
        this.props.resetInput();
        this.props.focusInput();

    };

    focus = () => {

        if(this.list.current != null) {
            this.list.current.children[this.state.focused].focus();
        }

    };

    handleKeyDown = (e) => {

        e.preventDefault();

        const customValuePresent = this.props.userInput.length > 0 && this.props.mode === 'value'

        let totalSize = this.state.items.size;
        let idx = this.state.focused;

        if(customValuePresent) {
            totalSize = totalSize + 1;
            idx = idx - 1;
        }

        // KeyCodes: 40 => Down, 38 => Up, 13 => Enter, 8 => Backspace, 27 => Escape

        switch(e.keyCode) {
            case 40:
                this.setState({focused: (this.state.focused === totalSize - 1) ? 0 : this.state.focused + 1});
                break;
            case 38:
                this.setState({focused: (this.state.focused === 0) ? totalSize - 1 : this.state.focused - 1});
                break;
            case 13:
                (idx === -1) ? this.addCustomValue(this.props.userInput) : this.selectItem(idx);
                break;
            case 8:
            case 27:
                this.props.focusInput();
                break;
            default:
                return;
        }

    };

    renderPicker(column) {

        const displayTime = column.type === 'datetime';

        let format = null;

        if(!displayTime) {
            format = {year: 'numeric', month: 'short', day: 'numeric'};
        } else {
            format = {year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'}
        }

        const onReset = column.nullable ? () => this.props.updateToken({value: 'null', value_label: this.props.schema.nullLabel}) : undefined;
        const onSave = (date) => this.props.updateToken({value: date.toISOString(), value_label: date.toLocaleString(document.documentElement.lang, format)});

        return (
            <DateTimePopUp displayTime={displayTime} visible={true} placement='bottom-start' resetLabel='Leer' saveLabel='Übernehmen' onReset={onReset} onSave={onSave}>
                { (ref) => <div ref={ref} className='table-filter-dropdown' /> }
            </DateTimePopUp>
        );

    }

    render() {

        if(!this.props.visible) {
            return null;
        }

        if(this.props.mode === 'value') {

            const token = this.props.getToken();
            const column = this.props.schema.columns.find((column) => column.columnName === token.column)

            if(column.type === 'date' || column.type === 'datetime') {
                return this.renderPicker(column);
            }

        }

        if(this.state.items.size === 0) {
            return null;
        }

        return (
            <ul className='table-filter-dropdown' ref={this.list}>
                { this.props.userInput.length > 0 && this.props.mode === 'value' && <li className='table-filter-dropdown-item item-column' tabIndex='-1' onKeyDown={this.handleKeyDown} onClick={() => this.addCustomValue(this.props.userInput)}>{this.props.userInput}</li> }
                {
                    this.state.items.map((item, idx) => {

                        let domItem;
                        let itemClass = 'table-filter-dropdown-item item-column';

                        if(item.value === 'null') {

                            itemClass = `${itemClass} null-value`;
                            domItem = (
                                <React.Fragment key={idx}>
                                    <li className='item-divider' />
                                    <li tabIndex='-1' className={itemClass} onKeyDown={this.handleKeyDown} onClick={() => this.selectItem(idx)}>{item.label}</li>
                                </React.Fragment>
                            )

                        } else {

                            domItem = <li key={idx} tabIndex='-1' className={itemClass} onKeyDown={this.handleKeyDown} onClick={() => this.selectItem(idx)}>{item.label}</li>

                        }

                        return domItem;

                    })
                }
            </ul>
        )

    }

}

Dropdown.propTypes = {
    queryBase: PropTypes.string,
    userInput: PropTypes.string,
    mode: PropTypes.string,
    visible: PropTypes.bool,
    schema: PropTypes.object,
    getToken: PropTypes.func,
    updateToken: PropTypes.func,
    resetInput: PropTypes.func,
    focusInput: PropTypes.func
};

class FilterInput extends React.Component {

    constructor(props) {

        super(props);
        this.inputElement = React.createRef();

    }

    focus = () => {
        this.inputElement.current.focus();
    };

    render() {

        return (
            <li className='input-token'>
                <input className='form-control token-input' type='text' ref={this.inputElement} value={this.props.value}
                       onFocus={this.props.onFocus}
                       onChange={(e) => this.props.onChange(e)}
                       onKeyDown={(e) => this.props.onKeyDown(e)}
                       onKeyUp={(e) => this.props.onKeyUp(e)}
                />
                { React.cloneElement(this.props.children, { focusInput: this.focus }) }
            </li>
        )

    }

}

FilterInput.propTypes = {
    value: PropTypes.string,
    children: PropTypes.node,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func
};

class DeleteButton extends React.Component {

    render() {

        if(!this.props.display) {
            return null;
        }

        return (
            <div className='delete-tokens'>
                <button className='clear-filter' type='button' onClick={this.props.clearTokens}>
                    {this.props.icon}
                </button>
            </div>
        )

    }

}

DeleteButton.propTypes = {
    display: PropTypes.bool.isRequired,
    clearTokens: PropTypes.func.isRequired,
    icon: PropTypes.element.isRequired
};

class Token extends React.Component {

    render() {

        let valueClass = 'label-token';

        if(this.props.value === 'null') {
            valueClass = `${valueClass} label-token-null`;
        }

        return (

            <li className='token'>
                <div className='token-elements'>
                    <div className='token-label'>{ this.props.label }</div>
                    { this.props.operator &&
                    <div className='token-operator'>{this.props.operator.label}</div>
                    }
                    { this.props.value !== undefined && this.props.value !== null  &&
                    <div className='token-value'>
                        <span className={valueClass}>{ this.props.value_label }</span>
                        <button className='remove-token' type='button' onClick={this.props.removeToken}>
                            {this.props.deleteIcon}
                        </button>
                    </div>
                    }
                </div>
            </li>

        )

    }

}

Token.propTypes = {
    label: PropTypes.string,
    operator: PropTypes.object,
    value: PropTypes.string,
    value_label: PropTypes.string,
    removeToken: PropTypes.func,
    deleteIcon: PropTypes.element.isRequired
};

const StoredFilters = ({path, searches, storageKey, load, customDefault, deleteIcon, defaultIcon, defaultActiveIcon}) => {
    const [filters, setFilters] = useState(List())

    const loadFilters = async () => {
        const result = await axios.get(path, {
            params: {key: storageKey}
        })

        if(result.status === 200) {
            setFilters(List(result.data))
        }
    }

    useEffect(() => {
        (async () => await loadFilters())()
        return () => {}
    }, [searches])

    const onDelete = async (e) => {

        e.preventDefault();
        e.stopPropagation();

        const result = await axios.delete(path, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            params: {key: storageKey}
        })

        if(result.status === 200) {
            await loadFilters()
        }

    }

    const onSelectFilter = (filter) => {
        load(filter.settings.map((t) => Object.assign({}, t)))
    }

    const onDeleteFilter = async (e, idx) => {

        e.preventDefault();
        e.stopPropagation();

        const filter = filters.get(idx)

        const result = await axios.delete(`${path}/${filter.id}`, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(result.status === 200) {
            await loadFilters()
        }

    }

    const onSetFavorite = async (e, filter) => {

        e.preventDefault();
        e.stopPropagation();

        const result = await axios.patch(`${path}/${filter.id}`, {is_default: !filter.isDefault}, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(result.status === 200) {
            await loadFilters()
        }

    }

    return (
        <React.Fragment>
            <div className='dropdown-header'>
                Gespeicherte Filter ({filters.size})
                {filters.size > 0 &&
                    <button type='button' className='clear-history' title='Alle gespeicherte Filter löschen' onClick={onDelete}>
                        {deleteIcon}
                    </button>
                }
            </div>
            {filters.size > 0 &&
                <ul className='dropdown-searches'>
                    {filters.map((f, idx) =>
                        <li key={f.id} onClick={() => onSelectFilter(f)}>
                            {f.name}
                            <ul className='history-actions'>
                                { customDefault &&
                                    <li>
                                        <span className='default' title='Als Standardfilter speichern' onClick={(e) => onSetFavorite(e, f)}>{f.isDefault ? defaultActiveIcon : defaultIcon}</span>
                                    </li>
                                }
                                <li>
                                    <span className='delete' title='Filter löschen' onClick={(e) => onDeleteFilter(e, idx)}>{deleteIcon}</span>
                                </li>
                            </ul>
                        </li>
                    )}
                </ul>
            }
        </React.Fragment>
    )
}

StoredFilters.propTypes = {
    path: PropTypes.string.isRequired,
    searches: PropTypes.objectOf(List),
    storageKey: PropTypes.string.isRequired,
    load: PropTypes.func.isRequired,
    deleteIcon: PropTypes.element.isRequired,
    defaultIcon: PropTypes.element.isRequired,
    defaultActiveIcon: PropTypes.element.isRequired,
    customDefault: PropTypes.bool.isRequired
}

class History extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            searches: List()
        };

        const searches = localStorage.getItem(this.props.storageKey);

        if(searches) {
            this.state['searches'] = this.state.searches.concat(JSON.parse(searches));
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.tokens !== this.props.tokens && this.props.tokens.size > 0 && Object.prototype.hasOwnProperty.call(this.props.tokens.last(), 'value')) {
            this.add(this.props.tokens);
        }

        if(prevState.searches !== this.state.searches) {
            localStorage.setItem(this.props.storageKey, JSON.stringify(this.state.searches));
        }

    }

    add(filter) {

        let searches = this.state.searches.filter((s) => JSON.stringify(s) !== JSON.stringify(filter));

        searches = searches.unshift(filter.map((t) => Object.assign({}, t)));

        if(this.props.limit === searches.size) {
            searches = searches.pop();
        }

        this.setState({searches: searches});

    }

    clear(e) {

        e.preventDefault();
        e.stopPropagation();

        this.setState({searches: this.state.searches.clear()});
    }

    async save(e, idx) {

        e.preventDefault();
        e.stopPropagation();

        const tokens = this.state.searches.get(idx)

        const data = {
            key: this.props.storageKey,
            name: this.tokensToString(tokens),
            settings: tokens
        }

        const result = await axios.post(this.props.savePath, data, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(result.status === 204) {
            this.setState({searches: this.state.searches.delete(idx)})
        }

    }

    tokensToString(tokens) {
        return tokens.map(token => `${token.label} ${token.operator.label} ${token.value_label}`).join(', ');
    }

    render() {

        return (
            <div className='dropdown'>
                <div className='icon-filter dropdown-toggle' data-toggle='dropdown'>
                    {this.props.historyIcon}
                </div>
                <div className='dropdown-menu'>
                    {
                        this.props.savePath &&
                        <StoredFilters customDefault={this.props.customDefault} path={this.props.savePath} searches={this.state.searches} storageKey={this.props.storageKey} load={this.props.load} deleteIcon={this.props.deleteIcon} defaultIcon={this.props.defaultIcon} defaultActiveIcon={this.props.defaultActiveIcon} />
                    }
                    <div className='dropdown-header'>
                        Letzte Suchanfragen ({this.state.searches.size})
                        { this.state.searches.size > 0 &&
                        <button type='button' className='clear-history' title='Suchverlauf löschen' onClick={(e) => this.clear(e)}>
                            {this.props.deleteIcon}
                        </button>
                        }
                    </div>
                    { this.state.searches.size > 0 &&
                        <ul className='dropdown-searches'>
                            { this.state.searches.map((tokens, idx) =>
                                <li key={idx} onClick={() => this.props.load(tokens.map((t) => Object.assign({}, t)))}>{ this.tokensToString(tokens) }
                                    {
                                        this.props.savePath &&
                                        <ul className='history-actions'>
                                            <li>
                                                <span className='save' title='Filter speichern' onClick={(e) => this.save(e, idx)}>{this.props.saveIcon}</span>
                                            </li>
                                        </ul>
                                    }
                                </li>
                            )}
                        </ul>
                    }
                </div>
            </div>
        )
    }
}

History.propTypes = {
    storageKey: PropTypes.string,
    limit: PropTypes.number,
    tokens: PropTypes.instanceOf(List),
    load: PropTypes.func,
    savePath: PropTypes.string,
    deleteIcon: PropTypes.element.isRequired,
    historyIcon: PropTypes.element.isRequired,
    defaultIcon: PropTypes.element.isRequired,
    saveIcon: PropTypes.element.isRequired,
    defaultActiveIcon: PropTypes.element.isRequired,
    customDefault: PropTypes.bool.isRequired
};

class ConcentrateFilter extends React.Component {

    modes = {
        column: 'operator',
        operator: 'value',
        value: 'column'
    }

    invertedModes = {
        operator: 'column',
        value: 'operator',
        column: 'value',
    }

    initialState = {
        tokens: List(),
        mode: 'column',
        showDropdown: false,
        userInput: '',
        unsetInput: false,
    }

    constructor(props) {

        super(props);

        this.wrapper = React.createRef();
        this.dropdown = React.createRef();

        const query = this.getQuery();

        if(query) {

            const tokens = this.restoreTokens(query);
            this.initialState.tokens = List(tokens);

        }

        this.state = this.initialState;

    }

    componentDidMount() {

        document.addEventListener('mousedown', this.handleClick, false);

        if(this.initialState.tokens.isEmpty() && this.props.defaultQuery) {

            const defaultTokens = this.restoreTokens(this.props.defaultQuery);

            this.setState({tokens: List(defaultTokens)}, this.updateView);

        }

    }

    componentWillUnmount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentDidUpdate(_, prevState) {

        if(this.state.mode !== prevState.mode && this.state.mode !== this.modes[prevState.mode]) {

            // Remove a token item if the state transitions to a previous states
            this.removeTokenItem();

        }

        if(!this.state.tokens.equals(prevState.tokens)) {

            // Update view when a token is removed or completed (meaning it has a value)

            let completed = false;

            if(this.state.tokens.size > 0) {
                completed = Object.prototype.hasOwnProperty.call(this.state.tokens.last(), 'value');
            }

            if(this.state.tokens.size < prevState.tokens.size || completed) {
                this.updateView();
            }

        }

    }

    getQuery = () => {

        if(window.location.search === '') {
            return null;
        }

        const result = qs.parse(window.location.search.substring(1), { arrayFormat: 'brackets'});

        if(result === null || !Object.prototype.hasOwnProperty.call(result, 'query')) {
            return null;
        }

        return result['query'][0];

    }

    restoreTokens = (query) => {

        let tokens = [];

        if(Array.isArray(query.column)) {

            // TODO: Remove this ugly workaround for a bug in the query string library

            query.column.forEach((currentColumn, idx) => {

                const configuration = this.props.schema['columns'].find((column) => column.columnName === currentColumn);
                const item = {column: currentColumn, value: query.value[idx], label: configuration['label'], operator: configuration['operators'].find((operator) => operator.value === query.operator[idx])};

                if(Object.prototype.hasOwnProperty.call(configuration, 'values')) {
                    item['value_label'] = configuration['values'].find((label) => label.value === item['value'])['label'];
                } else if (query.value[idx] === 'null') {
                    item['value_label'] = this.props.schema.nullLabel;
                } else if (configuration.type === 'datetime') {
                    item['value_label'] = new Date(query.value[idx]).toLocaleString(document.documentElement.lang);
                } else if (configuration.type === 'date') {
                    item['value_label'] = new Date(query.value[idx]).toLocaleString(document.documentElement.lang, {year: 'numeric', month: 'short', day: 'numeric'});
                } else {
                    item['value_label'] = query.value[idx];
                }

                tokens.push(item);

            })

            // End of workaround

        }
        else if(Array.isArray(query)) {
            query.forEach(column => this.restoreItem(tokens, column));
        }
        else {
            this.restoreItem(tokens, query);
        }

        return tokens;

    }

    restoreItem = (tokens, query) => {

        const currentColumn = query.column
        const configuration = this.props.schema['columns'].find((column) => column.columnName === currentColumn);
        const item = {column: currentColumn, value: query.value, label: configuration.label, operator: configuration['operators'].find((operator) => operator.value === query.operator)};

        if(Object.prototype.hasOwnProperty.call(configuration, 'values')) {
            item['value_label'] = configuration['values'].find((label) => label.value === item['value'])['label'];
        } else if (item.value === 'null') {
            item['value_label'] = this.props.schema.nullLabel;
        } else if (configuration.type === 'datetime') {
            item['value_label'] = new Date(item.value).toLocaleString(document.documentElement.lang);
        } else if (configuration.type === 'date') {
            item['value_label'] = new Date(item.value).toLocaleString(document.documentElement.lang, {year: 'numeric', month: 'short', day: 'numeric'});
        } else {
            item['value_label'] = query.value;
        }

        tokens.push(item);

    }

    querySerializer = (params) => {

        let query = qs.stringify(params, {arrayFormat: 'brackets', encode: true});

        if(query !== '') {
            Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(`?${query}`, Turbolinks.uuid())
        } else {
            Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(window.location.pathname, Turbolinks.uuid())
        }

        const stateEvent = new Event('popstate');
        window.dispatchEvent(stateEvent);

        return query;

    }

    updateView = () => {

        const query = this.state.tokens.filter((token) => Object.prototype.hasOwnProperty.call(token, 'value')).toJS().map(token => { return {column: token.column, operator: token.operator.value, value: token.value} });

        const currentParams = new URLSearchParams(location.search);
        const nonQueryParams = Object.fromEntries(Array.from(currentParams.entries()).filter(p => !p[0].startsWith('query[]')));

        axios.get(window.location.pathname, {
            headers: {
                'Accept': 'application/javascript',
                'X-Requested-With': 'XMLHttpRequest'
            },
            params: Object.assign({}, {query}, nonQueryParams),
            paramsSerializer: {
                serialize: this.querySerializer
            }
        }).then((result) => {

            if(result.status !== 200) {
                return;
            }

            const script = document.createElement('script');
            script.appendChild(document.createTextNode(result.data));

            document.body.appendChild(script);

            script.remove();

            const event = document.createEvent('Event');
            event.initEvent('ajax:complete', true, true);

            document.dispatchEvent(event);

        });

    }

    removeToken = (idx) => {

        if(idx === undefined) {
            idx = -1;
        }

        this.setState({tokens: this.state.tokens.delete(idx)});

    }

    clearTokens = () => {
        this.setState(Object.assign({}, this.initialState, {tokens: List()}));
    }

    getToken = (idx) => {

        if(idx === undefined) {
            idx = -1;
        }

        return this.state.tokens.get(idx);

    }

    nextInputMode = () => {
        this.setState({mode: this.modes[this.state.mode]});
    }

    updateToken = (items) => {

        let tokens;

        if(this.state.tokens.size === 0 || this.state.mode === 'column') {

            tokens = this.state.tokens.push(Object.assign({}, items));

        } else {

            const token = Object.assign({}, this.state.tokens.last(), items);
            tokens = this.state.tokens.set(-1, token);

        }

        this.setState({tokens: tokens}, () => {
            this.nextInputMode();
        });

    }

    removeTokenItem = () => {

        if(this.state.tokens.size === 0) {
            return;
        }

        const tokens = this.state.tokens;
        let token = tokens.last();

        if(Object.prototype.hasOwnProperty.call(token, 'value')) {
            delete token['value'];
            delete token['value_label'];
        } else if (Object.prototype.hasOwnProperty.call(token, 'operator')) {
            delete token['operator'];
        } else {
            delete token['column'];
            delete token['label'];
        }

        if(Object.keys(token).length === 0) {
            this.removeToken();
        } else {
            this.setState({tokens: tokens.set(-1, token)});
        }

    }

    handleKeyDown = (e) => {
        // Prevent surrounding forms from being submitted when adding user input
        if(e.keyCode === 13 && this.state.mode === 'value' && this.state.userInput.length > 0) {
            e.preventDefault()
            e.stopPropagation()
        }
    }

    handleKeyUp = (e) => {

        // KeyCodes: 8 => Backspace, 13 => Enter, 40 => Down, 48 => Up
        if(this.state.mode !== 'value') {
            e.preventDefault();
        }

        if(e.keyCode === 40 && this.dropdown.current) {
            this.dropdown.current.focus();
        }

        if(this.state.tokens.size === 0) {
            return;
        }

        if(e.keyCode === 8) {

            if(this.state.mode === 'value' && !this.state.unsetInput) {

                if(this.state.userInput.length === 0) {
                    this.setState({unsetInput: true});
                }

                return;

            }

            const nextMode = this.invertedModes[this.state.mode];

            this.setState({mode: nextMode, unsetInput: nextMode === 'value'});

        } else if (e.keyCode === 13) {

            if(this.state.mode === 'value' && this.state.userInput.length > 0) {
                this.updateToken({value: this.state.userInput, value_label: this.state.userInput});
                this.resetInput();
            }

        } else if (e.keyCode === 48) {

            if(this.state.mode === 'operator') {
                this.updateToken({operator: {label: '=', value: 'eq'}});
            }

        }
        /*
        else {

            // Search without a specific column

            if(this.state.mode === 'column') {

            }

        }
        */

    }

    handleChange = (e) => {

        if(this.state.tokens.size === 0 || this.state.mode !== 'value') {
            return;
        }

        this.setState({userInput: e.target.value, unsetInput: false});

    }

    handleFocus = () => {
        this.setState({showDropdown: true});
    }

    handleClick = (e) => {

        if(!this.wrapper.current || this.wrapper.current.contains(e.target)) {
            return;
        }

        this.setState({showDropdown: false});

    }

    resetInput = () => {
        this.setState({userInput: ''});
    }

    render() {

        return (
            <div className='table-filter' ref={this.wrapper}>
                <History storageKey={this.props.storageKey} savePath={this.props.savePath} customDefault={this.props.customDefault} deleteIcon={this.props.deleteIcon} historyIcon={this.props.historyIcon} saveIcon={this.props.saveIcon} defaultIcon={this.props.defaultIcon} defaultActiveIcon={this.props.defaultActiveIcon} limit={10} tokens={this.state.tokens} load={(tokens) => {this.setState({tokens: List(tokens)})}} />
                <ul className='tokens'>
                    { this.state.tokens.map((token, idx) => <Token key={idx} deleteIcon={this.props.deleteIcon} removeToken={() => this.removeToken(idx)} {...token} />) }
                    <FilterInput value={this.state.userInput} onFocus={this.handleFocus} onChange={this.handleChange} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp}>
                        <Dropdown schema={this.props.schema} mode={this.state.mode} visible={this.state.showDropdown}
                                  queryBase={this.props.queryBase} getToken={this.getToken} updateToken={this.updateToken}
                                  userInput={this.state.userInput} resetInput={this.resetInput} ref={this.dropdown}
                        />
                    </FilterInput>
                </ul>
                <DeleteButton display={this.state.tokens.size > 0} clearTokens={this.clearTokens} icon={this.props.deleteIcon} />
            </div>
        )

    }

}

ConcentrateFilter.propTypes = {
    schema: PropTypes.object.isRequired,
    queryBase: PropTypes.string.isRequired,
    storageKey: PropTypes.string.isRequired,
    defaultQuery: PropTypes.array,
    historyIcon: PropTypes.element,
    saveIcon: PropTypes.element,
    deleteIcon: PropTypes.element,
    defaultIcon: PropTypes.element,
    defaultActiveIcon: PropTypes.element,
    savePath: PropTypes.string,
    customDefault: PropTypes.bool
};

ConcentrateFilter.defaultProps = {
    defaultQuery: null,
    historyIcon: <i className='fa-light fa-filter'/>,
    saveIcon: <i className='fa-light fa-save'/>,
    deleteIcon: <i className='fa-light fa-trash'/>,
    defaultIcon: <i className='fa-light fa-star'/>,
    defaultActiveIcon: <i className='fa-solid fa-star'/>,
    customDefault: false
}

export default ConcentrateFilter;