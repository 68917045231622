import React, {useEffect, useMemo, useState} from 'react'
import SelectBox from './SelectBox'
import axios from 'axios'
import ImageZone from './ImageZone';

const layouts = {
    image_left: 'Bild links',
    image_top: 'Bild oben',
    image_right: 'Bild rechts',
    only_text: 'Ohne Bild',
}

const layoutItems = Object.keys(layouts).map((key) => ({value: key, title: layouts[key]}))

const contentLayouts = {
    text_boxes: 'Text-Boxen',
    images_with_text: 'Bilder mit Text',
    icons_with_text: 'Icons mit Text',
    arrows: 'Pfeile',
    bullets: 'Aufzählung'
}

const contentLayoutItems = Object.keys(contentLayouts).map((key) => ({value: key, title: contentLayouts[key]}))

const PlaceholderElement = () => {
    return <div className="card mb-3">
        <div className="row no-gutters">
            <div className="col-md-12 d-flex flex-row align-items-center justify-content-center" style={{minHeight: '200px'}}>
               <i className="fa-solid fa-sync fa-spin fa-2x" />
            </div>
        </div>
    </div>
}

const FullScreenTextArea = (props) => {
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [classNames, setClassNames] = useState('')

    useEffect(() => {
        if(!isFullScreen) {
            setClassNames(props.className)
            return
        }

        setClassNames(`${props.className} full-screen`)
    }, [isFullScreen])

    const onKeyDown = (e) => {
        if(e.key !== "Escape") { return }

        setIsFullScreen(!isFullScreen)
    }

    return <textarea {...props} onKeyDown={onKeyDown} className={classNames} />
}

const faHelperClasses = new Set(["fa-fw", "fa-li", "fa-ul", "fa-border", "fa-pull-left", "fa-pull-right",
    "fa-align-center", "fa-align-left", "fa-align-right", "fa-lg", "fa-2x", "fa-3x", "fa-4x", "fa-5x", "fa-sm", "fa-xs",
    "fa-rotate-90", "fa-rotate-180", "fa-rotate-270", "fa-flip-horizontal", "fa-flip-vertical", "fa-spin", "fa-pulse",
    "fa-inverse", "fa-sr-only", "fa-swap-opacity", "fa-stack", "fa-stack-1x", "fa-stack-2x"])

const IconList = ({value, onChange}) => {
    const iconClasses = useMemo(() => {
        const classes = []

        for (const sheet of document.styleSheets) {
            try {
                for (const rule of sheet.cssRules) {
                    if (rule instanceof CSSStyleRule) {
                        const matches = rule.selectorText.match(/\.fa-(.+)::before/)
                        if(matches && !faHelperClasses.has(`fa-${matches[1]}`)) {
                            classes.push(matches[1])
                        }
                    }
                }
            } catch {
                console.warn('Cannot access stylesheet due to CORS restrictions:', sheet.href);
            }
        }

        return classes
    }, [])

    return <SelectBox items={iconClasses.map(ic => ({value: ic, title: `fa-${ic}`}))} value={value}
                      onChange={({value}) => onChange(value)} />
}

const TextBoxElement = (props) => {
    const [title, setTitle] = useState(props.title)
    const [content, setContent] = useState(props.content)

    const contents = <>
        <input type={props.destroy ? 'hidden' : 'text'} className="form-control"
               name={`presentation_slide[elements_attributes][][title]`}
               value={title}
               onChange={({target: {value}}) => setTitle(value)}/>
        {!props.destroy && <FullScreenTextArea className="form-control mt-2"
                                     name={`presentation_slide[elements_attributes][][content]`}
                                     value={content}
                                     onChange={({target: {value}}) => setContent(value)}/>
        }
        {props.destroy &&
            <input type="hidden" name={`presentation_slide[elements_attributes][][content]`}
                   value={content}/>
        }
        <input type="hidden" name={`presentation_slide[elements_attributes][][icon]`}
               value={props.icon}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][image_prompt]`}
               value={props.imagePrompt}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][_destroy]`}
               value={props.destroy ? 'true' : 'false'}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][position]`}
               value={props.position}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][id]`}
               value={props.id}/>
    </>

    if (props.destroy) {
        return contents
    }

    return <div className="card mb-3">
        <div className="card-body bg-light">
            {contents}
            <button type="button" className="btn btn-sm btn-outline-danger float-right mt-2 mr-0 mb-0"
                    onClick={() => props.onDelete(props.index)}>
                <i className="fa fa-trash"/>
            </button>
        </div>
    </div>
}

const ImageWithTextElement = (props) => {
    const [title, setTitle] = useState(props.title)
    const [content, setContent] = useState(props.content)
    const [imagePrompt, setImagePrompt] = useState(props.imagePrompt)
    const [imageData, setImageData] = useState(props.imageData)
    const [imageContentType, setImageContentType] = useState(props.imageContentType)
    const [isLoading, setIsLoading] = useState(false)

    const imageUrl = `/administration/learning_goals/${props.learningGoalId}/presentation_slides/element_image`

    useEffect(() => {
        if (imageData || !imagePrompt) { return }

        (async () => {
            try {
                setIsLoading(true)

                const response = await axios.get(imageUrl, {
                    headers: {'Accept': 'application/json'},
                    params: {
                        prompt: imagePrompt,
                        title
                    }
                })

                if (response.status === 200) {
                    setImageData(response.data.image)
                    setImagePrompt(response.data.prompt)
                    setImageContentType(response.data.content_type)
                }
            } finally {
                setIsLoading(false)
            }
        })()
    }, [imageData, imagePrompt])

    const onLoadImage = (dataUrl) => {
        setImageData(dataUrl.split(',')[1])
    }

    const contents = <>
        <input type={props.destroy ? 'hidden' : 'text'} className="form-control"
               name={`presentation_slide[elements_attributes][][title]`}
               value={title}
               onChange={({target: {value}}) => setTitle(value)}/>
        {!props.destroy && <FullScreenTextArea className="form-control mt-2"
                                               style={{minHeight: '155px'}}
                                               name={`presentation_slide[elements_attributes][][content]`}
                                               value={content}
                                               onChange={({target: {value}}) => setContent(value)}/>
        }
        {props.destroy &&
                <input type="hidden" name={`presentation_slide[elements_attributes][][content]`}
                       value={content}/>
        }
        <input type="hidden" name={`presentation_slide[elements_attributes][][icon]`}
               value={props.icon}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][image_prompt]`}
               value={imagePrompt}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][image_data]`}
               value={imageData}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][image_content_type]`}
               value={imageContentType}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][_destroy]`}
               value={props.destroy ? 'true' : 'false'}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][position]`}
               value={props.position}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][id]`}
               value={props.id}/>
    </>

    if (props.destroy) {
        return contents
    }

    return <div className="card bg-light mb-3">
        <div className="card-body">
            <div className="row">
                <div className="col-md-4 d-flex flex-row align-items-center justify-content-center">
                    {isLoading && <i className="fa-solid fa-sync fa-spin fa-2x"/>}
                    {!isLoading && imageData &&
                            <ImageZone source={imageData} editable={true} prompt={imagePrompt} alt={imagePrompt}
                                       onLoadImage={onLoadImage}
                                       path={imageUrl}/>
                    }
                </div>
                <div className="col-md-8">
                    {contents}
                    <button type="button" className="btn btn-sm btn-outline-danger float-right mt-2 mr-0 mb-0"
                            onClick={() => props.onDelete(props.index)}>
                        <i className="fa fa-trash"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
}

const IconWithTextElement = (props) => {
    const [icon, setIcon] = useState(props.icon)
    const [title, setTitle] = useState(props.title)
    const [content, setContent] = useState(props.content)

    const contents = <>
        <input type={props.destroy ? 'hidden' : 'text'} className="form-control"
               name={`presentation_slide[elements_attributes][][title]`}
               value={title}
               onChange={({target: {value}}) => setTitle(value)}/>
        {!props.destroy && <FullScreenTextArea className="form-control mt-2"
                                     name={`presentation_slide[elements_attributes][][content]`}
                                     value={content}
                                     onChange={({target: {value}}) => setContent(value)}/>
        }
        {props.destroy &&
            <input type="hidden" name={`presentation_slide[elements_attributes][][content]`}
                   value={content}/>
        }
        <input type="hidden" name={`presentation_slide[elements_attributes][][icon]`}
               value={icon}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][image_prompt]`}
               value={props.imagePrompt}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][_destroy]`}
               value={props.destroy ? 'true' : 'false'}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][position]`}
               value={props.position}/>
        <input type="hidden" name={`presentation_slide[elements_attributes][][id]`}
               value={props.id}/>
    </>

    if (props.destroy) {
        return contents
    }

    return <div className="card bg-light mb-3">
        <div className="card-body">
            <div className="row">
                <div className="col-md-4 d-flex flex-column align-items-center justify-content-start">
                    <IconList value={icon} onChange={setIcon} />
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <i className={`fa-solid fa-${icon} fa-5x`}/>
                    </div>
                </div>
                <div className="col-md-8">
                    {contents}
                    <button type="button" className="btn btn-sm btn-outline-danger float-right mt-2 mr-0 mb-0"
                            onClick={() => props.onDelete(props.index)}>
                        <i className="fa fa-trash"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
}

const elementMap = {
    'text_boxes': TextBoxElement,
    'images_with_text': ImageWithTextElement,
    'icons_with_text': IconWithTextElement,
    'arrows': TextBoxElement,
    'bullets': TextBoxElement
}

const ContentElements = ({learningGoalId, contentLayout, elements, isLoading, onDelete}) => {
    return <>
        {elements.map((e, idx) => {
            const Element = elementMap[contentLayout]
            return <Element key={idx} index={idx} learningGoalId={learningGoalId} onDelete={onDelete} {...e} />
        })}
        {isLoading && <PlaceholderElement/>}
    </>
}

const NameGeneratorButton = ({learningGoalId, onUpdate}) => {
    const [isLoading, setIsLoading] = useState(false)

    const onNameWizardClick = async () => {
        try {
            setIsLoading(true)

            const response = await axios.get(`/administration/learning_goals/${learningGoalId}/presentation_slides/name`, {
                headers: {'Accept': 'application/json'}
            })

            if (response.status === 200) {
                onUpdate(response.data.name)
            }
        } finally { setIsLoading(false) }
    }

    const content = isLoading ? <i className="fa-solid fa-sync fa-spin"/> : <i className="fa-solid fa-sparkles"/>

    return <button type="button" className="btn btn-secondary" onClick={onNameWizardClick}>
        {content}
    </button>
}

const SlideWizard = ({learningGoalId, slideId = null}) => {
    const [name, setName] = useState('')
    const [layout, setLayout] = useState(null)
    const [contentLayout, setContentLayout] = useState(null)
    const [speakerNotes, setSpeakerNotes] = useState('')
    const [narratorText, setNarratorText] = useState('')
    const [narrationUrl, setNarrationUrl] = useState(null)
    const [elements, setElements] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(location.href.endsWith('/new') || location.href.endsWith('/presentation_slides')) { return }

        (async () => {
            const response = await axios.get(location.href, {
                headers: {'Accept': 'application/json'}
            })

            if (response.status === 200) {
                const {name, layout, contentLayout, speakerNotes, narratorText, narration, elements} = response.data;

                setName(name)
                setLayout(layout)
                setContentLayout(contentLayout)
                setSpeakerNotes(speakerNotes || '')
                setNarratorText(narratorText || '')
                setElements(elements)

                if(narration) {
                    setNarrationUrl(response.data.narrationUrl)
                }
            }
        })()
    }, [])

    const generateContent = async () => {
        setIsLoading(true)
        setElements([])

        try {

            const response = await axios.get(`/administration/learning_goals/${learningGoalId}/presentation_slides/content`, {
                headers: {'Accept': 'application/json'},
                params: {
                    slide_id: slideId
                }
            })

            if(response.status === 200) {
                setElements(response.data)
            }

        } finally { setIsLoading(false) }
    }

    const onDelete = async (idx) => {

        const element = elements[idx]

        if(element.id) {
            setElements(elements.map((e, i) => i === idx ? Object.assign({}, e, {destroy: true}) : e))
        } else {
            setElements([...elements.slice(0, idx), ...elements.slice(idx + 1)])
        }
    }

    const onAdd = () => {
        setElements([...elements, {title: '', content: '', icon: 'globe', position: elements.length, imagePrompt: ''}])
    }

    return <>
        <div className="form-group">
            <label className="required" id="label_name" htmlFor="presentation_slide_name">Name</label>
            <div className="input-group">
                <input required="required" autoFocus="autofocus" className="form-control" type="text"
                       value={name} name="presentation_slide[name]" id="presentation_slide_name"
                       onChange={({target: {value}}) => setName(value)}
                />
                <div className="input-group-append input-group-append-button">
                    <NameGeneratorButton learningGoalId={learningGoalId} onUpdate={(value) => setName(value)}/>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label className="required" id="label_layout" htmlFor="presentation_slide_layout">Layout</label>
                    <SelectBox id="presentation_slide_layout" name="presentation_slide[layout]"
                               disabled={name.length === 0}
                               items={layoutItems} onChange={({value}) => setLayout(value)} value={layout}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="required" id="label_content_layout"
                           htmlFor="presentation_slide_content_layout">Inhalt-Layout</label>
                    <div className='input-group'>
                        <SelectBox id="presentation_slide_content_layout" name="presentation_slide[content_layout]"
                                   items={contentLayoutItems} disabled={layout === null}
                                   onChange={({value}) => setContentLayout(value)} value={contentLayout}/>
                        <div className='input-group-append input-group-append-button'>
                            <button type="button" className="btn btn-secondary" onClick={generateContent}
                                    disabled={!contentLayout}>
                                <i className={isLoading ? "fas fa-sync fa-spin" : "fas fa-sparkles"}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label className="required" id="label_speaker_notes"
                           htmlFor="presentation_speaker_notes">Sprechernotizen</label>
                    <FullScreenTextArea id="presentation_speaker_notes" name="presentation_slide[speaker_notes]"
                                        className="form-control" value={speakerNotes}
                                        onChange={({target: {value}}) => setSpeakerNotes(value)}
                    />
                </div>

            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <label className="required" id="label_narrator_text"
                           htmlFor="presentation_narrator_text">Sprechertext</label>
                    <FullScreenTextArea id="presentation_narrator_text" name="presentation_slide[narrator_text]"
                              className="form-control" value={narratorText}
                              onChange={({target: {value}}) => setNarratorText(value)}
                    />
                </div>
                {narrationUrl &&
                        <audio controls src={narrationUrl}
                               style={{minWidth: '100%', marginBottom: '10px', marginTop: '-10px'}}/>
                }
            </div>
        </div>
        <ContentElements learningGoalId={learningGoalId} contentLayout={contentLayout} elements={elements}
                         isLoading={isLoading} onDelete={onDelete}/>
        <button type="button" className="btn btn-secondary mb-4" onClick={onAdd} disabled={!contentLayout}>
            <i className="fas fa-plus mr-2"/>
            Neues Element
        </button>
    </>

}

export default SlideWizard