import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Item = ({name, value, removable, onChange, onRemove}) => (
    <div className='input-group item-list-item mb-1'>
        <div className='input-group-prepend' title='Element sortieren'>
                <span className='input-group-text'>
                    <i className='fa-light fa-sort-alt' style={{visibility: removable ? 'initial' : 'hidden'}}/>
                </span>
        </div>

        <input type='text' className='form-control' name={value.length > 0 ? `${name}[]` : null} value={value} onChange={(e) => onChange(e.target.value)} />
        { removable &&
            <div className='input-group-append bg-transparent border-0' title='Element entfernen'>
                <button type='button' tabIndex='-1' className='btn btn-danger m-0' onClick={onRemove}>
                    <i className='fa-light fa-trash'/>
                </button>
            </div>
        }
    </div>
)

Item.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    removable: PropTypes.bool.isRequired
}

const ItemList = ({scope, name, items: initialItems}) => {
    initialItems.push('')

    const [items, setItems] = useState(initialItems)

    const onDragEnd = ({source, destination}) => {
        setItems(move(items, source.index, destination.index))
    }

    const move = (ary, from, to) => {
        return ary = ary.slice(), ary.splice(to, 0, ...ary.splice(from, 1)), ary
    }

    const onChangeItem = (idx, value) => {
        const list = [...items.slice(0, idx), value, ...items.slice(idx + 1)]

        if(idx + 1 === items.length && value !== '') {
            list.push('')
        }

        setItems(list)
    }

    const onRemoveItem = (idx) => {
        const list = [...items.slice(0, idx), ...items.slice(idx + 1)]

        if(list.length === 0) {
            list.push('')
        }

        setItems(list)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} >
                        { items.map((item, idx) =>
                            <Draggable key={idx} draggableId={`item_${idx}`} index={idx} isDragDisabled={idx === items.length - 1}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Item name={`${scope}[${name}]`} removable={idx !== items.length - 1} value={item} onChange={value => onChangeItem(idx, value)} onRemove={() => onRemoveItem(idx)} />
                                    </div>
                                )}
                            </Draggable>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )

}

ItemList.propTypes = {
    scope: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.array
}

export default ItemList