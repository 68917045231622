import React from 'react';
import ReactDOM from 'react-dom';
import PropType from 'prop-types';
import {List} from 'immutable';
import axios from 'axios';
import SelectBox from './SelectBox';

class Topic extends React.PureComponent {

    render() {

        return (
            <tr>
                <td className='border-top-0'>
                    <SelectBox name={this.props.id ? 'topics[]' : null} required={true} items={this.props.topics} value={this.props.id} onChange={this.props.onChange} />
                </td>
                <td className='border-top-0 action'>
                    <button type='button' className='btn btn-icon btn-flat btn-rounded'
                            onClick={this.props.onClickDelete}>
                        <i className='fa-light fa-trash'/>
                    </button>
                </td>
            </tr>
        )

    }

}

Topic.propTypes = {
    id: PropType.number,
    name: PropType.string,
    duration: PropType.number,
    topics: PropType.arrayOf(
        PropType.shape({
            value: PropType.number.isRequired,
            title: PropType.string.isRequired
        })
    ),
    onClickDelete: PropType.func.isRequired,
    onChange: PropType.func.isRequired
}

class BlockDurationBadge extends React.PureComponent {

    constructor(props) {
        super(props);
        this.target = document.getElementById('block-duration')
    }

    render() {

        const variant = (this.props.value === 16) ? 'secondary' : 'danger'

        return ReactDOM.createPortal(
            <span className={`badge badge-${variant}`}>{this.props.value} / 16 Tagen</span>,
            this.target
        )

    }

}

BlockDurationBadge.propTypes = {
    value: PropType.number.isRequired
}

export default class InstanceAssignments extends React.PureComponent {

    state = {
        selection: List(),
        topics: List()
    }

    async componentDidMount() {

        const result = await axios.get(location.href, {
            headers: {
                'Accept': 'application/json'
            }
        });

        if(result.status === 200) {
            this.setState({selection: List(result.data.selection), topics: List(result.data.topics)})
        }

    }

    onDelete = (idx) => {
        this.setState({selection: this.state.selection.delete(idx)})
    }

    onChange = (idx, item) => {

        const selection = this.state.selection.get(idx)

        if(item.value === selection.id) {
            return;
        }

        const topic = this.state.topics.find(topic => topic.id === item.value)

        this.setState({selection: this.state.selection.set(idx, topic)})

    }

    onAdd = () => {
        this.setState({selection: this.state.selection.push({})})
    }

    render() {

        const topics = this.state.topics.map(instance => ({title: instance.name, value: instance.id})).toArray()
        const totalDuration = this.state.selection.reduce((sum, topic) => sum + (topic.duration || 0), 0);

        return (
            <React.Fragment>
                <BlockDurationBadge value={totalDuration} />
                <table className='table table-block-selection'>
                    <tbody>
                    {
                        this.state.selection.map((topic, idx) => <Topic key={idx} {...topic} topics={topics} onClickDelete={() => this.onDelete(idx)} onChange={(item) => this.onChange(idx, item)} />)
                    }
                    <tr>
                        <td className='border-top-0 pt-0' colSpan='2'>
                            <button type='button' onClick={this.onAdd} className='btn btn-block btn-add-block'>
                                <i className='fa-light fa-plus'/>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                { totalDuration === 16 && <button className='btn btn-primary' type='submit'>{this.props.labels.save}</button> }
                <a href={location.href.replace('/assignments/edit', '')} className='btn btn-default float-right'>{this.props.labels.back}</a>
            </React.Fragment>
        )

    }

}

InstanceAssignments.propTypes = {
    labels: PropType.shape({
        save: PropType.string.isRequired,
        back: PropType.string.isRequired
    }).isRequired
}