import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

class Entry extends React.PureComponent {

    render() {

        return (
            <div className='card file-browser-entry' onClick={this.props.onClick}>
                <div className='card-body'>
                    { this.props.name }
                </div>
            </div>
        )

    }

}

Entry.propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

class File extends React.PureComponent {

    onClick = async () => {}

    render() {
        return <Entry name={this.props.name} onClick={this.onClick} />
    }

}

File.propTypes = {
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
}

class Folder extends React.PureComponent {

    onClick = async () => {

        const result = await axios.get(`/students/programs/${this.props.programId}/storage/${this.props.fileStore}/entries/${this.props.identifier}`,{
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if(result.status === 200) {
            this.props.onBrowse(result.data);
        }

    }

    render() {
        return <Entry name={this.props.name} onClick={this.onClick} />
    }

}

Folder.propTypes = {
    programId: PropTypes.number.isRequired,
    fileStore: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onBrowse: PropTypes.func.isRequired
}

class FileStore extends React.PureComponent {

    onClick = async () => {

        const result = await axios.get(`/students/programs/${this.props.programId}/storage/${this.props.identifier}/entries`,{
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if(result.status === 200) {
            this.props.onBrowse(result.data);
        }

    }

    render() {
        return <Entry name={this.props.name} onClick={this.onClick} />;
    }

}

FileStore.propTypes = {
    programId: PropTypes.number.isRequired,
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onBrowse: PropTypes.func.isRequired
}

export default class FileBrowser extends React.PureComponent {

    state = {
        parent: null,
        entries: []
    }

    async componentDidMount() {
        await this.loadEntries(`/students/programs/${this.props.programId}/storage`);
    }

    updateEntries = (data) => {
        this.setState(data);
    }

    loadEntries = async (path) => {

        const result = await axios.get(path,{
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if(result.status === 200) {
            this.updateEntries(result.data);
        }

    }

    render() {

        return (
            <div className='file-browser'>
                { this.state.parent &&
                    <Entry name='Zurück' onClick={() => this.loadEntries(this.state.parent)} />
                }
                {
                    this.state.entries.map(entry => {

                        switch(entry.kind) {
                            case 'fileStore':
                                return <FileStore key={entry.identifier} programId={this.props.programId} onBrowse={(entries) => this.updateEntries(entries)} {...entry} />
                            case 'folder':
                                return <Folder key={entry.identifier} programId={this.props.programId} onBrowse={(entries) => this.updateEntries(entries)} {...entry} />;
                            case 'file':
                                return <File key={entry.identifier} {...entry} />;
                        }

                    })
                }
            </div>
        )

    }

}

FileBrowser.propTypes = {
    programId: PropTypes.number.isRequired
}