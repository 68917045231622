import React, {useState, useEffect, useContext, createContext, useReducer, useRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import SelectBox from './SelectBox';
import Modal, {Dialog} from './Modal';
import axios from 'axios';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import DateTimePicker from './DateTimePicker';
import {disableWheelInput} from "../scripts/form_utilities";

const locale = 'de-DE'
const currency = 'EUR'
const dateFormatter = new Intl.DateTimeFormat(locale, {day: '2-digit', month: '2-digit', year: 'numeric'})

const debounce = (callback, timeout) => {
    let timeoutId = null
    return (...args) => {
        window.clearTimeout(timeoutId)
        timeoutId = window.setTimeout(() => { callback.apply(null, args) }, timeout)
    }
}

const isPresent = (variable) => typeof(variable) !== 'undefined' && variable !== null && variable !== {} && variable !== [];

const dateRangeLabel = ({startsAt, endsAt}) => {
    if(!startsAt || !endsAt) {
        return
    }

    if(typeof startsAt === 'string') {
        startsAt = new Date(startsAt)
    }

    if(typeof endsAt === 'string') {
        endsAt = new Date(endsAt)
    }

    return `${dateFormatter.format(startsAt)} - ${dateFormatter.format(endsAt)}`
}

const dateRangeOverlaps = (leftStart, leftEnd, rightStart, rightEnd) => {
    return (leftStart <= rightStart && rightStart <= leftEnd) ||
        (leftStart <= rightEnd && rightEnd <= leftEnd) ||
        (rightStart < leftStart && leftEnd < rightEnd);
}

const overlappingDates = (schedule) => {
    const dateRanges = schedule.map((i, idx) => ({index: idx, kind: i.kind, startsAt: i.startsAt, endsAt: i.endsAt})).filter(i => i.kind !== 'break' && isPresent(i.startsAt) && isPresent(i.endsAt))
    const overlaps = []

    for(let i = 0; i < dateRanges.size - 1; i++) {
        for(let j = i + 1; j < dateRanges.size; j++) {
            const left = dateRanges.get(i)
            const right = dateRanges.get(j)

            if(dateRangeOverlaps(left.startsAt, left.endsAt, right.startsAt, right.endsAt)) {
                overlaps.push(left.index, right.index)
            }
        }
    }

    return overlaps
}

const Money = ({value}) => {
    return (
        <React.Fragment>
            {new Intl.NumberFormat(locale, {style: 'currency', currency}).format(isNaN(value) ? 0 : value / 100)}
        </React.Fragment>
    )
}

Money.propTypes = {
    value: PropTypes.number
}

const parseISOString = (date) => {
    const elements = date.split(/\D+/)
    return new Date(Date.UTC(elements[0], elements[1] - 1, elements[2], 0, 0, 0, 0))
}

const addDays = (date, days) => {
    const dateObj = new Date(date)

    dateObj.setDate(dateObj.getDate() + days)

    return toISOString(dateObj)
}

const toISOString = (date) => date.toISOString().substring(0, 10)

const getDays = async (from, kind, limit, to) => {
    if(from instanceof Date) {
        from = from.toISOString().substring(0, 10)
    }

    const result = await axios.get('/administration/days', {
        params: {from, kind, limit, to},
        headers: {
            'Accept': 'application/json'
        }
    })

    if(result.status === 200) {
        return result.data.map(d => d.date)
    } else {
        return []
    }
}

const initialState = {
    courseVersionId: null,
    schedule: List(),
    certificates: List(),
    invoiceRecipients: List(),
    examinationDate: {date: null, custom: false},
    splitMode: 'percentage',
    blocks: 0,
    startsAt: null,
    endsAt: null,
    amounts: {
        invoiceRecipients: [],
        subtotals: {},
        totals: {}
    }
}

let baseState = Object.assign({}, initialState)

const withCalculations = ({schedule, certificates, invoiceRecipients, splitMode}) => {

    const groupByRecipient = (items) => {
        return items.filter(item => Object.prototype.hasOwnProperty.call(item, 'invoiceRecipient') && item.invoiceRecipient !== null).reduce((groups, item) => {
            Array.isArray(groups[item.invoiceRecipient]) ? groups[item.invoiceRecipient].push(item) : groups[item.invoiceRecipient] = [item]
            return groups
        }, [])
    }

    const pricedItems = item => item.kind === 'block' && isPresent(item.instanceId) && item.instanceId !== 0

    const subtotals = {
        blocks: schedule.filter(pricedItems).reduce((sum, item) => sum + item.price, 0),
        certificates: certificates.filterNot(item => item.isFree).reduce((sum, certificate) => sum + certificate.price, 0)
    }

    const total = subtotals.blocks + subtotals.certificates

    const totals = { net: 0, discount: 0, taxes: 0, gross: 0 }

    const groupedSchedule = groupByRecipient(schedule.filter(pricedItems))
    const groupedCertificates = groupByRecipient(certificates.filterNot(c => c.isFree))

    const recipients = invoiceRecipients.map((recipient, idx) => {

        const isJobAgency = recipient.kind === 'job_agency'

        let net

        if(splitMode === 'lines') {
            net = (groupedSchedule[idx] || []).reduce((sum, item) => sum + item.price, 0) + (groupedCertificates[idx] || []).reduce((sum, item) => sum + item.price, 0)
        } else {
            net = total * (recipient.splitRatio / 100)
        }

        const discount = net * (recipient.discount / 100)
        const discounted = net - discount
        const gross = discounted * (isJobAgency ? 1 : 1.19)
        const taxes = gross - discounted

        totals.net += net
        totals.discount += discount
        totals.taxes += taxes
        totals.gross += gross

        return {net, discount, taxes, gross}

    })

    return {
        invoiceRecipients: recipients,
        subtotals,
        totals
    }
}

const withSorting = (schedule, withoutCaseStudies = false) => {

    schedule = schedule.sort((a, b) => {

        if(withoutCaseStudies && (a.kind === 'case_study' || b.kind === 'case_study')) {
            return 0
        }

        if(a.startsAt > b.startsAt || (a.startsAt === b.startsAt && b.endsAt >= a.endsAt)) { return 1 }
        if(a.startsAt === b.startsAt) { return 0 }
        if(b.startsAt > a.startsAt || (a.startsAt === b.startsAt && a.endsAt >= b.endsAt)) { return -1 }

        return 0

    })

    return schedule.sort((a, b) => {

        if(a.instanceId === 0 && b.instanceId !== 0) { return -1 }
        if(a.instanceId === 0 && b.instanceId === 0) { return 0 }
        if(a.instanceId !== 0 && b.instanceId === 0) { return 1 }

        return 0

    })

}

const reducer = (state, action) => {

    let newState;
    const payload = action.payload;

    /* console.log(action.type, payload) */

    switch(action.type) {
        case 'SCHEDULE_UPDATE':
            newState = {...state, schedule: state.schedule.set(payload.index, payload.item)}
            break
        case 'SCHEDULE_MODIFY':
            newState = {...state, schedule: state.schedule.set(payload.index, Object.assign({}, state.schedule.get(payload.index), payload.item))}
            break
        case 'SCHEDULE_ADD':
            newState = {...state, schedule: state.schedule.push(payload.item)}
            break
        case 'SCHEDULE_INSERT':
            newState = {...state, schedule: state.schedule.insert(payload.index, payload.item)}
            break
        case 'SCHEDULE_DELETE':
            newState = {...state, schedule: state.schedule.delete(payload)}
            break
        case 'SCHEDULE_MOVE':
            newState = {...state, schedule: state.schedule.splice(payload.from, 1).splice(payload.to, 0, state.schedule.get(payload.from))}
            break
        case 'SCHEDULE_SET':
            newState = {...state, schedule: payload.item}
            break
        case 'UPDATE_CERTIFICATES':
            newState = {...state, certificates: payload}
            break
        case 'UPDATE_INVOICE_RECIPIENTS':
            newState = {...state, invoiceRecipients: payload}
            break
        case 'RESTORE_STATE':
            newState = Object.assign({}, state, payload)
            break
        case 'RESET_STATE':
            newState = Object.assign({}, baseState)
            break
        case 'SET_EXAMINATION_DATE':
            newState = {...state, examinationDate: payload}
            break
        case 'SET_SPLIT_MODE':
            newState = {...state, splitMode: payload}
            break
        default:
            throw new Error()
    }

    const blocks = newState.schedule.filter(i => i.kind === 'block' && isPresent(i.instanceId) && i.instanceId !== 0).size
    const startsAt = (newState.schedule.first(i => i.startsAt !== null) || {startsAt: null}).startsAt
    const endsAt = isPresent(newState.examinationDate.date) ? newState.examinationDate.date :  (newState.schedule.last(i => i.endsAt !== null) || {endsAt: null}).endsAt

    newState = {...newState, amounts: withCalculations(newState) }
    newState = {...newState, blocks, startsAt, endsAt}

    if(action.type !== 'RESTORE_STATE') { saveState(newState) }

    return newState

}

const Context = createContext({state: initialState, dispatch: () => {}})

const saveState = debounce(async (state) => {

    /* console.log('SAVE_STATE', state) */

    const {blocks, startsAt, endsAt, ...data} = state

    await axios.put(location.href.replace('/edit', ''),
        {block_count: blocks, starts_at: startsAt, ends_at: endsAt, data},
        {
            headers: {
                'Accept': 'application/javascript',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        }
    )

}, 250)

const moveCaseStudies = (schedule) => {
    const blocks = {}

    for(let i=0; i < schedule.size; i++) {
        let item = schedule.get(i)

        if(item.kind === 'block') {
            blocks[item.id] = true
            continue
        }

        if(item.kind !== 'case_study') { continue }

        // Check if there are any case studies that are schedule before their respective blocks.

        if(!blocks[item.blockId]) {
            const blockIdx = schedule.findIndex(b => b.id === item.blockId)

            schedule = schedule.remove(i)
            schedule = schedule.insert(blockIdx, item)
        }
    }

    return schedule
}

const updateCaseStudies = async (schedule) => {
    const dates = {}
    let lastEndDate

    for(let i=0; i < schedule.size; i++) {
        let item = schedule.get(i)

        if(item.kind === 'break') { continue }

        if(item.kind !== 'case_study' && isPresent(item.endsAt)) {
            lastEndDate = addDays(item.endsAt, 1)
        }

        if(item.kind === 'case_study' && isPresent(lastEndDate)) {
            const block = schedule.find(b => b.id === item.blockId)
            const date = block.endsAt > lastEndDate ? block.endsAt : lastEndDate
            const days = await getDays(date, 'case_study', 4)
            dates[i] = {startsAt: days[0], endsAt: days[days.length - 1]}
            lastEndDate = addDays(block.endsAt > lastEndDate ? lastEndDate : days[days.length - 1], 1)
        }
    }

    Object.keys(dates).forEach(idx => {
        schedule = schedule.set(idx, Object.assign({}, schedule.get(idx), dates[idx]))
    })

    return schedule
}

const addBreaks = async (schedule) => {
    schedule = schedule.filterNot(i => i.kind === 'break')

    let breaks = 1
    let newSchedule = List(schedule)

    for(let i=0; i < schedule.size - 1; i++) {
        const left = schedule.get(i)
        const right = schedule.get(i + 1)

        if(isPresent(left.endsAt) && isPresent(right.startsAt)) {
            const startsAt = addDays(left.endsAt, 1)
            const endsAt = addDays(right.startsAt, -1)

            if (startsAt < endsAt) {
                const days = await getDays(startsAt, 'open', 0, endsAt)

                if (days.length > 0) {
                    newSchedule = newSchedule.insert(i + breaks, {kind: 'break', duration: days.length, startsAt, endsAt})
                    breaks += 1
                }
            }
        }
    }

    return newSchedule
}

const updateExaminationDate = async (schedule, examinationDate, blocks, dispatch) => {
    if(examinationDate.custom === true) { return }

    if(schedule.filter(item => item.kind === 'block' && item.hasProgramExamination === false).size === blocks) {
        dispatch({type: 'SET_EXAMINATION_DATE', payload: {date: null, custom: false}})
        return
    }

    const lastScheduledItem = schedule.filter(item => item.kind === 'break' || isPresent(item.endsAt)).reduce((prev, current) => new Date(current.endsAt) > new Date(prev.endsAt) ? current : prev)

    if(!lastScheduledItem || !isPresent(lastScheduledItem.endsAt)) {
        if(examinationDate.date !== null) {
            dispatch({type: 'SET_EXAMINATION_DATE', payload: {date: null, custom: false}})
        }

        return
    }

    const endDate = new Date(lastScheduledItem.endsAt)
    endDate.setDate(endDate.getDate() + 1)

    const days = await getDays(endDate, 'exam', 1)
    let date = null

    if(days.length > 0) {
        date = days[0]
    }

    if(examinationDate.date === null || examinationDate.date !== date) {
        dispatch({type: 'SET_EXAMINATION_DATE', payload: {date, custom: false}})
    }
}

const addCaseStudy = (state, dispatch, blockId, blockKey, name) => {
    const index = state.schedule.findLastIndex(i => i.kind === 'case_study')

    let item = {kind: 'case_study', name, blockId, blockKey}

    if(0 > index) {
        dispatch({type: 'SCHEDULE_ADD', payload: {item}})
    } else {
        dispatch({type: 'SCHEDULE_INSERT', payload: {index: index + 1, item}})
    }
}

const reducerCallback = async (state, action, dispatch) => {
    let schedule = withSorting(state.schedule, true)
    schedule = moveCaseStudies(schedule)
    schedule = await updateCaseStudies(schedule)
    schedule = withSorting(schedule)
    schedule = await addBreaks(schedule)

    dispatch({type: 'SCHEDULE_SET', payload: {item: schedule}})

    await updateExaminationDate(schedule, state.examinationDate, state.blocks, dispatch)
}

const useReducerWithCallback = (reducer, initialState, callback) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const actionRef = useRef()

    const customDispatch = (action) => {
        actionRef.current = action;
        dispatch(action)
    }

    useEffect(() => {(async () => {
        if(!actionRef.current) { return }

        switch(actionRef.current.type) {
            case 'SCHEDULE_SET':
            case 'SET_EXAMINATION_DATE':
                break
            default:
                await callback(state, actionRef.current, customDispatch)
        }
    })()}, [callback, state, customDispatch])

    return [state,  customDispatch]
}

const ApplicationState = ({children}) => {
    const [state, dispatch] = useReducerWithCallback(reducer, initialState, reducerCallback)

    useEffect(() => {(async () => {

        const result = await axios.get(location.href, {
            headers: {
                'Accept': 'application/json'
            }
        })

        if(result.status === 200) {
            const {data: {schedule, certificates, invoiceRecipients, ...otherState}} = result.data

            baseState = Object.assign({}, otherState, {schedule: List(schedule), certificates: List(certificates), invoiceRecipients: List(invoiceRecipients)})

            dispatch({type: 'RESTORE_STATE', payload: baseState})
        }

    })()}, [])

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    )
}

ApplicationState.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

const Block = ({index, ...props}) => {
    const {state, dispatch} = useContext(Context)

    const [instances, setInstances] = useState(List())
    const [instanceId, setInstanceId] = useState(props.instanceId)
    const [invoiceRecipient, setInvoiceRecipient] = useState(props.invoiceRecipient)

    useEffect(() => {(async () => {

            const result = await axios.get(`/administration/block_instances`, {
                headers: {
                    'Accept': 'application/json',
                },
                params: {
                    'block_version_id': props.id,
                    'all_versions': true
                }
            })

            if(result.status === 200) {
                setInstances(List(result.data).insert(0, {id: 0, name: 'Angerechnet'}))
            }

    })()}, [props.id])

    useEffect(() => {(async () => {

        let item = null

        if(isPresent(instanceId) && instanceId !== props.instanceId) {
            const {startsAt, endsAt} = instances.find(item => item.id === instanceId)
            const caseStudy = state.schedule.find(i => i.kind === 'case_study' && i.blockId === props.id)

            if(!caseStudy) {

                if(instanceId !== 0) {
                    addCaseStudy(state, dispatch, props.id, props.blockKey, props.name)
                }

            } else {

                if(instanceId === 0) {
                    const index = state.schedule.findIndex(item => item.kind === 'case_study' && item.blockId === props.id)
                    dispatch({type: 'SCHEDULE_DELETE', payload: index})
                }

            }

            item = {instanceId, startsAt, endsAt}
        }

        if(invoiceRecipient !== props.invoiceRecipient) {
            item = {invoiceRecipient}
        }

        if(item) { dispatch({type: 'SCHEDULE_MODIFY', payload: {index, item}}) }

    })()}, [instanceId, invoiceRecipient])

    return (
        <React.Fragment>
            <td>
                { props.blockKey ? `[${props.blockKey}] ${props.name}`: props.name }
            </td>
            <td>
                <SelectBox items={instances.map(i => ({value: i.id, title: i.name})).toJS()} value={props.instanceId} onChange={(item) => setInstanceId(item.value)} />
            </td>
            {state.splitMode === 'lines' &&
                <td>
                    <SelectBox disabled={props.instanceId === 0} includeEmpty={true} items={state.invoiceRecipients.map((r, idx) => ({value: idx, title: r.name})).toJS()} value={props.invoiceRecipient} onChange={(item) => setInvoiceRecipient(item.value)} />
                </td>
            }
            <td className='text-right'>
                <Money value={props.instanceId === 0 || !isPresent(props.instanceId) ? 0 : props.price} />
            </td>
        </React.Fragment>
    )
}

Block.propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.number,
    name: PropTypes.string,
    blockKey: PropTypes.string,
    instanceId: PropTypes.number,
    price: PropTypes.number,
    invoiceRecipient: PropTypes.number
}

const CaseStudy = ({blockKey, name, ...props}) => {
    const {state} = useContext(Context)

    return (
        <React.Fragment>
            <td>{blockKey ? `[${blockKey}] ${name}` : name}</td>
            <td>{dateRangeLabel(props)}</td>
            {state.splitMode === 'lines' && <td />}
            <td />
        </React.Fragment>
    )
}

CaseStudy.propTypes = {
    blockKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
}

const Gap = (props) => {
    const {state} = useContext(Context)

    return (
        <React.Fragment>
            <td>{props.description}</td>
            <td>{dateRangeLabel(props)}</td>
            { state.splitMode === 'lines' && <td/> }
            <td />
        </React.Fragment>
    )
}

Gap.propTypes = {
    description: PropTypes.string.isRequired
}

const Break = (props) => {
    const {state, dispatch} = useContext(Context)
    const [showModal, setShowModal] = useState(false)
    const [blockVersions, setBlockVersions] = useState([])
    const [blockVersionId, setBlockVersionId] = useState(null)
    const [blockInstances, setBlockInstances] = useState([])
    const [blockInstanceId, setBlockInstanceId] = useState(null)

    useEffect(() => {(async () => {
        if(!showModal) { setBlockVersions([]); return }

        const result = await axios.get('/administration/block_versions', {
            headers: {
                'Accept': 'application/json'
            },
            params: {
                from: props.startsAt,
                to: props.endsAt
            }
        })

        if(result.status === 200) {
            const blockIds = state.schedule.filter(i => i.kind === 'block').map(b => b.id)

            const data = result.data.filter(b => !blockIds.contains(b.id))

            if(data.length === 0) {
                setShowModal(false)
                alert('Kein passenden Blöcke gefunden')
            }

            setBlockVersions(data)
        }
    })()}, [showModal])

    useEffect(() => {(async () => {
        if(!blockVersionId) { return }

        const result = await axios.get('/administration/block_instances', {
            headers: {
                'Accept': 'application/json'
            },
            params: {
                block_version_id: blockVersionId,
                from: props.startsAt,
                to: props.endsAt
            }
        })

        if(result.status === 200) {
            setBlockInstances(result.data)
        }
    })()}, [blockVersionId])

    const onAddBlock = () => {

        const blockVersion = blockVersions.find(b => b.id === blockVersionId)
        const blockInstance = blockInstances.find(b => b.id === blockInstanceId)

        const item = {kind: 'block', id: blockVersionId, name: blockVersion.name, blockKey: blockVersion.key, validity: blockVersion.validity, price: blockVersion.price, instanceId: blockInstanceId, invoiceRecipient: null, startsAt: blockInstance.startsAt, endsAt: blockInstance.endsAt, hasProgramExamination: blockVersion.hasProgramExamination}

        dispatch({type: 'SCHEDULE_INSERT', payload: {index: props.index, item}})

        addCaseStudy(state, dispatch, blockVersionId, blockVersion.key, blockVersion.name)

        setShowModal(false)
    }

    return (
        <React.Fragment>
            <td>
                { `${props.duration} ${props.duration === 1 ? 'Unterrichtstag' : 'Unterrichtstage'}` }
                { props.duration >= 16 &&
                    <React.Fragment>
                        <i className='fa-light fa-chalkboard-user' title='Block einplanen' style={{marginLeft: '5px', cursor: 'pointer'}} onClick={() => setShowModal(true)} />
                        <Modal isVisible={showModal}>
                            <Dialog onClose={() => setShowModal(false)} title='Block auswählen'>
                                <div className='form-group'>
                                    <SelectBox items={blockVersions.map(b => ({value: b.id, title: b.humanName}))} value={blockVersionId} onChange={(item) => setBlockVersionId(item.value)} />
                                </div>
                                { blockInstances.length > 0 &&
                                    <div className='form-group'>
                                        <SelectBox items={blockInstances.map(b => ({value: b.id, title: b.name}))} value={blockInstanceId} onChange={(item) => setBlockInstanceId(item.value)} />
                                    </div>
                                }
                                { blockInstanceId &&
                                    <button type='button' className='btn btn-primary' onClick={onAddBlock}>Hinzufügen</button>
                                }
                            </Dialog>
                        </Modal>
                    </React.Fragment>
                }
            </td>
            <td>{dateRangeLabel(props)}</td>
            {state.splitMode === 'lines' && <td/>}
            <td />
        </React.Fragment>
    )
}

Break.propTypes = {
    index: PropTypes.number.isRequired,
    startsAt: PropTypes.string.isRequired,
    endsAt: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired
}

const Examination = ({date}) => {
    const {state} = useContext(Context)
    const [showPicker, setShowPicker] = useState(false)

    if(date) {
        date = parseISOString(date)
    }

    return (
        <React.Fragment>
            <td />
            <td>
                {isPresent(date) ? dateFormatter.format(date) : date}
                <span style={{marginLeft: '5px', cursor: 'pointer'}}>
                    <i className='fa-light fa-calendar' />
                </span>
                <Modal isVisible={showPicker}>
                    <DateTimePicker saveLabel={'Speichern'} onSave={() => setShowPicker(false)} />
                </Modal>
            </td>
            {state.splitMode === 'lines' && <td />}
            <td/>
        </React.Fragment>
    )
}

Examination.propTypes = {
    date: PropTypes.string
}

const FreeText = ({index, text}) => {
    const {state, dispatch} = useContext(Context)

    const [value, setValue] = useState(text)

    useEffect(() => {
        if(value === text) { return }
        dispatch({type: 'SCHEDULE_MODIFY', payload: {index, item: {text: value}}})
    }, [value])

    return (
        <React.Fragment>
            <td colSpan={state.splitMode === 'lines' ? 3 : 2}>
                <textarea className='form-control' value={value} onChange={(e) => setValue(e.target.value)} /></td>
            <td/>
        </React.Fragment>
    )
}

FreeText.propTypes = {
    index: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
}

const Certificate = ({index, ...props}) => {
    const {state, dispatch} = useContext(Context)
    const [invoiceRecipient, setInvoiceRecipient] = useState(null)
    const [isFree, setIsFree] = useState(props.isFree)

    useEffect(() => {
        if(invoiceRecipient === props.invoiceRecipient && isFree === props.isFree) { return }
        const certificate = Object.assign(state.certificates.get(index), {invoiceRecipient, isFree})
        dispatch({type: 'UPDATE_CERTIFICATES', payload: state.certificates.set(index, certificate)})
    }, [invoiceRecipient, isFree])

    return (
        <React.Fragment>
            <td>{props.title}</td>
            <td className='text-center'>
                <div className='checkbox'>
                    <input type='checkbox' readOnly={true} checked={props.isFree} disabled={props.price === 0} />
                    <label className='mb-0' onClick={() => setIsFree(!props.isFree)} />
                </div>
            </td>
            { state.splitMode === 'lines' &&
                <td>
                    <SelectBox disabled={props.isFree} items={state.invoiceRecipients.map((recipient, idx) => ({value: idx, title: recipient.name})).toJS()} includeEmpty={true} value={props.invoiceRecipient} onChange={(item) => setInvoiceRecipient(item.value)} />
                </td>
            }
            <td className='text-right'>
                <Money value={props.isFree ? 0 : props.price} />
            </td>
        </React.Fragment>
    )
}

Certificate.propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    isFree: PropTypes.bool.isRequired,
    invoiceRecipient: PropTypes.number
}

const LineTypeLabels =  {
    block: 'Block',
    break: 'Lücke',
    case_study: 'Fallstudie',
    examination: 'Abschlussprüfung',
    free_text: 'Freitext',
    gap: 'Unterbrechung'
}

const LineType = {
    block: Block,
    case_study: CaseStudy,
    examination: Examination,
    break: Break,
    free_text: FreeText,
    certificate: Certificate,
    gap: Gap
}

const DraggableLine = ({index, type, onDelete, isDeletable, rowHighlightClass, ...props}) => {
    const deleteColumn = isDeletable ? <td onClick={onDelete}><i className='fa-light fa-trash-alt' style={{cursor: 'pointer'}}/></td> : <td />
    const LineContents = LineType[type]
    const isDraggable = type === 'case_study' || type === 'free_text'

    return (
        <Draggable draggableId={`${index}`} index={index} isDragDisabled={!isDraggable}>
            {provided => (
                <tr ref={provided.innerRef} className={rowHighlightClass} style={{cursor: 'pointer'}} {...provided.draggableProps} >
                    <td className='key' {...provided.dragHandleProps}>
                        {index + 1}
                        {isDraggable &&
                            <i className='fa-light fa-arrows-up-down ml-2'/>
                        }
                    </td>
                    <td>
                        <LineTypeBadge type={type} />
                    </td>
                    <LineContents index={index} {...props} />
                    { deleteColumn }
                </tr>
            )}
        </Draggable>
    )
}

DraggableLine.propTypes = {
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    isDeletable: PropTypes.bool.isRequired,
    rowHighlightClass: PropTypes.string,
    startsAt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    endsAt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    instanceId: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ])
}

const LineTypeBadge = ({type}) => {

    let badge = ''

    if(type === 'block')
        badge = 'badge-primary'
    else if(type === 'case_study')
        badge = 'badge-info'
    else if(type === 'examination')
        badge = 'badge-success'
    else if(type === 'break')
        badge = 'badge-warning'
    else if(type === 'free_text')
        badge = 'badge-dark'
    else if (type === 'gap')
        badge = 'badge-danger'
    else
        badge = 'badge-default'

    return (
        <span className={`badge ${badge} text-nowrap`}>
            { LineTypeLabels[type] }
        </span>
    )
}

LineTypeBadge.propTypes = {
    type: PropTypes.string.isRequired,
}

const Line = ({index, type, children, onDelete, isDeletable, ...props}) => {
    const deleteColumn = isDeletable ? <td onClick={onDelete}><i className='fa-light fa-trash-alt' style={{cursor: 'pointer'}}/></td> : <td />
    return (
        <tr >
            <td>{index + 1}</td>
            <td>{children}</td>
            {
                React.createElement(LineType[type], Object.assign(props, {index}))
            }
            { deleteColumn }
        </tr>
    )
}

Line.propTypes = {
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    isDeletable: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ])
}

const Kinds = ({options, value, onChange}) => {
    const [selection, setSelection] = useState(value)

    useEffect(() => onChange(selection), [selection])

    return(
        <select value={selection} onChange={(e) => setSelection(e.target.value)}>
            { options.map((option, idx) => <option key={idx} value={option.value}>{option.label}</option>) }
        </select>
    )
}

Kinds.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func
}

const BlockForm = ({item, onChange}) => {
    const {state} = useContext(Context)
    const [blockVersions, setBlockVersions] = useState(List())
    const [blockVersionId, setBlockVersionId] = useState()

    useEffect(() => {(async () => {

        const result = await axios.get('/administration/block_versions', {
            headers: {
                'Accept': 'application/json'
            }
        })

        if(result.status === 200) {
            setBlockVersions(List(result.data))
        }

    })()}, [])

    useEffect(() => {(async () => {

        if(!blockVersionId) { return }

        const blockVersion = blockVersions.find(b => b.id === blockVersionId)

        onChange({id: blockVersionId, name: blockVersion.name, blockKey: blockVersion.key, validity: blockVersion.validity, price: blockVersion.price, instanceId: null, invoiceRecipient: null, startsAt: null, endsAt: null, hasProgramExamination: blockVersion.hasProgramExamination})

    })()}, [blockVersionId])

    return (
        <React.Fragment>
            <div className='form-group'>
                <label>Block</label>
                <SelectBox items={blockVersions.filterNot(b => state.schedule.filter(i => i.kind === 'block').map(i => i.id).includes(b.id)).map(b => ({value: b.id, title: b.humanName})).toJS()} name='blockId' required={true} value={item.blockId} onChange={item => setBlockVersionId(item.value)} />
            </div>
        </React.Fragment>
    )
}

BlockForm.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

const FreeTextForm = ({item, onChange}) => (
    <div className='form-group'>
        <label>Text</label>
        <textarea className='form-control' name='text' required={true} onChange={e => onChange({text: e.target.value})}>
            {item.text}
        </textarea>
    </div>
)

FreeTextForm.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

const GapForm = ({onChange}) => {
    const [description, setDescription] = useState('')
    const [startsAt, setStartsAt] = useState(new Date())
    const [endsAt, setEndsAt] = useState(new Date())

    useEffect(() => {
        onChange({startsAt: toISOString(startsAt), endsAt: toISOString(endsAt), description})
    }, [startsAt, endsAt, description])

    const onChangeStartsAt = (date) => {
        setStartsAt(date)

        if(date > endsAt) {
            setEndsAt(date)
        }
    }

    return(
        <React.Fragment>
            <div className='form-group'>
                <label className='required'>Beschreibung</label>
                <input className='form-control' name='description' type='text' required value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className='row'>
                <div className='col-12 col-sm-6'>
                    <div className='form-group'>
                        <label className='required'>Startdatum</label>
                        <input className='form-control' name='startsAt' type='text' required readOnly={true} value={dateFormatter.format(startsAt)} />
                        <DateTimePicker date={startsAt} displayTime={false} saveLabel='Speichern' onChange={onChangeStartsAt} />
                    </div>
                </div>
                <div className='col-12 col-sm-6'>
                    <div className='form-group'>
                        <label className='required'>Enddatum</label>
                        <input className='form-control' name='endsAt' type='text' required readOnly={true} value={dateFormatter.format(endsAt)} />
                        <DateTimePicker date={endsAt} displayTime={false} saveLabel='Speichern' onChange={setEndsAt} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

GapForm.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

const ScheduleForm = ({onSave}) => {
    const kinds = [
        {value: 'block', title: 'Block'},
        {value: 'gap', title: 'Unterbrechung'},
        {value: 'free_text', title: 'Freitext'}
    ]

    const {dispatch} = useContext(Context)
    const [item, setItem] = useState({})

    const onSubmit = (e) => {
        e.preventDefault()

        if(!e.target.reportValidity()) {
            return;
        }

        if(item.kind === 'block') {
            dispatch({type: 'SCHEDULE_INSERT', payload: {index: 0, item}})
        } else {
            dispatch({type: 'SCHEDULE_ADD', payload: {item}})
        }

        onSave()
    }

    const onChange = (update) => {
        setItem(Object.assign({}, item, update))
    }

    let Form

    if(item.kind === 'block') {
        Form = BlockForm
    } else if(item.kind === 'free_text') {
        Form = FreeTextForm
    } else {
        Form = GapForm
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group'>
                <label>Art</label>
                <SelectBox name='kind' items={kinds} value={item.kind} required={true} onChange={(item) => onChange({kind: item.value})} />
            </div>
            { item.kind && <Form item={item} onChange={onChange} />}
            <button type='submit' className='btn btn-primary'>Speichern</button>
        </form>
    )
}

ScheduleForm.propTypes = {
    onSave: PropTypes.func.isRequired
}

class Schedule extends React.PureComponent {

    static contextType = Context

    state = { showForm: false, showPicker: false }

    onDragEnd = ({source, destination}) => {
        const {schedule} = this.context.state
        const sourceItem = schedule.get(source.index)

        if(source.index > destination.index) {

            if(sourceItem.kind === 'case_study' && schedule.findIndex(item => item.kind === 'block' && item.id === sourceItem.blockId) >= destination.index) {
                alert('Fallstudie kann nicht vor dem zugehörigen Block eingeplant werden')
                return
            }

        } else {

            if(sourceItem.kind === 'block' && destination.index >= schedule.findIndex(item => item.kind === 'case_study' && item.blockId === sourceItem.id)) {
                alert('Block kann nicht nach der zugehörigen Fallstudie eingeplant werden')
                return
            }

        }

        if(source.index !== destination.index) {
            this.context.dispatch({type: 'SCHEDULE_MOVE', payload: {from: source.index, to: destination.index}})
        }
    }

    isDeletableLine = ({kind}) => {
        return kind === 'block' || kind === 'free_text' || kind === 'gap'
    }

    onDelete = (idx) => {
        const {schedule} = this.context.state

        const target = schedule.get(idx)

        this.context.dispatch({type: 'SCHEDULE_DELETE', payload: idx})

        if(target.kind === 'block') {
            let index = schedule.findIndex(item => item.kind === 'case_study' && item.blockId === target.id);

            if(index >= 0) {
                this.context.dispatch({type: 'SCHEDULE_DELETE', payload: index - 1})
            }
        }
    }

    onSaveExaminationDate = (date) => {
        const lastScheduledItem = this.context.state.schedule.filter(i => isPresent(i.endsAt)).reduce((prev, current) => new Date(current.endsAt) > new Date(prev.endsAt) ? current : prev)

        if(new Date(lastScheduledItem.endsAt) >= date) {
            alert('Abschlussprüfung darf nicht vor anderen Terminen eingeplant werden')
        }

        this.context.dispatch({type: 'SET_EXAMINATION_DATE', payload: {date: toISOString(date), custom: true}})
        this.setState({showPicker: false})
    }

    render() {

        const {schedule, splitMode, examinationDate, amounts} = this.context.state

        const overlaps = overlappingDates(schedule)

        const rowHighlight = (index, line) => {
            if(line.kind === 'break') { return 'table-warning' }
            if(overlaps.includes(index)) { return 'table-danger' }
            return null
        }

        return (
            <React.Fragment>
                <div className='d-flex'>
                    <h2>Verlauf</h2>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd} onDragUpdate={this.onDragUpdate}>
                    <table className='table table-vertical-middle'>
                        <thead>
                            <tr>
                                <th className='key'>Pos.</th>
                                <th style={{width: '1px'}}>Art</th>
                                <th>Bezeichnung</th>
                                <th style={{minWidth: '203px'}}>Zeitraum</th>
                                { splitMode === 'lines' && <th>Rechnungsempfänger</th> }
                                <th className='text-right'>Preis</th>
                                <th className='action'/>
                            </tr>
                        </thead>
                        <Droppable droppableId={'schedule'}>
                            {(provided) => (
                                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        schedule.map((line, idx) => (
                                            <DraggableLine key={idx} index={idx} type={line.kind} rowHighlightClass={rowHighlight(idx, line)} isDeletable={this.isDeletableLine(line)} onDelete={() => this.onDelete(idx)} {...line} />
                                        ))
                                    }
                                    {provided.placeholder}
                                    { examinationDate.date &&
                                        <tr>
                                            <td className='key'>{schedule.size + 1}</td>
                                            <td>
                                                <LineTypeBadge type='examination' />
                                            </td>
                                            <td />
                                            <td>
                                                {dateFormatter.format(new Date(examinationDate.date))}
                                                <span style={{marginLeft: '5px', cursor: 'pointer'}} onClick={() => this.setState({showPicker: true})}>
                                                    <i className='fa-light fa-calenda' />
                                                </span>
                                                <Modal isVisible={this.state.showPicker}>
                                                    <DateTimePicker date={new Date(this.context.state.examinationDate.date)} displayTime={false} saveLabel={'Speichern'} onSave={this.onSaveExaminationDate}>
                                                        <button type='button' className='reset' onClick={() => this.setState({showPicker: false})} tabIndex='5'>Abbrechen</button>
                                                    </DateTimePicker>
                                                </Modal>
                                            </td>
                                            <td />
                                            <td className='action'/>
                                        </tr>
                                    }
                                    <tr>
                                        <td className='key'/>
                                        <td colSpan='2'>
                                            <button type='button' className='btn btn-sm btn-primary m-0' onClick={() => this.setState({showForm: true})}>
                                                <i className='fa-light fa-plus mr-2'/>
                                                Position hinzufügen
                                            </button>
                                        </td>
                                        <td colSpan={splitMode === 'lines' ? 3 : 2} className='text-right text-bold'>
                                            <Money value={amounts.subtotals.blocks} />
                                        </td>
                                        <td className='action'/>
                                    </tr>
                                </tbody>
                            )}
                        </Droppable>
                    </table>
                </DragDropContext>
                <Modal isVisible={this.state.showForm}>
                    <Dialog title='Position hinzufügen' onClose={() => this.setState({showForm: false})}>
                        <ScheduleForm onSave={() => this.setState({showForm: false})} />
                    </Dialog>
                </Modal>
            </React.Fragment>
        )

    }

}

const CertificateForm = ({onSave}) => {
    const {state, dispatch} = useContext(Context)

    const [certificates, setCertificates] = useState([])
    const [certificate, setCertificate] = useState({})
    const [showAll, setShowAll] = useState(!isPresent(state.courseVersionId))

    useEffect(() => {(async () => {

        const params = {}

        const start = state.schedule.find(i => isPresent(i.startsAt))

        if(isPresent(start)) {
            params['date'] = start.startsAt
        }

        if(isPresent(state.courseVersionId) && !showAll) {
            params['course_version_id'] =  state.courseVersionId
        }

        const config = { headers: { 'Accept': 'application/json' }, params }

        const result = await axios.get('/administration/sales/quotes/certificates', config)

        if(result.status === 200) {

            const data = List(result.data).filterNot(c => state.certificates.some(i => i.id === c.id))

            setCertificate({})
            setCertificates(data.toJS())
        }

    })()}, [showAll])

    const onChange = (item) => {

        const selection = certificates.find(c => c.id === item.value)

        setCertificate(Object.assign({}, selection, {isFree: selection.price === 0, invoiceRecipient: null}))

    }

    const isEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
    }

    const onSubmit = (e) => {

        e.preventDefault()

        if(e.target.reportValidity()) {
            dispatch({type: 'UPDATE_CERTIFICATES', payload: state.certificates.push(certificate)})
            if(!isEmpty(certificate)) { onSave() }
        }

    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group'>
                <label>Zertifikat</label>
                {certificates.length === 0 &&
                    <div className='alert alert-info'>Keine Zertifikate verfügbar</div>
                }
                {certificates.length > 0 &&
                    <SelectBox name='certificate' items={certificates.map(c => ({value: c.id, title: `${c.title} (${c.kindName})`}))} required={true} onChange={onChange} />
                }
            </div>
            <div className='checkbox mb-3'>
                <input type='checkbox' name='show-all' readOnly={true} checked={showAll} />
                <label onClick={() => setShowAll(!showAll)}>Alle Zertifikate</label>
            </div>
            <button type='submit' disabled={isEmpty(certificate)} className='btn btn-primary'>Speichern</button>
        </form>
    )

}

CertificateForm.propTypes = {
    onSave: PropTypes.func.isRequired
}

const badgeMap = {
    'tuev': 'badge-primary',
    'habmann': 'badge-info'
}

class Certificates extends React.PureComponent {

    static contextType = Context

    state = {
        showModal: false,
        certificateTypes: []
    }

    async componentDidMount() {
        const response = await axios.get('/administration/certificate_types', {
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            this.setState({certificateTypes: response.data})
        }
    }

    onDelete = (idx) => {
        this.context.dispatch({type: 'UPDATE_CERTIFICATES', payload: this.context.state.certificates.delete(idx)})
    }

    getCertificateType = (kind) => {
        const type = this.state.certificateTypes.find(ct => ct.alias === kind)
        let badge = badgeMap[kind]

        if(!badge) {
            badge = 'badge-dark'
        }

        return Object.assign({}, type, {badge})
    }

    render() {

        const {certificates, splitMode, amounts} = this.context.state

        return (
            <React.Fragment>
                <div className='d-flex'>
                    <h2>Abschlusszertifikate</h2>
                </div>
                <table className='table table-vertical-middle'>
                    <thead>
                        <tr>
                            <th className='key'>Pos.</th>
                            <th style={{width: '1px'}}>Art</th>
                            <th>Bezeichnung</th>
                            <th className='text-center'>Kostenlos</th>
                            { splitMode === 'lines' &&
                                <th>Rechnungsempfänger</th>
                            }
                            <th className='text-right'>Preis</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            certificates.map((line, idx) => {
                                const certificateKind = this.getCertificateType(line.kind)
                                return (
                                    <Line key={line.id} index={idx} {...line} type='certificate' isDeletable={true}
                                          onDelete={() => this.onDelete(idx)}>
                                        <span className={`badge ${certificateKind.badge}`}>
                                            {certificateKind.name}
                                        </span>
                                    </Line>
                                )
                            })
                        }
                        <tr>
                            <td className='key'/>
                            <td colSpan='2'>
                                <button type='button' className='btn btn-sm btn-primary m-0' onClick={() => this.setState({showModal: true})}>
                                    <i className='fa-light fa-plus mr-2'/>
                                    Abschlusszertifikat hinzufügen
                                </button>
                            </td>
                            <td colSpan={splitMode === 'lines' ? 3 : 2} className='text-right text-bold'>
                                <Money value={amounts.subtotals.certificates} />
                            </td>
                            <td className='action'/>
                        </tr>
                    </tbody>
                </table>
                <Modal isVisible={this.state.showModal}>
                    <Dialog title='Abschlusszertifikat hinzufügen' onClose={() => this.setState({showModal: false})}>
                        <CertificateForm onSave={() => this.setState({showModal: false})} />
                    </Dialog>
                </Modal>
            </React.Fragment>
        )

    }

}

const Address = ({name, street, street_number, zip, city}) => (
    <p>
        <b>{name}</b><br />
        {street} {street_number}<br />
        {zip} {city}
    </p>
)

Address.propTypes = {
    name: PropTypes.string,
    street: PropTypes.string,
    street_number: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string
}

const JobAgencySelection = ({recipient, onChange}) => {
    const [jobAgencies, setJobAgencies] = useState(List())

    useEffect(() => {(async () => {
        const result = await axios.get('/administration/job_centers', {
            headers: { 'Accept': 'application/json' }
        })

        if(result.status === 200) {
            setJobAgencies(List(result.data))
        }
    })()}, [])

    const onChangeAgency = (item) => {
        const agency = jobAgencies.find(agency => agency.id === item.value)

        onChange(Object.assign({}, recipient, agency, {kind: 'job_agency'}))
    }

    return (
        <div className='form-group'>
            <label className='required'>Standort</label>
            <SelectBox required={true} items={jobAgencies.map(agency => ({value: agency.id, title: agency.name})).toJS()} onChange={onChangeAgency} />
        </div>
    )
}

JobAgencySelection.propTypes = {
    recipient: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

const ContactDetails = ({recipient, onChange}) => (
    <React.Fragment>
        <div className='form-group'>
            <label className='required'>Name</label>
            <input className='form-control' name='name' type='text' required value={recipient.name || ''} onChange={onChange} />
        </div>
        <div className='row'>
            <div className='col-12 col-sm-8'>
                <div className='form-group'>
                    <label className='required'>Straße</label>
                    <input className='form-control' name='street' type='text' required value={recipient.street || ''} onChange={onChange} />
                </div>
            </div>
            <div className='col-12 col-sm-4'>
                <div className='form-group'>
                    <label className='required'>Hausnummer</label>
                    <input className='form-control' name='street_number' type='text' required value={recipient.street_number || ''} onChange={onChange} />
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12 col-sm-4'>
                <div className='form-group'>
                    <label className='required'>Postleitzahl</label>
                    <input className='form-control' name='zip' type='text' required value={recipient.zip || ''} onChange={onChange} />
                </div>
            </div>
            <div className='col-12 col-sm-8'>
                <div className='form-group'>
                    <label className='required'>Stadt</label>
                    <input className='form-control' name='city' type='text' required value={recipient.city || ''} onChange={onChange} />
                </div>
            </div>
        </div>
    </React.Fragment>
)

ContactDetails.propTypes = {
    recipient: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

const InvoiceRecipientForm = ({onSave}) => {
    const kinds = [
        { value: 'job_agency', title: 'Agentur für Arbeit' },
        { value: 'company', title: 'Firma' },
        { value: 'student', title: 'Teilnehmer'},
        { value: 'person', title: 'Person' },
    ]

    const {state, dispatch} = useContext(Context)

    const {invoiceRecipients} = state

    const [kind, setKind] = useState(null)
    const [recipient, setRecipient] = useState({splitRatio: invoiceRecipients.size === 0 ? 100 : 0, discount: 0})

    const onChange = (e) => {
        setRecipient(Object.assign({}, recipient, {[e.target.name]: e.target.value}))
    }

    const onSubmit = (e) => {

        e.preventDefault()

        if(e.target.reportValidity()) {
            onSave()
            dispatch({type: 'UPDATE_INVOICE_RECIPIENTS', payload: invoiceRecipients.push(recipient)})
        }

    }

    useEffect(() => {(async () => {
        setRecipient({splitRatio: invoiceRecipients.size === 0 ? 100 : 0, discount: 0})

        if(!kind || kind !== 'student') { return }

        const result = await axios.get(`${location.href}/lead`, {
            headers: { 'Accept': 'application/json' }
        })

        if(result.status === 200) {
            const {name, address} = result.data

            setRecipient(Object.assign({}, recipient, {name, ...address}))
        }
    })()}, [kind])

    let details;

    switch(kind) {
        case 'job_agency':
            details = <JobAgencySelection recipient={recipient} onChange={setRecipient} />
            break
        case 'company':
        case 'person':
        case 'student':
            details = <ContactDetails recipient={recipient} onChange={onChange} />
            break
        default:
            details = null
    }

    return (
        <form onSubmit={onSubmit}>
            <div className='form-group'>
                <label>Art</label>
                <SelectBox items={kinds} value={kind} onChange={(item) => setKind(item.value)} />
            </div>
            { details }
            <button className='btn btn-primary' type='submit'>Speichern</button>
        </form>
    )
}

InvoiceRecipientForm.propTypes = {
    onSave: PropTypes.func.isRequired
}

class InvoiceRecipients extends React.PureComponent {

    static contextType = Context

    state = {
        showModal: false
    }

    toPercentage = (value) => {
        value = parseInt(value, 10)
        return isNaN(value) ? 0 : Math.min(Math.max(value, 0), 100)
    }

    onChange = (e, idx) => {
        const {invoiceRecipients} = this.context.state

        const value = this.toPercentage(e.target.value)
        const recipient = Object.assign(invoiceRecipients.get(idx), {[e.target.name]: value})

        this.context.dispatch({type: 'UPDATE_INVOICE_RECIPIENTS', payload: invoiceRecipients.set(idx, recipient)})
    }

    onDelete = (idx) => {
        const {invoiceRecipients, schedule} = this.context.state

        const recipient = invoiceRecipients.get(idx)
        const remainder = recipient.splitRatio / (invoiceRecipients.size - 1)

        this.context.dispatch(
            {
                type: 'UPDATE_INVOICE_RECIPIENTS',
                payload: invoiceRecipients.delete(idx).map(i => Object.assign(i, {splitRatio: i.splitRatio + remainder}))
            }
        )

        // State is not yet updated so when checking for remaining recipients add one

        if(invoiceRecipients.size > 2) {

            // Removing an recipient should clear its assignments

            this.context.dispatch({
                type: 'SCHEDULE_UPDATE',
                payload: schedule.map(item => item.invoiceRecipient === idx ? Object.assign(item, {invoiceRecipient: null}) : item)
            })


        } else if(invoiceRecipients.size === 2) {

            // If only a single recipient is left, remove all assignments

            this.context.dispatch({
                type: 'SCHEDULE_UPDATE',
                payload: schedule.map(item => ( item.kind === 'block' ? Object.assign(item, {invoiceRecipient: null}) : item))
            })

            this.context.dispatch({type: 'SET_SPLIT_MODE', payload: 'percentage'})

        }

    }

    render() {
        const {invoiceRecipients, splitMode, amounts} = this.context.state

        const hasMultipleRecipients = invoiceRecipients.size > 1

        return (
            <React.Fragment>
                <h2 className='float-left'>Rechnungsempfänger</h2>
                {hasMultipleRecipients &&
                    <div className='float-right pb-2' style={{width: '200px'}}>
                        <SelectBox items={[
                            {value: 'percentage', title: 'Prozentual aufteilen'},
                            {value: 'lines', title: 'Nach Positionen aufteilen'}
                        ]} value={splitMode} onChange={item => this.context.dispatch({
                            type: 'SET_SPLIT_MODE',
                            payload: item.value
                        })}/>
                    </div>
                }
                <table className='table'>
                    <thead>
                        <tr>
                            <th className='key'>Pos.</th>
                            <th>Empfänger</th>
                            { splitMode === 'percentage' && hasMultipleRecipients && <th>Aufteilungsquote</th> }
                            <th>Rabatt</th>
                            <th />
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                    {
                        invoiceRecipients.map((recipient, idx) => {

                            const isJobAgency = recipient.kind === 'job_agency'
                            const {net, discount, taxes, gross} = amounts.invoiceRecipients.get(idx)

                            return (
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>
                                        <Address {...recipient} />
                                    </td>
                                    { splitMode === 'percentage' && (recipient.splitRatio < 100 || hasMultipleRecipients) &&
                                        <td>
                                            <input className='form-control' name='splitRatio' type='number' disabled={invoiceRecipients.size === 1} step='5' min='0' max='100' value={recipient.splitRatio} onChange={(e) => this.onChange(e, idx)} onWheel={disableWheelInput}/>
                                        </td>
                                    }
                                    <td>
                                        <input className='form-control' name='discount' type='number' disabled={isJobAgency} min='0' max='100' value={recipient.discount} onChange={(e) => this.onChange(e, idx)} onWheel={disableWheelInput}/>
                                    </td>
                                    <td className='pr-0 width-0 text-nowrap'>
                                        <div>Nettobetrag</div>
                                        { recipient.discount > 0 && <React.Fragment><div>Rabatt {recipient.discount}%</div></React.Fragment>}
                                        { !isJobAgency && <React.Fragment><div>zzgl. 19% MwSt.</div></React.Fragment> }
                                        <hr className='mt-1 mb-1'/>
                                        <div className='text-bold pr-5'>Zwischensumme</div>
                                    </td>
                                    <td className='text-right pl-0 width-0 text-nowrap'>
                                        <div><Money value={net}/></div>
                                        { recipient.discount > 0 && <React.Fragment><div><Money value={discount * -1}/></div></React.Fragment>}
                                        { !isJobAgency && <React.Fragment><div><Money value={taxes} /></div></React.Fragment> }
                                        <hr className='mt-1 mb-1'/>
                                        <div><Money value={gross}/></div>
                                    </td>
                                    <td style={{cursor: 'pointer'}} onClick={() => this.onDelete(idx)}>
                                        <i className='fa-light fa-trash-alt'/>
                                    </td>
                                </tr>
                            )

                        })
                    }
                    <tr>
                        <td colSpan={splitMode === 'percentage' && hasMultipleRecipients ? 7 : 6} style={{height: '3px', padding: '0px'}}/>
                    </tr>
                    <tr>
                        <td className='key'/>
                        <td colSpan={splitMode === 'percentage' && hasMultipleRecipients ? 3 : 2}>
                            <button type='button' className='btn btn-sm btn-primary m-0' onClick={() => this.setState({showModal: true})}>
                                <i className='fa-light fa-plus mr-2'/>
                                Rechnungsempfänger hinzufügen
                            </button>
                        </td>
                        <td className='pr-0 width-0 text-nowrap'>
                            <div>Nettobetrag</div>
                            { amounts.totals.discount > 0 && <React.Fragment><div>Rabatte</div></React.Fragment>}
                            <div>zzgl. 19% MwSt.</div>
                            <hr className='mt-1 mb-1'/>
                            <div className='text-bold pr-5'>Gesamtbetrag</div>
                        </td>
                        <td className='text-right pl-0 width-0 text-nowrap'>
                            <div><Money value={amounts.totals.net} /></div>
                            { amounts.totals.discount > 0 && <React.Fragment><div><Money value={amounts.totals.discount * -1} /></div></React.Fragment> }
                            <div><Money value={amounts.totals.taxes} /></div>
                            <hr className='mt-1 mb-1'/>
                            <div className='text-bold'><Money value={amounts.totals.gross} /></div>
                        </td>
                        <td className='action'/>
                    </tr>
                    </tbody>
                </table>
                <Modal isVisible={this.state.showModal}>
                    <Dialog title='Rechnungsempfänger hinzufügen' onClose={() => this.setState({showModal: false})}>
                        <InvoiceRecipientForm onSave={() => this.setState({showModal: false})} />
                    </Dialog>
                </Modal>
            </React.Fragment>
        )

    }

}

const ResetButton = () => {
    const {dispatch} = useContext(Context)

    const onClickRevert = () => {
        if(confirm('Angebot wirklich zurücksetzen?')) { dispatch({type: 'RESET_STATE', payload: {}}) }
    }

    const content = (
        <button className='btn text-gray text-hover display-block padding-10 no-mrg-btm' onClick={onClickRevert}>
            <i className='fa-light fa-clock-rotate-left text-primary pdd-right-5' />
            <b>Zurücksetzen</b>
        </button>
    )

    const target = document.querySelector('#quote-revert')

    if(!target) { return null }

    return ReactDOM.createPortal(content, target);
}

const submit = () => {

    const form = document.createElement('form')

    form.setAttribute('action', `${location.href}/submit`)
    form.setAttribute('method', 'POST')
    form.style.display = 'none'

    const hiddenInput = document.createElement('input')

    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'authenticity_token')
    hiddenInput.setAttribute('value', document.querySelector('meta[name="csrf-token"]').content)

    form.appendChild(hiddenInput)

    const submitInput = document.createElement('input')

    submitInput.setAttribute('type', 'submit')

    form.appendChild(submitInput)

    document.body.appendChild(form)
    form.querySelector('[type="submit"]').click()

}

const SendButton = () => {
    const {state: {invoiceRecipients, certificates, schedule, amounts}} = useContext(Context)

    if(
        invoiceRecipients.size === 0 ||
        certificates.size === 0 ||
        schedule.filter(i => i.kind === 'block').size === 0 ||
        amounts.totals.net === 0
    ) { return null }

    const onClickSend = () => {
        if(confirm('Das Angebot kann nicht mehr verändert werden, nachdem es an den Teilnehmer/Interessent per E-Mail versendet worden ist.')) {
            submit()
        }
    }

    const content = (
        <button className='btn btn-success btn-sm m-0' onClick={onClickSend}>
            { /*
            <i className='fa-light fa-envelope text-white pdd-right-5' />
            <b>Verschicken</b>
            */ }
            <i className='fa-light fa-check text-white pdd-right-5' />
            <b>Angebot fertigstellen</b>
        </button>
    )

    const target = document.querySelector('#quote-send')

    if(!target) { return null }

    return ReactDOM.createPortal(content, target);
}

const InvalidPercentageSplit = () => {
    const {state: {invoiceRecipients, splitMode}} = useContext(Context)

    if(splitMode === 'lines' || invoiceRecipients.reduce((sum, item) => sum + item.splitRatio, 0) === 100) { return null }

    return <div className='alert alert-danger' role='alert'>Gesamtquote entspricht nicht 100% der Angebotssumme</div>
}

const MissingScheduleInvoiceRecipients = () => {
    const {state: {amounts, splitMode}} = useContext(Context)

    if (splitMode !== 'lines' || amounts.totals.net === (amounts.subtotals.blocks + amounts.subtotals.certificates)) { return null }

    return <div className='alert alert-danger' role='alert'>Es wurden nicht alle Positionen einem Rechnungsempfänger zugewiesen</div>
}

const MissingCertificateInvoiceRecipients = () => {
    const {state: {splitMode, certificates}} = useContext(Context)

    if(splitMode !== 'lines') { return null }

    const paidCertificates = certificates.filterNot(item => item.isFree)

    if(paidCertificates.size === paidCertificates.count(item => isPresent(item.invoiceRecipient))) { return null }

    return <div className='alert alert-danger' role='alert'>Es wurden nicht allen Zertifikaten ein Rechnungsempfänger zugewiesen</div>
}

const OverlappingRanges = () => {
    const {state: {schedule}} = useContext(Context)

    const overlaps = overlappingDates(schedule)

    if(overlaps.length === 0) { return null }

    return <div className='alert alert-danger' role='alert'>Es wurden überlappende Zeiträume gefunden</div>
}

const MissingInvoiceRecipient = () => {
    const {state: {invoiceRecipients}} = useContext(Context)

    if(invoiceRecipients.size > 0) { return null }

    return <div className='alert alert-danger' role='alert'>Kein Rechnungsempfänger angegeben</div>
}

const InvoiceSumIsZero = () => {
    const {state: {amounts}} = useContext(Context)

    if(amounts.totals.net > 0) { return null }

    return <div className='alert alert-danger' role='alert'>Rechnungsbetrag ist Null</div>
}

const MissingCertificate = () => {
    const {state: {certificates}} = useContext(Context)

    if(certificates.size > 0) { return null }

    return <div className='alert alert-danger' role='alert'>Kein Zertifikat hinzugefügt</div>
}

const MissingBlock = () => {
    const {state: {schedule}} = useContext(Context)

    if(schedule.filter(i => i.kind === 'block').size > 0) { return null }

    return <div className='alert alert-danger' role='alert'>Kein Block hinzugefügt</div>
}

const Errors = () => {

    return (
        <React.Fragment>
            <OverlappingRanges />
            <InvalidPercentageSplit />
            <MissingScheduleInvoiceRecipients />
            <MissingCertificateInvoiceRecipients />
            <MissingInvoiceRecipient />
            <InvoiceSumIsZero />
            <MissingCertificate />
            <MissingBlock />
        </React.Fragment>
    )

}

class Quotes extends React.PureComponent {

    render() {

        return (
            <ApplicationState>
                <Errors />
                <Schedule />
                <Certificates />
                <InvoiceRecipients />
                <ResetButton />
                {this.props.enableSendButton &&
                    <SendButton />
                }
            </ApplicationState>
        )

    }

}

Quotes.propTypes = {
    enableSendButton: PropTypes.bool.isRequired
};

Quotes.defaultProps = {
    enableSendButton: false
};

export default Quotes;