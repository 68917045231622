import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Immutable, {List, Set, Map} from 'immutable';
import SelectBox from './SelectBox';
import PropType from 'prop-types';

const romanNumerals = [
    '','C','CC','CCC','CD','D','DC','DCC','DCCC','CM',
    '','X','XX','XXX','XL','L','LX','LXX','LXXX','XC',
    '','I','II','III','IV','V','VI','VII','VIII','IX'
]

function romanize (num) {
    const digits = String(+num).split("")

    let roman = '', i = 3

    while (i--) {
        roman = (romanNumerals[+digits.pop() + (i * 10)] || "") + roman;
    }

    return Array(+digits.join("") + 1).join("M") + roman;
}

const formatDate = (date) => (
    new Intl.DateTimeFormat(document.documentElement.lang, {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(date))
)

const mapBlockData = (data) => {
    let object = {}

    data.forEach((d) =>{
        if(!object[d.blockId]) {
            object[d.blockId] = {}
        }
        object[d.blockId][d.blockStartId] = d.instances
    })
    return object
}

const updateHistory = (key, value) => {
    const url = new URL(location.href)

    if(value === null) {
        url.searchParams.delete(key)
    } else {
        url.searchParams.set(key, value)
    }

    history.pushState({turbolinks: true}, '', url)
}

const Utilisation = ({lecturerId, year}) => {
    if(!lecturerId || !year) { return null }

    const [utilisations, setUtilisations] = useState(Map())

    useEffect(() => {(async () => {
        const response = await axios.get(`/administration/lecturers/${lecturerId}/utilisation`, {
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setUtilisations(Map(response.data.map(u => {
                const {value, ...values} = u
                return [value, values]
            })))
        }
    })()}, [lecturerId])

    if(!utilisations.get(year)) {return null}

    const utilisation = utilisations.get(year)

    return (
        <React.Fragment>
            <span className={`badge badge-info ml-2`} title='Auslastung'>Auslastung {utilisation['utilisation']} von {utilisation['maxSchedulingPercentage']} %</span>
            <span className={`badge badge-info ml-2`} title='Unterrichtseinheiten'>{utilisation['utilisationUnits']} von {utilisation['teachingUnits']} UE</span>
        </React.Fragment>
    )
}

Utilisation.propTypes = {
    lecturerId: PropType.number,
    year: PropType.number
}

const lecturerInitials = ({firstName, lastName}) => firstName && lastName ? `${firstName[0]}${lastName[0]}` : ''

const combineFilters = (...filters) => item => filters.map(filter => filter(item)).every(x => x === true)

const LecturerBadge = ({lecturer, showLink}) => {
    const {firstName, lastName} = lecturer
    const classes = ['badge']
    const initials = lecturerInitials(lecturer)

    let badgeType = 'badge-info'

    if(lecturer.isEmployee) {
        badgeType = 'badge-primary'
    }

    if(lecturer.state === 'selected') {
        badgeType = 'badge-warning'
    }

    classes.push(badgeType)

    if(showLink) {
        classes.push('mt-1')
    }

    const content = (
        <span className={classes.join(' ')}>
            {initials}
        </span>
    )

    if(showLink) {
        return (
            <a href={`/administration/lecturers/${lecturer.id}`} title={`${firstName} ${lastName}`}>
                {content}
            </a>
        )
    }

    return content
}

LecturerBadge.propTypes = {
    lecturer: PropType.object.isRequired,
    showLink: PropType.bool.isRequired
}

const Block = ({blockStartId, blockId, blockData, blockInstanceLinks, lecturerLinks, lecturerId, onlyEmployees}) => {
    const [highlight, setHighlight] = useState(false)

    useEffect(() => {
        if(!blockData[blockId] || !blockData[blockId][blockStartId]) {
            setHighlight(false)
            return
        }

        setHighlight(blockData[blockId][blockStartId].some(instance =>
                instance.lecturers.filter(employedLecturers).some(lecturer =>
                        lecturer.id === lecturerId
                )
        ))
    }, [blockData, blockId, blockStartId, lecturerId, onlyEmployees])

    const employedLecturers = (lecturer) => {
        if (!onlyEmployees) {
            return true
        }

        return lecturer.isEmployee
    }

    return <td className={`text-center align-middle p-0${highlight ? ' bg-dark' : ''}`}>
        {blockData[blockId] && blockData[blockId][blockStartId] &&
                <ul className="block-instances-list">
                    {blockData[blockId][blockStartId].map((instance, instance_idx) => {
                        const lecturers = instance.lecturers.filter(employedLecturers)

                        return (
                                <li key={instance_idx}>
                                    <div className="lecturers">
                                        {
                                            lecturers.map((lecturer, lecturer_idx) => <LecturerBadge key={lecturer_idx} lecturer={lecturer} showLink={lecturerLinks} />)
                                        }
                                        {instance.missing_schedulings > 0 &&
                                                <span className="badge badge-danger mt-1" title="Nicht eingeplante Tage">
                                                    {instance.missing_schedulings}
                                                </span>
                                        }
                                    </div>
                                    {blockInstanceLinks &&
                                            <a href={`/administration/block_instances/${instance.block_instance_id}`}
                                               title="Zur Blockplanung">
                                                <i className={`fa-light fa-search ml-1${highlight ? ' text-white' : ''}`}/>
                                            </a>
                                    }
                                </li>
                        )
                    })}
                </ul>
        }
    </td>
}

const BlockRow = ({block, blockStarts, ...props}) => {
    return <tr id={`block_id_${block.id}`}>
        <td>
            {`[${block.key}] ${block.name}`}
        </td>
        {blockStarts.map((blockStart, idx) => <Block key={idx} blockStartId={blockStart.id} blockId={block.id} {...props} />)}
    </tr>
}

BlockRow.propTypes = {
    block: PropType.object.isRequired,
    blockStarts: PropType.instanceOf(List).isRequired,
    blockData: PropType.object.isRequired,
    onlyEmployees: PropType.bool,
    blockInstanceLinks: PropType.bool.isRequired,
    lecturerLinks: PropType.bool.isRequired,
    lecturerId: PropType.number
}

const BlockStartTable = ({
                             year, blocks, qualifications, lecturerId,
                             showSelection, onlyEmployees, onlyOpenSchedulings, lecturerLinks, blockInstanceLinks,
                             onClickBlockStart
                         }) => {
    const [blockStarts, setBlockStarts] = useState(List())
    const [blockData, setBlockData] = useState({})
    const blockQualifications = Set(qualifications.map(q => q.blockId))

    useEffect(() => {
        (async () => {
            let response = await axios.get('/administration/block_starts', {
                params: {year},
                headers: {'Accept': 'application/json'}
            })

            if (response.status === 200) {
                setBlockStarts(List(response.data))
            }

            response = await axios.get(location.href.split('?')[0], {
                params: {year, show_selection: showSelection},
                headers: {'Accept': 'application/json'}
            })

            if (response.status === 200) {
                setBlockData(mapBlockData(response.data))
            }
        })()
    }, [year, showSelection])

    const isQualified = ({id}) => {
        if (blockQualifications.size === 0) {
            return lecturerId === null
        }

        return blockQualifications.includes(id)
    }

    const openSchedulings = ({id}) => {
        if (!onlyOpenSchedulings) {
            return true
        }

        const blockStarts = blockData[id]

        if (typeof blockStarts === 'undefined') {
            return false
        }

        return Object.values(blockStarts).some(bs => bs.some(instance => instance.missing_schedulings > 0))
    }

    const qualificationType = ({id}) => {
        if(qualifications.size === 0) { return 'regular' }

        if(qualifications.find(q => q.blockId ===id).onlyAsSubstitute) {
            return 'substitute'
        } else {
            return 'regular'
        }
    }

    const groupedBlocks = blocks.filter(
            combineFilters(isQualified, openSchedulings)
    ).groupBy(qualificationType)

    return (
        <>
            {blockStarts.size === 0 &&
                <div className='alert alert-info'>Es sind keine Blockstarts vorhanden.</div>
            }
            {lecturerId && blockQualifications.size === 0 &&
                <div className='alert alert-info'>Dozent hat keine Block-Qualifizierungen.</div>
            }
            {blockStarts.size > 0 && (lecturerId === null || blockQualifications.size > 0) &&
                <table className='table table-hover fixed-header matrix'>
                    <thead>
                    <tr>
                        <th/>
                        {blockStarts.map((blockStart, idx) => (
                            <th key={idx} className='text-vertical' style={{cursor: 'pointer'}} onClick={() => onClickBlockStart(blockStart.date)}>
                                <span>
                                    {formatDate(blockStart.date)}
                                </span>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                        {groupedBlocks.has('regular') && groupedBlocks.get('regular').map((block) => (
                            <BlockRow key={block.id} block={block} blockStarts={blockStarts} blockData={blockData}
                                      blockInstanceLinks={blockInstanceLinks} onlyEmployees={onlyEmployees}
                                      lecturerLinks={lecturerLinks} lecturerId={lecturerId}
                            />
                        ))}
                        { groupedBlocks.has('substitute') &&
                            <>
                                <tr><td colSpan={blockStarts.size + 1} style={{borderBottom: '0'}}>&nbsp;</td></tr>
                                <tr className='table-warning'>
                                    <td colSpan={blockStarts.size + 1}>Nur als Vertretung</td>
                                </tr>
                            </>
                        }
                        {groupedBlocks.has('substitute') && groupedBlocks.get('substitute').map((block) => (
                            <BlockRow key={block.id} block={block} blockStarts={blockStarts} blockData={blockData}
                                      blockInstanceLinks={blockInstanceLinks} onlyEmployees={onlyEmployees}
                                      lecturerLinks={lecturerLinks} lecturerId={lecturerId}
                            />
                        ))}
                    </tbody>
                </table>
            }
        </>
    )
}

BlockStartTable.propTypes = {
    year: PropType.number.isRequired,
    blocks: PropType.instanceOf(Immutable.List).isRequired,
    showSelection: PropType.bool,
    onlyEmployees: PropType.bool,
    onlyOpenSchedulings: PropType.bool,
    lecturerLinks: PropType.bool.isRequired,
    blockInstanceLinks: PropType.bool.isRequired,
    qualifications: PropType.instanceOf(Immutable.List).isRequired,
    lecturerId: PropType.number,
    onClickBlockStart: PropType.func
}

const extraFields = 5

const InstanceDaysTable = ({blockStart, lecturers, onClickBack, showSelection, lecturerId, lecturerLinks, blockInstanceLinks, onlyOpenSchedulings, withParticipation}) => {
    const [days, setDays] = useState([])
    const [instances, setInstances] = useState([])

    const lecturerMap = lecturers.reduce((map, lecturer) => {
        map[lecturer.id] = lecturer
        return map
    }, {})

    useEffect(() => {(async () => {
        let response = await axios.get(`/administration/days?kind=open&from=${blockStart}&limit=16`, {
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setDays(response.data)
        }

        const params = new URLSearchParams()
        params.set('date', blockStart)

        if(showSelection) {
            params.set('show_selection', 'true')
        }

        response = await axios.get(`/administration/reports/block_instance_matrix/block_instances?${params}`, {
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setInstances(response.data)
        }
    })()}, [blockStart, showSelection])

    const selectedLecturer = (lecturer) => {
        if(typeof lecturer === 'undefined' || !lecturer) { return false }
        if(!lecturerId) { return true }

        return lecturer.id === lecturerId
    }

    const rowLabel = (instance) => {
        let label = `[${instance[2]}] ${instance[1]}`

        if(instance[3] !== null) {
            label = <>{label}<span className='badge badge-info ml-1'>{romanize(instance[3])}</span></>
        }

        if(blockInstanceLinks) {
            label = <>
                {label}
                <a href={`/administration/block_instances/${instance[0]}`} title="Zur Blockplanung">
                    <i className="fa-light fa-search ml-1"/>
                </a><br/>
                <span className="badge badge-light">
                    <i className="fas fa-users mr-1"/>{instance[4]}
                </span>
            </>
        }

        return label
    }

    const missingLecturer = (instance) => {
        if (!onlyOpenSchedulings) {
            return true
        }

        return Array.from({length: instance.length - extraFields}, (_, idx) => idx + extraFields).some(idx => {
            return instance[idx] === null || instance[idx].length === 0
        })
    }

    const nonEmpty = (instance) => {
        if (!withParticipation) {
            return true
        }

        return instance[4] > 0
    }

    return (
        <table className='table table-hover fixed-header matrix'>
            <thead>
                <tr>
                    <th>
                        <button type='button' className='btn btn-sm btn-primary' onClick={() => onClickBack(null)}>
                            <i className='fas fa-arrow-left' />
                        </button>
                    </th>
                    {days.map(day => (
                        <th key={day.id} className='text-vertical'>
                            <span>
                                {formatDate(day.date)}
                            </span>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
            { instances.filter(combineFilters(missingLecturer, nonEmpty)).map(instance => (
                <tr key={instance[0]}>
                    <td>{rowLabel(instance)}</td>
                    {Array.from({length: instance.length - extraFields}, (_, idx) => idx + extraFields).map(idx => (
                        <td key={idx}>
                            {
                                (instance[idx] || []).map(({id, state}) => ({...lecturerMap[id], state})).filter(selectedLecturer).map(lecturer => (
                                        <LecturerBadge key={lecturer.id} lecturer={lecturer}
                                                       isFilterActive={lecturerId === lecturer.id}
                                                       showLink={lecturerLinks}/>
                                ))
                            }
                        </td>
                    ))}
                </tr>
            )) }
            </tbody>
        </table>
    )
}

InstanceDaysTable.propTypes = {
    blockStart: PropType.string,
    lecturers: PropType.instanceOf(Immutable.List),
    onClickBack: PropType.func.isRequired,
    showSelection: PropType.bool,
    lecturerId: PropType.number,
    lecturerLinks: PropType.bool.isRequired,
    blockInstanceLinks: PropType.bool.isRequired,
    onlyOpenSchedulings: PropType.bool
}

const Switch = ({toggle, children}) => {
    if(toggle) { return children[1] }

    return children[0]
}

const BlockInstanceMatrix = (props) => {
    const [blocks, setBlocks] = useState(List())
    const [years, setYears] = useState(List())
    const [year, setYear] = useState(props.year || new Date().getFullYear())
    const [lecturers, setLecturers] = useState(List())
    const [lecturerId, setLecturerId] = useState(props.lecturerId)
    const [qualifications, setQualifications] = useState(List())
    const [showSelection, setShowSelection] = useState(props.showSelection)
    const [onlyEmployees, setOnlyEmployees] = useState(props.onlyEmployees)
    const [onlyOpenSchedulings, setOnlyOpenSchedulings] = useState(props.onlyOpenSchedulings)
    const [blockStart, setBlockStart] = useState(props.blockStart)
    const [withParticipation, setWithParticipation] = useState(props.withParticipation)

    useEffect(() => {(async () => {
        const response = await axios.get('/administration/block_starts/years', {
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setYears(List(response.data))
        }
    })()}, [])

    useEffect(() => {(async () => {
        const url = new URL('/administration/lecturers', location.href)
        url.searchParams.set('disabled', true)

        const response = await axios.get(url.toString(), {
            headers: { 'Accept': 'application/json' }
        })

        const employeeFilter = (lecturer) => {
            if(!onlyEmployees) { return true }

            return lecturer.isEmployee
        }

        if(response.status === 200) {
            setLecturers(List(response.data).filter(employeeFilter))
        }
    })()}, [onlyEmployees])

    useEffect(() => {(async () => {
        updateHistory('year', year)

        if(typeof showSelection !== 'undefined') {
            updateHistory('show_selection', showSelection)
        }

        const response = await axios.get('/administration/blocks', {
            params: {year},
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setBlocks(List(response.data))
        }
    })()}, [year, showSelection])

    useEffect(() => {
        const hash = window.location.hash;
        if(!window.location.hash) { return }

        const hashTarget = document.querySelector(window.location.hash)

        if(hashTarget) {
            const bounds = hashTarget.getBoundingClientRect()
            location.hash = ''
            location.hash = hash
            window.scrollTo({top: bounds.top - 100, behavior: 'smooth'})
        }
    }, [blocks])

    useEffect(() => {(async () => {
        updateHistory('lecturer_id', lecturerId)

        if(lecturerId === null) {
            setQualifications(List())
            return
        }

        let response = await axios.get(`/administration/lecturers/${lecturerId}/qualifications`, {
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setQualifications(List(response.data))
        }
    })()}, [lecturerId])

    useEffect(() => {
        updateHistory('block_start', blockStart)
    }, [blockStart])

    const updateOnlyEmployees = () => {
        const value = !onlyEmployees

        setOnlyEmployees(value)

        updateHistory('only_employees', value)

        if(lecturerId !== null) {
            const selectedLecturer = lecturers.find(l => l.id === lecturerId)

            if(!selectedLecturer.isEmployee) {
                setLecturerId(null)
            }
        }
    }

    const updateOnlyOpenSchedulings = () => {
        const value = !onlyOpenSchedulings

        setOnlyOpenSchedulings(value)

        updateHistory('only_open_schedulings', value)
    }

    const updateWithParticipation = () => {
        const value = !withParticipation

        setWithParticipation(value)

        updateHistory('with_participation', value)
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label htmlFor='year' className='required'>Geschäftsjahr</label>
                        <SelectBox id='year' items={years.map(y => ({value: y.value, title: y.value})).toJS()} value={year} disableInput={true} disabled={blockStart !== null} onChange={({value}) => setYear(value)} />
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-group'>
                        <label htmlFor='lecturer'>
                            Dozent (Qualifikationen)
                            <Utilisation lecturerId={lecturerId} year={year} />
                        </label>
                        <SelectBox id='lecturer' items={lecturers.map(l => ({value: l.id, title: l.name})).toJS()} value={lecturerId} onChange={({value}) => setLecturerId(value)} includeEmpty={true} />
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-auto">
                    <div className="checkbox p-0">
                        <input type="checkbox" id="block-instance-matrix-selected"
                               onChange={() => setShowSelection(!showSelection)} checked={showSelection}/>
                        <label htmlFor="block-instance-matrix-selected">Akzeptierte Bewerbungen anzeigen</label>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="checkbox p-0 ml-2">
                        <input type="checkbox" id="block-instance-matrix-employees"
                               onChange={updateOnlyEmployees} checked={onlyEmployees}/>
                        <label htmlFor="block-instance-matrix-employees">Nur interne Dozenten</label>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="checkbox p-0 ml-2">
                        <input type="checkbox" id="block-instance-matrix-schedulings"
                               onChange={updateOnlyOpenSchedulings} checked={onlyOpenSchedulings}/>
                        <label htmlFor="block-instance-matrix-schedulings">Blöcke ohne Dozenten</label>
                    </div>
                </div>
                {blockStart &&
                    <div className="col-auto">
                        <div className="checkbox p-0 ml-2">
                            <input type="checkbox" id="block-instance-with-participation"
                                   onChange={updateWithParticipation} checked={withParticipation}/>
                            <label htmlFor="block-instance-with-participation">Nur Blöcke mit Teilnehmern</label>
                        </div>
                    </div>
                }
            </div>
            <Switch toggle={blockStart}>
                <BlockStartTable
                        year={year} blocks={blocks} qualifications={qualifications} lecturerId={lecturerId}
                        lecturerLinks={props.showLecturerLinks} blockInstanceLinks={props.showBlockInstanceLinks}
                        showSelection={showSelection} onlyEmployees={onlyEmployees}
                        onlyOpenSchedulings={onlyOpenSchedulings}
                    onClickBlockStart={setBlockStart}
                />
                <InstanceDaysTable blockStart={blockStart} lecturers={lecturers} lecturerId={lecturerId}
                                   lecturerLinks={props.showLecturerLinks} blockInstanceLinks={props.showBlockInstanceLinks}
                                   showSelection={showSelection} onlyOpenSchedulings={onlyOpenSchedulings}
                                   onClickBack={setBlockStart} withParticipation={withParticipation}
                />
            </Switch>
        </React.Fragment>
    )
}

BlockInstanceMatrix.propTypes = {
    showBlockInstanceLinks: PropType.bool.isRequired,
    showLecturerLinks: PropType.bool.isRequired,
    onlyEmployees: PropType.bool,
    onlyOpenSchedulings: PropType.bool,
    year: PropType.number,
    lecturerId: PropType.number,
    showSelection: PropType.bool,
    blockStart: PropType.string
}

BlockInstanceMatrix.defaultProps = {
    showBlockInstanceLinks: false,
    showLecturerLinks: false,
    onlyEmployees: false,
    onlyOpenSchedulings: false,
    withParticipation: false,
    showSelection: false
}

export default BlockInstanceMatrix;