import AsyncSelect from 'react-select/async';
import React, {useCallback} from 'react';

let timeoutId

const debounce = (func, delay) => {
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }
}

const defaultMessages = {
    noOptions: 'Keine Auswahlmöglichkeiten gefunden',
    loading: 'Lade Daten...'
}

/**
 * A search select component with asynchronous options loading and debouncing functionality.
 *
 * @param {Object} props - The properties passed to the SearchSelect component.
 * @param {string} [props.id] - The id attribute for the select element.
 * @param {string} [props.className] - The class name(s) for the select element.
 * @param {Function} props.loadOptions - A function that loads options asynchronously based on user input.
 * @param {Function} props.onChange - A callback function triggered when a selection is made.
 * @param {Object} props.value - The currently selected value.
 * @param {number} [props.delay=250] - The debounce delay in milliseconds (default 250).
 * @param {Object} [props.messages] - An object containing custom messages for the component.
 * @param {string} [props.messages.noOptions] - Message to display when no options are available.
 * @param {string} [props.messages.loading] - Message to display while loading.
 */
const SearchSelect = (
    {
        id, name = null, className, loadOptions, onChange, value, delay = 250,
        messages: {noOptions, loading} = defaultMessages
    }
) => {
    const debouncedCallback = useCallback(debounce(loadOptions, delay), [])

    return <>
        <AsyncSelect id={id} name={name} className={className} defaultOptions={[]} loadOptions={debouncedCallback}
                     placeholder={null} noOptionsMessage={() => noOptions}
                     components={{IndicatorSeparator: () => null}} styles={SearchSelect.styles}
                     onChange={onChange} value={value}
                     loadingMessage={() => loading}
        />
    </>
}

SearchSelect.styles = {
    control: (base) => ({
        ...base, borderColor: '#e6ecf5', borderRadius: '0', boxShadow: 'none',
        '&:hover': {
            border: '1px solid #e6ecf5',
        }
    }),
    dropdownIndicator: (base) => ({
        ...base, color: '#cbcdd9'
    }),
    valueContainer: (base) => ({
        ...base, padding: '5px 8px'
    }),
    menu: (base) => ({
        ...base, borderRadius: '0', marginTop: '0', marginBottom: '0',
        boxShadow: '2px 5px 5px 0 rgba(0, 0, 0, 0.1)', border: '1px solid #e6ecf5',
        borderTop: 'none'
    }),
    noOptionsMessage: (base) => ({
        ...base
    }),
    indicatorsContainer: (base) => ({
        ...base, 'svg': {
            height: '15px', width: '15px', marginRight: '3px'
        }
    })
}

export default SearchSelect;