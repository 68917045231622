import React from 'react';
import ConcentrateFilter from './ConcentrateFilter';
import PropTypes from 'prop-types';

class FilterAdapter extends React.Component {

    render() {

        return <ConcentrateFilter
                    schema={this.props.schema}
                    storageKey={this.props.storageKey}
                    queryBase={this.props.queryBase}
                    defaultQuery={this.props.defaultQuery}
                    customDefault={this.props.customDefault}
                    deleteIcon={<i className='fa-light fa-trash' />}
                    historyIcon={<i className='fa-light fa-search' />}
                    savePath={this.props.savePath}
                />

    }

}

FilterAdapter.propTypes = {
    schema: PropTypes.object.isRequired,
    queryBase: PropTypes.string.isRequired,
    storageKey: PropTypes.string.isRequired,
    defaultQuery: PropTypes.array,
    customDefault: PropTypes.bool,
    savePath: PropTypes.string
};

FilterAdapter.defaultProps = {
    defaultQuery: null
}

export default FilterAdapter;