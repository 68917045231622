import React from 'react';
import PropTypes from 'prop-types';
import {StandardTextSelection} from './DocumentValidation';

const Overlay = ({visible, children}) => (visible ? <div className='overlay'>{children || null}</div> : null)

Overlay.propTypes = {
    visible: PropTypes.bool.isRequired,
    children: PropTypes.node
}

class ConfirmationDialog extends React.PureComponent {

    state = {
        reason: null,
        confirming: false
    }

    onCancel = () => {
        this.setState({confirming: false, reason: null});
    }

    onClick = () => {
        this.setState({confirming: true});
    }

    render() {

        return (
            <React.Fragment>
                <Overlay visible={this.state.confirming}>
                    <StandardTextSelection kind={this.props.kind} setStandardText={(id) => this.setState({reason: id})}/>
                    <div className='controls row'>
                        <div className='col'>
                            <button className='btn btn-sm btn-danger' type='button' onClick={this.onCancel}>
                                Abbrechen
                            </button>
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-sm btn-success' type='button' onClick={() => this.props.onRejected(this.state.reason)}>
                                Speichern
                            </button>
                        </div>
                    </div>
                </Overlay>
                {this.props.children}
                <div className='controls row'>
                    <div className='col'>
                        {this.props.acceptable &&
                            <button className='btn btn-sm btn-success' type='button' onClick={this.props.onAccepted}>
                                Akzeptieren
                            </button>
                        }
                    </div>
                    <div className='col-auto'>
                        <button className='btn btn-sm btn-danger' type='button' onClick={this.onClick}>
                            Ablehnen
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

ConfirmationDialog.propTypes = {
    kind: PropTypes.string.isRequired,
    onAccepted: PropTypes.func.isRequired,
    onRejected: PropTypes.func.isRequired,
    acceptable: PropTypes.bool.isRequired,
    children: PropTypes.node
}

ConfirmationDialog.defaultProps = {
    acceptable: true
}

export default ConfirmationDialog;