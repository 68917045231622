import React from 'react';
import Calendar from './Calendar';
import TimePicker from './TimePicker';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

class DateTimePicker extends React.PureComponent {

    constructor(props) {

        super(props);

        const date = this.props.date;
        date.setSeconds(0, 0);

        this.state = {
            date,
            displayTime: this.props.displayTime
        }

    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.date !== this.props.date) {
            this.setState({date: this.props.date})
        }

        if(this.state.date === prevState.date) {
            return
        }

        if(this.props.onChange) {
            this.props.onChange(this.state.date)
        }

        if(this.props.onSave && !this.props.displayTime) {
            this.props.onSave(this.state.date)
        }

    }

    onChange = ({year, month, date, hours, minutes}) => {

        const newDate = new Date(this.state.date.getTime());

        if(!year) {
            newDate.setHours(hours, minutes, 0, 0);
        } else {
            newDate.setFullYear(year, month, date);
        }

        this.setState({date: newDate});

    }

    onModeChange = (mode) => {

        if(mode === 'date') {
            this.setState({displayTime: true});
        } else {
            this.setState({displayTime: false});
        }

    }

    render() {

        return (

            <div className='date-time-picker'>
                <Calendar disabledDays={this.props.disabledDays} locale={this.props.locale} date={this.state.date} minDate={this.props.minDate} onChange={this.onChange} onModeChange={this.onModeChange} />
                { this.state.displayTime && this.props.displayTime && <TimePicker locale={this.props.locale} date={this.state.date} onChange={this.onChange} /> }
                { this.props.onSave && this.props.displayTime && <button type='button' className='save' onClick={() => this.props.onSave(this.state.date)} tabIndex='3'>{this.props.saveLabel}</button> }
                { this.props.showReset && this.props.onReset && <button type='button' className='reset' onClick={() => this.props.onReset()} tabIndex='4'>{this.props.resetLabel}</button> }
                { this.props.children }
            </div>

        )

    }

}

DateTimePicker.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    locale: PropTypes.string.isRequired,
    displayTime: PropTypes.bool.isRequired,
    saveLabel: PropTypes.string.isRequired,
    resetLabel: PropTypes.string.isRequired,
    disabledDays: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onReset: PropTypes.func,
    showReset: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

DateTimePicker.defaultProps = {
    date: new Date(),
    locale: document.documentElement.lang,
    displayTime: true,
    resetLabel: 'Leer',
    showReset: true
}

export class DateTimePopUp extends React.PureComponent {

    render() {

        const { children, visible, ...pickerProps } = this.props;

        if(!visible) {
            return children(null);
        }

        return (

            <Manager>
                <Reference>
                    {({ref}) => (
                        children(ref)
                    )}
                </Reference>
                <Popper modifiers={[{ name: 'preventOverflow', enabled: true }]} placement={this.props.placement} strategy='fixed'>
                    {({ref, style, placement}) => (
                        <div ref={ref} style={Object.assign({}, style, {zIndex: 100})} data-placement={placement}>
                            <DateTimePicker {...pickerProps} />
                        </div>
                    )}
                </Popper>
            </Manager>

        )

    }

}

DateTimePopUp.propTypes = {
    visible: PropTypes.bool.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    locale: PropTypes.string.isRequired,
    displayTime: PropTypes.bool.isRequired,
    saveLabel: PropTypes.string.isRequired,
    resetLabel: PropTypes.string.isRequired,
    placement: PropTypes.string.isRequired,
    minDate: PropTypes.instanceOf(Date),
    disableDates: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onReset: PropTypes.func,
    children: PropTypes.func
}

DateTimePopUp.defaultProps = {
    date: new Date(),
    locale: document.documentElement.lang,
    displayTime: true,
    resetLabel: 'Leer',
    placement: 'bottom'
}

export default DateTimePicker;