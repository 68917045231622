import React from 'react';
import PropType from 'prop-types';
import {useState} from 'react';
import SelectBox from './SelectBox';
import DropZone from './DropZone';
import InformationBoxWithVideo from './InformationBoxWithVideo';
import DocumentLightbox from "./DocumentLightbox";

const WizardEducationVoucher = ({submitLater: _submitLater = '', number: _number = '', labels, files = [], previews = [], state = ''}) => {
    const [submitLater, setSubmitLater] = useState(_submitLater)
    const [number, setNumber] = useState(_number)

    return <>
        <div className='form-group'>
            <label className='required' htmlFor='education_voucher_submit_later'>{labels.submitLater}</label>
            <SelectBox id='education_voucher_submit_later' name='education_voucher[submit_later]' required={true}
                       items={[{title: "\u00A0", value: ''}, {title: labels.notAvailable, value: true}, {title: labels.available, value: false}]} value={submitLater}
                       onChange={({value}) => setSubmitLater(value)}
            />
        </div>
        { submitLater === true &&
            <InformationBoxWithVideo title={labels.information} />
        }
        { submitLater === false &&
            <>
                <div className="form-group">
                    <label id="label_number" htmlFor="education_voucher_number" className="required">
                        {labels.number}
                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                           className="help-tag">
                            <i className="fa-light fa-question-circle"/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-help">
                            <div className="card-body">
                                {labels.help}
                            </div>
                        </div>
                    </label>
                    <input autoFocus="autofocus" className="form-control" type="text" name="education_voucher[number]"
                           id="education_voucher_number" value={number} required={true}
                           pattern="\d{3}[A-Za-z]\d{6}\s?-\s?(?:0[1-9]|[1-9][0-9])"
                           onChange={e => setNumber(e.target.value)}
                    />
                </div>

                <div className="form-group">
                    <label id="label_files" htmlFor="education_voucher_files" className="required">
                        {labels.files}
                    </label>
                    <div className="row">
                        <div className="col-lg-5">
                            <DropZone description={labels.uploadDescription} buttonLabel={labels.uploadButtonLabel}
                                      multiple={true} required={true} name="education_voucher[files][]" files={files}
                                      disabled={!['new', 'submitted'].includes(state)}
                            />
                        </div>
                        <div className="col-lg-7">
                            <div className="card bg-light mt-3 mt-lg-0">
                                <div className="card-heading border bottom">
                                    <i className="fa-light fa-info-circle mr-2 font-size-18"/>
                                    Bitte achten Sie darauf einen gültigen Bildungsgutschein hochzuladen.
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <ul className="pl-3 mb-3 list-styled">
                                                <li>Der Bildungsgutschein darf nicht ausgefüllt sein.</li>
                                                <li>Der Bildungsgutschein muss in guter Qualität bereitgestellt
                                                    werden.
                                                </li>
                                                <li>Bitte laden Sie wenn möglich eine PDF-Datei hoch.</li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="row">
                                                {previews.map((item, idx) =>
                                                    <div className="col-6" key={idx}>
                                                        <DocumentLightbox previewUrl={item.previewUrl} file={item.file} showControls={false}/>
                                                        <span className="badge badge-info">{item.title}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
}

WizardEducationVoucher.propTypes = {
    submitLater: PropType.bool,
    number: PropType.string,
    labels: PropType.shape({
        submitLater: PropType.string,
        number: PropType.string,
        files: PropType.string,
        uploadDescription: PropType.string,
        uploadButtonLabel: PropType.string,
        information: PropType.string,
        notAvailable: PropType.string,
        available: PropType.string,
        help: PropType.string
    }),
    previews: PropType.arrayOf(PropType.shape({
        title: PropType.string,
        previewUrl: PropType.string,
        file: PropType.shape({
            url: PropType.string,
            content_type: PropType.string,
            size: PropType.number,
        })
    })),
    files: PropType.arrayOf(PropType.shape({
        id: PropType.string,
        name: PropType.string,
        type: PropType.string,
        size: PropType.number
    })),
    state: PropType.string
}

export default WizardEducationVoucher