import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from './SelectBox';
import axios from 'axios';
import {List} from 'immutable';
import RejectButton from './RejectButton';

class ExaminationValidation extends React.PureComponent {

    examinerSelection = React.createRef();

    constructor(props) {

        super(props);

        this.state = {
            timeInput: '',
            examiners: List(),
            selectedExaminer: null,
            availableExaminers: List(),
            time: '',
            showRoomUrl: false,
            roomUrl: ''
        }

    }

    async componentDidUpdate(_prevProps, prevState) {

        if(prevState.time !== this.state.time) {
            await this.getAvailableExaminers();
        }

    }

    validRoomUrl = () => {

        if(this.state.showRoomUrl) {
            return this.state.roomUrl !== '' && this.state.roomUrl.match(/^https?:\/\/.+$/)
        } else {
            return true
        }

    }

    handleChangeTime = (e) => {

        const value = e.target.value;

        let time = ''
        let examiners = List()

        if (value.match(/^(2[0-3]|[01]?[0-9]):([0-5][0-9])$/)) {
            time = value;
        }

        this.setState({timeInput: value, time: time, examiners: examiners});

    }

    getAvailableExaminers = async () => {

        const result = await axios.get(`/administration/program_examinations/${this.props.programExaminationId}/examiners`, {
            headers: {'Accept': 'application/json'},
            params: {'time': this.state.time}
        });

        if(result.status === 200) {

            this.setState({availableExaminers: List(result.data)});
        }

    }

    addExaminer = () => {

        if(!this.state.selectedExaminer) {
           return;
        }

        const availableExaminers = this.state.availableExaminers.filterNot(e => e.value === this.state.selectedExaminer.value);

        this.setState({examiners: this.state.examiners.push(this.state.selectedExaminer), selectedExaminer: null, availableExaminers: availableExaminers});
        this.examinerSelection.current.reset();

    }

    removeExaminer = (value) => {

        const [idx, examiner] = this.state.examiners.findEntry(e => e.value === value);

        this.setState({examiners: this.state.examiners.delete(idx), availableExaminers: this.state.availableExaminers.push(examiner).sortBy(e => e.lastName)});

    }

    render() {

        return (

            <React.Fragment>
                <div className='card'>
                    <div className='card-heading border bottom'>
                        <h4 className='card-title inline-block'>Daten</h4>
                    </div>
                    <div className='card-block'>
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <div className='form-group'>
                                    <label className='required'>Datum</label>
                                    <input className='form-control' type='text' name='program_examination[date]' value={this.props.examinationDate} disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className='form-group mb-0'>
                                    <label className='required'>Uhrzeit</label>
                                    <input className='form-control' type='text' name='program_examination[starts_at]' pattern='^(2[0-3]|[01]?[0-9]):([0-5][0-9])$' maxLength='5' size='5' value={this.state.timeInput} autoFocus onChange={this.handleChangeTime} />
                                </div>
                            </div>
                        </div>

                        { this.state.time !== '' &&
                            <React.Fragment>
                                <div className='form-group mb-0'>
                                    <label className='required'>Prüfer</label>
                                    {this.state.examiners.size > 0 &&
                                        <table className='table'>
                                            <tbody>
                                            {
                                                this.state.examiners.map((e, idx) => {

                                                    return (
                                                        <tr key={e.value}>
                                                            <td className='vertical-center px-2'>
                                                                {e.title}
                                                                <input type='hidden' name={`program_examination[examiners_attributes][${idx}][lecturer_id]`} value={e.value} />
                                                            </td>
                                                            <td className='text-right px-0'>
                                                                <button type='button' className='btn btn-danger btn-sm m-0' onClick={() => this.removeExaminer(e.value)}>
                                                                    <i className='fa-light fa-trash' />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );

                                                })
                                            }
                                            </tbody>
                                        </table>
                                    }
                                    <div className='row'>
                                        <div className='col pr-2'>
                                            <SelectBox ref={this.examinerSelection} items={this.state.availableExaminers.toJS()} onChange={item => this.setState({selectedExaminer: item})} />
                                        </div>
                                        <div className='col-auto pl-0'>
                                            <button type='button' className='btn btn-default m-0' onClick={this.addExaminer}>Hinzufügen</button>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className='controls row'>
                    <div className='col'>
                        { this.state.examiners.size > 0 && this.validRoomUrl() &&
                            <button type='submit' className='btn btn-success'>Zulassen</button>
                        }
                    </div>
                    <div className='col-auto'>
                        <RejectButton scope='program_examination' textKind='examination' label='Ablehnen' />
                    </div>
                </div>
            </React.Fragment>

        )

    }

}

ExaminationValidation.propTypes = {
    programExaminationId: PropTypes.number.isRequired,
    examinationDate: PropTypes.string.isRequired
}

export default ExaminationValidation;