import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from './SelectBox';
import Sortable from "sortablejs/modular/sortable.core.esm";

class AddOption extends React.PureComponent {

    state = {
        name: '',
        content: ''
    }

    kinds = [
        {title: 'og:title', value: 'og:title'},
        {title: 'og:description', value: 'og:description'},
        {title: 'og:image', value: 'og:image'},
        {title: 'og:image:width', value: 'og:image:width'},
        {title: 'og:image:height', value: 'og:image:height'},
        {title: 'og:video', value: 'og:video'},
        {title: 'og:video:type', value: 'og:video:type'},
        {title: 'og:video:width', value: 'og:video:height'},
        {title: 'article:author', value: 'article:author'},
        {title: 'article:publisher', value: 'article:publisher'},
        {title: 'fb:app_id', value: 'fb:app_id'},
        {title: 'twitter:card', value: 'twitter:card'},
        {title: 'twitter:title', value: 'twitter:title'},
        {title: 'twitter:description', value: 'twitter:description'},
        {title: 'twitter:url', value: 'twitter:url'},
        {title: 'twitter:image', value: 'twitter:image'}
    ]

    addOption = () => {

        if(this.state.name === '' || this.state.content === '') {
            return;
        }

        this.props.addOption(this.state);

        this.setState({
            name: '',
            content: ''
        });

        this.nameInput.reset();

    }

    onKeyPress = (e) => {

        if(e.key !== 'Enter' || this.state.name.length === 0 || this.state.content.length === 0) {
            return
        }

        e.preventDefault();
        e.stopPropagation();

        this.addOption();

        this.contentInput.focus();

    }

    render() {

        return (
            <thead>
            <tr>
                <th className='pl-0 pt-0'>
                    <SelectBox ref={e => this.nameInput = e} required={false} disableInput={true} items={this.kinds} value={this.state.name} onChange={(e) => this.setState({name: e.value})} placeholder='Tag wählen' />
                </th>
                <th className='pl-0 pt-0'>
                    <input ref={e => this.contentInput = e} type='text' value={this.state.content} placeholder='Wert' onKeyPress={this.onKeyPress} onChange={(e) => this.setState({content: e.target.value})} className='form-control'/>
                </th>
                <th className='action px-0 pt-0 align-top'>
                    <button type='button' title='Option hinzufügen' className='btn btn-icon btn-flat btn-rounded m-0' onClick={this.addOption}>
                        <i className='fa-light fa-plus'/>
                    </button>
                </th>
            </tr>
            </thead>
        )
    }
}

AddOption.propTypes = {
    addOption: PropTypes.func.isRequired
};

class PageMetadata extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            metadata: this.props.metadata,
        };

        this.optionsReference = React.createRef();

    }

    componentDidMount() {
        this.initializeSortable();
    }

    componentWillUnmount() {
        this.removeSortable();
    }

    initializeSortable = () => {

        if(!this.optionsReference.current) {
            return;
        }

        this.sortable = Sortable.create(this.optionsReference.current, {
            handle: 'tr.sortable-handle',
            direction: 'vertical',
            animation: 150,
            ghostClass: 'sortable-placeholder',
        });

        this.setState({
            sortableOrder: this.sortable.toArray()
        });

    }

    removeSortable = () => {

        if(this.sortable) {
            this.sortable.destroy();
        }

    }

    addOption = (option) => {

        this.setState( {
            metadata: [...this.state.metadata, {
                name: option.name,
                content: option.content
            }]
        });

    }

    removeOption = (option) => {

        this.setState( {
            metadata: this.state.metadata.filter((o) => { return o.name !== option.name }),
        });

    };

    render() {

        return (
            <div className='table-overflow'>
                <table className='table'>
                    <AddOption addOption={this.addOption}/>
                    <tbody ref={this.optionsReference} className='sortable'>
                    {this.state.metadata.map((metadata, idx) =>
                        <tr key={idx} className='sortable-handle'>
                            <td>{metadata.name}</td>
                            <td className='text-break'>{metadata.content}</td>
                            <td className='action px-0'>
                                <button type='button' title='Option löschen' className='btn btn-icon btn-flat btn-rounded no-mrg' onClick={() => this.removeOption(metadata)}>
                                    <i className='fa-light fa-trash'/>
                                </button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>

                <input type='hidden' name={`${this.props.scope}[page_metadata]`} value={JSON.stringify(this.state.metadata)}/>
            </div>
        )

    }
}

PageMetadata.propTypes = {
    scope: PropTypes.string.isRequired,
    metadata: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired
    })).isRequired
};

export default PageMetadata;