import React, {useState, useEffect, useRef} from 'react';
import FullCalendar from '@fullcalendar/react';
import deLocale from '@fullcalendar/core/locales/de';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3'
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from './Modal';
import DateTimePicker from './DateTimePicker';

const convertEntryToEvents = (entry) => {

    return {
        title: entry.name || 'Belegt',
        start: entry.startsAt, end: entry.endsAt,
        resourceId: entry.userId,
        editable: false,
        backgroundColor: entry.isAllDay ? '#888DA8' : '#193280',
        extendedProps: { eventId: entry.id, unsaved: false, allDay: entry.isAllDay }
    }

}

export const fetchEvents = async (info) => {
    let data = []

    const result = await axios.get(`/administration/career_advisors/calendar_entries`, {
        params: {
            start: info.startStr,
            end: info.endStr
        },
        headers: {
            'Accept': 'application/json'
        }
    })

    if (result.status === 200) {
        data = result.data.map(convertEntryToEvents)
    }

    return data
}

const Event = ({event, time, onDelete}) => {
    const onClickDelete = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if(confirm('Wirklich löschen?')) {
            event.remove()
            onDelete()
        }
    }

    return (
        <div className='fc-event-main-frame'>
            <div className='fc-event-time'>{time}</div>
            <div className='fc-event-title-container'>
                <div className='fc-event-title fc-sticky'>{event.title}{event.extendedProps.unsaved && <i style={{marginLeft: '5px', cursor: 'pointer'}} className='fa-solid fa-trash' onClick={onClickDelete} />}</div>
            </div>
        </div>
    )
}

Event.propTypes = {
    event: PropTypes.object.isRequired,
    time: PropTypes.string.isRequired,
    onDelete: PropTypes.func
}

const SalesCalendar = ({
    careerAdvisors, initialEvent, eventTitle, eventColor, eventLength, eventMinDuration, eventConstraint,
    retroactiveEvents, licenseKey, children, editable, weekends, timeZone
}) => {
    let [calendar, setCalendar] = useState(null)

    let eventData = null

    if(!initialEvent) {
        initialEvent = {}
    }

    if(initialEvent && initialEvent.careerAdvisorId && initialEvent.startDate && initialEvent.endDate) {
        eventData = {
            title: eventTitle, start: new Date(initialEvent.startDate), end: new Date(initialEvent.endDate),
            resourceId: initialEvent.careerAdvisorId,
            editable: true, backgroundColor: eventColor,
            extendedProps: { unsaved: true, allDay: initialEvent.allDay }
        }
    }

    const calendarRef = useRef(null)
    const [event, setEvent] = useState(eventData)

    useEffect(() => {
        if(!calendarRef.current || !event) { return }

        calendarRef.current.getApi().unselect()
        calendarRef.current.getApi().addEvent(event)
    }, [event])

    const eventEnd = (info, startDate) => {
        if(eventMinDuration === 0) { return new Date(info.endStr) }

        if(eventLength > 0) {
            return new Date(startDate.getTime() + Math.min(eventMinDuration, eventLength) * 60_000)
        }

        return new Date(startDate.getTime() + eventMinDuration * 60_000)
    }

    const addEvent = (info) => {
        const {resource} = info
        const startDate = new Date(info.startStr)
        const event = {
            title: eventTitle, start: startDate, end: eventEnd(info, startDate),
            resourceId: resource.id,
            editable: true, backgroundColor: eventColor,
            extendedProps: { unsaved: true }
        }

        setEvent(event)
    }

    const removeEvent = () => {
        setEvent(null)
    }

    const changeEvent = ({event}) => {
        setEvent(event)
    }

    const eventResize = (info) => {
        const {event} = info

        if(event.start <= new Date() && !retroactiveEvents) { info.revert() }

        if(eventLength === 0) { return }

        if(
            event.end - event.start > eventLength * 60_000 ||
            eventMinDuration > 0 && (event.end - event.start) / 60_000 < eventMinDuration
        ) { info.revert() }
    }

    const selectAllow = (info) => {
        let isAllowed = eventLength === 0 ? true : info.end - info.start <= eventLength * 60_000

        const validDate = retroactiveEvents ? true : info.start > new Date()

        return isAllowed && validDate
    }

    const eventContent = (info) => <Event event={info.event} time={info.timeText} onDelete={removeEvent} />

    const validRange = () => retroactiveEvents ? {} : {start: new Date()}

    const filteredEvents = async (info) => {
        let events = await fetchEvents(info);

        if (initialEvent.id) {
            events = events.filter(event => event.extendedProps.eventId !== initialEvent.id)
        }

        return events
    }

    const initialDate = initialEvent.startDate

    if(!eventConstraint) {
        eventConstraint = {
            daysOfWeek: [1, 2, 3, 4, 5],
            startTime: '08:00',
            endTime: '18:00'
        }
    }

    const onSaveDate = (date) => {
        calendarRef.current.getApi().gotoDate(date)

        setCalendar(null)
    }

    const getDate = () => calendar ? calendarRef.current.getApi().getDate() : new Date()

    const setTime = (date, time) => {
        const parts = time.split(':')
        const newDate = new Date(date.getTime())

        newDate.setHours(parseInt(parts[0], 10), parseInt(parts[1], 10), 0, 0, 0)

        return newDate
    }

    const fullDayEligibility = (resource, e) => {
        const eventStart = e.start.setHours(0, 0, 0, 0)
        const selectedDate = calendarRef.current.getApi().getDate().setHours(0, 0, 0, 0)

        return eventStart === selectedDate && e.getResources().some(r => r.id === resource.id)
    }

    const onClickResource = (resource) => {
        const date = calendarRef.current.getApi().getDate()

        if(resource.getEvents().filter(fullDayEligibility.bind(self, resource)).length > 0) {
            alert('Termin kann nicht für den ganzen Tag gesetzt werden, da bereits Termine vorhanden sind.')
            return
        }

        const start = setTime(date, eventConstraint.startTime)
        const end = setTime(date, eventConstraint.endTime)

        const event = {
            title: 'Neuer Termin',
            start, end,
            resourceId: resource.id,
            editable: false, backgroundColor: '#51AE2F',
            extendedProps: { unsaved: true, allDay: true }
        }

        setEvent(event)
    }

    const onResourceLabelDidMount = ({resource, el}) => {
        if(!editable) { return }
        el.style.cursor = 'pointer';
        el.addEventListener('click', () => onClickResource(resource))
    }

    return (
        <>
            <div style={{marginTop: '25px', marginBottom: '25px'}}>
                <FullCalendar
                    ref={calendarRef}
                    locale={deLocale}
                    plugins={[luxonPlugin, resourceTimeGridPlugin, bootstrapPlugin, interactionPlugin]}
                    timeZone={timeZone}
                    initialView='resourceTimeGridDay'
                    themeSystem='bootstrap'
                    height='auto'
                    customButtons={{
                        picker: {
                            text: 'Datumsauswahl',
                            click: () => setCalendar(calendarRef)
                        }
                    }}
                    bootstrapFontAwesome={{
                        picker: 'fa-calendar-days'
                    }}
                    headerToolbar={{
                        start: 'prev,today,next picker',
                        center: '',
                        end: 'title'
                    }}
                    titleFormat={{year: 'numeric', month: 'long', weekday: 'long', day: 'numeric'}}
                    weekends={weekends}
                    hiddenDays={weekends ? [0] : [6, 0]}
                    allDaySlot={false}
                    selectable={event === null}
                    selectOverlap={false}
                    selectAllow={selectAllow}
                    select={addEvent}
                    slotMinTime={eventConstraint.startTime}
                    slotMaxTime={eventConstraint.endTime}
                    slotDuration={'00:15'}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit'
                    }}
                    scrollTimeReset={false}
                    nowIndicator={true}
                    editable={editable}
                    eventStartEditable={true}
                    eventResizableFromStart={true}
                    eventDurationEditable={true}
                    eventOverlap={false}
                    eventConstraint={eventConstraint}
                    eventChange={changeEvent}
                    eventContent={eventContent}
                    eventResize={eventResize}
                    eventDrop={eventResize}
                    initialDate={initialDate}
                    resources={careerAdvisors}
                    events={filteredEvents}
                    schedulerLicenseKey={licenseKey}
                    validRange={validRange}
                    resourceLabelDidMount={onResourceLabelDidMount}
                    slotLaneClassNames={({date}) => date.getHours() >= 18 ? 'extended-business-hours' : null}
                    dayCellClassNames={({date}) => date.getDay() === 6 ? 'extended-business-hours' : null}
                />
                {children ? children(event) : null}
            </div>
            <Modal isVisible={calendar !== null}>
                <DateTimePicker disabledDays={[0]} date={getDate()} saveLabel={'Speichern'} displayTime={false} onSave={onSaveDate}>
                    <button type='button' className='save' onClick={() => setCalendar(null)}>Abbrechen</button>
                </DateTimePicker>
            </Modal>
        </>
    )
}

SalesCalendar.propTypes = {
    careerAdvisors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired, title: PropTypes.string.isRequired
        })
    ).isRequired,
    initialEvent: PropTypes.shape({
        id: PropTypes.number,
        careerAdvisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        allDay: PropTypes.bool
    }),
    eventColor: PropTypes.string,
    eventTitle: PropTypes.string,
    eventLength: PropTypes.number,
    eventMinDuration: PropTypes.number,
    eventConstraint: PropTypes.shape({
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        startTime: PropTypes.string,
        endTime: PropTypes.string
    }),
    retroactiveEvents: PropTypes.bool,
    licenseKey: PropTypes.string.isRequired,
    children: PropTypes.func,
    editable: PropTypes.bool,
    weekends: PropTypes.bool,
    timeZone: PropTypes.string
}

SalesCalendar.defaultProps = {
    retroactiveEvents: true,
    editable: true,
    weekends: false,
    timeZone: 'local'
}

export default SalesCalendar