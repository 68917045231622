import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {List} from 'immutable';

const HiddenComponent = ({isVisible, placeholder, children}) => {
    if (isVisible) {
        return children;
    }

    return <div className='rte-content' dangerouslySetInnerHTML={{__html: placeholder}} />
}

class Article extends React.PureComponent {
    render() {

        return (
            <div className='card widget-feed'>
                <div className='feed-header'>
                    <ul className='list-unstyled list-info'>
                        <li>
                            <img src={this.props.avatar} className='thumb-img' />
                            <div className='info'>
                                <span className='title no-pdd-vertical text-semibold inline-block'>{this.props.author}</span>
                                <span className='sub-title'>
                                    <i className='fa-light fa-alarm-clock pdd-right-5' />
                                    <span title={this.props.publishedAt} data-toggle='tooltip' data-placement='bottom'>
                                        {this.props.publishedAtInWords}
                                    </span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='feed-body'>
                    <h5 className='text-semibold'>{this.props.title}</h5>
                    <HiddenComponent isVisible={this.props.image} placeholder={this.props.htmlTeaser}>
                        <div className='row'>
                            <div className='col-md-4 pt-2'>
                                <img
                                    srcSet={`${this.props.imageVariants.small} 400w, ${this.props.imageVariants.medium} 800w, ${this.props.imageVariants.large} 1200w`}
                                    sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, (min-width: 801px) 1200px"
                                    src={this.props.imageVariants.large}
                                    width={this.props.imageWidth}
                                    height={this.props.imageHeight}
                                    loading="lazy"
                                    className='img-fluid'
                                />
                            </div>
                            <div className='col-md-8'>
                                <div className='rte-content' dangerouslySetInnerHTML={{__html: this.props.htmlTeaser}} />
                            </div>
                        </div>
                    </HiddenComponent>
                </div>
            </div>
        )

    }

}

Article.propTypes = {
    id: PropTypes.number.isRequired,
    author: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    htmlTeaser: PropTypes.string.isRequired,
    publishedAt: PropTypes.string.isRequired,
    publishedAtInWords: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    image: PropTypes.string,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    imageVariants: PropTypes.object
}

export default class Articles extends React.PureComponent {

    state = {
        articles: List(),
        nextPage: 1,
        lastPage: false
    }

    async componentDidMount() {
        await this.fetchPage()
    }

    fetchPage = async () => {

        if(this.state.lastPage) {
            return
        }

        const url = new URL(this.props.url)
        url.searchParams.set('page', this.state.nextPage)

        const response = await axios.get(url.toString(), {
            headers: {
                'Accept': 'application/json'
            }
        })

        if(response.status === 200) {

            const articles = this.state.articles.concat(response.data.articles)
            const lastPage = response.data.lastPage
            const nextPage = this.state.nextPage + 1

            this.setState({articles, nextPage, lastPage})

        }

    }

    render() {

        return (
            <React.Fragment>
                { this.state.articles.map(article => <Article key={article.id} {...article} />) }
                { this.state.articles.size > 0 && !this.state.lastPage &&
                    <div className='row justify-content-center'>
                        <button className='btn btn-default btn-rounded' type='button' onClick={this.fetchPage}>
                            { this.props.label }
                        </button>
                    </div>
                }
            </React.Fragment>
        )

    }

}

Articles.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}