import React from 'react';
import PropTypes from 'prop-types';
import Plyr from 'plyr';
import Bowser from "bowser";
import Lightbox from "./Lightbox";

class InformationBoxWithVideo extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            showLightbox: false
        };

        this.plyerElement = React.createRef();

        // Check if mobile for smaller videos
        this.userAgent = Bowser.parse(window.navigator.userAgent);

        if(this.userAgent.platform.type === 'mobile') {

            this.videoPath = this.props.videoMobilePath;
            this.imagePath = this.props.imageMobilePath;

        } else {

            this.videoPath = this.props.videoWebPath;
            this.imagePath = this.props.imageWebPath;

        }

    }

    componentDidMount() {

        this.player = new Plyr(this.plyerElement.current, {
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
            resetOnEnd: true
        });

        this.player.on('ended', event => {
            this.close();
        });

    }

    play = () => {

        this.setState({
            showLightbox: true
        }, () => {
            this.player.play();
        });

    }

    close = () => {

        this.setState({
            showLightbox: false
        }, () => {
            this.player.pause();
        });

    }

    render() {

        return (
            <React.Fragment>
                <div className='information'>
                    <i className='fa-light fa-info-circle'/>
                    <div className='title'>{this.props.title}</div>
                    {this.props.videoWebPath &&
                        <div onClick={this.play} className='preview' data-toggle='collapse' aria-expanded='false' aria-controls='video_help' title='Hilfevideo starten'>
                            <img src={this.imagePath}/>
                        </div>
                    }
                </div>

                {this.props.videoWebPath &&
                    <Lightbox isVisible={this.state.showLightbox} onClose={this.close}>
                        <video ref={this.plyerElement} poster={this.imagePath} playsInline={true} controls={true}>
                            <source src={this.videoPath} type="video/mp4"/>
                        </video>
                    </Lightbox>
                }
            </React.Fragment>
        )

    }

}

InformationBoxWithVideo.propTypes = {
    title: PropTypes.string.isRequired,
    videoWebPath: PropTypes.string,
    videoMobilePath: PropTypes.string,
    imageWebPath: PropTypes.string,
    imageMobilePath: PropTypes.string,
};

export default InformationBoxWithVideo;