import React, {useState, useEffect, useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import PropType from 'prop-types';
import {Map, Set} from 'immutable';
import Tooltip from './Tooltip';

const defaultContext = {
    tenderPeriodId: null,
    blockInstanceId: null,
    schedulings: [],
    selection: Map(),
    isEditable: false
}

const BlockSchedulerContext = React.createContext(defaultContext)

const Utilisation = ({lecturerId, year}) => {
    const {selection} = useContext(BlockSchedulerContext)
    const [utilisation, setUtilisation] = useState({})

    useEffect(() => {(async () => {
        const response = await axios.get(`/administration/lecturers/${lecturerId}/utilisation`, {
            headers: { 'Accept': 'application/json' },
            params: {year}
        })

        if(response.status === 200) {
            setUtilisation(response.data)
        }
    })()}, [lecturerId, selection])

    if(!utilisation) { return null }

    return (
        <React.Fragment>
            <span className={`badge ${utilisation['utilisation'] > utilisation['maxSchedulingPercentage'] ? 'badge-danger' : 'badge-info'}`} title='Auslastung'>Auslastung {utilisation['utilisation']} von {utilisation['maxSchedulingPercentage']}%</span>
        </React.Fragment>
    )
}

Utilisation.propTypes = {
    lecturerId: PropType.number,
    year: PropType.number
}

const Scheduling = ({id, date, commission, overlappingCommissions, onClick}) => {
    const {tenderPeriodId, selection, isEditable} = useContext(BlockSchedulerContext)

    const iconRef = useRef()
    const labelRef = useRef()

    let backgroundClass = commission ? 'bg-success' : 'bg-none'
    let alreadySelected = false
    let acceptedCommission = (overlappingCommissions || []).find(oc => oc.date === date && oc.state === 'accepted')
    let concurrentCommissions = []

    if(!acceptedCommission && !commission) {
        backgroundClass = 'bg-warning'
    }

    if(overlappingCommissions) {
        concurrentCommissions = overlappingCommissions.filter(oc => oc.state !== 'accepted')

        if(commission) {
            backgroundClass = concurrentCommissions.length > 0 ?  'bg-danger' : backgroundClass
        }

        alreadySelected = overlappingCommissions.find(oc => oc.state === 'selected')
    }

    const onClickLabel = (e, id, commissionId) => {
        if(!isEditable) {
            e.preventDefault()
            e.stopPropagation()

            return
        }

        onClick(id, commissionId)
    }

    const radioClasses = ['radio', 'no-label']

    if(!isEditable) {
        radioClasses.push('radio-disabled')
    }

    return (
        <td className={`text-center vertical-center p-1 ${backgroundClass}`} ref={labelRef}>
            {commission && !alreadySelected &&
                <React.Fragment>
                    { !acceptedCommission &&
                        <div className={radioClasses.join(' ')}>
                            <input readOnly value={(commission || {}).id} type='radio' name={`scheduling-${id}`} checked={commission && selection.get(id) === commission.id}  />
                            <label onClick={(e) => onClickLabel(e, id, commission.id)} />
                        </div>
                    }
                    { acceptedCommission &&
                        <i ref={iconRef} className='fa-light fa-xl fa-xmark' />
                    }
                    { concurrentCommissions.length > 0 && !acceptedCommission &&
                        <Tooltip referenceElement={labelRef.current} verticalOffset={-15} horizontalOffset={-5} style={{background: '#fff', padding: '0'}}>
                            <div className='list-group'>
                                {
                                    concurrentCommissions.map(c => (
                                        <a key={c.id} className='list-group-item' href={`/administration/tender_periods/${tenderPeriodId}/block_instances/${c.block_instance_id}/applications`} >{`[${c.key}] ${c.name}`}</a>
                                    ))
                                }
                            </div>
                        </Tooltip>
                    }
                </React.Fragment>
            }
            {commission && alreadySelected &&
                (
                    <React.Fragment>
                        <a href={`/administration/commissioning_requests/${alreadySelected.commissioning_request_id}`} title='Zuschlag für andere Blockplanung erteilt'>
                            <i ref={iconRef} className='fa-light fa-xl fa-xmark' />
                        </a>
                        <Tooltip referenceElement={labelRef.current} verticalOffset={-15} horizontalOffset={-5} style={{background: '#fff', padding: '0'}}>
                            <div className='list-group'>
                                {
                                    concurrentCommissions.map(c => (
                                        <a key={c.id} className='list-group-item' href={`/administration/tender_periods/${tenderPeriodId}/block_instances/${c.block_instance_id}/applications`} >{`[${c.key}] ${c.name}`}</a>
                                    ))
                                }
                            </div>
                        </Tooltip>
                    </React.Fragment>
                )
            }
            {!commission && <i className='fa-light fa-xl fa-xmark' title='Nicht auf Tag beworben' /> }
        </td>
    )
}

Scheduling.propTypes = {
    id: PropType.number.isRequired,
    date: PropTypes.string.isRequired,
    commission: PropType.object,
    overlappingCommissions: PropType.array,
    isEditable: PropType.bool.isRequired,
    onClick: PropTypes.func
}

const Commissionings = ({priority, year, lecturer, commissionings, onClick, onSelectAll}) => {
    const {tenderPeriodId, blockInstanceId, schedulings, isEditable} = useContext(BlockSchedulerContext)
    const [multipleSelections, setMultipleSelections] = useState({})

    useEffect(() => {(async () => {
        if(schedulings.length === 0) {
            return
        }

        const response = await axios.get(`/administration/tender_periods/${tenderPeriodId}/commissionings`, {
            headers: { 'Accept': 'application/json' },
            params: {
                start: schedulings[0].date,
                end: schedulings[schedulings.length - 1].date,
                lecturer_id: lecturer.id,
                block_instance_id: blockInstanceId
            }
        })

        if(response.status === 200) {
            setMultipleSelections(response.data)
        }
    })()}, [])

    const schedulingCommission = commissionings.reduce((result, value) => ({...result, [value.schedulingId]: value}), {})

    return (
        <tr>
            <td className='vertical-center'>
                <span title='Priorität laut Qualifizierung'>{priority}</span>
            </td>
            <td>
                <div className='text-nowrap'>
                    {lecturer.name}
                    <a href={`/administration/lecturers/${lecturer.id}`}>
                        <i className='fa-light fa-search ml-2'/>
                    </a>
                </div>
                <Utilisation lecturerId={lecturer.id} year={year} />
            </td>
            { schedulings.map(s => <Scheduling key={s.id} id={s.id} date={s.date} commission={schedulingCommission[s.id]} overlappingCommissions={multipleSelections[s.date]} isEditable={isEditable} onClick={onClick} /> ) }
            {isEditable &&
                <td className='vertical-center action'>
                    <button className='btn btn-icon btn-flat btn-rounded' onClick={() => onSelectAll(commissionings)}
                            title='Alle Tage auswählen/abwählen'>
                        <i className='fa-light fa-check'/>
                    </button>
                </td>
            }
        </tr>
    )

}

Commissionings.propTypes = {
    priority: PropTypes.number,
    year: PropTypes.number,
    lecturer: PropTypes.object,
    commissionings: PropTypes.array,
    isEditable: PropTypes.bool.isRequired,
    selection: PropTypes.object,
    onClick: PropTypes.func,
    onSelectAll: PropTypes.func
}

const BlockScheduler = ({tenderPeriodId, blockInstanceId, isEditable}) => {
    const [schedulings, setSchedulings] = useState([])
    const [applications, setApplications] = useState([])
    const [selection, setSelection] = useState(Map)

    useEffect(() => {(async () => {
        let response = await axios.get(`/administration/block_instances/${blockInstanceId}/schedulings`, {
            headers: {'Accept': 'application/json'}
        });

        if(response.status === 200) {
            setSchedulings(response.data)
        }

        response = await axios.get(location.href, {
            headers: {'Accept': 'application/json'}
        })

        if(response.status === 200) {
            setApplications(response.data)
        }
    })()}, [])

    useEffect(() => {
        let selected = Map()

        for(let {commissionings} of applications) {
            selected = commissionings.filter(c => c.state === 'selected').reduce((obj, c) => obj.set(c.schedulingId, c.id), selected)
        }

        setSelection(selected)
    }, [applications])

    const updateCommissioning = async (schedulingId, commissionId, toggle) => {
        const data = {
            scheduling_id: schedulingId,
            commissioning_id: commissionId,
            toggle: toggle
        }

        return axios.put(`/administration/tender_periods/${tenderPeriodId}/commissioning`, data, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })
    }

    const onClick = async (schedulingId, commissionId) => {
        const toggle = selection.get(schedulingId) === commissionId

        const response = await updateCommissioning(schedulingId, commissionId, toggle)

        if(response.status === 200) {

            if(toggle) {
                setSelection(selection.delete(schedulingId))
            } else {
                setSelection(selection.set(schedulingId, commissionId))
            }

        }
    }

    const onSelectAll = async (commissionings) => {

        if(!isEditable) {
            return
        }

        const commissioningIds = Set(commissionings.map(c => c.id))

        if(commissioningIds.some(c => selection.includes(c))) {
            const deselected = selection.filter(c => commissioningIds.has(c))

            for (let [key, value] of deselected) {
                await updateCommissioning(key, value, true)
            }

            setSelection(selection.filterNot(c => commissioningIds.has(c)))
        } else {
            let selected = Map()

            for(let {id, schedulingId} of commissionings) {
                selected = selected.set(schedulingId, id)
            }

            for (let [key, value] of selected) {
                await updateCommissioning(key, value, false)
            }

            setSelection(selection.merge(selected))
        }
    }

    return (
        <div className='table-responsive'>
            <table className='table no-border'>
                <thead>
                    <tr>
                        <th title='Priorität laut Qualifizierung'>Prio</th>
                        <th>Dozent</th>
                        {
                            schedulings.map(({id, date}) => (
                                <th key={id} className='text-nowrap text-center pl-1 pr-1'>{new Intl.DateTimeFormat(document.documentElement.lang, {day: '2-digit', month: '2-digit'}).format(new Date(date))}</th>
                            ))
                        }
                        {isEditable &&
                            <th/>
                        }
                    </tr>
                </thead>
                <tbody>
                    <BlockSchedulerContext.Provider value={{tenderPeriodId, blockInstanceId, schedulings, selection, isEditable}}>
                        {
                            applications.map(({id, priority, year, lecturer, commissionings}) => (
                                <Commissionings key={id} priority={priority} year={year} lecturer={lecturer} commissionings={commissionings} onClick={onClick} onSelectAll={onSelectAll} />
                            ))
                        }
                    </BlockSchedulerContext.Provider>
                </tbody>
            </table>
        </div>
    )
}

BlockScheduler.propTypes = {
    tenderPeriodId: PropTypes.number.isRequired,
    blockInstanceId: PropTypes.number.isRequired,
    isEditable: PropTypes.bool.isRequired
}

export default BlockScheduler