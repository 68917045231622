import React, {useState} from 'react';
import SelectBox from './SelectBox';
import PropTypes from 'prop-types';
import DateTimeInput from './DateTimeInput';

const WizardJobCenter = ({completedJobProfile, completeJobProfileUntil, jobProfileAssistance, labels}) => {

    const [profileCompleted, setProfileCompleted] = useState(completedJobProfile || null)
    const [requiresAssistance, setRequiresAssistance] = useState(jobProfileAssistance || false)
    const [completionDate, setCompletionDate] = useState(completeJobProfileUntil)

    return <>
        <div className='form-group'>
            <label className='required' htmlFor='job_center_completed_job_profile'>{labels.completedJobProfile}</label>
            <SelectBox id='job_center_completed_job_profile' name='job_center[completed_job_profile]' required={true} items={[{title: "\u00A0", value: ''}, {title: 'Ja', value: true}, {title: 'Nein', value: false}]} value={profileCompleted} onChange={({value}) => setProfileCompleted(value)} />
        </div>
        {profileCompleted === false &&
            <>
                <div className='form-group'>
                    <label className='required' htmlFor='job_center_complete_job_profile_until'>{labels.completedJobProfileUntil}</label>
                    <DateTimeInput showReset={false} minDate={new Date()} className='form-control' id='job_center_complete_job_profile_until' name='job_center[complete_job_profile_until]' displayTime={false} date={completionDate} required={true} onChange={date => setCompletionDate(date)} />
                </div>
                <div className="checkbox mb-3">
                    <input name="job_center[job_profile_assistance]" type="hidden" value="0" autoComplete="off" />
                    <input type="checkbox" name="job_center[job_profile_assistance]" id="job_center_job_profile_assistance" checked={requiresAssistance} onChange={() => setRequiresAssistance(!requiresAssistance)}/>
                    <label htmlFor="job_center_job_profile_assistance" >
                        {labels.jobProfileAssistance}
                    </label>
                </div>
            </>
        }
    </>

}

WizardJobCenter.propTypes = {
    completedJobProfile: PropTypes.bool,
    completeJobProfileUntil: PropTypes.string,
    jobProfileAssistance: PropTypes.bool,
    labels: PropTypes.object
}

WizardJobCenter.defaultProps = {
    jobProfileAssistance: false
}

export default WizardJobCenter;