import React from 'react';
import PropType from 'prop-types';
import {List} from 'immutable';
import axios from 'axios';
import SelectBox from "./SelectBox";

class BlockInstanceForm extends React.PureComponent {

    state = {
        selectedBlockStart: null,
        blockStarts: List(),
        blockVersions: List(),
    }

    componentDidMount() {
        this.fetchBlockStarts();
    }

    fetchBlockStarts = async () => {

        const result = await axios.get(`/administration/block_starts`, {
            params: {upcoming: true},
            headers: {'Accept': 'application/json'}
        });

        if(result.status === 200) {
            this.setState({blockStarts: List(result.data)});
        }

    }

    fetchBlockVersions = async (blockStart) => {

        const params = {
            date: this.state.blockStarts.find(p => p['id'] === blockStart.value).date,
        }

        const result = await axios.get(`/administration/block_versions`, {
            params: params,
            headers: {'Accept': 'application/json'}
        });

        if(result.status === 200) {
            this.setState({blockVersions: List(result.data), selectedBlockStart: blockStart});
        }

    }

    formatDate = (date) => new Intl.DateTimeFormat(document.documentElement.lang, {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(date))

    render() {

        return (
            <React.Fragment>
                <div className='form-group'>
                    <label htmlFor='block_start_id' className='required'>{this.props.labelBlockStart}</label>
                    <SelectBox id='block_start_id' name='block_instance[block_start_id]' required={true} value={this.props.blockStartId} items={this.state.blockStarts.map(item => { return {value: item.id, title: this.formatDate(item.date)} }).toJS()} onChange={(block_version) => this.fetchBlockVersions(block_version)}/>
                </div>

                {this.state.selectedBlockStart &&
                    <div className='form-group'>
                        <label htmlFor='block_version_id' className='required'>{this.props.labelBlockVersion}</label>
                        <SelectBox id='block_version_id'
                                   name='block_instance[block_version_id]' required={true}
                                   value={this.props.blockVersionId}
                                   items={this.state.blockVersions.map(item => {
                                       return {value: item.id, title: item.humanName}
                                   }).toJS()}/>
                    </div>
                }
            </React.Fragment>
        )

    }

}

BlockInstanceForm.propTypes = {
    blockStartId: PropType.number,
    blockVersionId: PropType.number,
    labelBlockStart: PropType.string.isRequired,
    labelBlockVersion: PropType.string.isRequired,
}

export default BlockInstanceForm;
