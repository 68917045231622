import React from 'react';
import PropType from 'prop-types';
import {List, Set} from 'immutable';
import axios from 'axios';
import SelectBox from "./SelectBox";

class BulkSelection extends React.PureComponent {

    checkboxes = List();

    state = {
        values: Set()
    }

    componentDidMount() {

        const checked = [];

        this.checkboxes = this.checkboxes.concat(document.querySelectorAll('input[data-mode="bulk"]'));

        this.checkboxes.forEach((checkbox) => {

            checkbox.addEventListener('change', this.onChange, false);

            if(checkbox.checked) {
                checked.push(checkbox.value);
            }

        });

        if(this.checkboxes.size > 0) {

            this.toggle = this.checkboxes.first().closest('table').querySelector('input[data-mode="toggle"]');

            this.toggle.addEventListener('change', this.onToggle, false);

        }

        this.setState({values: this.state.values.concat(checked)}, () => {

            if(this.props.onChange) {
                this.props.onChange('add', null, this.state.values.size);
            }

        });

    }

    componentWillUnmount() {

        this.checkboxes.forEach((checkbox) => {
           checkbox.removeEventListener('change', this.onChange, false);
        });

        if(this.toggle) {
            this.toggle.removeEventListener('change', this.onToggle, false);
        }
        
    }

    onChange = (e) => {

        e.stopPropagation();
        this.updateSelection(e.target);

    }

    onToggle = (e) => {

        let values;

        if(e.target.checked) {
            values = this.checkboxes.map(c => c.value).toSet();
        } else {
            values = this.state.values.clear();
        }

        this.setState({values}, () => {

            this.checkboxes.forEach((c) => {
                c.checked = e.target.checked
            });

            if(this.props.onChange) {
                this.props.onChange(this.state.values.size);
            }

        });

    }

    clearSelection = () => {
        this.setState({values: this.state.values.clear()});
    }

    updateSelection = (checkbox) => {

        let values;

        if(checkbox.checked) {
            values = this.state.values.add(checkbox.value);
        } else {
            values = this.state.values.remove(checkbox.value);
        }

        this.setState({values}, () => {

            if(this.props.onChange) {
                this.props.onChange(this.state.values.size);
            }

        });

    }

    render() {
        return null;
    }

}

BulkSelection.propTypes = {
    onChange: PropType.func
}

class BlockInstanceAssignment extends React.PureComponent {

    selectionComponent = React.createRef();

    state = {
        selectionSize: 0,
        blockInstance: null,
        blockInstances: List(),
        allBlocks: false
    }

    async componentDidMount() {
        await this.fetchBlockInstances()
    }

    fetchBlockInstances = async () => {

        const params = {
            starts_at: this.props.startsAt,
            all_blocks: this.state.allBlocks
        }

        const result = await axios.get(`/administration/blocks/${this.props.blockId}/block_instances`, {
            params: params,
            headers: {'Accept': 'application/json'}
        });

        if(result.status === 200) {
            this.setState({blockInstances: List(result.data).filterNot((item) => item.id === this.props.blockInstanceId)});
        }

    }

    onSelectionChange = (size) => {

        const update = {selectionSize: size};

        if(size === 0) {
            update['blockInstance'] = null;
        }

        this.setState(update);

    }

    onBlockInstanceChange = (item) => {
        this.setState({blockInstance: this.state.blockInstances.find((blockInstance) => blockInstance.id === item.value )});
    }

    onAllBlocksChange = () => {

        this.setState({allBlocks: !this.state.allBlocks}, async () => {
            await this.fetchBlockInstances();
        });

    }

    moveParticipants = async () => {

        if(!this.selectionComponent.current) {
            return;
        }

        let result = await axios.post(`/administration/block_instances/${this.state.blockInstance.id}/participations/assignment`,
            {
                participations: this.selectionComponent.current.state.values.map((v) => parseInt(v, 10)).toJS()
            },
            {
                headers: {
                    'Accept': 'application/javascript',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
            }
        );

        if(result.status === 200) {
            location.reload()
        }

    }

    render() {

        return (
            <React.Fragment>
                <BulkSelection onChange={this.onSelectionChange} ref={this.selectionComponent} />
                { this.state.selectionSize > 0 &&
                    <div className='card'>
                        <div className='card-heading border bottom'>
                            <h4 className='card-title'>
                                Teilnahmen verschieben
                            </h4>
                        </div>
                        <div className='card-block'>
                            <div className='checkbox mb-3'>
                                <input id='all_blocks' type='checkbox' name='all_blocks' value={this.state.allBlocks} onChange={this.onAllBlocksChange} />
                                <label htmlFor='all_blocks' className='mb-0'>{`Alle Blockplanungen mit Startdatum ${ new Date(this.props.startsAt).toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit'}) } anzeigen`}</label>
                            </div>

                            { this.state.blockInstances.size > 0 &&
                                <React.Fragment>
                                    <div className='form-group'>
                                        <label className='required' htmlFor='block_instance'>{this.props.labelSelection}</label>
                                        <SelectBox id='block_instance' name='block_instance' required={true} items={this.state.blockInstances.map(item => { return {value: item.id, title: item.name} }).toJS()} onChange={this.onBlockInstanceChange} />
                                    </div>
                                </React.Fragment>
                            }

                            { this.state.blockInstances.size === 0 &&
                                <div className='alert alert-info'>{this.props.labelNoBlockInstance}</div>
                            }

                            { this.state.blockInstances.size > 0 && this.state.blockInstance &&
                                <button className='btn btn-primary mb-0' onClick={this.moveParticipants}>
                                    {this.props.labelButton} ({ this.state.selectionSize })
                                </button>
                            }
                        </div>
                    </div>
                }
            </React.Fragment>
        )

    }

}

BlockInstanceAssignment.propTypes = {
    blockInstanceId: PropType.number.isRequired,
    blockId: PropType.number.isRequired,
    blockVersionId: PropType.number.isRequired,
    startsAt: PropType.string.isRequired,
    labelButton: PropType.string.isRequired,
    labelSelection: PropType.string.isRequired,
    labelNoBlockInstance: PropType.string.isRequired
}

export default BlockInstanceAssignment;
