import React, {useEffect, useRef} from 'react';
import Reveal from 'reveal.js';
import RevealNotes from 'reveal.js/plugin/notes/notes.esm';

const loadSlides = async (container, urls) => {
    for (let url of urls) {
        const response = await fetch(url);

        const slideSection = document.createElement('section');
        slideSection.innerHTML = await response.text();

        container.appendChild(slideSection);
    }
};

const Presentation = ({slides}) => {
    const container = useRef()

    useEffect(() => {
        if(!container.current) { return }

        (async () => {
            await loadSlides(container.current, slides)

            Reveal.initialize({
                width: 1920,
                height: 1200,
                margin: 0,
                hash: true,
                respondToHashChanges: true,
                history: true,
                plugins: [RevealNotes]
            });
        })()

        return () => {
            Reveal.destroy();
        }
    }, [container])

    return <div ref={container} className="slides"/>
}

export default Presentation