import React, {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import {List} from 'immutable';
import PropType from 'prop-types';
import SelectBox from './SelectBox';

const formatDate = (date) => (
    new Intl.DateTimeFormat(document.documentElement.lang, {day: '2-digit', month: '2-digit', year: 'numeric'}).format(new Date(date))
)

const checkValidity = ({validFrom, validTo}, {date}) => {
    const start = new Date(date)
    const from = validFrom ? new Date(validFrom) : -Infinity
    const to = validTo ? new Date(validTo) : Infinity

    return start >= from && start <= to
}

const Cell = ({block, blockStart, checked, onClick}) => {
    const isValid = useMemo(() => checkValidity(block, blockStart), [block, blockStart])

    return (
        <td className='text-center align-middle'>
            <div className='checkbox checkbox-without-label'>
                <input type='checkbox'
                       id={`block_instance_batch_${block.id}_${blockStart.id}`}
                       name={`block_instance_batch[${block.id}][${blockStart.id}]`}
                       disabled={!isValid}
                       checked={checked}
                       onChange={onClick}
                />
                { isValid && <label htmlFor={`block_instance_batch_${block.id}_${blockStart.id}`} /> }
            </div>
        </td>
    )
}

Cell.propTypes = {
    block: PropType.object.isRequired,
    blockStart: PropType.object.isRequired,
    checked: PropType.bool.isRequired,
    onClick: PropType.func.isRequired
}

const BlockPlanningMatrix = () => {
    const [selection, setSelection] = useState({})
    const [blockStarts, setBlockStarts] = useState(List())
    const [blocks, setBlocks] = useState(List())
    const [years, setYears] = useState(List())
    const [year, setYear] = useState(new Date().getFullYear())
    const [applyInterval, setApplyInterval] = useState(false)

    useEffect(() => {(async () => {
        let response = await axios.get('/administration/block_starts/years', {
            params: { active: true, upcoming: true },
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setYears(List(response.data))
        }

        response = await axios.get('/administration/blocks', {
            params: {year},
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setBlocks(List(response.data))
        }
    })()}, [])

    useEffect(() => {(async () => {
        const response = await axios.get('/administration/block_starts', {
            params: {year, upcoming: true},
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setBlockStarts(List(response.data))
        }
    })()}, [year])

    const isBlockPresent = (id) => typeof selection[id] !== 'undefined'

    const check = (block, blockStart, applyInterval = false) => {
        let starts = new Set()

        if (isBlockPresent(block.id)) {
            starts = selection[block.id]
        }

        if(starts.has(blockStart.id)) {

            starts.delete(blockStart.id)

            if(starts.size === 0) {
                starts = undefined
            }

        } else {

            starts.add(blockStart.id)

            if(applyInterval && block.schedulingInterval !== null) {

                const idx = blockStarts.findIndex(bs => bs.id === blockStart.id)

                for(let i=idx; i < blockStarts.size; i += block.schedulingInterval) {
                    starts.add(blockStarts.get(i).id)
                }

            }

        }

        selection[block.id] = starts

        setSelection({...selection})
    }

    const toggleAll = ({id}, direction) => {
        const hasSelection = direction === 'horizontal' ? isBlockPresent(id) : Object.values(selection).some(set => set.has(id))
        const action = hasSelection ? 'abwählen' : 'auswählen'

        if(!confirm(`Wirklich alle Elemente ${action}?`)) {
            return
        }

        if(hasSelection) {

            if(direction === 'horizontal') {

                delete selection[id]

            } else {

                Object.values(selection).map(set => {
                    if(!set.has(id)) { return set }
                    set.delete(id)

                    if(set.size === 0) {
                        return undefined
                    }

                    return set
                })

            }

        } else {

            if(direction === 'horizontal') {

                selection[id] = new Set(blockStarts.map(bs => bs.id))

            } else {

                blocks.forEach(block => {

                    if(!isBlockPresent(block.id)) {
                        selection[block.id] = new Set()
                    }

                    selection[block.id].add(id)

                })

            }

        }

        setSelection({...selection})
    }

    return (
        <React.Fragment>
            {blockStarts.size === 0 &&
                <div className='alert alert-info'>Es sind keine Blockstarts vorhanden.</div>
            }

            {blockStarts.size > 0 &&
                <React.Fragment>
                    <div className='form-group'>
                        <label htmlFor='year'>Geschäftsjahr</label>
                        <SelectBox id='year' items={years.map(y => ({value: y.value, title: y.value})).toJS()} value={year} disableInput={true} onChange={({value}) => setYear(value)} />
                    </div>

                    <div className='checkbox'>
                        <input id='apply-interval' className='form-check-input' type='checkbox' checked={applyInterval} onChange={() => setApplyInterval(!applyInterval)} />
                        <label htmlFor='apply-interval' className='form-check-label'>Intervall anwenden</label>
                    </div>

                    <table className='table table-hover fixed-header matrix'>
                        <thead>
                            <tr>
                                <th/>
                                {blockStarts.map((blockStart, idx) => (
                                    <th key={idx}
                                        className='text-vertical'
                                        style={{cursor: 'pointer'}}
                                        onClick={() => toggleAll(blockStart, 'vertical')}
                                    >
                                        {formatDate(blockStart.date)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {blocks.map(block => (
                                <tr key={block.id}>
                                    <td className='pl-0' style={{cursor: 'pointer'}} onClick={() => toggleAll(block, 'horizontal')}>{`[${block.key}] ${block.name}`}</td>
                                    {blockStarts.map(blockStart => (
                                        <Cell key={`${block.id}-${blockStart.id}`} block={block} blockStart={blockStart}
                                              checked={isBlockPresent(block.id) && selection[block.id].has(blockStart.id)}
                                              onClick={() => check(block, blockStart, applyInterval)}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default BlockPlanningMatrix;