import React, {useState} from 'react';
import PropType from 'prop-types';
import SelectBox from "./SelectBox";

const AffiliateCodeForm = ({scope, labels, kind: _kind, kinds, discountKinds, discountKind: _discountKind, monthlyDiscount: _monthlyDiscount, yearlyDiscount: _yearlyDiscount, trialDays: _trialDays}) => {
    const [kind, setKind] = useState(_kind)
    const [trialDays, setTrialDays] = useState(_trialDays || '')
    const [monthlyDiscount, setMonthlyDiscount] = useState(_monthlyDiscount || '')
    const [yearlyDiscount, setYearlyDiscount] = useState(_yearlyDiscount || '')
    const [discountKind, setDiscountKind] = useState(_discountKind ||'')

    return <>
        <div className='form-group'>
            <label htmlFor='kind' className='required'>{labels.kind}</label>
            <SelectBox id='kind' name={`${scope}[kind]`} required={true} items={kinds} value={kind} onChange={({value}) => setKind(value)} />
        </div>

        {kind === 'trial' &&
            <div className="form-group">
                <label htmlFor="trial_days">{labels.trialDays}</label>
                <div className="input-group">
                    <input type="number" name={`${scope}[trial_days]`} id="trial_days" value={trialDays} min="1" className="form-control" onChange={({target: {value}}) => setTrialDays(value)} />
                    <div className="input-group-append">
                        <span className="input-group-text">{labels.days}</span>
                    </div>
                </div>
            </div>
        }

        {kind === 'discount' &&
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="discount_kind">Rabattart</label>
                        <SelectBox id='discount_kind' name={`${scope}[discount_kind]`} required={true} items={discountKinds} value={discountKind} onChange={({value}) => setDiscountKind(value)} />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="monthly_discount">{labels.monthlyDiscount}</label>
                        <div className="input-group">
                            <input type="number" name={`${scope}[monthly_discount]`} id="monthly_discount" value={monthlyDiscount} min="1" className="form-control" onChange={({target: {value}}) => setMonthlyDiscount(value)} />
                            <div className="input-group-append">
                                <span className="input-group-text">{discountKind === 'percentage' ? '%' : '€'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="affiliate_code_yearly_discount">{labels.yearlyDiscount}</label>
                        <div className="input-group">
                            <input type="number" name={`${scope}[yearly_discount]`} id="yearly_discount" value={yearlyDiscount} min="1" className="form-control" onChange={({target: {value}}) => setYearlyDiscount(value)}/>
                            <div className="input-group-append">
                                <span className="input-group-text">{discountKind === 'percentage' ? '%' : '€'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}

AffiliateCodeForm.propTypes = {
    scope: PropType.string.isRequired,
    kind: PropType.string.isRequired,
    kinds: PropType.array.isRequired,
    discountKind: PropType.string.isRequired,
    discountKinds: PropType.array.isRequired,
    monthlyDiscount: PropType.number,
    yearlyDiscount: PropType.number,
    trialDays: PropType.number,
    labels: PropType.shape({
        kind: PropType.string.isRequired,
        days: PropType.string.isRequired,
        trialDays: PropType.string.isRequired,
        monthlyDiscount: PropType.string.isRequired,
        yearlyDiscount: PropType.string.isRequired,
    })
}

export default AffiliateCodeForm;
