import React from 'react';
import PropTypes from 'prop-types';

class DownloadPreview extends React.PureComponent {

    render() {

        return (
            <div className='unknown-wrapper'>
                <p>{this.props.message}</p>
                <button type='button'  className='btn btn-info btn-icon btn-rounded' title='Datei herunterladen' onClick={() => window.location.href = this.props.file.downloadUrl}>
                    <i className='fa-light fa-file-download' />
                </button>
            </div>
        )

    }

}

DownloadPreview.propTypes = {
    message: PropTypes.string,
    file: PropTypes.object
};

export default DownloadPreview;