import React from 'react';
import PropTypes from 'prop-types';
import {PieChart, Pie, Cell, Tooltip} from 'recharts';

const HiddenComponent = ({isVisible, placeholder, children, ...props}) => (
    isVisible ? React.cloneElement(children, props) : React.cloneElement(placeholder, props)
)

const Placeholder = ({data}) => (
    <React.Fragment>
        {
            data.map(
                (entry, idx) => <div key={idx}>{entry.value} x {entry.name}</div>
            )
        }
    </React.Fragment>
)

Placeholder.propTypes = {
    data: PropTypes.array
}

export default class VotesChart extends React.PureComponent {

    COLORS = ["#52ae47", "#4a9c4f", "#428b57", "#3a795f", "#316768", "#295570", "#214478", "#193280"];

    state = {
        showChart: false
    }

    componentDidMount() {

        this.container.addEventListener('vote-charts:toggle', this.toggleVisibility)

        const toggle = document.querySelector('th[data-toggle="vote-charts"]')

        // Ensure click handler is only added once even if component is mounted multiple times

        if(!toggle.onclick) {

            toggle.onclick = () => {

                const charts = document.querySelectorAll('.vote-chart')

                for (const chart of charts) {
                    chart.dispatchEvent(new Event('vote-charts:toggle'))
                }

            }

        }

    }

    componentWillUnmount() {

        this.container.removeEventListener('vote-charts:toggle', this.toggleVisibility)

        const toggle = document.querySelector('th[data-toggle="vote-charts"]')

        if(toggle.onclick) {
            toggle.onclick = null
        }

    }

    toggleVisibility = () => {
        this.setState({showChart: !this.state.showChart});
    }

    render() {

        return (
            <div className={'vote-chart'} ref={el => this.container = el} style={{cursor: 'pointer'}} onClick={this.toggleVisibility}>
                <HiddenComponent isVisible={this.state.showChart} placeholder={<Placeholder />} {...this.props}>
                    <PieChart width={this.props.width} height={this.props.height}>
                        <Pie
                            dataKey='value'
                            startAngle={0}
                            endAngle={180}
                            data={this.props.data}
                            cx='50%' cy='100%'
                            outerRadius={95}
                            fill='#8884d8'
                        >
                            {
                                this.props.data.map((entry, idx) => (
                                    <Cell key={`cell-${idx}`} fill={this.COLORS[idx % this.COLORS.length]} />
                                ))
                            }
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </HiddenComponent>
            </div>
        )

    }

}

VotesChart.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    ).isRequired
}

VotesChart.defaultProps = {
    width: 200,
    height: 100
}
