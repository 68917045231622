import React, {useCallback, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {computePosition, offset, flip, shift, arrow} from '@floating-ui/dom';

const Tooltip = ({referenceElement, children, style, verticalOffset, horizontalOffset}) => {
    const [isVisible, setIsVisible] = useState(false)
    const [tooltipPosition, setTooltipPosition] = useState({left: '0', top: '0'})
    const [arrowPosition, setArrowPosition] = useState({left: '0', top: '0', placement: 'bottom'})
    const arrowRef = useRef()

    const onNodeChange = async node => {
        if(!node) { return }

        node.addEventListener('click', hideTooltip)

        await updatePosition(node)
    }

    const tooltipRef = useCallback(onNodeChange, [referenceElement])

    const showTooltip = () => setIsVisible(true)
    const hideTooltip = () => setIsVisible(false)

    useEffect(() => {(async () => {
        if(!referenceElement) { return }

        referenceElement.addEventListener('mouseenter', showTooltip)
        referenceElement.addEventListener('mouseleave', hideTooltip)

        return () => {
            referenceElement.removeEventListener('mouseenter', showTooltip)
            referenceElement.removeEventListener('mouseleave', hideTooltip)
        }
    })()}, [referenceElement])

    const updatePosition = async (node) => {
        const {x, y, placement, middlewareData} = await computePosition(referenceElement, node, {
            strategy: 'fixed',
            placement: 'bottom',
            middleware: [
                flip(),
                offset(verticalOffset),
                shift({padding: 5}),
                arrow({element: arrowRef.current})
            ],
        })

        const {x: arrowX, y: arrowY} = middlewareData.arrow;

        setTooltipPosition({left: `${x + horizontalOffset}px`, top: `${y}px`})
        setArrowPosition({left: arrowX ? `${arrowX}px` : '', top: arrowY ? `${arrowY}px` : 0, placement})
    }

    const {color, background, padding} = style

    const getTooltipStyle = ({top, left}) => ({
        position: 'fixed',
        top,
        left,
        background,
        color,
        padding,
        borderRadius: '5px',
        zIndex: '99999'
    })

    const getArrowStyle = ({top, left, placement}) => {
        const side = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
        }[placement.split('-')[0]];

        return {
            position: 'absolute',
            top,
            left,
            right: '',
            bottom: '',
            background: background,
            width: '8px',
            height: '8px',
            transform: 'rotate(45deg)',
            [side]: '-4px'
        }
    }

    if(!isVisible || !children) { return null }

    return (
        <div ref={tooltipRef} role='tooltip' style={getTooltipStyle(tooltipPosition)}>
            { children }
            <div ref={arrowRef} style={getArrowStyle(arrowPosition)} />
        </div>
    )
}

Tooltip.propTypes = {
    referenceElement: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    style: PropTypes.object,
    verticalOffset: PropTypes.number,
    horizontalOffset: PropTypes.number
}

Tooltip.defaultProps = {
    style: {
        color: '#fff',
        background: '#000',
        padding: '5px',
    },
    verticalOffset: 5,
    horizontalOffset: 0
}

export default Tooltip;