import React from 'react';
import DateTimeInput from './DateTimeInput';
import axios from 'axios';
import {Matrix, Cell} from './JobCenterMatrix';

class JobSurveyReport extends React.PureComponent {

    state = {
        rangeStart: new Date(new Date().getFullYear(), 0, 1),
        rangeEnd: new Date(),
        data: null
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(_prevProps, prevState) {

        if(this.state.rangeStart == null || this.state.rangeEnd == null) {
            return
        }

        if(this.state.rangeStart > this.state.rangeEnd) {
            return
        }

        if(prevState.rangeStart !== this.state.rangeStart || prevState.rangeEnd !== this.state.rangeEnd) {
            await this.fetchData();
        }

    }

    onChange = (key, value) => {
        this.setState({ [key]: value });
    }

    getISODate = (date) => {
        return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
    }

    fetchData = async () => {

        const result = await axios.get(location.href, {
            params: {
                start_date: this.getISODate(this.state.rangeStart),
                end_date: this.getISODate(this.state.rangeEnd)
            },
            headers: {'Accept': 'application/json'}
        });

        if(result.status !== 200) {
            return;
        }

        this.setState({data: result.data});

    }

    getCell = (key, row, column, value) => {
        return <Cell key={key} value={value} />;
    }

    render() {

        return (
            <React.Fragment>
                <div className='row mb-3'>
                    <div className='col-auto'>
                        <label>Zeitraum von</label>
                        <DateTimeInput displayTime={false} date={this.state.rangeStart} className='form-control' onChange={this.onChange.bind(this, 'rangeStart')} />
                    </div>
                    <div className='col-auto'>
                        <label>Zeitraum bis</label>
                        <DateTimeInput displayTime={false} date={this.state.rangeEnd} className='form-control' onChange={this.onChange.bind(this, 'rangeEnd')} />
                    </div>
                </div>
                { this.state.rangeStart <= this.state.rangeEnd &&
                    <Matrix getCell={this.getCell} {...this.state.data} />
                }
            </React.Fragment>
        )

    }

}

export default JobSurveyReport;