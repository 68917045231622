import React from 'react';
import PropTypes from 'prop-types';

const FileSizeFormatter = ({size, loaded}) => {
    const format = (bytes, decimals) => {
        if(bytes === 0) return '0 Bytes';

        const
            kibibyte = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(kibibyte));

        return parseFloat((bytes / Math.pow(kibibyte, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    let content = format(size)
    let badge = 'info'

    if(typeof loaded !== 'undefined') {

        content = `${ format(loaded) } / ${ format(size) }`

        if(loaded === size) {
            badge = 'success'
        }

    }

    return(
        <span className={`badge badge-${badge}`}>
            { content }
        </span>
    )
}

FileSizeFormatter.propTypes = {
    size: PropTypes.number.isRequired,
    loaded: PropTypes.number
};

export default FileSizeFormatter