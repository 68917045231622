/* globals $ */

import React from 'react';
import PropTypes from 'prop-types';
import SelectBox from './SelectBox';

class TerminationValidation extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            reason: null,
            terminatedAt: '',
            examParticipation: false
        }

    }

    componentDidMount() {

        $('input[data-provide="datepicker"]').datepicker({
            language: 'de',
            maxViewMode: 2,
            todayHighlight: true,
            todayBtn: true,
            clearBtn: true,
            autoclose: true,
        }).on('changeDate', this.dateChanged);

    }

    dateChanged = (e) => {
        this.setState({terminatedAt: e.target.value});
    }

    render() {

        return (
            <div className='termination-validation'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='form-group'>
                            <label className='required'>{this.props.translations.terminatedAt}</label>
                            <input className='form-control' name='terminated_at' required={true} value={this.state.terminatedAt} onChange={this.dateChanged} data-provide='datepicker' pattern='[0-9]{2}.[0-9]{2}.2[0-9]{3}' />
                        </div>
                        <div className='form-group'>
                            <label className='required'>{this.props.translations.reason}</label>
                            <SelectBox name='reason' required={true} includeEmpty={true} items={ this.props.reasons.map(item => { return {title: item[0], value: item[1]} }) } onChange={ item => this.setState({reason: item.value}) } />
                        </div>
                        <div className='checkbox'>
                            <input id='exam-participation' name='exam_participation' type='checkbox' checked={this.state.examParticipation} onChange={e => this.setState({examParticipation: e.target.checked}) } />
                            <label htmlFor='exam-participation'>{this.props.translations.examParticipation}</label>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}

TerminationValidation.propTypes = {
    document: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    reasons: PropTypes.array.isRequired
};

export default TerminationValidation;