import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { List } from 'immutable';
import qs from 'qs';
import PDFPreview from './PDFPreview';
import ImagePreview from './ImagePreview';
import DownloadPreview from './DownloadPreview';

export class PrintingService {

    static async print(file) {

        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && file.contentType === 'application/pdf') {

            const blankWindow = window.open(file.url,'_blank','noreferrer');
            blankWindow.focus();

            return;

        }

        const response = await axios.get(file.url, {responseType: 'blob'});

        if(response.status !== 200) {
            return;
        }

        const iFrame = document.createElement('iframe');

        iFrame.onload = () => {

            iFrame.contentWindow.onbeforeunload = () => { iFrame.remove() };
            iFrame.contentWindow.onafterprint = () => { iFrame.remove() };
            iFrame.contentWindow.focus();
            iFrame.contentWindow.print();

        };

        iFrame.style.position = 'fixed';
        iFrame.style.right = '0';
        iFrame.style.bottom = '0';
        iFrame.style.width = '0';
        iFrame.style.height = '0';
        iFrame.style.border = '0';

        iFrame.src = URL.createObjectURL(response.data);

        document.body.appendChild(iFrame);

    }

}

class DocumentPreview extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            documentId: 0,
            index: 0,
            files: List()
        };

        this.wrapper = React.createRef();

    }

    componentDidMount() {

        window.addEventListener('querychange', this.updateSelection, false);

        this.updateSelection();

    }

    async componentDidUpdate(prevProps, prevState) {

        if(prevState.documentId === this.state.documentId) {
            return;
        }

        const files = await axios.get(`${this.props.baseUrl}/${this.state.documentId}`, {headers: {'Accept': 'application/json'}});

        if (files.status === 200 && files.data) {
            this.setState({files: List(files.data)});
        }

    }

    componentWillUnmount() {
        window.removeEventListener('querychange', this.updateSelection, false);
    }

    updateSelection = () => {

        const parameters = qs.parse(location.search.slice(1));

        const id = (parameters.document) ? parseInt(parameters.document, 10) : this.props.documentId;
        const index = (parameters.index) ? parseInt(parameters.index, 10) : 0;

        this.setState({documentId: id, index: index});

    };

    render() {

        const activeFile = this.state.files.get(this.state.index);

        if(!activeFile) {
            return null;
        }

        let preview;

        if(activeFile.contentType === 'application/pdf') {
            preview = <PDFPreview file={activeFile} print={(file) => PrintingService.print(file)} loadingFile={this.props.loadingFile} loadingFailed={this.props.loadingFailed} />;
        } else if(['jpeg', 'png', 'bmp', 'gif', 'webp', 'svg+xml'].indexOf(activeFile.contentType.split('/')[1]) >= 0) {
            preview = <ImagePreview file={activeFile} print={(file) => PrintingService.print(file)} loadingFailed={this.props.loadingFailed} />;
        } else {
            preview = <DownloadPreview file={activeFile} message={this.props.invalidFormat} />
        }

        return preview;

    }

}

DocumentPreview.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    documentId: PropTypes.number.isRequired,
    invalidFormat: PropTypes.string,
    loadingFile: PropTypes.string,
    loadingFailed: PropTypes.string
};

export default DocumentPreview;