// noinspection JSVoidFunctionReturnValueUsed

import HUI from './hui';

export function disableWheelInput(e) {
    e.target.blur()
    e.stopPropagation()

    setTimeout(() => {
        e.target.focus()
    }, 0)
}

export function toggle_full_screen(e) {
    if(e.key !== "Escape") { return }

    if (e.target.classList.contains("full-screen")) {
        e.target.classList.remove("full-screen")
    } else {
        e.target.classList.add("full-screen")
    }
}

export default (() => {

    document.addEventListener('turbolinks:load', () => {
        HUI.on(document.body, 'wheel', 'input[type="number"]', disableWheelInput);
        HUI.on(document.body, 'keydown', 'textarea', toggle_full_screen);
    }, false);

})();