import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import PDFPreview from './PDFPreview';
import ImagePreview from './ImagePreview';
import DownloadPreview from './DownloadPreview';
import {PrintingService} from './DocumentPreview';
import AttachmentValidator from './AttachmentValidator';

const fileObject = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    contentType: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
})

const AttachmentPreview = ({files, selector}) => {
    const [activeFile, setActiveFile] = useState()

    const onClickFile = (file) => {
        setActiveFile(file)
    }

    // TODO: This can be remove once the preview components have been migrated to use the same format as this component
    const migrateFile = (file) => {
        return Object.assign({}, file, {downloadUrl: file.url})
    }

    useEffect(() => {
        if(files.size === 0) { return }

        setActiveFile(files[0])
    }, [])

    if(!activeFile) { return null }

    let preview

    if(activeFile.contentType === 'application/pdf') {
        preview = <PDFPreview file={migrateFile(activeFile)} print={(file) => PrintingService.print(file)} loadingFile={'Lade Datei'} loadingFailed={'Laden fehlgeschlagen'} />;
    } else if(['jpeg', 'png', 'bmp', 'gif', 'webp', 'svg+xml'].indexOf(activeFile.contentType.split('/')[1]) >= 0) {
        preview= <ImagePreview file={migrateFile(activeFile)} print={(file) => PrintingService.print(file)} loadingFailed={'Laden fehlgeschlagen'} />;
    } else {
        preview = <DownloadPreview file={migrateFile(activeFile)} message={''} />
    }

    return (
        <>
            { preview }
            {
                ReactDOM.createPortal(
                    <AttachmentValidator files={files} onClick={onClickFile} />,
                    document.querySelector(selector)
                )
            }
        </>
    )
}

AttachmentPreview.propTypes = {
    files: PropTypes.arrayOf(fileObject).isRequired,
    selector: PropTypes.string.isRequired
}

export default AttachmentPreview