import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import html2canvas from 'html2canvas';

export class Timer extends React.PureComponent {

    interval = 1000;
    timeout = null;

    constructor(props) {

        super(props);

        this.state = {
            showTime: true,
            remaining: props.duration
        }

    }

    componentDidUpdate(prevProps) {

        if(prevProps.duration !== this.props.duration) {
            this.setState({remaining: this.props.duration});
        }

    }

    step = () => {

        if(this.state.remaining === 0) {
            return;
        }

        const drift = Date.now() - this.expectedTime;

        this.setState({remaining: this.state.remaining - 1}, () => {

            if(this.state.remaining === 0 && this.props.onExpired) {
                this.props.onExpired();
            }

        });

        this.expectedTime += this.interval;

        this.timeout = setTimeout(this.step, Math.max(0, this.interval - drift));

    }

    componentDidMount() {

        this.expectedTime = Date.now() + this.interval;
        this.timeout = setTimeout(this.step, this.interval);

    }

    componentWillUnmount() {

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

    }

    render() {

        const hours = Math.floor(this.state.remaining / 3600);
        const minutes = Math.floor(this.state.remaining / 60) - hours * 60;
        const seconds = this.state.remaining - minutes * 60 - hours * 3600;

        return (
            <div className='timer'>
                { hours > 0 &&
                    <React.Fragment>
                        <span className='minutes'>{hours.toString().padStart(2, '0')}</span>
                        <span className='separator'>:</span>
                    </React.Fragment>
                }
                <span className='minutes'>{minutes.toString().padStart(2, '0')}</span>
                <span className='separator'>:</span>
                <span className='minutes'>{seconds.toString().padStart(2, '0')}</span>
                <button type='button' className={`btn-${this.state.showTime ? 'close' : 'show'}-timer`}
                        title={this.state.showTime ? 'Zeit verbergen' : 'Zeit anzeigen'} onClick={() => this.setState({showTime: !this.state.showTime})}>
                    <i className={this.state.showTime ? 'fa-light fa-times' : 'fa-light fa-eye'}/>
                </button>
            </div>
        );

    }

}

Timer.propTypes = {
    duration: PropTypes.number.isRequired,
    onExpired: PropTypes.func
}

class Choice extends React.PureComponent {

    timeout = null;

    state = {
        saved: null
    }

    componentWillUnmount() {
        this.unsetTimeout();
    }

    unsetTimeout = () => {

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

    }

    onChange = async (e) => {

        const result = await this.props.onChange(this.props.id, e);

        this.setState({saved: result});

        this.unsetTimeout();

        this.timeout = setTimeout(() => {
            this.setState({saved: null});
        }, 1000);

    }

    getMessage = () => {

        if(this.state.saved) {
            return <span className='badge badge-success ml-2'>Auswahl wurde gespeichert</span>;
        } else if(this.state.saved === false) {
            return <span className='badge badge-danger ml-2'>Auswahl konnte nicht gespeichert werden</span>
        } else {
            return null;
        }

    }

    render() {

        return (
            <li key={this.props.id}>
                <div className={this.props.kind}>
                    <input id={`choice-${this.props.questionId}-${this.props.id}`} type={this.props.kind} name={`question-${this.props.questionId}`} checked={this.props.checked} onChange={this.onChange} />
                    <label htmlFor={`choice-${this.props.questionId}-${this.props.id}`} style={{display: 'inline-block'}}>{this.props.name}{this.getMessage()}</label>
                </div>
            </li>
        )

    }

}

Choice.propTypes = {
    questionId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

class Question extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            choices: this.props.choices
        }

    }

    onChange = async (choice_id) => {

        if(typeof this.cancelTokenSource !== 'undefined') {
            this.cancelTokenSource.cancel('Cancelled superseded request');
        }

        this.cancelTokenSource = axios.CancelToken.source();

        const url = `/students/block_achievement/${this.props.blockAchievement.id}/answer`

        const configuration =  {
            cancelToken: this.cancelTokenSource.token,
            headers : {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        };

        try {

            const result = await axios.post(url, { choice_id: choice_id, choices: this.state.choices.map(c => c.id) }, configuration);

            this.cancelTokenSource = undefined;

            if(result.status === 200) {
                this.setState({...result.data});
                return true;
            }

        } catch(e) {

            if(axios.isCancel(e)) {
                console.warn(e.message);
            } else {
                console.error('Failed to submit answer');
            }

        }

        return false;

    }

    render() {

        return (

            <div className='card'>
                <div className='card-heading border bottom'>
                    <h4 className='dropdown card-title'>
                        { this.props.title }
                        { (this.props.help || []).length > 0 &&
                            <React.Fragment>
                                <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='help-tag float-right'>
                                    <i className='fa-light fa-question-circle'/>
                                </a>
                                <div className='dropdown-menu dropdown-menu-right dropdown-menu-help'>
                                    <div className='card-body'>
                                        { this.props.help }
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </h4>
                    { this.props.kind === 'checkbox' &&
                        <div className='card-sub-title'>(Mehrfachauswahl möglich)</div>
                    }
                </div>
                <div className='card-body'>
                    <ul className='choices'>
                        { this.state.choices.map(choice => <Choice key={choice.id} questionId={this.props.id} kind={this.props.kind} onChange={this.onChange} {...choice} />) }
                    </ul>
                </div>
            </div>

        )

    }

}

Question.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    help: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.object).isRequired,
    kind: PropTypes.string.isRequired,
    blockAchievement: PropTypes.object.isRequired
}

export class Countdown extends React.PureComponent {

    timeout = null;

    componentDidMount() {
        this.timeout = setTimeout(this.fetchData, this.props.duration);
    }

    componentWillUnmount() {

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

    }

    render() {
        return (
            <div className='multiple-choice'>
                <div className='alert alert-warning'>Die Multiple-Choice-Prüfung hat noch nicht begonnen.</div>
                <div className='countdown'>
                    <Timer duration={this.props.duration} onExpired={this.props.onExpired} />
                </div>
            </div>
        );
    }

}

Countdown.propTypes = {
    duration: PropTypes.number.isRequired,
    onExpired: PropTypes.func.isRequired
}

class Examination extends React.PureComponent {

    container = React.createRef();

    state = {
        countdown: 0,
        showTimer: true,
        questions: [],
        isSaving: false,
        blockAchievement: {
            id: null,
            isProofPermitted: false
        }
    }

    async componentDidMount() {
        await this.fetchData();
    }

    fetchData = async () => {

        const result = await axios.get(location.href, {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({...result.data});
        }

    }

    completeExam = () => {

        this.setState({isSaving: true}, async () => {

            await axios.put(`/students/block_achievement/${this.state.blockAchievement.id}/`, { handed_in: true }, {
                headers: {
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
            });

            if(this.state.blockAchievement.isProofPermitted) {
                await this.saveImage();
            }

            location.href = `/students/programs/${ this.props.programId }/block_achievements/${this.state.blockAchievement.id}`;

        });

    }

    toBlob = async (canvas) => {

        return new Promise(resolve => {

            canvas.toBlob(async (blob) => {

                await axios.put(`/students/block_achievement/${this.state.blockAchievement.id}`, blob, {
                    headers: {
                        'Content-Type': blob.type,
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    }
                });

                resolve();

            }, 'image/jpg', 0.1);

        });

    }

    saveImage = async () => {

        const canvas = await html2canvas(this.container.current, {scrollX: 0, scrollY: -window.scrollY});

        await this.toBlob(canvas);

    }

    toggleProofPermission = async () => {

        const allowProof = !this.state.blockAchievement.isProofPermitted;

        const result = await axios.put(`/students/block_achievement/${this.state.blockAchievement.id}`, { is_proof_permitted: allowProof }, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if(result.status === 200) {
            this.setState({ blockAchievement: Object.assign({}, this.state.blockAchievement, { isProofPermitted: allowProof })});
        }

    }

    render() {

        if(this.state.countdown < 0) {
            return <Countdown duration={this.state.countdown * -1} onExpired={this.fetchData} />;
        }

        return (

            <div className='row multiple-choice'>
                {this.state.isSaving &&
                    <div className='is-saving'>
                        <i className='fa-light fa-sync'/>
                    </div>
                }
                <div className='col-12 col-md order-2 order-md-1'>
                    <div ref={this.container} className='questions'>
                        { this.state.questions.map(question => <Question key={question.id} blockAchievement={this.state.blockAchievement} {...question} />) }
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='checkbox'>
                                <input id='permit-proof' type='checkbox' name='permit-proof' checked={this.state.blockAchievement.isProofPermitted} onChange={this.toggleProofPermission} />
                                <label htmlFor='permit-proof'>Anfertigung des Prüfungsnachweises erlauben</label>
                                <p className='small'>
                                    Ihre Antworten werden, zusätzlich zu den auf dem Server gespeicherten Angaben, in Form eines Bildes abgespeichert.
                                    Im Falle eines Widerspruchs, können wir so sicherstellen, dass Ihre Antworten korrekt an den Server übertragen worden sind.
                                    Ohne diesen Nachweis, gelten alleine die auf dem Server gespeicherten Angaben.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            { this.state.questions.length > 0 &&
                                <button className='btn btn-success' onClick={this.completeExam}>
                                    {!this.state.isSaving ? 'Blockprüfung abschließen' : 'Wird gespeichert...'}
                                </button>
                            }
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-default'>Zurück</button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-auto order-1 order-md-2 mb-4 mb-md-0'>
                    <div className='sidebar'>
                        <h2 className='title'>
                            Verbleibende Zeit
                        </h2>
                        <Timer onClick={() => this.setState({showTimer: false})} onExpired={this.completeExam} duration={this.state.countdown} />
                    </div>
                </div>
            </div>

        );

    }

}

Examination.propTypes = {
    programId: PropTypes.number.isRequired,
}

export default Examination;