import React, {useEffect, useState} from 'react';


const ArticleSearchComponent = ({labels, ...props}) => {

    const [query, setQuery] = useState('');
    const [articles, setArticles] = useState([]);

    const handleSearch = async (query) => {
        if (query.trim()) {
            const response = await fetch(`/knowledge_base/search?query=${query}`);
            const result = await response.json();
            setArticles(result);
        } else {
            setArticles([]);
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleSearch(query)
        }, 500)

        return () => clearTimeout(timeoutId)

    }, [query]);


    return (
        <div>
            <input
                id="search-focus"
                type="search"
                className="form-control mb-3"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                placeholder="Suche.."
            />
            <div>
                {articles.map((article) => (
                    <a key={article.id} href={`/${labels.path}/knowledge_base/knowledge_base_articles/${article.id}`}>
                        <div className="accordion panel-group case-studies" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default p-3">
                                <div className="panel-heading">
                                    <h4 className="panel-title">
                                        <span><i className="fas fa-newspaper mr-2"></i> {article.title}</span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};


export default ArticleSearchComponent;
