import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import SelectBox from './SelectBox';

class CourseSelection extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            courseVersions: [],
            isCustom: false,
        };

    }

    componentDidMount() {

        axios.get(
            '/administration/course_versions', {
                headers: {'Accept': 'application/json'}
            })
            .then((result) => {

                if(result.status !== 200) {
                    return;
                }

                this.setState({ courseVersions: result.data });

            });

    }

    isCustomChanged = (e) => {

        const checked = e.target.checked;
        this.setState({ isCustom: checked });

    };

    render() {

        return (
            <div className='form-group'>
                <div className='row'>
                    <div className='col'>
                        <label className='required'>Kurs</label>
                    </div>
                    {this.props.showCustom &&
                        <div className='col-auto'>
                            <div className='checkbox p-0'>
                                <input type='checkbox' name={`${this.props.name}[is_custom]`} id='course_selection_is_custom' value='1'
                                       onChange={this.isCustomChanged} checked={this.state.isCustom}/>
                                <label htmlFor='course_selection_is_custom'>Ohne Vorlage</label>
                            </div>

                        </div>
                    }
                </div>
                {this.state.isCustom &&
                    <input type='text' name={`${this.props.name}[name]`} className='form-control' required={true} placeholder='Bitte geben Sie einen Namen für die Bildungsmaßnahme an'/>
                }
                {!this.state.isCustom &&
                    <SelectBox name={`${this.props.name}[course_version_id]`} items={ this.state.courseVersions.map(item => {return {value: item.id, title:  item.name}}) } value={ this.props.courseVersionId } required={ true } />
                }
            </div>
        )
    }
}

CourseSelection.propTypes = {
    name: PropTypes.string.isRequired,
    courseVersionId: PropTypes.number,
    showCustom: PropTypes.bool.isRequired
};

export default CourseSelection;