import React from 'react';
import PropType from 'prop-types';
import axios from 'axios';
import {List} from 'immutable';
import DateTimeInput from "./DateTimeInput";

class MultipleChoiceExamination extends React.PureComponent {

    state = {
        questions: List(),
        showTimer: true,
    }

    async componentDidMount() {

        // const result = await axios.get(`/students/block_examinations/${this.props.blockExaminationIdentifier}/questions`, { headers: {'Accept': 'application/json'}});
        //
        // if(result.status === 200) {
        //     this.setState({questions: List(result.data)});
        // }

    }

    toggleTimerVisibility = () => {
        this.setState({showTimer: !this.state.showTimer});
    };

    render() {

        return (
            <div className='row multiple-choice'>
                <div className='col-12 col-md order-2 order-md-1'>

                    {/* Question 1 */}
                    <div className='card'>
                        <div className='card-heading border bottom'>
                            <h4 className='card-title'>
                                Wofür steht die Abkürzung KGaA?
                                <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='help-tag float-right'>
                                    <i className='fa-light fa-question-circle'/>
                                </a>
                            </h4>
                            <div className='dropdown-menu dropdown-menu-right dropdown-menu-help'>
                                <div className='card-body'>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <ul className='choices'>
                                <li>
                                    <div className='radio'>
                                        <input id='choice-1-1' type='radio' name='question[1]'/>
                                        <label htmlFor='choice-1-1'>Kreditgesellschaft auf Aktien <span className='badge badge-success ml-2'>Auswahl wurde gespeichert</span></label>
                                    </div>
                                </li>
                                <li>
                                    <div className='radio'>
                                        <input id='choice-1-2' type='radio' name='question[1]'/>
                                        <label htmlFor='choice-1-2'>Kommanditgesellschaft auf Aktien <span className='badge badge-danger ml-2'>Auswahl konnte nicht gespeichert werden</span></label>
                                    </div>
                                </li>
                                <li>
                                    <div className='radio'>
                                        <input id='choice-1-3' type='radio' name='question[1]'/>
                                        <label htmlFor='choice-1-3'>Kardinalgesellschaft auf Aktien</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='radio'>
                                        <input id='choice-1-4' type='radio' name='question[1]'/>
                                        <label htmlFor='choice-1-4'>Kompetenzgesellschaft auf Aktien</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Question 2 */}
                    <div className='card'>
                        <div className='card-heading border bottom'>
                            <h4 className='card-title'>
                                Welches dieser Tiere hält keinen Winterschlaf?
                                <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='help-tag float-right'>
                                    <i className='fa-light fa-question-circle'/>
                                </a>
                            </h4>
                            <div className='dropdown-menu dropdown-menu-right dropdown-menu-help'>
                                <div className='card-body'>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <ul className='choices'>
                                <li>
                                    <div className='checkbox '>
                                        <input id='choice-2-1' type='checkbox' name='question[2]'/>
                                        <label htmlFor='choice-2-1'>Eichhörnchen</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='checkbox '>
                                        <input id='choice-2-2' type='checkbox' name='question[2]'/>
                                        <label htmlFor='choice-2-2'>Fledermaus</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='checkbox '>
                                        <input id='choice-2-3' type='checkbox' name='question[2]'/>
                                        <label htmlFor='choice-2-3'>Igel</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='checkbox '>
                                        <input id='choice-2-4' type='checkbox' name='question[2]'/>
                                        <label htmlFor='choice-2-4'>Siebenschläfer</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='checkbox '>
                                        <input id='choice-2-5' type='checkbox' name='question[2]'/>
                                        <label htmlFor='choice-2-5'>Hund</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Question 3 */}
                    <div className='card'>
                        <div className='card-heading border bottom'>
                            <h4 className='card-title'>
                                Was ist ein Oxymoron?
                                <a href='#' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className='help-tag float-right'>
                                    <i className='fa-light fa-question-circle'/>
                                </a>
                            </h4>
                            <div className='dropdown-menu dropdown-menu-right dropdown-menu-help'>
                                <div className='card-body'>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <ul className='choices'>
                                <li>
                                    <div className='radio '>
                                        <input id='choice-3-1' type='radio' name='question[2]'/>
                                        <label htmlFor='choice-3-1'>Ein innerer Widerspruch</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='radio '>
                                        <input id='choice-3-2' type='radio' name='question[2]'/>
                                        <label htmlFor='choice-3-2'>Ein Versfuß</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='radio '>
                                        <input id='choice-3-3' type='radio' name='question[2]'/>
                                        <label htmlFor='choice-3-3'>Eine Wiederholung</label>
                                    </div>
                                </li>
                                <li>
                                    <div className='radio '>
                                        <input id='choice-3-4' type='radio' name='question[2]'/>
                                        <label htmlFor='choice-3-4'>Eine Frageform</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* Controls */}
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-success'>Blockprüfung abschließen</button>
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-default'>Zurück</button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-auto order-1 order-md-2 mb-4 mb-md-0'>
                    <div className='sidebar'>
                        <h2 className='title'>
                            {!this.state.showTimer &&
                                <button type='button' onClick={this.toggleTimerVisibility} className='btn-show-timer'>
                                    <i className='fa-light fa-eye'/>
                                </button>
                            }
                            Verbleibende Zeit
                        </h2>
                        {this.state.showTimer &&
                            <div className='timer'>
                                <span className='minutes'>75</span>
                                <span className='separator'>:</span>
                                <span className='minutes'>00</span>
                                <button type='button' className='btn-close-timer' title='Timer verbergen' onClick={this.toggleTimerVisibility}>
                                    <i className='fa-light fa-times'/>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

        )

    }

}

MultipleChoiceExamination.propTypes = {
    blockExaminationIdentifier: PropType.string.isRequired
}

export default MultipleChoiceExamination;