import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Stage, Layer, Image as KonvaImage} from 'react-konva';
import axios from 'axios';

export default class AvatarEditor extends React.PureComponent {

    state = {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        scale: 1
    }

    image = null;
    minScale = 0;

    containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '999999'
    }

    componentDidMount() {

        const triggerElement = document.querySelector(this.props.trigger);

        if(triggerElement) {
            triggerElement.addEventListener('click', () => this.imageInput.click())
        }

    }

    onFileChanged = (e) => {

        const image = new Image();

        image.onload = this.onImageLoad
        image.src = URL.createObjectURL(e.target.files[0]);

    }

    onImageLoad = (e) => {

        const image = e.target;

        if(image.width < this.props.width || image.height < this.props.height) {

            alert(this.props.translations.alert);

            this.setState({imageLoaded: false});

            return false;

        }

        this.image = image;

        const ratio = Math.max(this.props.width / image.width, this.props.height / image.height);

        this.minScale = Math.ceil(ratio * 100);

        const minScaleFactor = this.minScale * 0.01

        this.setState({
            width: image.width,
            height: image.height,
            x: (this.props.width - image.width * minScaleFactor) * 0.5,
            y: (this.props.height - image.height * minScaleFactor) * 0.5,
            scale: this.minScale
        });

    }

    dragBounds = pos => {

        const scale = this.state.scale * 0.01

        const x = Math.max(this.props.width - (this.state.width * scale), Math.min(pos.x, 0))
        const y = Math.max(this.props.height - (this.state.height * scale), Math.min(pos.y, 0))

        return { x, y }

    }

    scaleImage = value => {

        const oldScale = this.state.scale * 0.01
        const newScale = value * 0.01;

        const deltaWidth = this.props.width - this.state.width * oldScale;
        const deltaHeight = this.props.height - this.state.height * oldScale;

        const scaleX = deltaWidth === 0 ? 0 : this.state.x / deltaWidth
        const scaleY = deltaHeight === 0 ? 0 : this.state.y / deltaHeight

        const x = Math.ceil((this.props.width - this.state.width * newScale) * Math.min(scaleX, 1))
        const y = Math.ceil((this.props.height - this.state.height * newScale) * Math.min(scaleY, 1))

        this.setState({
            scale: value, x, y,
        });

    }

    onChangeScale = (e) => {
        this.scaleImage(parseInt(e.target.value, 10))
    }

    onClickScale = (value) => {
        this.scaleImage(Math.min(Math.max(this.minScale, this.state.scale + value), 100));
    }

    onDragMove = e => {

        this.setState({
            x: e.target.x(),
            y: e.target.y()
        });

    }

    onSave = async () => {

        const url = this.stage.toDataURL({mimeType: 'image/jpeg', quality: 1});

        const response = await fetch(url);
        const blob = await response.blob();

        const formData = new FormData();
        formData.append('avatar', blob, 'avatar.jpg');

        await axios.post(this.props.url, formData, {
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        location.reload();

    }

    onClose = () => {

        this.image = null;
        this.minScale = 0;

        this.setState({
            width: 0,
            height: 0,
            x: 0,
            y: 0,
            scale: 1
        });

    }

    render() {

        if(!this.image) {
            return <input type='file' ref={el => this.imageInput = el} style={{display: 'none'}} accept='image/png, image/jpeg' onChange={this.onFileChanged} />;
        }

        // <img src='mask.svg' style={{pointerEvents: 'none', position: 'absolute', left: 0, top: 0}} />

        const tree = (
            <div className='avatar-layer' style={this.containerStyle}>
                <div className='avatar-modal' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <div className='avatar-header'>{this.props.translations.title}</div>
                    <div className='avatar-body'>
                        <div className='avatar-stage-container' style={{position: 'relative'}}>
                            <Stage ref={el => this.stage = el} className='avatar-stage' width={this.props.width} height={this.props.height}>
                                <Layer>
                                    <KonvaImage
                                        x={this.state.x}
                                        y={this.state.y}
                                        image={this.image}
                                        scaleX={this.state.scale * 0.01}
                                        scaleY={this.state.scale * 0.01}
                                        draggable
                                        dragBoundFunc={this.dragBounds}
                                        onDragMove={this.onDragMove}
                                    />
                                </Layer>
                            </Stage>
                            <svg version='1.1' width='100%' height='100%' viewBox={`0 0 ${this.props.width} ${this.props.height}`} style={{pointerEvents: 'none', position: 'absolute', left: 0, top: 0, fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2}}>
                                <path d='M150,300L0,300L0,0L300,0L300,300L150,300C232.787,300 300,232.787 300,150C300,67.213 232.787,0 150,0C67.213,0 0,67.213 0,150C0,232.787 67.213,300 150,300Z' style={{fillOpacity: this.props.maskFillOpacity}} />
                            </svg>
                        </div>
                    </div>
                    <div className='avatar-zoom'>
                        <span className='zoom-minus' onClick={() => this.onClickScale(-10)} title={this.props.translations.zoomOut} />
                        <input type='range' min={Math.ceil(this.minScale)} max={100} value={this.state.scale} step={1} onChange={this.onChangeScale} />
                        <span className='zoom-plus' onClick={() => this.onClickScale(10)} title={this.props.translations.zoomIn}/>
                    </div>
                    <div className='avatar-controls'>
                        <button type='button' className={`${this.props.btnSaveClass} save-button`.trim()} onClick={this.onSave}>
                            {this.props.translations.save}
                        </button>
                        <button type='button' className={`${this.props.btnCloseClass} close-button`.trim()} onClick={this.onClose}>
                            {this.props.translations.close}
                        </button>
                    </div>
                </div>
            </div>
        )

        return ReactDOM.createPortal(
            tree,
            document.querySelector('body')
        );

    }

}

AvatarEditor.propTypes = {
    trigger: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    maskFillOpacity: PropTypes.number.isRequired,
    translations: PropTypes.shape({
        title: PropTypes.string.isRequired,
        save: PropTypes.string.isRequired,
        close: PropTypes.string.isRequired,
        alert: PropTypes.string.isRequired,
        zoomIn: PropTypes.string,
        zoomOut: PropTypes.string
    }).isRequired,
    btnSaveClass: PropTypes.string,
    btnCloseClass: PropTypes.string
}

AvatarEditor.defaultProps = {
    maskFillOpacity: 0.6
}
