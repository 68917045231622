import React from 'react';
import PropTypes from 'prop-types';
import InventoryCategorySelection from './InventoryCategorySelection';

class HardwareApplicationValidation extends React.PureComponent {

    state = {
        acceptable: false
    }

    render() {
        return (
            <div className='hardware-application-validation'>
                <div className='card'>
                    <div className='card-body'>
                        <InventoryCategorySelection onSelectionChange={s => this.setState({acceptable: !s.isEmpty()})} isShipment={true} />
                        <input type='hidden' name='rental[hardware_application_id]' value={this.props.hardwareApplicationId}/>
                    </div>
                </div>
            </div>
        );
    }
}

HardwareApplicationValidation.propTypes = {
    programId: PropTypes.number.isRequired,
    hardwareApplicationId: PropTypes.number.isRequired
}

export default HardwareApplicationValidation;