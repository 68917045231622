import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {DateTimePopUp} from './DateTimePicker';

const DateTimeInput = ({date: initialDate, required, locale, displayTime, saveLabel, resetLabel, name, className, onChange, minDate, showReset, autosave}) => {
    const container = useRef(null)
    const [date, setDate] = useState(null)
    const [showPicker, setShowPicker] = useState(false)

    let format = {day: '2-digit', month: '2-digit', year: 'numeric'}

    if(displayTime) {
        format = Object.assign(format, {hour: '2-digit', minute: '2-digit'})
    }

    const dateFormatter = new Intl.DateTimeFormat(document.documentElement.lang, format)

    const onClickedDocument = (e) => {
        if(container.current == null || container.current.contains(e.target)) {
            return;
        }

        setShowPicker(false)
    }

    const onReset = () => {
        setDate(null)
        setShowPicker(false)

        if(onChange) {
            onChange(null)
        }
    }

    const onSave = (newDate) => {
        setDate(newDate)
        setShowPicker(false)
    }

    const onChangeDate = (newDate) => {
        setDate(newDate)

        if(onChange) {
            onChange(newDate)
        }
    }

    useEffect(() => {
        document.addEventListener('click', onClickedDocument, true)

        return () => {
            document.removeEventListener('click', onClickedDocument, true)
        }
    }, [])

    useEffect(() => {
        if(initialDate === null || initialDate === '') {
            return
        }

        if(!(initialDate instanceof Date)) {
            setDate(new Date(initialDate))
        } else {
            setDate(initialDate)
        }
    }, [initialDate])

    const getValue = () => {
        if(date === null) {
            return ''
        }

        return dateFormatter.format(date)
    }

    if(minDate) {
        minDate = new Date(minDate)
        minDate.setHours(0, 0, 0, 0)
    }

    return (
        <div ref={container} className='input-date-time-picker'>
            <DateTimePopUp visible={showPicker}
                           date={date || new Date()}
                           locale={locale}
                           displayTime={displayTime}
                           onReset={onReset}
                           onSave={onSave}
                           autosave={autosave}
                           resetLabel={resetLabel}
                           saveLabel={saveLabel}
                           onChange={onChangeDate}
                           placement='bottom-start'
                           minDate={minDate}
                           showReset={showReset}
            >
                {ref => (
                    <React.Fragment>
                        <input ref={ref} type='text' name={name} defaultValue={getValue()} required={required} className={className} onClick={() => setShowPicker(true)} />
                        { date &&
                            <input type='hidden' name={name} value={date.toISOString()} required={required}/>
                        }
                    </React.Fragment>
                )}
            </DateTimePopUp>
        </div>
    )
}

DateTimeInput.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
    ]),
    required: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    displayTime: PropTypes.bool.isRequired,
    saveLabel: PropTypes.string.isRequired,
    autosave: PropTypes.bool.isRequired,
    resetLabel: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    minDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string
    ]),
    showReset: PropTypes.bool
}

DateTimeInput.defaultProps = {
    date: null,
    required: false,
    locale: document.documentElement.lang,
    saveLabel: 'Übernehmen',
    displayTime: true,
    autosave: true
}

export default DateTimeInput;