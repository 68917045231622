import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {List} from 'immutable';
import SelectBox from './SelectBox';
import DateTimeInput from './DateTimeInput';

class InventoryCategorySelection extends React.PureComponent {

    constructor(props) {

        super(props);

        let dueAt = this.props.dueAt;

        if(this.props.dueAt == null) {
            dueAt = new Date();
        } else {
            dueAt = new Date(dueAt);
        }

        this.state = {
            categories: List(),
            selectedCategories: List(),
            isShipment: this.props.isShipment,
            dueAt: dueAt
        };

        this.selectTemplate = React.createRef();

        this.loadInventory();

    }

    componentDidUpdate(_prevProps, prevState) {

        if(this.props.onSelectionChange && this.state.selectedCategories !== prevState.selectedCategories) {
            this.props.onSelectionChange(this.state.selectedCategories);
        }

    }

    loadInventory = () => {

        axios.get(
            '/administration/inventories/categories', {
                headers: {'Accept': 'application/json'}
            }).then((result) => {

            if(result.status !== 200) {
                return;
            }

            this.setState({
                categories: List(result.data)
            });

        });

    };

    add = (item) => {

        this.setState( {
            selectedCategories: this.state.selectedCategories.push({ id: item.value, name: item.title }),
        }, () => {

            if(this.selectTemplate.current) {
                this.selectTemplate.current.reset();
            }

        });

    };

    remove = (idx) => {
        this.setState( {
            selectedCategories: this.state.selectedCategories.delete(idx),
        });
    };

    render() {

        if (this.state.categories.size === 0) {
            return (<div className='alert alert-warning'>Keine Kategorie vorhanden</div>);
        }

        return (
            <React.Fragment>
                <div className='form-group'>
                    <label className='required'>Kategorieauswahl</label>
                    <SelectBox ref={this.selectTemplate} items={this.state.categories.map(item => {return {value: item.id, title: item.name}}).toArray()} onChange={this.add}/>
                </div>

                {this.state.selectedCategories.size > 0 &&
                    <div className='form-group'>
                        <label className='required'>Ausgewählte Kategorien</label>
                        {this.state.selectedCategories.map((item, idx) => {
                            return (
                                <React.Fragment key={idx}>
                                    <div className='input-group mb-1'>
                                        <input type='text' className='form-control' value={item.name} disabled={true}/>
                                        <div className='input-group-append input-group-append-button'>
                                            <button type='button' className='btn btn-danger' onClick={() => this.remove(idx) }>
                                                <i className='fa-light fa-trash'/>
                                            </button>
                                        </div>
                                    </div>
                                    <input type='hidden' name='rental[category_ids][]' value={item.id}/>
                                </React.Fragment>
                            )
                        })}
                    </div>
                }

                <div className='checkbox mb-3'>
                    <input name='rental[is_shipment]' type='hidden' value='0'/>
                    <input type='checkbox' value='1' checked={this.state.isShipment} onChange={() => this.setState({isShipment: !this.state.isShipment})} name='rental[is_shipment]' id='rental_is_shipment'/>
                    <label htmlFor='rental_is_shipment'>Wird versendet</label>
                </div>

                { this.state.isShipment &&
                    <div className='form-group'>
                        <label htmlFor='rental_due_at'>Versanddatum</label>
                        <DateTimeInput name='rental[due_at]' date={this.state.dueAt} className='form-control' displayTime={false}/>
                    </div>
                }
            </React.Fragment>
        )

    }

}

InventoryCategorySelection.propTypes = {
    categoryIds: PropTypes.array,
    isShipment: PropTypes.bool.isRequired,
    dueAt: PropTypes.string,
    onSelectionChange: PropTypes.func
};

export default InventoryCategorySelection;