import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SelectBox from './SelectBox';
import { List } from 'immutable';

const KindContext = React.createContext('radio');

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class Answer extends React.PureComponent {

    render() {

        return(

            <div className='row'>

                <div className='col'>
                    <div className='input-group'>

                        <div className='input-group-prepend' title='Antwort sortieren'>
                            <div className='input-group-text'>
                                <i className='fa-light fa-sort-alt' style={{visibility: this.props.removable ? 'initial' : 'hidden'}}/>
                            </div>
                        </div>

                        <input type='text' className='form-control' name={this.props.value ? `${this.props.name}[name]` : null} value={this.props.value} disabled={this.props.disabled} onChange={(e) => this.props.onChange({value: e.target.value})} />

                        <div className='input-group-append' title='Richtige Antwort'>
                            <KindContext.Consumer>
                                {
                                    kind => (
                                        <div className={`input-group-text ${kind}`}>
                                            {!this.props.disabled &&
                                                <input type='hidden' name={this.props.value ? `${this.props.name}[is_correct]` : null} value='0'/>
                                            }
                                            <input type={kind} name={this.props.value ? `${this.props.name}[is_correct]` : null} readOnly={true} checked={this.props.checked} required={true} disabled={this.props.disabled} onChange={(e) => this.props.onChange({checked: e.target.checked, bip: true})} />
                                            <label onClick={(e) => this.props.onChange({checked: !e.target.parentElement.querySelector(`input[type="${kind}"]`).checked})} className={this.props.disabled ? 'disabled' : ''}/>
                                        </div>
                                    )
                                }
                            </KindContext.Consumer>
                        </div>

                    </div>
                </div>

                <div className='col-auto'>
                    <button type='button' className='btn btn-danger' style={{visibility: this.props.removable ? 'initial' : 'hidden'}} onClick={this.props.onRemove}>
                        <i className='fa-light fa-trash'/>
                    </button>
                </div>

            </div>

        )

    }

}

Answer.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    removable: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
};

Answer.defaultProps = {
    value: '',
    deletable: true,
    checked: false,
    disabled: false
};

class QuestionChoices extends React.PureComponent {

    constructor(props) {

        super(props);

        this.choices = React.createRef();
        let answers = this.props.answers || [];

        if(!this.props.disabled) {
            answers.push({value: '', checked: false});
        }

        this.state = {
            kind: this.props.kind || 'radio',
            answers: List(answers),
        }

    }

    onKindChange = (item) => {

        if(item.value === this.state.kind) {
            return;
        }

        this.setState({kind: item.value, answers: this.state.answers.map(answer => Object.assign(answer, {checked: false}))});

    }

    addAnswer = () => {

        this.setState({answers: this.state.answers.push({value: '', checked: false})}, () => {
            const inputs = this.choices.current.querySelectorAll('input[type="text"]');
            inputs[inputs.length - 1].focus();
        });

    }

    removeAnswer = (idx) => {
        this.setState({ answers: this.state.answers.delete(idx) });
    }

    updateAnswer = (idx, data) => {

        if(this.state.kind === 'radio' && data['checked']) {
            this.setState({ answers: this.state.answers.map(answer => Object.assign(answer, {checked: false})) });
        }

        this.setState({ answers: this.state.answers.update(idx, (value) => Object.assign({}, value, data)) });

    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.answers,
            result.source.index,
            result.destination.index
        );

        this.setState({answers: List(items)});
    }

    render() {

        const kinds = [{value: 'radio', title: 'Einfachauswahl'}, {value: 'checkbox', title: 'Mehrfachauswahl'}]

        return (

            <React.Fragment>

                <div className='form-group'>
                    <label>Fragetyp</label>
                    <SelectBox name={`${this.props.scope}[kind]`} items={kinds} required={true} value={this.state.kind} disableInput={this.props.disabled} disabled={this.props.disabled} onChange={this.onKindChange} />
                </div>

                <div ref={this.choices} className='form-group'>
                    <label>Antwortmöglichkeiten</label>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} >
                                    <KindContext.Provider value={this.state.kind}>
                                        { this.state.answers.map((answer, idx, answers) =>
                                            <Draggable key={idx} draggableId={`item_${idx}`} index={idx} isDragDisabled={idx === answers.size - 1 || this.props.disabled}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Answer name={`${this.props.scope}[${this.props.nestedAttributeKey}][${idx}]`} removable={idx !== answers.size - 1 && !this.props.disabled} value={answer.value} checked={answer.checked} disabled={this.props.disabled} onChange={(data) => this.updateAnswer(idx, data)} onRemove={() => this.removeAnswer(idx)} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )}
                                    </KindContext.Provider>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                {!this.props.disabled &&
                    <button type='button' className='btn btn-secondary btn-sm mb-4' onClick={this.addAnswer} disabled={this.state.answers.last()['value'] === ''}>
                        <span className='fa-light fa-plus pr-2'/>
                        Antwortmöglichkeit
                    </button>
                }

            </React.Fragment>

        )
    }
}

QuestionChoices.propTypes = {
    questionId: PropTypes.number,
    scope: PropTypes.string.isRequired,
    nestedAttributeKey: PropTypes.string.isRequired,
    kind: PropTypes.string,
    answers: PropTypes.array,
    disabled: PropTypes.bool
};

QuestionChoices.defaultProps = {
    disabled: false
}

export default QuestionChoices;