import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import Lightbox from './Lightbox';
import PDFPreview from './PDFPreview';
import {PrintingService} from './DocumentPreview';
import ImagePreview from './ImagePreview';

class MessagePreview extends React.PureComponent {
    iFrame = createRef()
    url = null

    state = {
        attachments: []
    }
    constructor(props) {
        super(props)

        const signedId = this.props.file.downloadUrl.split('/')[4]

        this.url = `/administration/outlook_messages/${signedId}`
    }

    async componentDidMount() {
        this.iFrame.current.addEventListener('load', () => {
            this.iFrame.current.style.height = this.iFrame.current.contentWindow.document.body.scrollHeight + 'px'
        })

        const result = await fetch(`${this.url}/attachments`)

        if(result.status === 200) {
            this.setState({attachments: await result.json()})
        } else {
            alert('Anhang konnte nicht geladen werden.')
            this.props.onLoadFailed()
        }
    }

    render() {

        return (
            <div style={{backgroundColor: '#FFFFFF', padding: '10px', borderRadius: '5px', position: 'relative'}}>
                <iframe ref={this.iFrame} sandbox='allow-same-origin' src={this.url} scrolling={'no'} />
                {this.state.attachments.length > 0 &&
                    <React.Fragment>
                        <hr/>
                        <h4 className='ml-3'>Dateianhänge</h4>
                        <ul className='ml-3'>
                            { this.state.attachments.map((attachment, i) => (
                                <li key={i}>
                                    <a {...attachment}>
                                        {attachment.download}
                                    </a>
                                </li>
                            )) }
                        </ul>
                    </React.Fragment>
                }
                <a href={this.props.file.downloadUrl} className='btn btn-primary btn-rounded m-0' style={{position: 'absolute', bottom: '10px', right: '20px'}} title='Nachricht herunterladen'>
                    <i className='fa-light fa-xl fa-download' />
                </a>
            </div>
        )
    }

}

MessagePreview.propTypes = {
    file: PropTypes.object.isRequired,
    onLoadFailed: PropTypes.func
}

class DocumentLightbox extends React.PureComponent {

    state = {
        isVisible: false,
    }

    showPreview = () => (
        this.props.file.contentType === 'application/pdf' ||
        this.props.file.contentType === 'application/x-ole-storage' ||
        ['jpeg', 'png', 'bmp', 'gif', 'webp', 'tiff', 'svg+xml'].indexOf(this.props.file.contentType.split('/')[1]) >= 0
    )

    handleClick = (e) => {

        e.preventDefault();

        if(this.showPreview()) {

            this.setState({isVisible: true});

        } else {

            const link = document.createElement('a');

            link.href = this.props.file.downloadUrl;
            link.download = 'download'

            link.click();

        }

    };

    render() {

        return (
            <React.Fragment>
                <a href='#' onClick={this.handleClick} className={this.props.showIcon ? null : 'mr-2'}>
                    {this.props.showIcon ? (
                        <i className='fa-light fa-lg fa-file-pdf' title={this.props.label}/>
                    ) : (
                        this.props.label
                    )}
                    { this.props.previewUrl &&
                        <img className='img-fluid img-thumbnail' src={this.props.previewUrl} alt='Preview' />
                    }
                </a>

                { this.props.showFileSize &&
                    <span className='badge badge-info'>{this.props.file.size}</span>
                }

                { this.state.isVisible &&
                    <Lightbox classes='preview-component' onClose={() => this.setState({isVisible: false})} {...this.state}>
                        {this.props.file.contentType === 'application/x-ole-storage' &&
                            <MessagePreview file={this.props.file} onLoadFailed={() => this.setState({isVisible: false})} />
                        }
                        {this.props.file.contentType === 'application/pdf' &&
                            <PDFPreview {...this.props} print={(file) => PrintingService.print(file)}/>
                        }
                        {['jpeg', 'png', 'bmp', 'gif', 'webp', 'tiff', 'svg+xml'].indexOf(this.props.file.contentType.split('/')[1]) !== -1 &&
                            <ImagePreview {...this.props} print={(file) => PrintingService.print(file)}/>
                        }
                    </Lightbox>
                }
            </React.Fragment>
        )

    }

}

DocumentLightbox.propTypes = {
    file: PropTypes.object.isRequired,
    showIcon: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    showControls: PropTypes.bool.isRequired,
    invalidFormat: PropTypes.string,
    showFileSize: PropTypes.bool.isRequired,
    loadingFailed: PropTypes.string.isRequired,
    loadingFile: PropTypes.string.isRequired,
    previewUrl: PropTypes.string
}

DocumentLightbox.defaultProps = {
    showControls: true,
    showIcon: false,
    showFileSize: false,
    loadingFailed: '',
    loadingFile: '',
    label: ''
}

export default DocumentLightbox;