import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import axios from 'axios';
import qs from 'qs';
import SelectBox from './SelectBox';
import DocumentList from './DocumentList';

export class StandardTextSelection extends React.PureComponent {

    state = {
        value: '',
        texts: List()
    };

    async componentDidMount() {

        const result = await axios.get(`/administration/standard_texts?kind=${this.props.kind}`, {headers: {'Accept': 'application/json'}});

        if(result.status !== 200 || result.data.length === 0) {
            return;
        }

        this.setState({texts: List(result.data)});

    }

    handleChange = (e) => {

        this.setState({value: e.value});
        this.props.setStandardText(e.value);

    };

    render() {

        return (
            <SelectBox includeEmpty={true} required={true} items={this.state.texts.map(text => {return {value: text.id, title: text.title}}).toArray()} value={this.state.value} placeholder='Bitte wählen' onChange={this.handleChange} />
        )

    }

}

StandardTextSelection.propTypes = {
    setStandardText: PropTypes.func,
    kind: PropTypes.string
};

class DocumentValidation extends React.PureComponent {

    VALID = 'valid';
    INVALID = 'invalid';
    PENDING = 'pending';

    constructor(props) {

        super(props);

        const files = this.props.document.files.map((file) => { return {name: file.name, size: file.size, contentType: file.contentType, viewed: false}});

        this.state = {
            filesViewed: 0,
            result: this.PENDING,
            document: this.props.document,
            files: List(files),
            standardTextId: null,
            submitted: false
        };

    }

    componentDidMount() {

        const currentFile = this.getCurrentFile();

        const [id, idx] = currentFile;

        if(this.props.document.id === id || (id === 0 && this.props.index === 0)) {
            this.fileViewed(idx);
        }

    }

    getCurrentFile = () => {

        const parameters = qs.parse(location.search.slice(1));

        const id = (parameters.document) ? parseInt(parameters.document, 10) : 0;
        const index = (parameters.index) ? parseInt(parameters.index, 10) : 0;

        return [id, index];

    };

    fileViewed = (idx) => {

        const files = this.state.files.update(idx, file => {

            file.viewed = true;
            return file;

        });

        this.setState({files: files, filesViewed: this.state.filesViewed + 1});

        if (this.props.onFileViewed) {
            this.props.onFileViewed(idx);
        }

    };

    setStandardText = (id) => {
        this.setState({standardTextId: id});
    };

    setResult = (result) => {

        this.setState({result: result}, async () => {

            if(result === this.PENDING || result === this.INVALID) {
                return
            }

            await this.completeValidation()

        });

    };

    resetResult = () => {

        this.setState({result: this.PENDING, submitted: false}, async () => {
            await this.saveValidationResult()
        });

    }

    saveValidationResult = async () => {

        const configuration =  {
            headers : {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        };

        const data = {
            result: this.state.result
        };

        if(this.state.result === this.INVALID) {
            data['standard_text_id'] = this.state.standardTextId
        }

        const result = await axios.patch(`/administration/programs/${this.props.document.programId}/documents/${this.props.document.id}`, data, configuration);

        if(result.status === 200) {

            if(this.props.onValidationChange) {
                this.props.onValidationChange(this.props.document.id, this.state.result);
            }

            if(this.props.onValidationChange === undefined && result.data) {
                location.href = result.data.location;
            }

        }
    }

    completeValidation = async () => {

        this.setState({submitted: true});

        await this.saveValidationResult();

    };

    render() {

        const Controls = () => (
            <React.Fragment>
                {(this.props.document.state !== 'pending' || this.state.result !== this.PENDING) &&
                    <div className='card-controls'>
                        {(this.props.document.state === this.VALID || this.state.result === this.VALID) &&
                            <span className='tag tag-success'>
                                Gültig
                                {this.props.validation &&
                                    <button className='ml-1' type='button' onClick={this.resetResult}>
                                        <i className='fa-light fa-redo' title='Zurücksetzen' />
                                    </button>
                                }
                            </span>
                        }
                        {this.props.document.state === 'replaced' && <span className='tag tag-danger'>Ersetzt</span>}
                        {(this.props.document.state === this.INVALID || this.state.result === this.INVALID) &&
                            <span className='tag tag-danger'>
                                Ungültig
                                {this.props.validation &&
                                    <button className='ml-1' type='button' onClick={this.resetResult}>
                                        <i className='fa-light fa-redo' title='Zurücksetzen' />
                                    </button>
                                }
                            </span>
                        }
                    </div>
                }
                {this.props.validation && this.props.document.state === 'pending' && this.state.files.size > 0 && this.state.filesViewed === this.state.files.size && this.state.result === this.PENDING &&
                    <div className='card-controls'>
                        <i className='accept' title='Akzeptieren' onClick={() => this.setResult(this.VALID)}/>
                        <i className='reject' title='Ablehnen' onClick={() => this.setResult(this.INVALID)}/>
                    </div>
                }
            </React.Fragment>
        );

        return (
            <DocumentList index={this.props.index} title={this.props.document.humanKind} document={this.props.document} controls={<Controls />} onFileViewed={this.fileViewed}>
                <React.Fragment>
                    {!this.state.submitted && this.state.result === this.INVALID &&
                        <div className='overlay'>
                            <div className='reason'>
                                <StandardTextSelection setStandardText={(id) => this.setStandardText(id)} kind={this.props.document.kind} />
                            </div>
                            <div className='controls'>
                                <button className='btn btn-success' type='button' onClick={this.completeValidation}>
                                    Speichern
                                </button>
                                <button className='btn btn-danger' type='cancel' onClick={() => this.setResult(this.PENDING)}>
                                    Abbrechen
                                </button>
                            </div>
                        </div>
                    }
                </React.Fragment>
            </DocumentList>
        )
    }

}

DocumentValidation.propTypes = {
    index: PropTypes.number,
    document: PropTypes.object.isRequired,
    validation: PropTypes.bool.isRequired,
    onValidationChange: PropTypes.func,
    onFileViewed: PropTypes.func
};

export default DocumentValidation;