import React from 'react';
import PropTypes from 'prop-types';
import DateTimeInput from './DateTimeInput';
import axios from 'axios';
import qs from 'qs';

const toSnakeCase = (value) => value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

class Header extends React.PureComponent {
    render() {

        let columns;

        if(Array.isArray(this.props.columns)) {
            columns = this.props.columns.map((column, idx) => <th key={idx} className='column-heading'>{column}</th>);
        } else {
            columns = Object.keys(this.props.columns).map((column, idx) => {
                return (
                    <React.Fragment key={idx}>
                        <th colSpan={this.props.columns[column].length} className='column-heading'>{column}</th>
                        {Object.keys(this.props.columns).length !== idx + 1 &&
                            <th className='gap'/>
                        }
                    </React.Fragment>
                )
            })
        }

        return (
            <thead>
                <tr>
                    <th />
                    { columns }
                </tr>
                { !Array.isArray(this.props.columns) &&
                    <tr>
                        <th />
                        {
                            Object.values(this.props.columns).map((array, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        {
                                            array.map((column, idx) => <th key={idx}>{column}</th>)
                                        }
                                        { Object.keys(this.props.columns).length !== i + 1 &&
                                            <th className='gap'/>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </tr>
                }
            </thead>
        )
    }

}

Header.propTypes = {
    columns: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.string),
                PropTypes.object
        ]
    )
}

export class Cell extends React.PureComponent {

    render() {

        return (
            <td className='cell'>
                {this.props.href &&
                    <a href={this.props.href}>{this.props.value || 0}</a>
                }
                {!this.props.href &&
                    <span>{this.props.value || 0}</span>
                }
            </td>
        );

    }

}

Cell.propTypes = {
    value: PropTypes.number,
    href: PropTypes.string
}

export class Matrix extends React.PureComponent {

    render() {

        if(!this.props.data) {
            return null;
        }

        let columns;
        const isArray = Array.isArray(this.props.columns);

        if(isArray) {
            columns = this.props.columns.length;
        } else {
            columns = this.props.columns[Object.keys(this.props.columns)[0]].length * Object.keys(this.props.columns).length;
        }

        const sums = new Array(columns).fill(0);

        return (
            <table className='table table-hover fixed-header matrix'>
                <Header columns={this.props.columns} />
                <tbody>
                {
                    this.props.rows.map((row, idx) => {
                        return (
                            <tr key={idx}>
                                <td className='row-heading'>{row}</td>
                                {

                                    this.props.data[idx].map((item, idx1) => {

                                        if(Array.isArray(item)) {

                                            return (
                                                <React.Fragment key={idx1}>
                                                    {
                                                        item.map((subItem, idx2) => {

                                                        sums[idx1 * item.length + idx2] += subItem;

                                                        return this.props.getCell(idx2, row, null, subItem);

                                                        })
                                                    }

                                                    {this.props.data[idx].length !== idx1 + 1 &&
                                                        <td className='gap'/>
                                                    }
                                                </React.Fragment>
                                            )

                                        } else {

                                            sums[idx1] += item;

                                            return this.props.getCell(idx1, row, this.props.columns[idx1], item);

                                        }

                                    })

                                }
                            </tr>
                        )
                    })
                }
                </tbody>
                <tfoot>
                    <tr className='sum'>
                        <td className='row-heading'>Summe</td>
                        {
                            sums.map((sum, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        {!isArray && sums.length !== idx + 1 && idx > 0 && idx % (sums.length / Object.keys(this.props.columns).length) === 0 &&
                                            <td className='gap'/>
                                        }
                                        { this.props.getCell(idx, null, this.props.columns[idx], sum) }
                                    </React.Fragment>
                                )
                            })
                        }
                    </tr>
                </tfoot>
            </table>
        )

    }

}

Matrix.propTypes = {
    getCell: PropTypes.func.isRequired,
    columns: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.object
    ]),
    rows: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.number),
            PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        ]
    ))
}

class JobCenterMatrix extends React.PureComponent {

    constructor(props) {

        super(props);

        const currentDate = new Date()
        const beginningOfYear = new Date(currentDate.getFullYear(), 0, 1)

        const rangeStart = this.props.rangeStart ? new Date(this.props.rangeStart) : beginningOfYear
        const rangeEnd = this.props.rangeEnd ? new Date(this.props.rangeEnd) : currentDate

        this.state = {rangeStart, rangeEnd, data: null}

    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(_prevProps, prevState) {

        if(this.state.rangeStart == null || this.state.rangeEnd == null) {
            return
        }

        if(this.state.rangeStart > this.state.rangeEnd) {
            return
        }

        if(prevState.rangeStart !== this.state.rangeStart || prevState.rangeEnd !== this.state.rangeEnd) {
            await this.fetchData();
        }

    }

    onChangeRange = (key, value) => {
        this.setState({ [key]: value });

        value = `${value.getFullYear()}-${(value.getMonth() + 1).toString().padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`

        const url = new URL(location)
        url.searchParams.set(toSnakeCase(key), value)

        history.pushState({}, '', url)
    }

    fetchData = async () => {

        const result = await axios.get(location.href, {
            params: {
                start_date: this.state.rangeStart.toISOString(),
                end_date: this.state.rangeEnd.toISOString()
            },
            headers: {'Accept': 'application/json'}
        });

        if(result.status !== 200) {
            return;
        }

        this.setState({data: result.data});

    }
    getCell = (key, row, column, value) => {

        if (!this.props.showLinks) {
            return <Cell key={key} value={value} />;
        }

        const url = new URL('administration/sales/education_vouchers', window.location.origin);

        const params = {
            query: [
                {column: 'certifications_name', operator: 'eq', value: column},
                {column: 'programs_starts_at', operator: 'lteq', value: this.state.rangeEnd},
                {column: 'programs_ends_at', operator: 'gteq', value: this.state.rangeStart},
            ]
        }

        if(row) {
            params['query'].push({column: 'job_centers_name', operator: 'eq', value: row});
        }

        let query = qs.stringify(params, {arrayFormat: 'brackets', encode: true});
        url.search = `?${query}`;

        return <Cell key={key} value={value} href={url.toString()} />;

    }

    render() {

        return (
            <React.Fragment>
                <div className='row mb-3'>
                    <div className='col-auto'>
                        <label>Zeitraum von</label>
                        <DateTimeInput displayTime={false} date={this.state.rangeStart} className='form-control' onChange={this.onChangeRange.bind(this, 'rangeStart')} />
                    </div>
                    <div className='col-auto'>
                        <label>Zeitraum bis</label>
                        <DateTimeInput displayTime={false} date={this.state.rangeEnd} className='form-control' onChange={this.onChangeRange.bind(this, 'rangeEnd')} />
                    </div>
                </div>
                { this.state.rangeStart <= this.state.rangeEnd &&
                    <Matrix getCell={this.getCell} {...this.state.data} />
                }
            </React.Fragment>
        )

    }

}

JobCenterMatrix.propTypes = {
    showLinks: PropTypes.bool.isRequired,
    rangeStart: PropTypes.string,
    rangeEnd: PropTypes.string
};

JobCenterMatrix.defaultProps = {
    showLinks: false
}

export default JobCenterMatrix;