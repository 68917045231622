import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import SelectBox from './SelectBox';
import DateTimeInput from './DateTimeInput';
import ConcentrateFilter from "./ConcentrateFilter";

class ExcuseDayPeriod extends React.Component {

    kind = React.createRef();

    constructor(props) {

        super(props);

        this.state = {
            from: this.props.from,
            to: this.props.to,
            kind: null
        };

    }

    componentDidUpdate() {
        if(this.state.from > this.state.to) {
            this.setState({to: this.state.from});
        }
    }

    addExcuseDay = () => {

        if(this.props.onAdd) {
            this.props.onAdd({from: this.state.from, to: this.state.to, kind: this.state.kind});
        }

        this.setState({from: this.props.from, to: this.props.to, kind: null}, () => {
            this.kind.current.reset();
        });

    }

    disableButton = () => {
        return this.state.from > this.state.to || this.state.kind === null;
    }

    render() {

        return (
            <tr>
                <td className='pl-0 pr-1'>
                    <label className='required'>Von</label>
                    <DateTimeInput className='form-control' displayTime={false} date={this.state.from} onChange={date => this.setState({from: date})} />
                </td>
                <td className='px-1'>
                    <label className='required'>Bis</label>
                    <DateTimeInput className='form-control' displayTime={false} date={this.state.to} onChange={date => this.setState({to: date})} />
                </td>
                <td className='px-1' style={{minWidth: '100px'}}>
                    <label className='required'>Art</label>
                    <SelectBox ref={this.kind} disableInput={true} items={this.props.kinds} required={true} onChange={item => this.setState({kind: item})} />
                </td>
                <td className='pl-1 pr-0 text-right'>
                    <label>&nbsp;</label>
                    <button type='button' className='btn btn-secondary m-0 px-2' disabled={this.disableButton()} onClick={this.addExcuseDay}>
                        <i className='fa-light fa-plus' />
                    </button>
                </td>
            </tr>
        )

    }

}

ExcuseDayPeriod.propTypes = {
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    kinds: PropTypes.array.isRequired,
    onAdd: PropTypes.func
}

ExcuseDayPeriod.defaultProps = {
    from: new Date(),
    to: new Date()
}

class ExcuseDays extends React.PureComponent {

    formatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};

    toDateString = (date) => date.toISOString().split('T')[0]

    render() {

        if(this.props.excuseDays.size === 0) {
            return null;
        }

        return this.props.excuseDays.map((e, idx) => (
                <tr key={idx}>
                    <td className='pl-0 pr-1 vertical-center'>{e.from.toLocaleDateString(this.props.locale, this.formatOptions)}</td>
                    <td className='px-1 vertical-center'>{e.to.toLocaleDateString(this.props.locale, this.formatOptions)}</td>
                    <td className='px-1 vertical-center'>{e.kind.title}</td>
                    <td className='pr-0 pl-1 text-right vertical-center'>
                        <input type='hidden' name={`excuse[excused_periods_attributes][][from]`} value={this.toDateString(e.from)} />
                        <input type='hidden' name={`excuse[excused_periods_attributes][][to]`} value={this.toDateString(e.to)} />
                        <input type='hidden' name={`excuse[excused_periods_attributes][][kind]`} value={e.kind.value} />
                        <button type='button' className='btn btn-danger m-0 px-2' onClick={() => this.props.onDelete(idx)}>
                            <i className='fa-light fa-trash' />
                        </button>
                    </td>
                </tr>
            )
        )

    }

}

ExcuseDays.propTypes = {
    excuseDays: PropTypes.object.isRequired,
    locale: PropTypes.string,
    onDelete: PropTypes.func,
}

ExcuseDays.defaultProps = {
    locale: document.documentElement.lang
}

class ExcuseValidation extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            excuseDays: List(props.excuseDays.map((d) => ({from: new Date(d.from), to: new Date(d.to), kind: props.kinds.find((k) => k.value === d.kind)}))),
            reason: this.props.reason
        };

    }

    removeExcuseDay = (idx) => {
        this.setState({excuseDays: this.state.excuseDays.delete(idx)});
    }

    render() {

        return (
            <React.Fragment>
                <div className='card'>
                    <div className='card-block'>
                        <div className='form-group'>
                            <label className='required'>Grund</label>
                            <SelectBox name='excuse[reason]' required={true} includeEmpty={true} items={this.props.reasons} value={this.state.reason} />
                        </div>

                        <table className='table'>
                            <tbody>
                                <ExcuseDayPeriod kinds={this.props.kinds} onAdd={(a) => this.setState({excuseDays: this.state.excuseDays.push(a)})} />
                                <ExcuseDays excuseDays={this.state.excuseDays} onDelete={(idx) => this.removeExcuseDay(idx)} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )

    }

}

ExcuseValidation.propTypes = {
    excuseDays: PropTypes.array.isRequired,
    reason: PropTypes.string,
    reasons: PropTypes.array.isRequired,
    kinds: PropTypes.array.isRequired
};

ExcuseValidation.defaultProps = {
    excuseDays: []
}

export default ExcuseValidation;