import React from "react";
import PropTypes from "prop-types";

class LoadingPreview extends React.PureComponent {

    render() {

        let className = 'fa-solid fa-sync fa-spin';

        if(this.props.failed) {
            className = 'fa-light fa-exclamation-triangle';
        }

        return(
            <div className='unknown-wrapper'>
                <p>{this.props.message}</p>
                <i className={className} />
            </div>
        )

    }

}

LoadingPreview.propTypes = {
    failed: PropTypes.bool,
    message: PropTypes.string
};

export default LoadingPreview;