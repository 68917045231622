import React from 'react';
import PropTypes from 'prop-types';

class SubmitButton extends React.PureComponent {

    constructor(props) {

        super(props);

        this.label = React.createRef();
        this.button = React.createRef();

        this.state = {
            submitted: false,
            labelWidth: 0
        }

    }

    componentDidMount() {

        this.button.current.form.addEventListener('submit', () => this.setState({submitted: true}));

        this.setState({labelWidth: this.label.current.offsetWidth});

    }

    render() {

        let classes = 'submit-button';

        if(this.props.class) {
            classes = `${classes} ${this.props.class}`;
        }

        return (
            <button className={classes} type='submit' onClick={this.handleClick} ref={this.button} name={this.props.name}>
                {!this.state.submitted && <div className='btn-label' ref={this.label}>{this.props.label}</div>}
                {this.state.submitted && <i className='fa-solid fa-sync fa-spin' style={{width: `${this.state.labelWidth}px`}}/>}
            </button>
        )

    }

}

SubmitButton.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    class: PropTypes.string
};

export default SubmitButton;