import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export const Dialog = ({title, children, onClose}) => (
    <div className='card' style={{minWidth: '500px'}}>
        <div className='card-heading border bottom'>
            <h4 className='card-title'>
                {title}
                <span onClick={onClose} style={{cursor: 'pointer'}}>
                    <i className='fal fa-close float-right'  title='Schließen' style={{lineHeight: '30px'}}  />
                </span>
            </h4>
        </div>
        <div className='card-body'>
            { children }
        </div>
    </div>
)

Dialog.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    onClose: PropTypes.func.isRequired
}

export default class Modal extends React.PureComponent {

    constructor(props) {

        super(props);

        this.backdropStyle = {
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            padding: '25px',
            zIndex: 1050,
            top: 0,
            left: 0
        }

        this.containerStyle = {
            position: 'relative'
        }

    }

    render() {

        if(!this.props.isVisible) {
            return null
        }

        if(this.props.onVisible) {
            this.props.onVisible();
        }

        return ReactDOM.createPortal(
            (
                <div id='modal' style={this.backdropStyle}>
                    <div style={this.containerStyle}>
                        {this.props.children}
                    </div>
                </div>
            ),
            document.body
        )

    }

}

Modal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]),
    onVisible: PropTypes.func
}