import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListItemNode,
    ListNode,
    REMOVE_LIST_COMMAND
} from '@lexical/list'
import {$createLinkNode, $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {$isAtNodeEnd} from '@lexical/selection';
import {$findMatchingParent} from '@lexical/utils';
import {$generateHtmlFromNodes} from '@lexical/html';
import {
    $createTextNode,
    $getSelection,
    $isParagraphNode,
    $isRangeSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    FORMAT_TEXT_COMMAND,
    REDO_COMMAND,
    UNDO_COMMAND
} from 'lexical';
import Modal, {Dialog} from './Modal';

const getSelectedNode = (selection) => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

const Button = ({icon, command, payload, disabled, className, onClick}) => {
    const [editor] = useLexicalComposerContext()

    const dispatch = () => {
        editor.dispatchCommand(command, payload)
        if (onClick) {
            onClick()
        }
    }

    return (
        <button className={className} type='button' onClick={dispatch} disabled={disabled}>
            <i className={`fa-solid fa-${icon}`}/>
        </button>
    )
}

Button.propTypes = {
    icon: PropTypes.string.isRequired,
    command: PropTypes.object.isRequired,
    payload: PropTypes.any,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
}

Button.defaultProps = {
    disabled: false
}

const StatefulButton = ({icon, command, activateCommand}) => {
    const [enabled, setEnabled] = useState(false)
    const [editor] = useLexicalComposerContext()

    useEffect(() => {
        return editor.registerCommand(
            activateCommand,
            (payload) => {
                setEnabled(payload)
                return true;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor]);

    return <Button icon={icon} command={command} disabled={!enabled}/>
}

StatefulButton.propTypes = {
    icon: PropTypes.string.isRequired,
    command: PropTypes.object.isRequired,
    activateCommand: PropTypes.object.isRequired,
    disabled: PropTypes.bool
}

const FormatButton = ({icon, operation}) => {
    const [isActive, setIsActive] = useState(false)
    const [editor] = useLexicalComposerContext()

    const updateButton = useCallback(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection()

            if (!$isRangeSelection(selection)) {
                return;
            }

            setIsActive(selection.hasFormat(operation))
        })
    }, [editor])

    useEffect(() => {
        document.addEventListener('selectionchange', updateButton);
        return () => {
            document.removeEventListener('selectionchange', updateButton);
        };
    }, [updateButton]);

    return <Button icon={icon} className={isActive ? 'active' : null} command={FORMAT_TEXT_COMMAND} payload={operation}
                   onClick={() => setIsActive(!isActive)}/>
}

FormatButton.propTypes = {
    icon: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired
}

const LinkButton = () => {
    const [isActive, setIsActive] = useState(false)
    const [editor] = useLexicalComposerContext()

    const updateButton = useCallback(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection()

            if (!$isRangeSelection(selection)) {
                return;
            }

            const node = getSelectedNode(selection);
            const link = $findMatchingParent(node, n => $isLinkNode(n))

            setIsActive(link !== null)
        })
    }, [editor])

    useEffect(() => {
        document.addEventListener('selectionchange', updateButton);
        return () => {
            document.removeEventListener('selectionchange', updateButton);
        };
    }, [updateButton]);

    return <Button icon='link' className={isActive ? 'active' : null} command={TOGGLE_LINK_COMMAND} payload={null}/>
}

const ListButton = ({kind}) => {
    const [isActive, setIsActive] = useState(false)
    const [editor] = useLexicalComposerContext()

    let command = kind === 'ul' ? INSERT_UNORDERED_LIST_COMMAND : INSERT_ORDERED_LIST_COMMAND

    if (isActive) {
        command = REMOVE_LIST_COMMAND
    }

    const updateButton = useCallback(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection()

            if (!$isRangeSelection(selection)) {
                return;
            }

            const node = getSelectedNode(selection);
            const list = $findMatchingParent(node, n => $isListNode(n))
            const listType = kind === 'ul' ? 'bullet' : 'number'

            setIsActive(list !== null && list.getListType() === listType)
        })
    }, [editor])

    useEffect(() => {
        document.addEventListener('selectionchange', updateButton);
        return () => {
            document.removeEventListener('selectionchange', updateButton);
        };
    }, [updateButton]);

    return <Button icon={`list-${kind}`} className={isActive ? 'active' : null} command={command}
                   onClick={() => setIsActive(!isActive)}/>
}

ListButton.propTypes = {
    kind: PropTypes.string.isRequired
}

const Toolbar = () => {
    return (
        <div className='rte-toolbar' style={{display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center'}}>
            <StatefulButton icon='rotate-left' command={UNDO_COMMAND} activateCommand={CAN_UNDO_COMMAND}/>
            <StatefulButton icon='rotate-right' command={REDO_COMMAND} activateCommand={CAN_REDO_COMMAND}/>
            <div className='divider'/>
            <FormatButton icon='bold' operation='bold'/>
            <FormatButton icon='italic' operation='italic'/>
            <FormatButton icon='underline' operation='underline'/>
            <FormatButton icon='strikethrough' operation='strikethrough'/>
            <div className='divider'/>
            <ListButton kind={'ul'}/>
            <ListButton kind={'ol'}/>
            <LinkButton/>
        </div>
    )
}

const HiddenFieldPlugin = ({scope, name}) => {
    const [editor] = useLexicalComposerContext()
    const [state, setState] = useState()
    const [htmlState, setHtmlState] = useState()

    const getJSONName = () => scope ? `${scope}[${name}]` : name
    const getHTMLName = () => scope ? `${scope}[html_${name}]` : `html_${name}`

    useEffect(() => {
        const state = editor.getEditorState()

        setState(state)
        state.read(() => {
            setHtmlState($generateHtmlFromNodes(editor))
        })
    }, [])

    useEffect(() => {
        return editor.registerUpdateListener(({editorState, dirtyElements, dirtyLeaves, prevEditorState, tags}) => {
            if (dirtyElements.size === 0 && dirtyLeaves.size === 0 || tags.has('history-merger') || prevEditorState.isEmpty()) {
                return
            }

            setState(editorState)

            editorState.read(() => {
                setHtmlState($generateHtmlFromNodes(editor))
            })
        });
    }, [editor])

    return (
        <>
            <input type='hidden' name={getJSONName()} defaultValue={JSON.stringify(state)}/>
            <input type='hidden' name={getHTMLName()} defaultValue={htmlState}/>
        </>
    )
}

HiddenFieldPlugin.propTypes = {
    scope: PropTypes.string,
    name: PropTypes.string.isRequired
}

const withRangeSelection = (callback) => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
        callback(selection)
    }
}

const isValidURL = (url) => /^https?:\/\/(?:www\.\S+?\.\S{2,}$|(?!www\.)\S+?\.\S{2,})$/.test(url)

const LinkInputDialog = ({anchorElement}) => {
    const [editor] = useLexicalComposerContext()
    const [isVisible, setIsVisible] = useState(false)
    const [node, setNode] = useState(null)
    const [text, setText] = useState('')
    const [url, setUrl] = useState('https://')

    const resetState = () => {
        setUrl('https://')
        setText('')
        setIsVisible(false)
    }

    const onToggleLink = () => {
        withRangeSelection((selection) => {
            const node = getSelectedNode(selection)
            const parent = node.getParent()

            if ($isLinkNode(parent)) {
                setText(node.getTextContent())
                setUrl(parent.getURL())

                setNode(parent)
            } else {
                const text = selection.getTextContent()

                if (isValidURL(text)) {
                    setUrl(text)
                    setText(text)
                } else {
                    setText(text)
                }

                setNode(node)
            }
        })

        setIsVisible(true)

        return true
    }

    const onSave = () => {

        editor.update(() => {

            if ($isLinkNode(node)) {

                const child = node.getFirstChild()

                child.setTextContent(text)
                node.setURL(url)

            } else {

                const linkNode = $createLinkNode(url, {target: '_blank', rel: 'noreferrer'})
                const textNode = $createTextNode(text)

                linkNode.append(textNode)

                const parent = node.getParent()

                withRangeSelection((selection) => {
                    selection.extract()
                    selection.removeText()
                })

                if ($isParagraphNode(node)) {
                    node.append(linkNode)
                } else {

                    if (node.getParent()) {
                        node.insertAfter(linkNode)
                    } else {
                        parent.append(linkNode)
                    }

                }

            }

            resetState()
        })

    }

    const onRemove = () => {

        editor.update(() => {
            node.replace(node.getFirstChild())
            resetState()
        })

    }

    useEffect(() => {
        return editor.registerCommand(
            TOGGLE_LINK_COMMAND,
            onToggleLink,
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor]);

    if (!anchorElement) {
        return null
    }

    const isURLValid = isValidURL(url)

    return ReactDOM.createPortal(
        (
            <Modal isVisible={isVisible}>
                <Dialog onClose={resetState} title={'Link'}>
                    <div className='row'>
                        <div className='col'>
                            <label className='required'>URL</label>
                            <input className='form-control' type='text' required={true} name='url' value={url}
                                   onChange={e => setUrl(e.target.value)} autoFocus={true}/>
                        </div>
                        {
                            isURLValid &&
                            <div className='col-auto'>
                                <a href={url} rel='noreferrer' target='_blank' tabIndex='-1'>
                                    <i className='fa-solid fa-arrow-up-right-from-square bottom'
                                       style={{position: 'absolute', bottom: '14px', right: '14px'}}/>
                                </a>
                            </div>
                        }
                    </div>
                    <div className='row mt-2 mb-2'>
                        <div className='col'>
                            <label className='required'>Text</label>
                            <input className='form-control' type='text' required={true} name='text' value={text}
                                   onChange={e => setText(e.target.value)}/>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col'>
                            <button className='btn btn-primary' type='button' onClick={onSave}
                                    disabled={!isURLValid || text === ''}>Speichern
                            </button>
                        </div>
                        {$isLinkNode(node) &&
                            <div className='col-auto'>
                                <button className='btn btn-danger' type='button' onClick={onRemove}>Entfernen</button>
                            </div>
                        }
                    </div>

                </Dialog>
            </Modal>
        ),
        anchorElement
    )
}

LinkInputDialog.propTypes = {
    anchorElement: PropTypes.object
}

const emptyState = {
    root: {
        children: [
            {
                children: [],
                direction: null,
                format: '',
                indent: 0,
                type: 'paragraph',
                version: 1
            }
        ],
        direction: null,
        format: '',
        indent: 0,
        type: 'root',
        version: 1
    }
}

const TextEditor = ({scope, name, placeholder = 'Text hier eingeben...', state = emptyState}) => {
    const [anchorElement, setAnchorElement] = useState(null)

    const onError = (e) => {
        console.error(e)
    }

    const theme = {
        text: {
            bold: 'rte-text-bold',
            italic: 'rte-text-italic',
            underline: 'rte-text-underline',
            strikethrough: 'rte-text-strikethrough',
            underlineStrikethrough: 'rte-text-underline-strikethrough'
        }
    }
    
    let editorState = state
    if (Object.keys(editorState).length === 0 && Object.getPrototypeOf(editorState) === Object.prototype) {
        editorState = emptyState
    }

    const initialConfig = {
        namespace: 'TextEditor',
        editorState: JSON.stringify(editorState),
        editable: true,
        theme,
        nodes: [ListNode, ListItemNode, LinkNode],
        onError
    }

    const onRef = (element) => {
        if (element === null) {
            return
        }
        setAnchorElement(element)
    }

    return (
        <div className='rte' ref={onRef}>
            <LexicalComposer initialConfig={initialConfig}>
                <Toolbar/>
                <div className='rte-scrollable'>
                    <RichTextPlugin
                        contentEditable={<ContentEditable className='rte-content'/>}
                        placeholder={<div className='rte-placeholder'>{placeholder}</div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                </div>
                <ListPlugin/>
                <LinkPlugin validateUrl={isValidURL}/>
                <HistoryPlugin/>
                <LinkInputDialog anchorElement={anchorElement}/>
                <HiddenFieldPlugin scope={scope} name={name}/>
            </LexicalComposer>
        </div>
    )

}

TextEditor.propTypes = {
    name: PropTypes.string.isRequired,
    scope: PropTypes.string,
    placeholder: PropTypes.string,
    state: PropTypes.object
}

export default TextEditor