import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import Modal, {Dialog} from './Modal';
import {StandardTextSelection} from './DocumentValidation';

const RejectButton = ({scope, label, textKind}) => {
    const [textId, setTextId] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const buttonRef = useRef()

    const onDialogClose = () => {

        setShowModal(false)
        setTextId(null)

    }

    const reject = (e) => {
        if(textId) { return }

        e.preventDefault()
        setShowModal(true)
    }

    return (
        <>
            <Modal isVisible={showModal}>
                <Dialog onClose={onDialogClose} title={'Ablehnungsgrund'}>
                    <div className='row'>
                        <div className='col'>
                            <StandardTextSelection kind={textKind} setStandardText={id => setTextId(id)}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <button className='btn btn-danger' type='submit' name='reject' onClick={() => buttonRef.current.click()}>{label}</button>
                        </div>
                    </div>
                </Dialog>
            </Modal>
            {textId && <input type='hidden' name={`${scope}[standard_text_id]`} value={textId} />}
            <button ref={buttonRef} formNoValidate={true} className='btn btn-danger' type='submit' name='reject' onClick={reject}>{label}</button>
        </>
    )

}

RejectButton.propTypes = {
    scope: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    textKind: PropTypes.string.isRequired
}

export default RejectButton