import React from 'react';
import PropTypes from 'prop-types';
import SalesCalendar from './SalesCalendar';

const EventInputs = ({inputNames, event}) => {
    if(event === null) { return null }

    let resourceId = event.resourceId

    if (typeof event.getResources === 'function') {
        resourceId = event.getResources()[0].id
    }

    return (
        <React.Fragment>
            <input type='hidden' name={inputNames.careerAdvisorId} defaultValue={resourceId} />
            <input type='hidden' name={inputNames.startDate} defaultValue={event.start.toISOString()} />
            <input type='hidden' name={inputNames.endDate} defaultValue={event.end.toISOString()} />
            <input type='hidden' name={inputNames.isAllDay} defaultValue={event.extendedProps.allDay || false} />
        </React.Fragment>
    )
}

EventInputs.propTypes = {
    inputNames: PropTypes.shape({
        careerAdvisorId: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        isAllDay: PropTypes.string.isRequired
    }).isRequired,
    event: PropTypes.object
}

const AppointmentScheduler = ({careerAdvisors, initialEvent, eventColor, eventTitle, eventLength, eventMinDuration, eventConstraint, retroactiveEvents, inputNames, licenseKey, timeZone}) => {

    const schedulerProperties = {
        careerAdvisors, initialEvent, eventTitle, eventColor, eventLength, eventMinDuration, eventConstraint,
        retroactiveEvents, licenseKey, timeZone
    }

    return (
        <SalesCalendar weekends={true} {...schedulerProperties}>
            {(event) => <EventInputs inputNames={inputNames} event={event} />}
        </SalesCalendar>
    )
}

AppointmentScheduler.propTypes = {
    careerAdvisors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired, title: PropTypes.string.isRequired
        })
    ).isRequired,
    inputNames: PropTypes.shape({
        careerAdvisorId: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired
    }).isRequired,
    eventColor: PropTypes.string.isRequired,
    eventTitle: PropTypes.string.isRequired,
    eventLength: PropTypes.number.isRequired,
    eventMinDuration: PropTypes.number.isRequired,
    eventConstraint: PropTypes.shape({
        daysOfWeek: PropTypes.arrayOf(PropTypes.number),
        startTime: PropTypes.string,
        endTime: PropTypes.string
    }).isRequired,
    licenseKey: PropTypes.string.isRequired,
    retroactiveEvents: PropTypes.bool.isRequired,
    initialEvent: PropTypes.shape({
        id: PropTypes.number,
        careerAdvisorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        allDay: PropTypes.bool
    }),
    timeZone: PropTypes.string.isRequired
}

AppointmentScheduler.defaultProps = {
    inputNames: {
        careerAdvisorId: 'career_advisor_id',
        startDate: 'start_date',
        endDate: 'end_date',
        isAllDay: 'is_all_day'
    },
    eventTitle: 'Neuer Termin',
    eventLength: 0,
    eventColor: '#51AE2F',
    eventMinDuration: 0,
    eventConstraint: {
        daysOfWeek: [1, 2, 3, 4, 5, 6],
        startTime: '08:00',
        endTime: '20:00',
    },
    retroactiveEvents: false
}

export default AppointmentScheduler