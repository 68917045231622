import React from 'react';
import PropType from 'prop-types';
import axios from 'axios';

class ResultCounter extends React.PureComponent {

    onClick = async () => {

        const result = await axios.get(window.location, {
            headers: {
                'Accept': 'application/javascript',
                'X-Requested-With': 'XMLHttpRequest',
                'X-HUI-COUNT': true
            }
        });

        if(result.status !== 200) {
            return;
        }

        const script = document.createElement('script');

        script.appendChild(document.createTextNode(result.data));

        document.body.appendChild(script);

        script.remove();

    }

    render() {

        return (
            <span className='badge badge-info' style={{cursor: 'pointer'}} onClick={this.onClick}>
                <i className={this.props.labelClass} />
                { this.props.count && <span style={{marginLeft: '3px'}}>{ this.props.count }</span> }
            </span>
        )

    }

}

ResultCounter.propTypes = {
    labelClass: PropType.string.isRequired,
    count: PropType.number
}

ResultCounter.defaultProps = {
    count: null
}

export default ResultCounter;