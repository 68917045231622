import React, {useState} from 'react'
import axios from 'axios';

const PromptEvaluator = ({identifier, variables}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reply, setReply] = useState('')
    const [data, setData] = useState(null)
    const [contentType, setContentType] = useState(null)
    const [variableState, setVariableState] = useState(
        () => variables.reduce((acc, variable) => {
            acc[variable] = '';
            return acc;
        }, {})
    );

    const setState = (key, value) => {
        setVariableState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const evaluatePrompt = async () => {
        setReply('')
        setData(null)
        setIsLoading(true)

        try {
            const request = await axios.post(`/administration/prompts/${identifier}/evaluate`, variableState, {
                headers: {
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
            })

            if(request.status === 200) {
                setReply(request.data.response)
                setData(request.data.image)
                setContentType(request.data.content_type)
            }
        } finally {
            setIsLoading(false)
        }
    }

    return <>
        <div className="card">
            <div className="card-heading border bottom">
                <h4 className="card-title">KI-Prompt Testen</h4>
            </div>
            <div className="card-body">
                {variables.map(variable => (
                    <div key={variable} className="form-group">
                        <label htmlFor="name">{variable}</label>
                        <textarea className="form-control" style={{minHeight: '80px'}}
                                  onChange={({target: {value}}) => setState(variable, value)}>
                            {variableState[variable]}
                        </textarea>
                    </div>
                ))}
                <div className="form-group">
                    <label htmlFor="name">Antwort</label>
                    <textarea className="form-control" defaultValue={reply} disabled={true} />
                    { data &&
                        <div className="w-100 mt-3" style={{aspectRatio: '1 / 1'}}>
                            <img className="w-100 h-100" src={`data:${contentType};base64,${data}`} alt="Antwort"/>
                        </div>
                    }
                </div>
            </div>
            <div className="card-footer">
                <button type="button" className="btn btn-primary btn-sm" onClick={evaluatePrompt} disabled={isLoading}>
                    Auswerten
                    { isLoading && <i className="fas fa-sync fa-spin ml-1"></i> }
                </button>
            </div>
        </div>
    </>
}

export default PromptEvaluator