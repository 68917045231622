import React from 'react';
import PropTypes from 'prop-types';

class FileTypeIcon extends React.PureComponent {

    iconForType(type) {

        switch(type) {
            case 'audio/aac':
            case 'audio/midi':
            case 'audio/x-midi':
            case 'audio/mpeg':
            case 'audio/ogg':
            case 'audio/opus':
            case 'audio/wav':
            case 'audio/webm':
            case 'audio/3gpp':
            case 'audio/3gpp2':
                return 'fa-light fa-file-audio';
            case 'application/x-abiword':
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.oasis.opendocument.text':
            case 'application/rtf':
            case 'text/plain':
                return 'fa-light fa-file-word';
            case 'text/csv':
            case 'application/vnd.oasis.opendocument.spreadsheet':
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'fa-light fa-file-spreadsheet';
            case 'application/x-freearc':
            case 'application/x-bzip':
            case 'application/x-bzip2':
            case 'application/gzip':
            case 'application/java-archive':
            case 'application/vnd.rar':
            case 'application/x-tar':
            case 'application/zip':
            case 'application/x-7z-compressed':
                return 'fa-light fa-file-archive';
            case 'video/x-msvideo':
            case 'video/mpeg':
            case 'video/ogg':
            case 'video/mp2t':
            case 'video/webm':
            case 'video/3gpp':
            case 'video/3gpp2':
            case 'video/mp4':
                return 'fa-light fa-file-video';
            case 'image/bmp':
            case 'image/gif':
            case 'image/jpeg':
            case 'image/png':
            case 'image/svg+xml':
            case 'image/tiff':
            case 'image/webp':
                return 'fa-light fa-file-image';
            case 'text/css':
            case 'text/html':
            case 'text/javascript':
            case 'application/json':
            case 'application/ld+json':
            case 'application/php':
            case 'application/xhtml+xml':
            case 'application/xml':
            case 'text/xml':
            case 'application/vnd.mozilla.xul+xml':
                return 'fa-light fa-file-code';
            case 'application/vnd.oasis.opendocument.presentation':
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'fa-light fa-file-powerpoint';
            case 'application/pdf':
                return 'fa-light fa-file-pdf';
            default:
                return 'fa-light fa-file';
        }

    }

    render() {

        return (
            <i className={`content-type-icon ${this.iconForType(this.props.type)}`}/>
        );

    }

}

FileTypeIcon.propTypes = {
    type: PropTypes.string.isRequired
};

export default FileTypeIcon