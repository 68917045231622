import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Set} from 'immutable';
import FileTypeIcon from './FileTypeIcon';
import FileSizeFormatter from './FileSizeFormatter';

const fileObject = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    contentType: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
})

const Attachment = ({file, viewed, onClick}) => {
    const identifier = `file-${file.id}`
    const checkboxRef = useRef()

    return (
        <li onClick={() => checkboxRef.current.click()}>
            <div className='file'>
                <div className='file-name' >
                    <FileTypeIcon type={file.contentType}/>
                    <div className='title'>{file.name}</div>
                </div>
                <div className='file-information'>
                    <FileSizeFormatter size={file.size}/>
                </div>
            </div>
            <div className={'attachment-validator'}>
                <input ref={checkboxRef} type='checkbox' id={identifier} checked={viewed} onChange={() => onClick(file)} required={true} />
                <label htmlFor={identifier} />
            </div>
        </li>
    )

}

Attachment.propTypes = {
    file: fileObject.isRequired,
    viewed: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
}

const AttachmentValidator = ({files, onClick}) => {
    const [viewedFiles, setViewedFiles] = useState(Set())

    const viewFile = (file) => {
        setViewedFiles(viewedFiles.add(file.id))

        if(onClick) {
            onClick(file)
        }
    }

    useEffect(() => {
        if(files.size === 0) { return }

        setViewedFiles(viewedFiles.add(files[0].id))
    }, [])

    return (
        <div className='card'>
            <div className='card-body'>
                <ul className='file-list'>
                    { files.map(f => <Attachment key={f.id} file={f} viewed={viewedFiles.has(f.id)} onClick={viewFile} />) }
                </ul>
            </div>
        </div>
    )
}

AttachmentValidator.propTypes = {
    files: PropTypes.arrayOf(fileObject).isRequired,
    onClick: PropTypes.func
}

export default AttachmentValidator