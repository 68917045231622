import React, {useEffect, useState} from 'react';
import SelectBox from './SelectBox';
import PropType from 'prop-types';
import axios from 'axios';

const AdvancedTrainingSelection = ({scope, blockStarts, estimatedStartAt, labels, children, ...props}) => {
    const [blockStart, setBlockStart] = useState(estimatedStartAt || null)
    const [courses, setCourses] = useState([])
    const [courseId, setCourseId] = useState(props.courseId)

    useEffect(() => {(async() => {
        if(blockStart === null) {
            setCourses([])
            return
        }

        const response = await axios.get('/administration/course_versions', {
            headers: { 'Accept': 'application/json' },
            params: { 'date': blockStart }
        })

        if(response.status === 200) {
            setCourses(response.data.map(c => ({title: c.name, value: c.courseId})))
        }
    })()}, [blockStart])

    return <>
        <div className="form-group">
            <label className="required">{labels.blockStarts}</label>
            <SelectBox name={scope ? `${scope}[estimated_start_at]` : 'estimated_start_at'} items={blockStarts}
                       value={blockStart} onChange={({value}) => setBlockStart(value)} includeEmpty={true}/>
        </div>
        <div className="form-group">
            {children &&
                <div className="row">
                    <div className="col">
                        <label>{labels.courses}</label>
                    </div>
                    <div className="col-auto">
                        {children}
                    </div>
                </div>
            }
            {!children &&
                <label>{labels.courses}</label>
            }
            <SelectBox name={scope ? `${scope}[course_id]` : 'course_id'}
                       items={courses}
                       value={courseId}
                       onChange={({value})=> setCourseId(value)}
                       disabled={blockStart === null}/>
        </div>
    </>

}

AdvancedTrainingSelection.propTypes = {
    scope: PropType.string,
    courseId: PropType.number,
    blockStarts: PropType.arrayOf(PropType.shape({title: PropType.string, value: PropType.string})).isRequired,
    estimatedStartAt: PropType.string,
    labels: PropType.shape({blockStarts: PropType.string, courses: PropType.string}).isRequired,
    children: PropType.oneOfType([
        PropType.arrayOf(PropType.node),
        PropType.node
    ])
}

export default AdvancedTrainingSelection