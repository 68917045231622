export default class HUI {

    static isElement(node) {
        return node instanceof Element || node instanceof HTMLDocument
    }

    static on(node, event, selector, eventHandler) {

        node.addEventListener(event, e => {

            const target = e.target.closest(selector);

            if(target) {
                eventHandler.call(target, e);
            }

        }, false);

    }

}