/* global Turbolinks */

import React from 'react';
import PropTypes from 'prop-types';
import {DateTimePopUp} from './DateTimePicker';

class LocationDateSelection extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            visible: false,
            date: new Date(this.props.date),
        };

    }

    updateDate = (date) => {

        const params = new URLSearchParams(location.search);

        params.set('date', date.toISOString().substring(0, 10));

        this.setState({
            date: date,
            visible: false
        }, () => {
            Turbolinks.visit(`?${params.toString()}`);
        });

    }

    onClick = (e) => {

        e.preventDefault();
        this.setState({visible: !this.state.visible});

    };

    render() {

        return (
            <div className='location-date-selection'>
                <DateTimePopUp visible={this.state.visible} displayTime={false} saveLabel='Übernehmen' onSave={(date) => this.updateDate(date)}>
                    {ref => (
                        <React.Fragment>
                            <span>{ this.state.date.toLocaleString(document.documentElement.lang, {year: 'numeric', month: '2-digit', day: '2-digit'}) }</span>
                            <button ref={ref} type='button' className='btn btn-link p-0 m-0 ml-2' title='Datum für Teilnehmerzahl' onClick={this.onClick}>
                                <i className='fa-light fa-calendar' />
                            </button>
                        </React.Fragment>
                    )}
                </DateTimePopUp>
            </div>
        )
    }
}

LocationDateSelection.propTypes = {
    date: PropTypes.string,
};

export default LocationDateSelection;