import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import SelectBox from './SelectBox';
import PropType from "prop-types";

class SurveyAddOption extends React.PureComponent {

    state = {
        name: '',
        value: ''
    }

    addOption = () => {

        if(this.state.name === '' || this.state.value === '') {
            return;
        }

        this.props.addOption(this.state);

        this.setState({
            name: '',
            value: ''
        });

    }

    onChangeName = (e) => {

        const value = this.state.name.replace(/\s+/g, '-').toLowerCase();
        const update = {};

        if(this.state.value === '' || this.state.value === value) {
            update['value'] = e.target.value.replace(/\s+/g, '-').toLowerCase();
        }

        this.setState(Object.assign({name: e.target.value}, update));

    }

    onKeyPress = (e) => {

        if(e.key !== 'Enter' || this.state.value.length === 0 || this.state.name.length === 0) {
            return
        }

        e.preventDefault();
        e.stopPropagation();

        this.addOption();

        this.nameInput.focus();

    }

    render() {

        return (
            <thead>
                <tr>
                    <th className='pl-0 pt-0'>
                        <input ref={e => this.nameInput = e} type='text' name='name' value={this.state.name} placeholder='Bezeichnung' onKeyPress={this.onKeyPress} onChange={this.onChangeName} className='form-control'/>
                    </th>
                    <th className='pl-0 pt-0'>
                        <input type='text' name='value' value={this.state.value} placeholder='Wert' onKeyPress={this.onKeyPress} onChange={(e) => this.setState({value: e.target.value})} className='form-control'/>
                    </th>
                    <th className='action px-0 pt-0 align-top'>
                        <button type='button' title='Option hinzufügen' className='btn btn-icon btn-flat btn-rounded m-0' onClick={this.addOption}>
                            <i className='fa-light fa-plus'/>
                        </button>
                    </th>
                </tr>
            </thead>
        )
    }
}

SurveyAddOption.propTypes = {
    addOption: PropTypes.func.isRequired
};

class SurveyKindSelection extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            kind: props.kind,
            options: List(props.options),
            removedOptions: List()
        };

        this.optionsReference = React.createRef();

    }

    componentDidMount() {
        this.initializeSortable();
    }

    componentWillUnmount() {
        this.removeSortable();
    }

    initializeSortable = () => {

        if(!this.optionsReference.current) {
            return;
        }

        this.sortable = Sortable.create(this.optionsReference.current, {
            handle: 'tr.sortable-handle',
            direction: 'vertical',
            animation: 150,
            ghostClass: 'sortable-placeholder',
        });

        this.setState({
            sortableOrder: this.sortable.toArray()
        });

    }

    removeSortable = () => {

        if(this.sortable) {
            this.sortable.destroy();
        }

    }

    kindChanged = (e) => {

        this.setState({
            kind: e.value
        }, this.initializeSortable);

    };

    addOption = (option) => {

        this.setState( {
            options: this.state.options.push({
                name: option.name,
                value: option.value
            })
        });

    }

    removeOption = (option) => {

        this.setState( {
            options: this.state.options.filter((o) => { return o.value !== option.value }),
            removedOptions: this.state.removedOptions.push(option)
        });

    };

    render() {

        return (
            <React.Fragment>
                <div className='form-group'>
                    <label className='required'>Art</label>
                    <SelectBox name='survey_field[kind]' items={ this.props.kinds.map(item => { return { value: item.value, title: item.name } }) } value={ this.state.kind } onChange={this.kindChanged} required={ true } />
                </div>
                { this.state.kind === 'star_rating' &&
                    <div className='checkbox'>
                        <input type='hidden' name='survey_field[is_analytical]' value='0'/>
                        <input type='checkbox' name='survey_field[is_analytical]' id='survey_field_is_analytical' defaultChecked={this.props.isAnalytical} value='1'/>
                        <label htmlFor='survey_field_is_analytical'>{ this.props.labels.isAnalytical }</label>
                    </div>
                }

                { ['radio', 'checkbox', 'selectbox'].includes(this.state.kind) &&
                    <div className='form-group'>
                        <label className='required'>Optionen</label>
                        <div className='table-overflow'>
                            <table className='table'>
                                <SurveyAddOption addOption={this.addOption}/>
                                <tbody ref={this.optionsReference} className='sortable'>
                                    {this.state.options.map((option, idx) =>
                                        <tr key={idx} className='sortable-handle'>
                                            <td className='text-break'>
                                                {option.name}
                                                <input type='hidden' name='survey_field[survey_options_attributes][][name]' value={option.name}/>
                                                <input type='hidden' name='survey_field[survey_options_attributes][][id]' value={option.id}/>
                                            </td>
                                            <td className='text-break'>
                                                {option.value}
                                                <input type='hidden' name='survey_field[survey_options_attributes][][value]' value={option.value}/>
                                            </td>
                                            <td className='action px-0'>
                                                <button type='button' title='Option löschen' className='btn btn-icon btn-flat btn-rounded m-0' onClick={() => this.removeOption(option)}>
                                                    <i className='fa-light fa-trash'/>
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {this.state.removedOptions.map((option, idx) =>
                                <React.Fragment key={idx}>
                                    <input type='hidden' name='survey_field[survey_options_attributes][][id]' value={option.id}/>
                                    <input type='hidden' name='survey_field[survey_options_attributes][][_destroy]' value='1'/>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

SurveyKindSelection.propTypes = {
    kinds: PropTypes.array.isRequired,
    kind: PropTypes.string,
    isAnalytical: PropTypes.bool,
    options: PropTypes.array.isRequired,
    labels: PropType.shape({
        isAnalytical: PropType.string.isRequired,
    }).isRequired
};

export default SurveyKindSelection;