import React from 'react';
import SelectBox from './SelectBox';
import axios from 'axios';
import PropTypes from 'prop-types';
import {disableWheelInput} from "../scripts/form_utilities";

class BlockAchievementForm extends React.PureComponent {

    state = {
        participant: null,
        participants: [],
        days: []
    }

    async componentDidMount() {

        const result = await axios.get(location.href, {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({participants: result.data});
        }

    }

    async componentDidUpdate(_prevProps, prevState) {

        if(prevState.participant !== this.state.participant) {
            await this.fetchDays();
        }

    }

    fetchDays = async () => {

        const result = await axios.get(`/lecturers/block_instances/${this.props.blockInstanceId}/schedulings`, {headers: {'Accept': 'application/json'}});

        if(result.status === 200) {

            let data = result.data;

            let terminationDate = null;

            if(this.state.participant.terminated_at) {
                terminationDate = new Date(this.state.participant.terminated_at);
            }

            data = data.filter(d => {

                const date =  new Date(d.date);
                let included;

                if(terminationDate) {
                    included = date <= terminationDate && date <= Date.now();
                } else {
                    included = date <= Date.now();
                }

                return included;

            });

            data = data.map(d => { return {title: d.day, value: d.day_id} });

            this.setState({days: data});

        }

    }

    onParticipantChange = (item) => {

        const participant = this.state.participants.find(p => p.id === item.value);

        this.setState({participant: participant});

    }

    render() {

        return (

            <div className='row'>
                <div className='col-xl-6'>
                    <div className='form-group'>
                        <label className='required' htmlFor='block_achievement_participation_id'>Teilnehmer</label>
                        <SelectBox id='block_achievement_participation_id' name='block_achievement[participation_id]' required={true} items={this.state.participants.map(p => { return {title: p.name, value: p.id} })} onChange={this.onParticipantChange} />
                    </div>
                </div>
                <div className='col-xl-3'>
                    <div className='form-group'>
                        <label className='required' htmlFor='block_achievement_percentage'>Ergebnis (%)</label>
                        <input required={true} autoFocus='autofocus' className='form-control' min='0' max='100' type='number' onWheel={disableWheelInput} name='block_achievement[percentage]' id='block_achievement_percentage' />
                    </div>
                </div>
                <div className='col-xl-3'>
                    {this.state.participant &&
                        <div className='form-group'>
                            <label className='required' htmlFor='block_achievement_day_id'>Prüfungsdatum</label>
                            <SelectBox id='block_achievement_day_id' name='block_achievement[day_id]' required={true} disableInput={true} items={this.state.days} />
                        </div>
                    }
                </div>
            </div>

        )

    }

}

BlockAchievementForm.propTypes = {
    blockInstanceId: PropTypes.number.isRequired
}

export default BlockAchievementForm;