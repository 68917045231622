import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import SelectBox from './SelectBox';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import DateTimeInput from "./DateTimeInput";

const formatCategories = (data) => {
    return data.reduce((h, value) => {
        if(h[value.categoryId]) {
            return h
        }

        h[value.categoryId] = {
            name: value.categoryName,
            color: value.categoryColor
        }

        return h
    }, {})
}

const formatData = (data) => {
    let lastDate = null
    let items = []

    data.forEach((value) => {
        if (lastDate !== value.date) {
            items.push({date: value.date})
            lastDate = value.date
        }

        items.at(-1)[value.categoryId] = value.rating
    }, {})

    return items
}

const EducationalInstitutionRatingChart = ({lecturerId, from: _from, to: _to, qualificationId: _qualificationId, dateGrouping: _dateGrouping, groupByCategory: _groupByCategory, labels}) => {
    const [dateGrouping, setDateGrouping] = useState(_dateGrouping || 'block_start')
    const [qualificationId, setQualificationId] = useState(_qualificationId || null)
    const [groupByCategory, setGroupByCategory] = useState(_groupByCategory ? !!_groupByCategory : true)
    const [qualifications, setQualifications] = useState([])
    const [from, setFrom] = useState(_from ? new Date(_from) : null)
    const [to, setTo] = useState(_to ? new Date(_to) : null)
    const [data, setData] = useState([])
    const [categories, setCategories] = useState({})

    useEffect(() => {(async () => {
        let response = await axios.get(`/administration/lecturers/${lecturerId}/qualifications`, {
            params: {'with_block_name': true},
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            setQualifications(response.data)
        }

    })()}, [lecturerId])

    useEffect(() => {(async () => {
        let response = await axios.get(`/administration/lecturers/${lecturerId}/educational_institution_rating`, {
            params: {
                from: from,
                to: to,
                qualification_id: qualificationId,
                date_grouping: dateGrouping,
                group_by_category: groupByCategory
            },
            headers: { 'Accept': 'application/json' }
        })

        if(response.status === 200) {
            if(groupByCategory) {
                setCategories(formatCategories(response.data))
                setData(formatData(response.data))
            } else {
                setData(response.data)
            }
        }

        let url = new URL(location.href)

        if(from) { url.searchParams.set('from', from.toISOString().split('T')[0]) } else { url.searchParams.delete('from') }
        if(to) { url.searchParams.set('to', to.toISOString().split('T')[0]) } else { url.searchParams.delete('to') }
        if(qualificationId) { url.searchParams.set('qualification_id', qualificationId) } else { url.searchParams.delete('qualification_id') }
        url.searchParams.set('date_grouping', dateGrouping)
        url.searchParams.set('group_by_category', groupByCategory)

        history.pushState({}, '', url)
    })()}, [lecturerId, from, to, qualificationId, dateGrouping, groupByCategory])

    const tooltipFormatter = (value, name) => {
        return [parseFloat(value).toFixed(1), name];
    }

    const yTickFormatter = (tick) => {
        return `${tick} ${tick > 1 ? 'Sterne' : 'Stern'}`
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="period_from">{labels.from}</label>
                        <DateTimeInput id="period_from" name="from" displayTime={false} required={true} className="form-control" date={from} onChange={(date) => setFrom(date)} />
                    </div>

                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="period_to">{labels.to}</label>
                        <DateTimeInput id="period_to" name="to" displayTime={false} required={true} className="form-control" date={to} onChange={(date) => setTo(date)} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="period_from" className="required">{labels.dateGrouping}</label>
                        <SelectBox items={[{title: 'Blockstartdatum', value: 'block_start'}, {title: 'Kalenderjahr', value: 'year'}]} value={dateGrouping} onChange={({value}) => setDateGrouping(value)}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="period_from">{labels.qualification}</label>
                        <SelectBox items={qualifications.map(q => ({value: q.id, title: `[${q.blockKey}] ${q.blockName}`}))} value={parseInt(qualificationId)} includeEmpty={true} onChange={({value}) => setQualificationId(value)}/>
                    </div>
                </div>
            </div>
            <div className='checkbox p-0 ml-2'>
                <input type='checkbox' id='group-by-category' onChange={() => setGroupByCategory(!groupByCategory)} checked={groupByCategory}  />
                <label htmlFor='group-by-category'>{labels.groupByCategory}</label>
            </div>

            <h2 className="mt-5">Auswertung</h2>

            {
            <ResponsiveContainer height={400}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey='date' height={80} tick={{angle: -45, textAnchor: 'end'}} interval={0} />
                    <YAxis type="number" domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} tickFormatter={yTickFormatter}/>
                    <Tooltip formatter={tooltipFormatter}/>
                    { !groupByCategory &&
                        <Line name='Alle Kategorien' type="monotone" dataKey="rating" stroke="#000000" activeDot={{ r: 8 }} />
                    }
                    { groupByCategory && Object.keys(categories).map((idx) =>
                        <Line key={idx} type="monotone" dataKey={idx} name={categories[idx].name} stroke={categories[idx].color} activeDot={{ r: 8 }} />
                    )}
                    <Legend verticalAlign="top" height={36}/>
                </LineChart>
            </ResponsiveContainer>
            }

        </React.Fragment>
    )
}

EducationalInstitutionRatingChart.propTypes = {
    lecturerId: PropTypes.number.isRequired,
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    qualificationId: PropTypes.number,
    dateGrouping: PropTypes.string,
    groupByCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    labels: PropTypes.shape({
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        qualification: PropTypes.string.isRequired,
        dateGrouping: PropTypes.string.isRequired,
        groupByCategory: PropTypes.string.isRequired,
    })
}

EducationalInstitutionRatingChart.defaultProps = {
    dateGrouping: 'block_start',
    groupByCategory: true
}

export default EducationalInstitutionRatingChart