import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import axios from "axios";

export const TextGenerator = ({htmlId, promptIdentifier, attributeName, title}) => {
    const [isLoading, setIsLoading] = useState(false)

    const container = useRef()

    useEffect(() => {
        let domElement = document.getElementById(htmlId)
        domElement.remove
        container.current.prepend(domElement)
    }, [container])


    const generateText = async () => {

        try {
            setIsLoading(true)

            let textarea = document.getElementById(htmlId),
                params = {
                    [attributeName]: textarea.value,
                }

            const response = await axios.post(`/administration/prompts/${promptIdentifier}/evaluate`, params, {
                headers: {
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
            })

            if (response.status === 200) {
                textarea.value = response.data.response
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className='textarea-container' ref={container}>
                <div className='textarea-control'>
                    <button type='button' className='btn btn-icon btn-flat btn-rounded' title={title} onClick={generateText}>
                        <i className='fa-solid fa-sparkles'/>
                    </button>
                </div>
                {isLoading &&
                    <div className='loading'>
                        <i className='fa-light fa-2x fa-sync fa-spin'/>
                        <span>Text wird generiert...</span>
                    </div>
                }
            </div>
        </React.Fragment>
    );

}

TextGenerator.propTypes = {
    htmlId: PropTypes.string.isRequired,
    promptIdentifier: PropTypes.string.isRequired,
    attributeName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default TextGenerator;