import React from 'react';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';

class TableBlock extends React.PureComponent {

    render() {

        return (

            <div className='cdx-block'>
                <div className='tc-table__wrap'>
                    <table className='tc-table'>
                        <tbody>
                        {
                            this.props.data.content.map((row, rIdx) => {
                                return (
                                    <tr key={rIdx}>
                                        {
                                            row.map((column, cIdx) => {

                                                return (
                                                    <td key={cIdx} className='tc-table__cell'>
                                                        <Markup content={column} attributes={{className: 'tc-table__area'}} />
                                                    </td>
                                                )

                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>

        );

    }

}

TableBlock.propTypes = {
    data: PropTypes.object
};

class CodeBlock extends React.PureComponent {

    render() {

        return (
            <div className='ce-code cdx-block'>
                <pre>
                    <code>
                        {this.props.data.code}
                    </code>
                </pre>
            </div>
        )

    }

}

CodeBlock.propTypes = {
    data: PropTypes.object
};

class HeaderBlock extends React.PureComponent {

    render() {

            const LevelComponent = `h${this.props.data.level}`;

            return <LevelComponent className='ce-header'>{this.props.data.text}</LevelComponent>;

    }

}

HeaderBlock.propTypes = {
    data: PropTypes.object
};

class ListBlock extends React.PureComponent {

    render() {

        const classes = `cdx-block cdx-list cdx-list--${ this.props.data.style }`;

        return (

            <ul className={classes}>
                {
                    this.props.data.items.map((item, idx) => {

                        return (
                            <li key={idx} className='cdx-list__item'>
                                <Markup content={item} />
                            </li>
                        )

                    })
                }
            </ul>

        )

    }

}

ListBlock.propTypes = {
  data: PropTypes.object
};

class ChecklistBlock extends React.PureComponent {

    render() {

        return (

            <div className='cdx-block cdx-checklist'>
                {
                    this.props.data.items.map((item, idx) => {

                        const classes = `cdx-checklist__item${ (item.checked) ? ' cdx-checklist__item--checked' : '' }`;

                        return (
                            <div key={idx} className={classes}>
                                <span className='cdx-checklist__item-checkbox' />
                                <div className='cdx-checklist__item-text'>
                                    <Markup content={item.text} />
                                </div>
                            </div>
                        )

                    })
                }
            </div>

        );

    }

}

ChecklistBlock.propTypes = {
    data: PropTypes.object
};

class ParagraphBlock extends React.PureComponent {

    render() {

        return (
            <div className='ce-paragraph cdx-block'>
                <Markup content={this.props.data.text} />
            </div>
        )

    }

}

ParagraphBlock.propTypes = {
    data: PropTypes.object
};

class RichTextViewer extends React.PureComponent {

    components = {
        header: HeaderBlock,
        paragraph: ParagraphBlock,
        checklist: ChecklistBlock,
        list: ListBlock,
        code: CodeBlock,
        table: TableBlock
    };

    render() {

        if(!Object.prototype.hasOwnProperty.call(this.props.data, 'blocks')) {
            return null;
        }

        return (

            <div className='rte-viewer'>
                {

                    this.props.data.blocks.map((block, idx) => {

                        const BlockComponent = this.components[block.type];

                        if(!BlockComponent) {
                            return null;
                        }

                        return (
                            <div key={idx} className='ce-block'>
                                <div className='ce-block__content'>
                                    <BlockComponent data={ block.data } />
                                </div>
                            </div>
                        )

                    })

                }
            </div>

        )

    }

}

RichTextViewer.propTypes = {
    data: PropTypes.object
};

export default RichTextViewer;